import moment from "moment";
import toast from "react-hot-toast";
import ErrorToast from "./components/toast/ErrorToast";
import React from "react";
import { useGlobalContext } from "./context/global.context";
import { decryptAndDeobfuscate, finalDecryption } from "./utils/encryptionObfuscate";
// import { useGlobalContext } from "./context/useGlobalContext";

export const service = {
  setLocalStorageItem: (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error("Error setting localStorage item:", error);
    }
  },
  getLocalStorageItem: (key) => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch (error) {
      console.error("Error getting localStorage item:", error);
      return null;
    }
  },
  setSessionStorageItem: (key, value) => {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error("Error setting sessionStorage item:", error);
    }
  },
  getSessionStorageItem: (key) => {
    try {
      const item = sessionStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch (error) {
      console.error("Error getting sessionStorage item:", error);
      return null;
    }
  },
  setCookie: (name, value) => {
    const expires = moment()
      .add(process.env.REACT_APP_COOKIE_EXPIRES_IN, "hours")
      .toDate()
      .toUTCString();
    document.cookie = `${name}=${value}; expires=${expires}; path=/`;
  },
  getCookie: (name) => {
    const cookie = `; ${document.cookie}`;
    console.log("COOKIE : ", cookie);
    const parts = cookie.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  },

  // notifyApiError: (error) => {
  //   toast.custom((t) => (
  //     <ErrorToast
  //       id={t.id}
  //       message={error.response.data.message}
  //       error={error.response.data.error || error.response.statusText}
  //     />
  //   ));
  // },

  // NotifyApiError: (error) => {
  //   const { setShowErrorModal } = useGlobalContext(); // Access the context

  //   // Show the error modal
  //   setShowErrorModal(true);

  //   // Show the custom toast
  //   toast.custom((t) => (
  //     <ErrorToast
  //       id={t.id}
  //       message={error.response.data.message}
  //       error={error.response.data.error || error.response.statusText}
  //     />
  //   ));
  // },

  // notifyApiError: (error, setMessage, setError) => {
  //   console.log(error,'notifyApiErrornotifyApiErrornotifyApiError>>>>>>>>>>>>>>>>>>>>>>>>>>')

  //   let decryptdata= decryptAndDeobfuscate(error.response?.data)

  //     setMessage(decryptdata?.message || "An error occurred.")
  //     setError(JSON.stringify(decryptdata.error) || error.message)
  //     console.log(error)
  //     toast.custom((t) => (
  //       <ErrorToast
  //         id={t.id}
  //         message={decryptdata?.message || "An error occurred."}
  //         error={JSON.stringify(decryptdata?.error) || error.message}
  //       />
  //     ));
  //   },

  notifyApiError: async (error, setMessage, setError) => {
    let type = typeof error?.response?.data;
    let decryptdata = null;

  
  
    const contentType=error?.response?.headers?.get('content-type')
    if (type === "string") {
      if (contentType && !contentType?.includes('text/html')) {

      decryptdata = await finalDecryption(error?.response?.data);
    }
    } else if(type === "object") {
      decryptdata = error.response.data;
    }

    // console.log( decryptAndDeobfuscate(error.response.data),'>>>>>>>>>',decryptdata.error)

    toast.custom((t) => (
      <ErrorToast
        id={t.id}
        message={decryptdata?.message || "An error occurred."}
        error={JSON?.stringify(decryptdata?.error) || error.message}
      />
    ));
  },
};
