import React, { useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import { GlobalContext } from "./context/global.context";

export default function AppLayout() {
  const [sidebarOpened, setSidebarOpened] = React.useState(false);
  const location = useLocation();

  const { isQrPolicy } = useContext(GlobalContext)
  const showSidebar = () => {
    const currentPath = location.pathname;
   
    // Check other paths where the sidebar should not be shown
    if (
      currentPath === "/upload-dealer-documents" ||
      currentPath === "/dealer-registration" ||
      currentPath === "/dealer-registration-status" ||
      currentPath.startsWith("/service/generate-policy/") ||
      currentPath.startsWith("/sales/generate-policy/") ||
      currentPath.startsWith("/payment-status/") || 
      isQrPolicy && (currentPath.includes("purchase-status") || currentPath.includes("generate-policy"))
    ) {
      return false;
    } else {
      return true;
    }
    
  };

  const toggleSidebar = () => {
    setSidebarOpened(!sidebarOpened);
  };

  return (
    <>
      <Header toggle={toggleSidebar} />
      <div className="flex">
        {showSidebar() && <Sidebar opened={sidebarOpened} toggleSidebar={toggleSidebar} />}
        <Outlet />
      </div>
    </>
  );
}
