import React, { useState, useEffect, useContext, useRef } from "react";
import coverImage from "../assets/img/coverImage.png";
import Input from "../components/ui/Input";
import Button from "../components/ui/Button";
import CurrencyFormat from "react-currency-format";
import toast, { Toaster } from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "../components/ui/Select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { apiService } from "../api.service";
import Radio from "../components/ui/Radio";
import { service } from "../service";
import * as helper from "../helper.js";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { GlobalContext, useGlobalContext } from "../context/global.context";
import moment from "moment";

export const GeneratePolicy = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { setMessage, setError } = useGlobalContext();
  let dealerId;
  if (params.dealerId) {
    dealerId = helper.decrypt(params.dealerId);
  } else {
    dealerId = service.getSessionStorageItem("dealerInfo")?.dealerId;
  }
  // const dealerId =
  //   params.dealerId || service.getSessionStorageItem("dealerInfo")?.dealerId;
  // const isQrPolicy = params.dealerId !== undefined;
  const { isQrPolicy, setIsQrPolicy } = useContext(GlobalContext);
  // const isQrPolicy = true;
  // setIsQrPolicy(true)
  // console.log(dealerId);
  const [planData, setPlanData] = useState([]);
  const [genderOptions, setGenderOptions] = useState([]);
  const [makeOptions, setMakesOptions] = useState([]);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [policyHolderTypeOptions, setPolicyHolderTypeOptions] = useState([]);
  const [salutationOptions, setSalutationOptions] = useState([]);
  const [showNominee, setShowNominee] = useState(false);
  const [relationshipOptions, setRelationshipOptions] = useState([]);
  const [seriesType, setSeriesType] = useState("1");
  const [placeholder, setPlaceholder] = useState("MH-01-AB-6831");
  const isOpenLink = params.dealerId ? true : false;
  const [showAppointee, setShowAppointee] = useState(false);
  const [isSearchSubmitted, setIsSearchSubmitted] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState();
  const [modelId, setModelId] = useState();
  const [policyHolderTypeId, setPolicyHolderTypeId] = useState();
  const [bhYearOptions, setBhYearOptions] = useState();
  const normalRegNoRef = useRef([]);

  const columnHelper = createColumnHelper();

  let tvsTypeId;
  // let tvsTypeId = null;

  if (location.pathname.includes("/sales/generate-policy")) {
    tvsTypeId = 1;
  } else if (location.pathname.includes("service/generate-policy")) {
    tvsTypeId = 2;
  } else {
    tvsTypeId = null;
  }

  const columns = [
    columnHelper.display({
      id: "actions",
      cell: (props) => (
        <input
          type="radio"
          name="id"
          value={props.row.original.id}
          checked={
            generatePolicyForm.getFieldProps("planId").value ===
            props.row.original.id
          }
          onChange={() => onChangePlan(props.row.original.id)}
        />
      ),
    }),
    columnHelper.accessor("name", {
      header: "Plan Name",
    }),
    columnHelper.accessor("rsaTenure", {
      header: "Rsa Tenure",
    }),
    columnHelper.accessor("rsaCoveredKms", {
      header: "Rsa Covered Kms",
    }),
    columnHelper.accessor("policyPrice", {
      header: "Price",
      cell: (data) => {
        return (
          <span>
            {data.getValue() ? (
              <CurrencyFormat
                value={data.getValue()}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
              />
            ) : (
              "-"
            )}
          </span>
        );
      },
    }),
  ];

  const requiredWhenSeriesType = (seriesTypeValue) => {
    Yup.string().when("seriesType", (value, schema) => {
      return value === String(seriesTypeValue)
        ? schema.required("Required")
        : schema.notRequired();
    });
  };
  const table = useReactTable({
    data: planData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  const searchValidationSchema = Yup.object().shape({
    searchValue: Yup.string().required("Required"),
    searchField: Yup.string().required("Required"),
  });
  // const PREFIX = "MD";
  const PREFIXENG = "";
  const rsaCareFormValidationSchema = Yup.object().shape({
    planId: Yup.number().required("Required"),
    engineNumber: Yup.string()
      .matches(/^[A-Z0-9]{10,17}$/, "Invalid")
      .required("Required"),
    chassisNumber: Yup.string()
      .test(
        "starts-with-md",
        "Chassis number must start with MD",
        (value) => !value || value.startsWith("MD")
      )
      .matches(/^MD[A-Z0-9]{15}$/, "Invalid")
      .required("Required"),
    vehicleType: Yup.string().required("Required"),
    manufacturer: Yup.string().required("Required"),
    model: Yup.string().required("Required"),
    seriesType: Yup.string().required("Please select a series type"),
    reg1: requiredWhenSeriesType(1),
    reg2: requiredWhenSeriesType(1),
    reg3: requiredWhenSeriesType(1),
    reg4: requiredWhenSeriesType(1),

    bhReg1: requiredWhenSeriesType(2),
    bhReg2: requiredWhenSeriesType(2),
    bhReg3: requiredWhenSeriesType(2),
    bhReg4: requiredWhenSeriesType(2),
    policyHolderType: Yup.string().required("Required"),
    salutation: Yup.string().required("Required"),
    firstName: Yup.string()
      .required("Required")
      .min(2, "Too Short!")
      .required("Required")
      .matches(/^[a-zA-Z]+$/, "First name should contain only letters"),
    middleName: Yup.string()
      .min(2, "Too Short!")
      .matches(/^[a-zA-Z]+$/, "Middle name should contain only letters"),
    lastName: Yup.string()
      .required("Required")
      .min(2, "Too Short!")
      .required("Required")
      .matches(/^[a-zA-Z]+$/, "Last name should contain only letters"),
    email: Yup.string()
      .email("Invalid email")
      .required("Required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email format"
      ),
    mobileNumber: Yup.string()
      .typeError("Mobile number must be a valid number")
      .required("Mobile number is required")
      .matches(/^[6-9]\d{9}$/, "Mobile number must be a valid 10-digit number"),
    gender: Yup.string().required("Required"),
    dateOfBirth: Yup.string().required("Required"),
    addressLine1: Yup.string()
      // .strict(true)
      .required("Address Line 1 is required")
      .matches(
        /^(?! )[^\s].*(?<! )$/,
        "Address Line 1 cannot start or end with spaces"
      ),
    // .min(1, "Address Line 1 must be at least 5 characters")
    // .max(100, "Address Line 1 can't be longer than 100 characters"),
    // .matches(/^(?! )[A-Za-z0-9\s]+(?! )$/, "Address Line 1 must not start or end with spaces and must contain at least one non-space character"),

    addressLine2: Yup.string()
      // .strict(true)
      .required("Address Line 2 is required")
      .matches(
        /^(?! )[^\s].*(?<! )$/,
        "Address Line 2 cannot start or end with spaces"
      ),
    pincode: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    nomineeFullName: Yup.string()
      .matches(
        /^[a-zA-Z]+(\s[a-zA-Z]+)*$/,
        "Nominee fullname should only contain letters"
      )
      .min(2, "Nominee fullname name must be at least 2 characters")
      .max(50, "Nominee fullname name must be at most 50 characters"),

    nomineeAge: Yup.number()
      .typeError("Nominee Age must be a number")
      .min(1, "Nominee Age cannot be less than 1")
      .max(120, "Nominee Age cannot be more than 120"),
    nomineeRelationship: Yup.string(),

    appointeeFullName: Yup.string()
      .matches(
        /^[a-zA-Z]+(\s[a-zA-Z]+)*$/,
        "Appointee fullname should only contain letters"
      )
      .min(2, "Appointee fullname name must be at least 2 characters")
      .max(50, "Appointee fullname name must be at most 50 characters"),

    appointeeAge: Yup.number()
      .typeError("Appointee Age must be a number")
      .min(18, "Appointee Age cannot be less than 18")
      .max(120, "Appointee Age cannot be more than 120"),
    appointeeRelationship: Yup.string(),
  });

  const searchForm = useFormik({
    initialValues: {
      searchField: "engine_no",
      searchValue: "",
    },
    onSubmit: async (values) => {
      // navigate('/purchase-status/MU0000070005')
      // return
      generatePolicyForm.resetForm();
      console.log(values);

      const payload = {
        engineNumber: values.searchValue.toUpperCase(),
        tvsTypeId,
      };
      // const loadingToast = toast.loading("Fetching policy data...");
      await apiService
        .search(payload)
        .then((data) => {
          const result = data.data.data;
          console.log(result);

          const searchResult = {
            engineNumber: result.engineNumber,
            chassisNumber: result.chassisNumber,
            vehicleType: result.vehicleType,
            firstName: result.firstName,
            manufacturer: result.manufacturer,
            model: result.model,
            seriesType: result.registrationSeriesTypeId?.toString(),
            policyHolderType: result.policyHolderType,
            salutation: result.salutation,
            middleName: result.middleName,
            lastName: result.lastName,
            email: result.email,
            mobileNumber: result.mobileNumber,
            gender: result.gender,
            dateOfBirth: result.dateOfBirth,
            addressLine1: result.addressLine1,
            addressLine2: result.addressLine2,
            pincode: result.pincode,
            city: result.city,
            state: result.state,
            nomineeFullName: result.nomineeFullName,
            nomineeAge: result.nomineeAge,
            nomineeRelationship: result.nomineeRelationship,
            appointeeFullName: result.appointeeFullName,
            appointeeAge: result.appointeeAge,
            appointeeRelationship: result.appointeeRelationship,
          };

          result?.registrationNumber?.split("-").map((it, index) => {
            console.log(it);
            let reg;
            switch (result.registrationSeriesTypeId) {
              case 1:
                reg = `reg${index + 1}`;
                searchResult[reg] = it;
                break;
              case 2:
                reg = `bhReg${index + 1}`;
                searchResult[reg] = it;
                generatePolicyForm.handleChange({
                  target: {
                    name: reg,
                    value: it,
                  },
                });
                break;
              default:
                toast.error("Invalid registration series type");
            }
          });

          generatePolicyForm.setValues(searchResult);

          getPlans(result.model, result.policyHolderType);
          // Set isSearchSubmitted to true to disable fields
          setIsSearchSubmitted(true);
          setSeriesType(result.registrationSeriesTypeId?.toString());
        })
        .catch((error) => {
          console.error(error);
          // toast.error(error.response.data.message);
          service.notifyApiError(error, setMessage, setError);
        });
    },
  });
  const generatePolicyForm = useFormik({
    initialValues: {
      productType: "",
      planId: "",
      engineNumber: "",
      chassisNumber: "",
      vehicleType: "",
      manufacturer: "",
      model: "",
      seriesType: "1",
      reg1: "",
      reg2: "",
      reg3: "",
      reg4: "",
      bhReg1: "",
      bhReg2: "BH",
      bhReg3: "",
      bhReg4: "",
      policyHolderType: "",
      salutation: "",
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      mobileNumber: "",
      gender: "",
      dateOfBirth: "",
      addressLine1: "",
      addressLine2: "",
      pincode: "",
      city: "",
      state: "",
      nomineeFullName: "",
      nomineeAge: "",
      nomineeRelationship: "",
      appointeeFullName: "",
      appointeeAge: "",
      appointeeRelationship: "",
      planId: "",
    },

    validationSchema: rsaCareFormValidationSchema,
    validateOnBlur: true,
    onSubmit: async (values, { setSubmitting }) => {
      console.log(values);
      console.log(generatePolicyForm.errors);

      if (!generatePolicyForm.isValid) {
        toast.error("Form is invalid");
      }

      // alert(8);
      let registrationNumber;
      if (values.seriesType == "1") {
        registrationNumber = `${values.reg1}-${values.reg2}-${values.reg3}-${values.reg4}`;
      } else {
        registrationNumber = `${values.bhReg1}-${values.bhReg2}-${values.bhReg3}-${values.bhReg4}`;
      }
      let payload = {
        dealerId: dealerId,
        tvsTypeId: tvsTypeId,
        isQrPolicy: isQrPolicy,
        planId: values.planId,
        productType: values.productType,
        vehicle: {
          engineNumber: values.engineNumber,
          chassisNumber: values.chassisNumber,
          vehicleType: values.vehicleType,
          manufacturer: values.manufacturer,
          model: values.model,
          registrationNumber: registrationNumber,
        },
        owner: {
          salutation: values.salutation,
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
          email: values.email,
          mobileNumber: values.mobileNumber,
          gender: values.gender,
          dateOfBirth: values.dateOfBirth,
          addressLine1: values.addressLine1,
          addressLine2: values.addressLine2,
          pincode: values.pincode,
          city: values.city,
          state: values.state,
          policyHolderType: values.policyHolderType,
        },
      };

      console.log("sdfs  :P ", values.planId === 30);
      const nomineeAge = parseInt(values.nomineeAge, 10);
      let showNominee = planData.find(
        (plan) => plan.id === values.planId
      )?.showNominee;
      // console.log(showAppointee);
      console.log("show");
      console.log(showNominee);
      if (showNominee) {
        payload = {
          ...payload,
          nominee: {
            nomineeFullName: values.nomineeFullName,
            age: values.nomineeAge,
            relationship: values.nomineeRelationship,
          },
        };
      }

      const showAppointee = showNominee && nomineeAge < 18;
      console.log("showAPpointe", showAppointee);
      if (showAppointee) {
        payload = {
          ...payload,
          appointee: {
            appointeeFullName: values.appointeeFullName,
            age: values.appointeeAge,
            relationship: values.appointeeRelationship,
          },
        };
      }

      console.log(service.getSessionStorageItem("dealerInfo"));
      console.log(payload);
      const loadingToast = toast.loading("Generating policy...");
      await apiService
        .generatePolicy(payload)
        .then((res) => {
          console.log(res);
          if (res.data.isPaytmPayment) {
            invokePaytmCheckoutPage(res.data);
          }
          console.log(res.data.data.policyNumber);

          // navigate(`/purchase-status/${res.data.data.policyNumber}`);
          if (tvsTypeId === 1) {
            // Sales path
            navigate(`/sales/purchase-status/${res.data.data.policyNumber}`);
          } else if (tvsTypeId === 2) {
            // Service path
            navigate(`/service/purchase-status/${res.data.data.policyNumber}`);
          }

          toast.success(res.data.message);
          // navigate("/bank-statement");
        })
        .catch((error) => {
          console.log(error.response.data.message);
          // toast.error(error.response.data.message);
          service.notifyApiError(error, setMessage, setError);
        })
        .finally(() => {
          toast.dismiss(loadingToast);
          setSubmitting(false);
        });
    },
  });

  // generatePolicyForm.resetForm();
  // const onNomineeAgeChange = (event) => {
  //   console.log(event);

  //   const nomineeAge = event.target.value
  //   console.log(nomineeAge);

  // }
  const onEngineNoChange = (event) => {
    const value = event.target.value;
    // console.log("Input value: ", value); // Debugging line

    if (value.startsWith(PREFIXENG)) {
      generatePolicyForm.setFieldValue("engineNumber", value.toUpperCase());
    } else {
      generatePolicyForm.setFieldValue(
        "engineNumber",
        PREFIXENG.toUpperCase() + value.toUpperCase()
      );
    }
  };

  const showErrorToast = () => {
    if (!generatePolicyForm.isValid) {
      toast.error("Form is invalid");
    }
  };

  const onChassisNoChange = (event) => {
    const value = event.target.value.toUpperCase(); // Convert to uppercase
    generatePolicyForm.setFieldValue("chassisNumber", value); // Set the value in uppercase
  };

  const onNomineeAgeChange = (event) => {
    const value = event.target.value.replace(/^\D/g, "");
    generatePolicyForm.setFieldValue("nomineeAge", value); // Set the value in uppercase
  };

  const onNomineeAgeBlur = (event) => {
    const nomineeAge = event.target.value;
    generatePolicyForm.setFieldValue("nomineeAge", nomineeAge);

    if (nomineeAge && parseInt(nomineeAge, 10) < 18) {
      setShowAppointee(true);
    } else {
      setShowAppointee(false);
    }
    console.log(nomineeAge && parseInt(nomineeAge, 10) < 18);
  };

  const onAppointeeAgeChange = (event) => {
    const appointeeAge = event.target.value.replace(/^\D/g, "");
    generatePolicyForm.setFieldValue("appointeeAge", appointeeAge);
  };

  // const transformRegistrationNumber = (value, seriesType) => {
  //   if (!value) return value; // If the field is empty, return the value as is
  //   value = value.toUpperCase().replace(/[^\w\s]/gi, ""); // Convert to uppercase and remove non-alphanumeric characters

  //   if (seriesType === "bh") {
  //     // BH Series format
  //     return value.replace(/^(\w{2})(\w{2})(\d{4})(\w{2})$/, "$1-$2-$3-$4");
  //   } else {
  //     // Normal Series format
  //     return value.replace(/^(\w{2})(\d{2})(\w{2})(\d{4})$/, "$1-$2-$3-$4");
  //   }
  // };
  const transformRegistrationNumber = (value, seriesType) => {
    if (!value) return value; // If the field is empty, return the value as is
    value = value.toUpperCase().replace(/[^\w\s]/gi, ""); // Clean input

    if (seriesType.toLowerCase() === "2") {
      // For BH series
      // Ensure proper length and format
      return value.replace(/^(\w{2})(BH)(\d{4})(\w{2})$/, "$1-$2-$3-$4");
    } else {
      // Normal Series format
      return value.replace(/^(\w{2})(\d{2})(\w{2})(\d{4})$/, "$1-$2-$3-$4");
    }
  };

  const formatRegistrationNumber = (seriesType, value) => {
    console.log(seriesType);

    // Clean up the input by removing non-alphanumeric characters
    let cleaned = value.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();

    if (seriesType === "1") {
      // Limit length to 12 characters for safety
      cleaned = cleaned.substring(0, 12);

      // Define the format as MH-01-SB-9087
      let formatted = "";
      // Add the first two letters
      if (cleaned.length > 0) formatted += cleaned.slice(0, 2); // MH
      // Add dash and the next two digits
      if (cleaned.length > 2) formatted += "-" + cleaned.slice(2, 4); // 01
      // Add dash and the next two letters
      if (cleaned.length > 4) formatted += "-" + cleaned.slice(4, 6); // SB
      // Add dash and the last four digits
      if (cleaned.length > 6) formatted += "-" + cleaned.slice(6, 10); // 9087

      return formatted;
    } else {
      // For other series types, apply a different format if needed
      // Cleaned value should be formatted as XX-HH-HHHH-HH
      let formatted = cleaned;

      if (formatted.length > 2) {
        formatted = formatted.slice(0, 2) + "-" + formatted.slice(2);
      }
      if (formatted.length > 4) {
        formatted = formatted.slice(0, 5) + "-" + formatted.slice(5);
      }
      if (formatted.length > 9) {
        formatted = formatted.slice(0, 10) + "-" + formatted.slice(10);
      }

      // Return up to 13 characters in case of longer inputs
      return formatted.slice(0, 13);
    }
  };

  function isDate(val) {
    // Cross realm comptatible
    return Object.prototype.toString.call(val) === "[object Date]";
  }

  function isObj(val) {
    return typeof val === "object";
  }

  function stringifyValue(val) {
    if (isObj(val) && !isDate(val)) {
      return JSON.stringify(val);
    } else {
      return val;
    }
  }

  function buildForm({ action, params }) {
    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", action);

    Object.keys(params).forEach((key) => {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", key);
      input.setAttribute("value", stringifyValue(params[key]));
      form.appendChild(input);
    });

    return form;
  }

  function post(details) {
    const form = buildForm(details);
    document.body.appendChild(form);
    form.submit();
    form.remove();
  }

  const invokePaytmCheckoutPage = (data) => {
    let information = {
      action: "https://securegw.paytm.in/order/process",
      params: data,
    };
    console.log(information);
    // return
    post(information);
  };

  // const handleSeriesTypeChange = (e) => {
  //   const selectedType = e.target.value;
  //   console.log("ser : ", e);
  //   generatePolicyForm.setFieldValue("seriesType", selectedType);
  //   if (selectedType === "2") {
  //     setPlaceholder("21-BH-1234-AB");
  //   } else {
  //     setPlaceholder("MH-01-AB-6831");
  //   }
  // };

  //  const handleSeriesTypeChange = (e) => {
  //     const selectedType = e.target.value;
  //     console.log("Selected series type: ", selectedType);

  //     // Clear the registration number field
  //     generatePolicyForm.setFieldValue("registrationNumber", "");

  //     // Clear any existing error for the registration number
  //     generatePolicyForm.setFieldError("registrationNumber", undefined);

  //     // Set the appropriate placeholder based on selected type
  //     if (selectedType === "2") {
  //         setPlaceholder("21-BH-1234-AB");
  //     } else {
  //         setPlaceholder("MH-01-AB-6831");
  //     }

  //     // Set the series type in the form
  //     generatePolicyForm.setFieldValue("seriesType", selectedType);
  // };

  const generateBhYearOptions = () => {
    const endYear = parseInt(moment().format("YY").toString());
    const options = [];
    for (let index = 21; index <= endYear; index++) {
      options.push({ id: index + 1, label: String(index), value: index });
    }
    setBhYearOptions(options);
  };

  const handleSeriesTypeChange = (event) => {
    alert(event);
    setSeriesType(event.target.value);
    generatePolicyForm.setFieldValue("seriesType", event.target.value); // Update Formik state
  };

  const onChangePlan = (planId) => {
    generatePolicyForm.setFieldValue("planId", planId);
    const selectedPlan = planData.find((plan) => plan.id === planId);
    setSelectedPlan(selectedPlan);
    setShowNominee(selectedPlan.showNominee);
  };

  const showPolicyHolderTypeChangeToast = () => {
    toast(
      (t) => (
        <span>
          You have selected PA plan. Please change policy holder type to
          "Individual"
          <button
            onClick={() => {
              generatePolicyForm.setFieldValue("policyHolderType", 1); // 1 - Individual
              toast.dismiss(t.id); // Dismiss the current toast
            }}
            style={{
              marginLeft: "10px",
              padding: "4px 8px",
              backgroundColor: "#4CAF50",
              color: "white",
              border: "none",
              borderRadius: "3px",
            }}
          >
            Change
          </button>
        </span>
      ),
      { duration: Infinity }
    );
  };
  const calculateMinDate = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 60);
    return date.toISOString().split("T")[0];
  };

  const calculateMaxDate = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    return date.toISOString().split("T")[0];
  };
  const getPlans = async (modelId, policyHolderTypeId) => {
    // const loadingToast = toast.loading("Loading plan Data");
    try {
      const payload = {
        dealerId: dealerId,
        dmsIcId: 5,
        tvsTypeId,
        modelId,
        policyHolderTypeId,
      };
      const plans = await apiService.getPlans(payload);
      const plan = plans.data.data.find(
        (plan) => plan.id === generatePolicyForm.getFieldProps("planId").value
      );
      setShowNominee(plan ? plan.showNominee : false);
      setPlanData(plans.data.data);
    } catch (error) {
      setPlanData([]);

      console.error("Error fetching plans : ", error);
    } finally {
      // toast.dismiss(loadingToast);
    }
  };

  const onSelectVehicleType = (value) => {
    generatePolicyForm.setFieldValue("vehicleType", value);
  };
  const onSelectManufacturerType = (value) => {
    generatePolicyForm.setFieldValue("manufacturer", value);
  };
  const onSelectModel = (id) => {
    setModelId(id);
    if (policyHolderTypeId && selectedTabIndex === 0) {
      // General
      getPlans(modelId, policyHolderTypeId);
    } else {
      // Rider
      getPlans(0, policyHolderTypeId);
    }
    generatePolicyForm.setFieldValue("model", id);
    resetSelectedPlan();
  };
  const onSelectSalutation = (value) => {
    generatePolicyForm.setFieldValue("salutation", value);
  };
  const onSelectGender = (value) => {
    generatePolicyForm.setFieldValue("gender", value);
  };
  const onSelectPolicyHolderType = (policyHolderTypeId) => {
    setPolicyHolderTypeId(parseInt(policyHolderTypeId));

    if (selectedTabIndex === 0) {
      // General
      getPlans(modelId, policyHolderTypeId);
    } else {
      // Rider
      getPlans(0, policyHolderTypeId);
    }
    generatePolicyForm.setFieldValue(
      "policyHolderType",
      parseInt(policyHolderTypeId)
    );
    resetSelectedPlan();
  };
  const onSelectAppointeeRelationshipType = (value) => {
    generatePolicyForm.setFieldValue("appointeeRelationship", value);
  };

  const onSelectNomineeRelationshipType = (value) => {
    generatePolicyForm.setFieldValue("nomineeRelationship", value);
  };

  const onChangeSearch = async (event) => {
    const value = event.target.value.toUpperCase();
    searchForm.setFieldValue("searchValue", value);
  };

  const resetSelectedPlan = () => {
    generatePolicyForm.setFieldValue("planId", null);
    setSelectedPlan(null);
  };

  const onPincodeChange = (e) => {
    const { value } = e.target;
    if (!isNaN(value) && value.length <= 6) {
      generatePolicyForm.handleChange(e);
      if (value.length === 6) {
        // alert(999)
        // const loadingToast = toast.loading("Loading data...");
        apiService
          .location(value)
          .then((response) => {
            generatePolicyForm.setFieldValue("city", response.data.data.city);
            generatePolicyForm.setFieldValue("state", response.data.data.state);
          })
          .catch((error) => {
            generatePolicyForm.setFieldValue("city", "");
            generatePolicyForm.setFieldValue("state", "");
            service.notifyApiError(error, setMessage, setError);
          });
      } else {
        generatePolicyForm.setFieldValue("city", "");
        generatePolicyForm.setFieldValue("state", "");
      }
    }
  };

  const invalidFields = Object.keys(generatePolicyForm.errors).filter(
    (field) => generatePolicyForm.touched[field]
  );

  const getGender = async () => {
    // const loadingToast = toast.loading("Loading gender data");
    try {
      await apiService.gender().then((res) => {
        setGenderOptions(res.data.data);
      });
    } catch (error) {
      console.error("Error fetching genders:", error);
      service.notifyApiError(error, setMessage, setError);
    } finally {
      // toast.dismiss(loadingToast);
    }
  };
  const getRelationship = async () => {
    // const loadingToast = toast.loading("Loading data...");
    try {
      await apiService.relationships().then((res) => {
        setRelationshipOptions(res.data.data);
      });
    } catch (error) {
      console.error("Error fetching relationships:", error);
      service.notifyApiError(error, setMessage, setError);
    } finally {
      // toast.dismiss(loadingToast);
    }
  };
  const getMakes = async () => {
    // const loadingToast = toast.loading("Loading data...");
    try {
      await apiService.getMakes().then((res) => {
        setMakesOptions(res.data.data);
      });
    } catch (error) {
      console.error("Error fetching getMakes:", error);
      service.notifyApiError(error, setMessage, setError);
    } finally {
      // toast.dismiss(loadingToast);
    }
  };
  const vehicleTypes = async () => {
    // const loadingToast = toast.loading("Loading vehicle data...");
    try {
      const payload = {
        tvsTypeId,
        isRider: modelId === 0 ? true : false,
      };
      await apiService.vehicleTypes(payload).then((res) => {
        setVehicleTypeOptions(res.data.data);
      });
    } catch (error) {
      console.error("Error fetching vehicleTypes:", error);
      service.notifyApiError(error, setMessage, setError);
    } finally {
      // toast.dismiss(loadingToast);
    }
  };

  const getPolicyHolderTypes = async () => {
    // const loadingToast = toast.loading("Loading policy holder type data...");
    try {
      await apiService.getPolicyHolderTypes().then((res) => {
        setPolicyHolderTypeOptions(res.data.data);
      });
    } catch (error) {
      console.error("Error fetching Policy Holder Types:", error);
      service.notifyApiError(error, setMessage, setError);
    } finally {
      // toast.dismiss(loadingToast);
    }
  };

  const handleNormalRegNoChange = (e, index) => {
    const { value } = e.target;
    const sanitizedValue = value.slice(0, 1); // Ensure only one character
    if (sanitizedValue && index < normalRegNoRef.current.length - 1) {
      normalRegNoRef.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index, type) => {
    if (e.key === "Backspace") {
      if (!e.target.value && index > 0) {
        normalRegNoRef.current[index - 1].focus();
      }
    }
  };

  const getSalutationOptions = async () => {
    // const loadingToast = toast.loading("Loading data...");
    try {
      await apiService.getSalutationOptions().then((res) => {
        setSalutationOptions(res.data.data);
      });
    } catch (error) {
      console.error("Error fetching Salutations :", error);
      service.notifyApiError(error, setMessage, setError);
    } finally {
      // toast.dismiss(loadingToast);
    }
  };
  const handleInputChange = (event, name) => {
    let value;
    switch (name) {
      case "reg1":
        value = event.target.value.replace(/[^A-Za-z]/g, "").toUpperCase();
        break;
      case "reg2":
        value = event.target.value.replace(/[^0-9]/g, "");
        break;
      case "reg3":
        value = event.target.value.replace(/[^A-Za-z]/g, "").toUpperCase();
        break;
      case "reg4":
        value = event.target.value.replace(/[^0-9]/g, "");
        break;
      default:
        toast.error("Invalid registration field");
    }
    generatePolicyForm.setFieldValue(name, value);
  };

  const getRegistrationNumberErrors = () => {
    if (
      generatePolicyForm.touched?.reg1 &&
      generatePolicyForm.touched?.reg2 &&
      generatePolicyForm.touched?.reg3 &&
      generatePolicyForm.touched?.reg4
    ) {
      if (generatePolicyForm.errors?.reg1) {
        return generatePolicyForm.errors?.reg1;
      }
      if (generatePolicyForm.errors?.reg2) {
        return generatePolicyForm.errors.reg2;
      }
      if (generatePolicyForm.errors?.reg3) {
        return generatePolicyForm.errors.reg3;
      }
      if (generatePolicyForm.errors?.reg4) {
        return generatePolicyForm.errors.reg4;
      }
    }

    // BH
    if (
      generatePolicyForm.touched?.bhReg1 &&
      generatePolicyForm.touched?.bhReg2 &&
      generatePolicyForm.touched?.bhReg3 &&
      generatePolicyForm.touched?.bhReg4
    ) {
      if (generatePolicyForm.errors?.bhReg1) {
        return generatePolicyForm.errors?.bhReg1;
      }
      if (generatePolicyForm.errors?.bhReg2) {
        return generatePolicyForm.errors.bhReg2;
      }
      if (generatePolicyForm.errors?.bhReg3) {
        return generatePolicyForm.errors.bhReg3;
      }
      if (generatePolicyForm.errors?.bhReg4) {
        return generatePolicyForm.errors.bhReg4;
      }
    }
  };
  const handleInputChangeBH = (event, name) => {
    let value;
    switch (name) {
      case "bhReg1":
        // value = event.target.value.replace(/[^0-9]/g, "");
        break;
      case "bhReg2":
        value = event.target.value.replace(/[^A-Za-z]/g, "").toUpperCase();
        break;
      case "bhReg3":
        value = event.target.value.replace(/[^0-9]/g, "");
        break;
      case "bhReg4":
        value = event.target.value.replace(/[^A-Za-z]/g, "").toUpperCase();
        break;
      default:
        toast.error("Invalid registration field");
        return;
    }
    generatePolicyForm.setFieldValue(name, value);
  };

  const handleTabSelect = (index) => {
    generatePolicyForm.resetForm();
    setPlanData([]);
    setSelectedTabIndex(index);
    if (index === 1) {
      getPlans(0, parseInt(policyHolderTypeId));
    }
  };

  const getModels = async () => {
    // const loadingToast = toast.loading("Loading modal data...");
    try {
      const payload = {
        makeId: 11,
        tvsTypeId,
      };
      await apiService.getModels(payload).then((res) => {
        setModelOptions(res.data.data);
      });
    } catch (error) {
      service.notifyApiError(error, setMessage, setError);

      console.error("Error fetching models:", error);
      throw error;
    } finally {
      // toast.dismiss(loadingToast);
    }
  };

  useEffect(() => {
    if (dealerId && !service.getSessionStorageItem("isUpdateQRReportCalled")) {
      const payload = {
        dealerId: parseInt(dealerId),
        field: "total_scans",
      };
      apiService.updateQRReport(payload).then(() => {
        service.setSessionStorageItem("isUpdateQRReportCalled", true);
      });
    }
  }, []);

  useEffect(() => {
    getGender();
    getMakes();
    getModels();
    vehicleTypes();
    getSalutationOptions();
    getPolicyHolderTypes();
    getRelationship();
  }, [tvsTypeId]);

  useEffect(() => {
    async function fetchData() {
      try {
        // const modelsData = await getModels();
        // console.log(modelsData,'ENGINENUMBER12ENGINENUMBER12')
        const formattedOptions = modelOptions.map((model) => ({
          id: model.id,
          label: model.name,
          value: String(model.id),
        }));
        setModelOptions(formattedOptions);
      } catch (error) {
        console.error("Error fetching models:", error);
      }
    }

    fetchData();
  }, []);
  useEffect(() => {
    // alert(params.dealerId !== undefined)
    setIsQrPolicy(params.dealerId !== undefined); // Set isQrPolicy to true when the component mounts
    generateBhYearOptions();
  }, []);
  // useEffect(() => {
  //   // Set isQrPolicy to true only if dealerId is present and it hasn't been set already
  //   if (dealerId && !isQrPolicy) {
  //     setIsQrPolicy(true);
  //   }
  // }, [dealerId, isQrPolicy, setIsQrPolicy]);
  // useEffect(() => {
  //   const fetchPlans = async () => {
  //     await getPlans(generatePolicyForm.getFieldProps("model").value);
  //   };

  //   fetchPlans();
  // }, [generatePolicyForm.getFieldProps("model").value]);

  return (
    <>
      <div className="flex flex-col w-full items-center">
        <div className="sticky -z-10 top-12 w-full">
          <img
            src={coverImage}
            className=" w-full h-36 object-cover"
            alt="cover_image"
          />
        </div>
        {/* <form onSubmit={formik.handleSubmit}> */}
        <div className="w-11/12 md:w-11/12 min-w-fit bg-white -mt-28 mx-2 md:-mt-20 border border-neutral-light rounded">
          <div className="bg-base-white px-6 py-4 border-b border-neutral-light rounded-t">
            <h3 className="text-xl font-semibold">
              Road Side Assistance (RSA) -
              {props.tvsType == "service" ? " Service" : " Sales"}
            </h3>
            <p className="text-neutral-darker mt-1 text-sm ">
              Whenever a vehicle breaks down, Roadside Assistance is available
              around-the-clock as an emergency service.{" "}
            </p>
          </div>
          <div className="px-6 pb-8">
            <div className="flex text-center justify-center mt-4">
              <div className="w-80">
                {/* <p className="text-sm mb-2">
                  Use the Engine Number of the vehicle to search Customer’s. If
                  its listed in our Database.
                </p> */}
                <form onSubmit={searchForm.handleSubmit}>
                  <div className="flex flex-col items-center md:flex-row md:items-center md:justify-center">
                    {/* <div className="flex flex-col">
                      {searchOptions.length > 0 && (
                        <Select
                          {...searchForm.getFieldProps("searchField")}
                          formik={searchForm}
                          id="searchField"
                          name="searchField"
                          onSelect={onSelectSearchType}
                          options={searchOptions}
                          className="mr-4 flex-col"
                        />
                      )}
                    </div> */}
                    <div className="flex flex-col ">
                      <Input
                        {...searchForm.getFieldProps("searchValue")}
                        formik={searchForm}
                        id="searchValue"
                        name="searchValue"
                        type="text"
                        placeholder="Search Engine Number..."
                        className="w-60 md:w-80"
                        onInputChange={onChangeSearch}
                      />
                    </div>
                    <div className="w-36">
                      <Button
                        type="submit"
                        label="Search"
                        variant="secondary"
                        className="w-36 mt-2 md:mt-0"
                        // onClick={searchForm.resetForm}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div>
              <Tabs
                selectedIndex={selectedTabIndex}
                onSelect={handleTabSelect}
                selectedTabClassName="text-primary-darkest border-b-2 border-primary font-medium"
              >
                {tvsTypeId === 1 && (
                  <TabList className="flex justify-center my-2 text-neutral-dark">
                    <Tab className="uppercase px-4 pb-2 active:text-secondary-darkest cursor-pointer focus:outline-none">
                      General
                    </Tab>
                    <Tab className="uppercase px-4 pb-2 cursor-pointer focus:outline-none">
                      Rider
                    </Tab>
                  </TabList>
                )}
                <TabPanel>
                  <form onSubmit={generatePolicyForm.handleSubmit}>
                    <div className="px-4">
                      <h4 className="text-lg font-medium py-2">
                        Vehicle details
                      </h4>
                      <div className="grid grid-cols-1 md:grid-cols-4 gap-y-2 gap-x-4">
                        <div className="flex flex-col">
                          <Input
                            {...generatePolicyForm.getFieldProps(
                              "engineNumber"
                            )}
                            formik={generatePolicyForm}
                            id="engineNumber"
                            name="engineNumber"
                            type="text"
                            label="Engine number"
                            placeholder="Enter engine number"
                            className="w-full md:w-60"
                            maxLength={17}
                            value={generatePolicyForm.values.engineNumber}
                            // onChange={(e) => {
                            //   const uppercaseValue = e.target.value.toUpperCase();  // Convert to uppercase
                            //   generatePolicyForm.setFieldValue("engineNumber", uppercaseValue);  // Update form value
                            // }}
                            onInputChange={onEngineNoChange}
                            //onChange={generatePolicyForm.handleChange}
                            // readOnly={isSearchSubmitted}
                            disabled={isSearchSubmitted} // Disable if search is submitted
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...generatePolicyForm.getFieldProps(
                              "chassisNumber"
                            )}
                            formik={generatePolicyForm}
                            id="chassisNumber"
                            name="chassisNumber"
                            type="text"
                            label="Chassis Number"
                            placeholder="Enter chassis number"
                            className="w-full md:w-60"
                            maxLength={17}
                            value={generatePolicyForm.values.chassisNumber}
                            //onChange={generatePolicyForm.handleChange}
                            onInputChange={onChassisNoChange}
                            disabled={isSearchSubmitted} // Disable if search is submitted
                          />
                        </div>
                        <div className="flex flex-col">
                          <Select
                            {...generatePolicyForm.getFieldProps("vehicleType")}
                            formik={generatePolicyForm}
                            value={generatePolicyForm.values.vehicleType}
                            id="vehicleType"
                            name="vehicleType"
                            label="Vehicle Type"
                            onSelect={(value) => onSelectVehicleType(value)}
                            options={vehicleTypeOptions}
                            className="mr-4 flex-col w-full md:w-60"
                            //value={generatePolicyForm.values.vehicleType}
                            onChange={generatePolicyForm.handleChange}
                            disabled={isSearchSubmitted}
                          />
                        </div>
                        <div className="flex flex-col">
                          <Select
                            {...generatePolicyForm.getFieldProps(
                              "manufacturer"
                            )}
                            formik={generatePolicyForm}
                            value={generatePolicyForm.values.manufacturer}
                            id="manufacturer"
                            name="manufacturer"
                            label="Manufacturer"
                            className="w-full md:w-60"
                            onSelect={(value) =>
                              onSelectManufacturerType(value)
                            }
                            options={makeOptions}
                            onChange={generatePolicyForm.handleChange}
                            disabled={isSearchSubmitted}
                          />
                        </div>
                        <div className="flex flex-col">
                          <Select
                            {...generatePolicyForm.getFieldProps("model")}
                            formik={generatePolicyForm}
                            value={generatePolicyForm.values.model}
                            id="model"
                            name="model"
                            label="Model"
                            onSelect={(value) => onSelectModel(value)}
                            options={modelOptions}
                            className="w-full md:w-60"
                            onChange={generatePolicyForm.handleChange}
                          />
                        </div>
                        {tvsTypeId == 2 && (
                          <>
                            <div className="flex flex-col justify-center items-left mt-2 text-left">
                              <label className="text-sm text-left">
                                Registration Series Type
                              </label>
                              <div className="flex items-center">
                                <Radio
                                  {...generatePolicyForm.getFieldProps(
                                    "seriesType"
                                  )}
                                  formik={generatePolicyForm}
                                  id="normal"
                                  name="seriesType"
                                  label="Normal"
                                  value="1"
                                  // onChange={(value) =>
                                  //   handleSeriesTypeChange(value)
                                  // }
                                  onChange={handleSeriesTypeChange}
                                  disabled={isSearchSubmitted}
                                />
                                <Radio
                                  {...generatePolicyForm.getFieldProps(
                                    "seriesType"
                                  )}
                                  formik={generatePolicyForm}
                                  id="bh"
                                  name="seriesType"
                                  label="BH"
                                  value="2"
                                  // onChange={(x)=>console.log(x)}
                                  // onChange={(value) =>
                                  //   handleSeriesTypeChange(value)
                                  // }
                                  onChange={handleSeriesTypeChange}
                                  disabled={isSearchSubmitted}
                                />
                              </div>
                            </div>
                            <div className="flex flex-col">
                              <label className="w-fit text-sm text-neutral-darker mb-0.5">
                                Registration Number
                              </label>

                              <div className="flex items-center gap-2">
                                {seriesType === "1" && ( // For Normal series
                                  <>
                                    <Input
                                      {...generatePolicyForm.getFieldProps(
                                        "reg1"
                                      )}
                                      formik={generatePolicyForm}
                                      id="reg1"
                                      name="reg1"
                                      type="text"
                                      placeholder="MH"
                                      className="w-full md:w-14"
                                      maxLength="2"
                                      onInputChange={(e) => {
                                        handleInputChange(e, "reg1");
                                      }}
                                      ref={(el) =>
                                        (normalRegNoRef.current[0] = el)
                                      }
                                      disabled={isSearchSubmitted}
                                      hideErrorMessage={true}
                                    />
                                    <Input
                                      {...generatePolicyForm.getFieldProps(
                                        "reg2"
                                      )}
                                      formik={generatePolicyForm}
                                      id="reg2"
                                      name="reg2"
                                      type="text"
                                      placeholder="02"
                                      className="w-full md:w-14"
                                      maxLength="2"
                                      onInputChange={(e) => {
                                        handleInputChange(e, "reg2");
                                      }}
                                      ref={(el) =>
                                        (normalRegNoRef.current[1] = el)
                                      }
                                      disabled={isSearchSubmitted}
                                      hideErrorMessage={true}
                                    />
                                    <Input
                                      {...generatePolicyForm.getFieldProps(
                                        "reg3"
                                      )}
                                      formik={generatePolicyForm}
                                      id="reg3"
                                      name="reg3"
                                      type="text"
                                      placeholder="AB"
                                      className="w-full md:w-14"
                                      maxLength="2"
                                      onInputChange={(e) => {
                                        handleInputChange(e, "reg3");
                                      }}
                                      ref={(el) =>
                                        (normalRegNoRef.current[2] = el)
                                      }
                                      disabled={isSearchSubmitted}
                                      hideErrorMessage={true}
                                    />
                                    <Input
                                      {...generatePolicyForm.getFieldProps(
                                        "reg4"
                                      )}
                                      formik={generatePolicyForm}
                                      id="reg4"
                                      name="reg4"
                                      type="text"
                                      placeholder="5767"
                                      className="md:max-w-[68px]"
                                      maxLength="4"
                                      onInputChange={(e) => {
                                        handleInputChange(e, "reg4");
                                      }}
                                      ref={(el) =>
                                        (normalRegNoRef.current[3] = el)
                                      }
                                      disabled={isSearchSubmitted}
                                      hideErrorMessage={true}
                                    />
                                  </>
                                )}
                                {seriesType === "2" && ( // For BH series
                                  <>
                                    <Select
                                      {...generatePolicyForm.getFieldProps(
                                        "bhReg1"
                                      )}
                                      formik={generatePolicyForm}
                                      id="bhReg1"
                                      name="bhReg1"
                                      type="text"
                                      placeholder="21"
                                      options={bhYearOptions}
                                      className="md:max-w-[65px]"
                                      onSelect={(value) => {
                                        console.log(value);
                                        generatePolicyForm.setFieldValue(
                                          "bhReg1",
                                          value
                                        );
                                      }}
                                      // onChange={generatePolicyForm.handleChange}
                                      disabled={isSearchSubmitted}
                                    />
                                    <Input
                                      {...generatePolicyForm.getFieldProps(
                                        "bhReg2"
                                      )}
                                      formik={generatePolicyForm}
                                      id="bhReg2"
                                      name="bhReg2"
                                      type="text"
                                      value="BH"
                                      className="w-full md:w-14"
                                      maxLength="2"
                                      readOnly
                                      onInputChange={(e) =>
                                        handleInputChangeBH(e, "bhReg2")
                                      }
                                      disabled={isSearchSubmitted}
                                      hideErrorMessage={true}
                                    />
                                    <Input
                                      {...generatePolicyForm.getFieldProps(
                                        "bhReg3"
                                      )}
                                      formik={generatePolicyForm}
                                      id="bhReg3"
                                      name="bhReg3"
                                      type="text"
                                      placeholder="9999"
                                      className="md:max-w-[68px]"
                                      maxLength="4"
                                      onInputChange={(e) =>
                                        handleInputChangeBH(e, "bhReg3")
                                      }
                                      disabled={isSearchSubmitted}
                                      hideErrorMessage={true}
                                    />
                                    <Input
                                      {...generatePolicyForm.getFieldProps(
                                        "bhReg4"
                                      )}
                                      formik={generatePolicyForm}
                                      id="bhReg4"
                                      name="bhReg4"
                                      type="text"
                                      placeholder="XY"
                                      className="w-full md:w-14"
                                      maxLength="2"
                                      onInputChange={(e) =>
                                        handleInputChangeBH(e, "bhReg4")
                                      }
                                      disabled={isSearchSubmitted}
                                      hideErrorMessage={true}
                                    />
                                  </>
                                )}
                              </div>
                              {
                                <div className="text-red-500 text-xs mt-1">
                                  {getRegistrationNumberErrors()}
                                </div>
                              }
                              {/* {seriesType === "1" && (
                              <>
                                {
                                  generatePolicyForm.touched.reg1 && (
                                    <div className="text-red-500 text-xs mt-1">
                                      {generatePolicyForm.errors.reg1}

                                    </div>
                                  )}
                                {
                                  generatePolicyForm.touched.reg2 && (
                                    <div className="text-red-500 text-xs mt-1">
                                      {generatePolicyForm.errors.reg2}

                                    </div>
                                  )}
                                {
                                  generatePolicyForm.touched.reg3 && (
                                    <div className="text-red-500 text-xs mt-1">
                                      {generatePolicyForm.errors.reg3}

                                    </div>
                                  )}
                                {
                                  generatePolicyForm.touched.reg4 && (
                                    <div className="text-red-500 text-xs mt-1">
                                      {generatePolicyForm.errors.reg4}

                                    </div>
                                  )}
                              </>
                            )}

                            {seriesType === "2" && (
                              <>
                                {
                                  generatePolicyForm.touched.bhReg2 && (
                                    <div className="text-red-500 text-xs mt-1">
                                      {generatePolicyForm.errors.bhReg2}

                                    </div>
                                  )}
                                {
                                  generatePolicyForm.touched.bhReg3 && (
                                    <div className="text-red-500 text-xs mt-1">
                                      {generatePolicyForm.errors.bhReg3}

                                    </div>
                                  )}
                                {
                                  generatePolicyForm.touched.bhReg4 && (
                                    <div className="text-red-500 text-xs mt-1">
                                      {generatePolicyForm.errors.bhReg4}

                                    </div>
                                  )}
                                {
                                  generatePolicyForm.touched.bhReg1 && (
                                    <div className="text-red-500 text-xs mt-1">
                                      {generatePolicyForm.errors.bhReg1}

                                    </div>
                                  )}
                              </>
                            )} */}
                            </div>
                          </>
                        )}
                      </div>
                      <h4 className="text-lg font-medium py-2">
                        Owner details
                      </h4>
                      <div className="grid grid-cols-1 md:grid-cols-4 gap-y-2 gap-x-4">
                        <div className="flex flex-col">
                          <Select
                            {...generatePolicyForm.getFieldProps(
                              "policyHolderType"
                            )}
                            formik={generatePolicyForm}
                            value={generatePolicyForm.values.policyHolderType}
                            id="policyHolderType"
                            name="policyHolderType"
                            label="Policy Holder Type"
                            onSelect={(value) =>
                              onSelectPolicyHolderType(value)
                            }
                            options={policyHolderTypeOptions}
                            disabled={isSearchSubmitted}
                            className="w-full md:w-60"
                          />
                        </div>
                        <div></div>
                        <div></div>
                        <div></div>

                        <div className="flex flex-col">
                          <Select
                            {...generatePolicyForm.getFieldProps("salutation")}
                            formik={generatePolicyForm}
                            value={generatePolicyForm.values.salutation}
                            id="salutation"
                            name="salutation"
                            label="Salutation"
                            onSelect={(value) => onSelectSalutation(value)}
                            options={salutationOptions}
                            disabled={isSearchSubmitted}
                            className="w-full md:w-60"
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...generatePolicyForm.getFieldProps("firstName")}
                            formik={generatePolicyForm}
                            id="firstName"
                            name="firstName"
                            type="text"
                            label="First Name"
                            placeholder="Enter first name"
                            className="w-full md:w-60"
                            disabled={isSearchSubmitted}
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...generatePolicyForm.getFieldProps("middleName")}
                            formik={generatePolicyForm}
                            id="middleName"
                            name="middleName"
                            type="text"
                            label="Middle Name"
                            placeholder="Enter middle name"
                            className="w-full md:w-60"
                            disabled={isSearchSubmitted}
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...generatePolicyForm.getFieldProps("lastName")}
                            formik={generatePolicyForm}
                            id="lastName"
                            name="lastName"
                            type="text"
                            label="Last Name"
                            placeholder="Enter last name"
                            className="w-full md:w-60"
                            disabled={isSearchSubmitted}
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...generatePolicyForm.getFieldProps("email")}
                            formik={generatePolicyForm}
                            id="email"
                            name="email"
                            type="text"
                            label="Email"
                            placeholder="Enter email"
                            className="w-full md:w-60"
                            disabled={isSearchSubmitted}
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...generatePolicyForm.getFieldProps(
                              "mobileNumber"
                            )}
                            formik={generatePolicyForm}
                            id="mobileNumber"
                            name="mobileNumber"
                            type="text"
                            label="Mobile number"
                            placeholder="Enter mobile number"
                            className="w-full md:w-60"
                            maxLength={10}
                            disabled={isSearchSubmitted}
                          />
                        </div>
                        <div className="flex flex-col">
                          {/* {console.log(genderOptions)} */}
                          <Select
                            {...generatePolicyForm.getFieldProps("gender")}
                            formik={generatePolicyForm}
                            value={generatePolicyForm.values.gender}
                            id="gender"
                            name="gender"
                            label="Gender"
                            onSelect={(value) => onSelectGender(value)}
                            options={genderOptions}
                            className="w-full md:w-60"
                            disabled={isSearchSubmitted}
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...generatePolicyForm.getFieldProps("dateOfBirth")}
                            formik={generatePolicyForm}
                            id="dateOfBirth"
                            name="dateOfBirth"
                            type="date"
                            label="Date of birth"
                            min={calculateMinDate()}
                            max={calculateMaxDate()}
                            value={generatePolicyForm.values.dateOfBirth}
                            onChange={generatePolicyForm.handleChange}
                            onBlur={generatePolicyForm.handleBlur}
                            placeholder="Enter date of birth"
                            className="w-full md:w-60"
                            disabled={isSearchSubmitted}
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...generatePolicyForm.getFieldProps(
                              "addressLine1"
                            )}
                            formik={generatePolicyForm}
                            id="addressLine1"
                            name="addressLine1"
                            type="text"
                            label="Address line 1"
                            placeholder="Enter address line 1"
                            className="w-full md:w-60"
                            disabled={isSearchSubmitted}
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...generatePolicyForm.getFieldProps(
                              "addressLine2"
                            )}
                            formik={generatePolicyForm}
                            id="addressLine2"
                            name="addressLine2"
                            type="text"
                            label="Address line 2 "
                            placeholder="Enter address line 2"
                            className="w-full md:w-60"
                            disabled={isSearchSubmitted}
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...generatePolicyForm.getFieldProps("pincode")}
                            formik={generatePolicyForm}
                            id="pincode"
                            name="pincode"
                            type="text"
                            label="Pincode"
                            placeholder="Enter pincode"
                            className="w-full md:w-60"
                            disabled={isSearchSubmitted}
                            // value={rsaCareForm.values.pincode}
                            // onChange={(e) => onPincodeChange(e)}
                            onInputChange={(e) => onPincodeChange(e)}
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...generatePolicyForm.getFieldProps("city")}
                            formik={generatePolicyForm}
                            id="city"
                            name="city"
                            type="text"
                            label="City"
                            placeholder="City"
                            className="w-full md:w-60"
                            readOnly
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...generatePolicyForm.getFieldProps("state")}
                            formik={generatePolicyForm}
                            id="state"
                            name="state"
                            type="text"
                            label="State"
                            placeholder="State"
                            className="w-full md:w-60"
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    {planData.length > 0 && (
                      <div>
                        <hr className="text-neutral my-4" />
                        <span className="text-xl font-semibold">
                          Select Plan
                        </span>

                        <div className="flex justify-center pb-4">
                          <table className="text-sm border border-neutral w-full mt-2">
                            <thead className="bg-primary text-white">
                              {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                  {headerGroup.headers.map((header) => (
                                    <td key={header.id} className="px-4 ">
                                      {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                          )}
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </thead>
                            <tbody>
                              {table.getRowModel().rows.map((row) => (
                                <tr
                                  key={row.id}
                                  className="even:bg-primary-lightest"
                                >
                                  {row.getVisibleCells().map((cell) => (
                                    <td key={cell.id} className="px-4 ">
                                      {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                      )}
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                    {showNominee && (
                      <>
                        <div className="px-4">
                          <h4 className="text-lg font-medium py-2">
                            Nominee details
                          </h4>
                          <div className="grid grid-cols-1 md:grid-cols-4 gap-y-2 gap-x-4">
                            <div className="flex flex-col">
                              <Input
                                {...generatePolicyForm.getFieldProps(
                                  "nomineeFullName"
                                )}
                                formik={generatePolicyForm}
                                id="nomineeFullName"
                                name="nomineeFullName"
                                type="text"
                                label="Nominee fullname"
                                placeholder="Enter nominee fullname"
                                className="w-full md:w-60"
                              />
                            </div>
                            <div className="flex flex-col">
                              <Input
                                {...generatePolicyForm.getFieldProps(
                                  "nomineeAge"
                                )}
                                formik={generatePolicyForm}
                                id="nomineeAge"
                                name="nomineeAge"
                                type="text"
                                label="Nomineee Age"
                                placeholder="Enter nominee age"
                                className="w-full md:w-60"
                                maxLength={3}
                                onInputChange={onNomineeAgeChange}
                                onBlur={onNomineeAgeBlur}
                              />
                            </div>
                            <div className="flex flex-col">
                              <Select
                                {...generatePolicyForm.getFieldProps(
                                  "nomineeRelationship"
                                )}
                                formik={generatePolicyForm}
                                id="nomineeRelationship"
                                name="nomineeRelationship"
                                label="Nominee Relationship"
                                onSelect={(value) =>
                                  onSelectNomineeRelationshipType(value)
                                }
                                options={relationshipOptions}
                                className="w-full md:w-60"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {showAppointee && (
                      <>
                        <div className="px-4">
                          <h4 className="text-lg font-medium py-2">
                            Appointee details
                          </h4>
                          <div className="grid grid-cols-1 md:grid-cols-4 gap-y-2 gap-x-4">
                            <div className="flex flex-col">
                              <Input
                                {...generatePolicyForm.getFieldProps(
                                  "appointeeFullName"
                                )}
                                formik={generatePolicyForm}
                                id="appointeeFullName"
                                name="appointeeFullName"
                                type="text"
                                label="Appointee fullname"
                                placeholder="Enter appointee fullname"
                                className="w-full md:w-60"
                              />
                            </div>
                            <div className="flex flex-col">
                              <Input
                                {...generatePolicyForm.getFieldProps(
                                  "appointeeAge"
                                )}
                                formik={generatePolicyForm}
                                id="appointeeAge"
                                name="appointeeAge"
                                type="text"
                                label="Appointee Age"
                                placeholder="Enter appointee age"
                                className="w-full md:w-60"
                                onInputChange={onAppointeeAgeChange}
                              />
                            </div>
                            <div className="flex flex-col">
                              <Select
                                {...generatePolicyForm.getFieldProps(
                                  "appointeeRelationship"
                                )}
                                formik={generatePolicyForm}
                                id="appointeeRelationship"
                                name="appointeeRelationship"
                                label="Appointee Relationship"
                                onSelect={(value) =>
                                  onSelectAppointeeRelationshipType(value)
                                }
                                options={relationshipOptions}
                                className="w-full md:w-60"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="flex justify-center">
                      <div className="flex justify-center pt-4 pb-8 w-72">
                        {/* {alert(formik.isValid)} */}
                        <Button
                          type="button"
                          label="Reset"
                          variant="ghost"
                          onClick={() => {
                            generatePolicyForm.resetForm();
                            searchForm.resetForm();
                            setIsSearchSubmitted(false);
                          }}
                        />
                        <Button
                          type="submit"
                          label="Generate Policy"
                          className="min-w-fit"
                          variant="primary"
                          onClick={showErrorToast}
                          // disabled={!generatePolicyForm.isValid}
                        />
                      </div>
                    </div>
                  </form>
                </TabPanel>
                <TabPanel>
                  <form onSubmit={generatePolicyForm.handleSubmit}>
                    <div className="px-4">
                      <h4 className="text-lg font-medium py-2">
                        Vehicle details
                      </h4>
                      <div className="grid grid-cols-1 md:grid-cols-4 gap-y-2 gap-x-4">
                        <div className="flex flex-col">
                          <Input
                            {...generatePolicyForm.getFieldProps(
                              "engineNumber"
                            )}
                            formik={generatePolicyForm}
                            id="engineNumber"
                            name="engineNumber"
                            type="text"
                            label="Engine number"
                            placeholder="Enter engine number"
                            className="w-full md:w-60"
                            onInputChange={onEngineNoChange}
                            disabled={isSearchSubmitted}
                            maxLength={17}
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...generatePolicyForm.getFieldProps(
                              "chassisNumber"
                            )}
                            formik={generatePolicyForm}
                            id="chassisNumber"
                            name="chassisNumber"
                            type="text"
                            label="Chassis Number"
                            placeholder="Enter chassis number"
                            className="w-full md:w-60"
                            maxLength={17}
                            disabled={isSearchSubmitted}
                            onInputChange={onChassisNoChange}
                          />
                        </div>
                        <div className="flex flex-col">
                          <Select
                            {...generatePolicyForm.getFieldProps("vehicleType")}
                            formik={generatePolicyForm}
                            value={generatePolicyForm.values.vehicleType}
                            id="vehicleType"
                            name="vehicleType"
                            label="Vehicle Type"
                            onSelect={(value) => onSelectVehicleType(value)}
                            options={vehicleTypeOptions}
                            className="mr-4 flex-col w-full md:w-60"
                            disabled={isSearchSubmitted}
                          />
                        </div>
                        <div className="flex flex-col">
                          <Select
                            {...generatePolicyForm.getFieldProps(
                              "manufacturer"
                            )}
                            formik={generatePolicyForm}
                            value={generatePolicyForm.values.manufacturer}
                            id="manufacturer"
                            name="manufacturer"
                            label="Manufacturer"
                            className="w-full md:w-60"
                            onSelect={(value) =>
                              onSelectManufacturerType(value)
                            }
                            options={makeOptions}
                            disabled={isSearchSubmitted}
                          />
                        </div>
                        <div className="flex flex-col">
                          <Select
                            {...generatePolicyForm.getFieldProps("model")}
                            formik={generatePolicyForm}
                            value={generatePolicyForm.values.model}
                            id="model"
                            name="model"
                            label="Model"
                            onSelect={(value) => onSelectModel(value)}
                            options={modelOptions}
                            className="w-full md:w-60"
                            disabled={isSearchSubmitted}
                          />
                        </div>
                      </div>
                      <h4 className="text-lg font-medium py-2">
                        Owner details
                      </h4>
                      <div className="grid grid-cols-1 md:grid-cols-4 gap-y-2 gap-x-4">
                        <div className="flex flex-col">
                          <Select
                            {...generatePolicyForm.getFieldProps(
                              "policyHolderType"
                            )}
                            formik={generatePolicyForm}
                            value={generatePolicyForm.values.policyHolderType}
                            id="policyHolderType"
                            name="policyHolderType"
                            label="Policy Holder Type"
                            onSelect={(value) =>
                              onSelectPolicyHolderType(value)
                            }
                            options={policyHolderTypeOptions}
                            className="w-full md:w-60"
                            disabled={isSearchSubmitted}
                          />
                        </div>

                        <div></div>
                        <div></div>
                        <div></div>
                        <div className="flex flex-col">
                          <Select
                            {...generatePolicyForm.getFieldProps("salutation")}
                            formik={generatePolicyForm}
                            value={generatePolicyForm.values.salutation}
                            id="salutation"
                            name="salutation"
                            label="Salutation"
                            onSelect={(value) => onSelectSalutation(value)}
                            options={salutationOptions}
                            className="w-full md:w-60"
                            disabled={isSearchSubmitted}
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...generatePolicyForm.getFieldProps("firstName")}
                            formik={generatePolicyForm}
                            id="firstName"
                            name="firstName"
                            type="text"
                            label="First Name"
                            placeholder="Enter first name"
                            className="w-full md:w-60"
                            disabled={isSearchSubmitted}
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...generatePolicyForm.getFieldProps("middleName")}
                            formik={generatePolicyForm}
                            id="middleName"
                            name="middleName"
                            type="text"
                            label="Middle Name"
                            placeholder="Enter middle name"
                            className="w-full md:w-60"
                            disabled={isSearchSubmitted}
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...generatePolicyForm.getFieldProps("lastName")}
                            formik={generatePolicyForm}
                            id="lastName"
                            name="lastName"
                            type="text"
                            label="Last Name"
                            placeholder="Enter last name"
                            className="w-full md:w-60"
                            disabled={isSearchSubmitted}
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...generatePolicyForm.getFieldProps("email")}
                            formik={generatePolicyForm}
                            id="email"
                            name="email"
                            type="text"
                            label="Email"
                            placeholder="Enter email"
                            className="w-full md:w-60"
                            disabled={isSearchSubmitted}
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...generatePolicyForm.getFieldProps(
                              "mobileNumber"
                            )}
                            formik={generatePolicyForm}
                            id="mobileNumber"
                            name="mobileNumber"
                            type="text"
                            label="Mobile number"
                            placeholder="Enter mobile number"
                            className="w-full md:w-60"
                            maxLength={10}
                            disabled={isSearchSubmitted}
                          />
                        </div>
                        <div className="flex flex-col">
                          {/* {console.log(genderOptions)} */}
                          <Select
                            {...generatePolicyForm.getFieldProps("gender")}
                            formik={generatePolicyForm}
                            value={generatePolicyForm.values.gender}
                            id="gender"
                            name="gender"
                            label="Gender"
                            onSelect={(value) => onSelectGender(value)}
                            options={genderOptions}
                            className="w-full md:w-60"
                            disabled={isSearchSubmitted}
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...generatePolicyForm.getFieldProps("dateOfBirth")}
                            formik={generatePolicyForm}
                            id="dateOfBirth"
                            name="dateOfBirth"
                            type="date"
                            label="Date of birth"
                            min={calculateMinDate()}
                            max={calculateMaxDate()}
                            value={generatePolicyForm.values.dateOfBirth}
                            onChange={generatePolicyForm.handleChange}
                            onBlur={generatePolicyForm.handleBlur}
                            placeholder="Enter date of birth"
                            className="w-full md:w-60"
                            disabled={isSearchSubmitted}
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...generatePolicyForm.getFieldProps(
                              "addressLine1"
                            )}
                            formik={generatePolicyForm}
                            id="addressLine1"
                            name="addressLine1"
                            type="text"
                            label="Address line 1"
                            placeholder="Enter address line 1"
                            className="w-full md:w-60"
                            disabled={isSearchSubmitted}
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...generatePolicyForm.getFieldProps(
                              "addressLine2"
                            )}
                            formik={generatePolicyForm}
                            id="addressLine2"
                            name="addressLine2"
                            type="text"
                            label="Address line 2 "
                            placeholder="Enter address line 2"
                            className="w-full md:w-60"
                            disabled={isSearchSubmitted}
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...generatePolicyForm.getFieldProps("pincode")}
                            formik={generatePolicyForm}
                            id="pincode"
                            name="pincode"
                            type="text"
                            label="Pincode"
                            placeholder="Enter pincode"
                            className="w-full md:w-60"
                            disabled={isSearchSubmitted}
                            // value={rsaCareForm.values.pincode}
                            // onChange={(e) => onPincodeChange(e)}
                            onInputChange={(e) => onPincodeChange(e)}
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...generatePolicyForm.getFieldProps("city")}
                            formik={generatePolicyForm}
                            id="city"
                            name="city"
                            type="text"
                            label="City"
                            placeholder="City"
                            className="w-full md:w-60"
                            readOnly
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...generatePolicyForm.getFieldProps("state")}
                            formik={generatePolicyForm}
                            id="state"
                            name="state"
                            type="text"
                            label="State"
                            placeholder="State"
                            className="w-full md:w-60"
                            readOnly
                          />
                        </div>
                      </div>
                    </div>

                    {planData.length > 0 && (
                      <div>
                        <hr className="text-neutral my-4" />
                        <span className="text-xl font-semibold">
                          Select Plan
                        </span>

                        <div className="flex justify-center pb-4">
                          <table className="text-sm border border-neutral w-full mt-2">
                            <thead className="bg-primary text-white">
                              {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                  {headerGroup.headers.map((header) => (
                                    <td key={header.id} className="px-4 ">
                                      {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                          )}
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </thead>
                            <tbody>
                              {table.getRowModel().rows.map((row) => (
                                <tr
                                  key={row.id}
                                  className="even:bg-primary-lightest"
                                >
                                  {row.getVisibleCells().map((cell) => (
                                    <td key={cell.id} className="px-4 ">
                                      {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                      )}
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                    {showNominee && (
                      <>
                        <div className="px-4">
                          <h4 className="text-lg font-medium py-2">
                            Nominee details
                          </h4>
                          <div className="grid grid-cols-1 md:grid-cols-4 gap-y-2 gap-x-4">
                            <div className="flex flex-col">
                              <Input
                                {...generatePolicyForm.getFieldProps(
                                  "nomineeFullName"
                                )}
                                formik={generatePolicyForm}
                                id="nomineeFullName"
                                name="nomineeFullName"
                                type="text"
                                label="Nominee fullname"
                                placeholder="Enter nominee fullname"
                                className="w-full md:w-60"
                                disabled={isSearchSubmitted}
                              />
                            </div>
                            <div className="flex flex-col">
                              <Input
                                {...generatePolicyForm.getFieldProps(
                                  "nomineeAge"
                                )}
                                formik={generatePolicyForm}
                                id="nomineeAge"
                                name="nomineeAge"
                                type="text"
                                label="Nomineee Age"
                                placeholder="Enter nominee age"
                                className="w-full md:w-60"
                                value={generatePolicyForm.values.age}
                                disabled={isSearchSubmitted}
                                maxLength={3}
                                onInputChange={onNomineeAgeChange}
                                onBlur={onNomineeAgeBlur}
                              />
                            </div>
                            <div className="flex flex-col">
                              <Select
                                {...generatePolicyForm.getFieldProps(
                                  "nomineeRelationship"
                                )}
                                formik={generatePolicyForm}
                                id="nomineeRelationship"
                                name="nomineeRelationship"
                                label="Nominee Relationship"
                                onSelect={(value) =>
                                  onSelectNomineeRelationshipType(value)
                                }
                                options={relationshipOptions}
                                className="w-full md:w-60"
                                disabled={isSearchSubmitted}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {showAppointee && (
                      <>
                        <div className="px-4">
                          <h4 className="text-lg font-medium py-2">
                            Appointee details
                          </h4>
                          <div className="grid grid-cols-1 md:grid-cols-4 gap-y-2 gap-x-4">
                            <div className="flex flex-col">
                              <Input
                                {...generatePolicyForm.getFieldProps(
                                  "appointeeFullName"
                                )}
                                formik={generatePolicyForm}
                                id="appointeeFullName"
                                name="appointeeFullName"
                                type="text"
                                label="Appointee fullname"
                                placeholder="Enter appointee fullname"
                                className="w-full md:w-60"
                                disabled={isSearchSubmitted}
                              />
                            </div>
                            <div className="flex flex-col">
                              <Input
                                {...generatePolicyForm.getFieldProps(
                                  "appointeeAge"
                                )}
                                formik={generatePolicyForm}
                                id="appointeeAge"
                                name="appointeeAge"
                                type="text"
                                label="Appointee Age"
                                placeholder="Enter appointee age"
                                className="w-full md:w-60"
                                disabled={isSearchSubmitted}
                              />
                            </div>
                            <div className="flex flex-col">
                              <Select
                                {...generatePolicyForm.getFieldProps(
                                  "appointeeRelationship"
                                )}
                                formik={generatePolicyForm}
                                id="appointeeRelationship"
                                name="appointeeRelationship"
                                label="Appointee Relationship"
                                onSelect={(value) =>
                                  onSelectAppointeeRelationshipType(value)
                                }
                                options={relationshipOptions}
                                className="w-full md:w-60"
                                disabled={isSearchSubmitted}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="flex justify-center">
                      <div className="flex justify-center pt-4 pb-8 w-72">
                        {/* {alert(formik.isValid)} */}
                        <Button
                          type="button"
                          label="Reset"
                          variant="ghost"
                          onClick={() => {
                            generatePolicyForm.resetForm();
                            searchForm.resetForm();
                          }}
                        />
                        <Button
                          type="submit"
                          label="Generate Policy"
                          className="min-w-fit"
                          variant="primary"
                          onClick={showErrorToast}

                          // disabled={!generatePolicyForm.isValid}
                        />
                      </div>
                    </div>
                  </form>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
        {/* <div className="flex justify-center">
          <div className="flex justify-center pt-4 pb-8 w-72">
           
            <Button type="button" label="Reset" variant="ghost" />
            <Button type="submit" label="Submit" variant="primary" disabled={!formik.isValid} />
          </div>
        </div> */}
        {/* </form> */}
      </div>
    </>
  );
};

export default GeneratePolicy;
