import React, { useState, useEffect, useRef } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useNavigate } from "react-router-dom";
import FileSaver from "file-saver";
import Tippy from "@tippyjs/react";
import {
  Description,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import moment from "moment";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import coverImage from "../../assets/img/coverImage.png";
import Input from "../../components/ui/Input";
import Button from "../../components/ui/Button";
import Select from "../../components/ui/Select";
import File from "../../components/ui/File";
import { apiService } from "../../api.service";

export const DocumentStatus = () => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [disableAboutToExpireSelect, setDisableAboutToExpireSelect] =
      useState(true);
    const [filteredData, setFilteredData] = useState([]);
    const [activeTabName, setActiveTabName] = useState("");
    const [sorting, setSorting] = React.useState([]);
    const [payload, setPayload] = useState({
      pagination: {
        pageNumber: 1,
        pageSize: 5,
      },
    });
    const [policyNumberToCancel, setPolicyNumberToCancel] = useState();
    const [showDateRangeModal, setShowDateRangeModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [cancelPolicyMessage, setCancelPolicyMessage] = useState("");
    const [filterModal, filterdataModal] = useState(false);
    const [noRecordFound, setNoRecordFound] = useState(false);
    const [showCancelPolicyModal, setShowCancelPolicyModal] = useState(false);
  
    const navigate = useNavigate();
    const viewAgreementPDF = () => {
        // Implement the logic to view AgreementPDF
        console.log("Viewing AgreementPDF");
    };
    const viewGST = () => {
        // Implement the logic to view GST
        console.log("Viewing GST");
    };
    const viewPanCard = () => {
        // Implement the logic to view PanCard
        console.log("Viewing PanCard");
    };
    const viewCancelledCheque = () => {
        // Implement the logic to view CancelledCheque
        console.log("Viewing CancelledCheque");
    };
    const documentTypes = [
        { name: "AgreementPDF", action: viewAgreementPDF },
        { name: "GST", action: viewGST },
        { name: "PanCard", action: viewPanCard },
        { name: "CancelledCheque", action: viewCancelledCheque },
    ];
    const columnHelper = createColumnHelper();
    
    const getButtonStyles = (status) => {
        const baseClasses =
          "w-fit px-4 flex items-center justify-center rounded-full font-semibold";
        switch (status) {
          case "pending":
            return `${baseClasses} bg-warn-lightest text-warn capitalize`;
          case "rejected":
            return `${baseClasses} bg-neutral-lightest text-danger capitalize`;
          case "approved":
            return `${baseClasses} bg-success-lightest text-success capitalize`;
          case "referback":
            return `${baseClasses} bg-neutral-lightest text-neutral-darkest capitalize`;
          default:
            return `${baseClasses} bg-warn-lightest text-warn.lightest capitalize`;
        }
      };
      const getViewButtonStyles = (view) => {
        const baseClasses = "w-fit px-4 flex items-center justify-center rounded-full font-semibold";
        
        switch (view) {
            case "AgreementPDF":
                return `${baseClasses} bg-warn-lightest text-warn capitalize`;
            case "GST":
                return `${baseClasses} bg-neutral-lightest text-danger capitalize`;
            case "PanCard":
                return `${baseClasses} bg-success-lightest text-success capitalize`;
            case "CancelledCheque":
                return `${baseClasses} bg-neutral-lightest text-neutral-darkest capitalize`;
            default:
                return `${baseClasses} bg-warn-lightest text-warn-lightest capitalize`;
        }
    };
       const DocumentUploadButtons = ({ views }) => {
        const fileInputRef = useRef(null);
    
        const handleFileUpload = (event) => {
            const file = event.target.files[0];
            if (file) {
                // Handle the file upload logic here
                console.log("File selected:", file);
                // You can implement your upload logic here, e.g., sending it to a server
            }
        };
    
        const triggerFileInput = () => {
            fileInputRef.current.click();
        };
    
        return (
            <div className="flex flex-col space-y-2">
                {views.map((view) => (
                    <button key={view} className={getViewButtonStyles(view)} onClick={triggerFileInput}>
                        {view} <span className="material-symbols-outlined">upload</span>
                    </button>
                ))}
                <input 
                    type="file" 
                    ref={fileInputRef} 
                    style={{ display: 'none' }} 
                    onChange={handleFileUpload} 
                />
            </div>
        );
    };
    const dateFilterForm = useFormik({
      initialValues: {
        fromDate: "",
        toDate: "",
      },
      validationSchema: Yup.object().shape({
        fromDate: Yup.date().required("Required"),
        toDate: Yup.date().required("Required"),
      }),
      onSubmit: async (values) => {
        console.log(values);
        setPayload((prev) => ({
          ...prev,
          dates: {
            fromDate: values.fromDate,
            toDate: values.toDate,
          },
        }));
        console.log(payload);
        setShowDateRangeModal(false);
      },
    });
  
    const cancelPolicyForm = useFormik({
      initialValues: {
        reason: "",
        type: "",
        file: "",
      },
      // validationSchema: Yup.object().shape({
      //   reason: Yup.string().required("Required"),
      //   type: Yup.string().required(),
      //   file: Yup.mixed().required("Required"),
      // }),
      onSubmit: async (values) => {
        console.log(values);
        const payload = {
          policyNumber: policyNumberToCancel,
          file: values.file,
          reason: values.reason,
          type: values.type,
        };
        const loadingToast = toast.loading("Loading policy cancellation data...");
        await apiService.requestPolicyCancellation(payload).then((res) => {
          console.log(res);
          setCancelPolicyMessage(res.data.message);
          toast.success(res.data.message);
          setShowCancelPolicyModal(false);
  
        }).catch((error) => {
          setCancelPolicyMessage("Error cancelling policy");
        }).finally(() => {
          toast.dismiss(loadingToast)
        })
      },
    });
    const handleButtonClick = (action) => {
        // Call the action associated with the button
        action();
    };
    const renderDocumentButtons = () => {
        return documentTypes.map(({ name, action }) => (
            <button
                key={name}
                onClick={() => handleButtonClick(action)}
                className={getViewButtonStyles(name)}
            >
                {name}
            </button>
        ));
    };
    
  
   
    const columns = [
      columnHelper.accessor("srNo", {
        accessorKey: "srNo",
        header: "#",
      }),
      columnHelper.accessor("dealerCode", {
        header: "RSA IC Name",
      }),
      columnHelper.accessor("dealerCode", {
        header: "Dealer Code",
      }),
      columnHelper.accessor("dealerName", {
        header: "Dealer Name",
      }),
      columnHelper.accessor("masterPolicyNumber", {
        header: "SAP Code",
      }),
      columnHelper.accessor("policyNumber", {
        header: "AD Name",
      }),
      columnHelper.accessor("customerName", {
        header: "Contact No",
      }),
      columnHelper.accessor("state", {
        header: "State",
      }),
      columnHelper.accessor("chassisNumber", {
        header: "Location",
      }),
   
      columnHelper.accessor("createdAt", {
        header: "Created Date",
      }),
      columnHelper.accessor("status", {
        header: "Approval Status",
        cell: (data) => {
          const status = data.getValue();
          return (
            <span className={`px-2 py-1 rounded ${getButtonStyles(status)}`}>
              {status}
            </span>
          );
        },
      }),
      columnHelper.accessor("state", {
        header: "Remark",
      }),

      columnHelper.accessor("", {
        header: "Action",
        cell: (data) => (
          <div className="text-center">
            <button
              type="button"
              onClick={() => {
                setPolicyNumberToCancel(data.row.getValue("policyNumber"));
              }}
            >
              <span className="material-symbols-outlined">download</span>
            </button>
          </div>
        ),
      }),
      columnHelper.accessor("state", {
        header: "View",
        cell: () => (
            <div className="flex flex-col space-y-2">
                <DocumentUploadButtons views={["AgreementPDF", "GST", "PanCard", "CancelledCheque"]} />
            </div>
        ),
    }),

    ];
    
    const table = useReactTable({
      data: filteredData.length > 0 ? filteredData : data,
      columns,
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      //debugTable: true,
      manualPagination: true,
    });
  
    const requestPolicyCancellation = async () => { };
  
    // const handleNextPage = () => {
    //   setPayload((prev) => ({
    //     ...prev,
    //     pageNumber: prev.pageNumber + 1,
    //   }));
    // };
  
    // const handlePreviousPage = () => {
    //   setPayload((prev) => ({
    //     ...prev,
    //     pageNumber: prev.pageNumber - 1 > 0 ? prev.pageNumber - 1 : 1,
    //   }));
    // };
  
    const totalPages = Math.ceil(totalCount / payload.pagination.pageSize);
    const isLastPage = payload.pagination.pageNumber === totalPages;
    const isFirstPage = payload.pagination.pageNumber === 1;
  
    const handleFirstPage = () => {
      setPayload((prev) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          pageNumber: 1,
        },
      }));
    };
  
    const handlePreviousPage = () => {
      setPayload((prev) => {
        const prevPage = prev.pagination.pageNumber - 1;
        return {
          ...prev,
          pagination: {
            ...prev.pagination,
            pageNumber: prevPage < 1 ? 1 : prevPage,
          },
        };
      });
    };
  
    const handleNextPage = () => {
      setPayload((prev) => {
        const nextPage = prev.pagination.pageNumber + 1;
        return {
          ...prev,
          pagination: {
            ...prev.pagination,
            pageNumber: nextPage > totalPages ? totalPages : nextPage,
          },
        };
      });
    };
  
    const handleLastPage = () => {
      setPayload((prev) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          pageNumber: totalPages,
        },
      }));
    };
  
  
    const downloadExcel = async () => {
      const loadingToast = toast.loading("Downloading Excel file...", {
        duration: 5000,
      });
      try {
        const response = await apiService.downloadExcel(payload);
  
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
  
        // Use FileSaver to save the file
        FileSaver.saveAs(blob, "data.xlsx");
        //   const url = window.URL.createObjectURL(new Blob([response.data]));
        //   const link = document.createElement("a");
        //   link.href = url;
        //   link.setAttribute("download", "file.xlsx");
        //   document.body.appendChild(link);
        //   link.click();
      } catch (error) {
        console.error("Error downloading the file", error);
        toast.error("Error downloading the file");
      } finally {
        toast.dismiss(loadingToast);
      }
    };
  
    const fetchPolicyData = async () => {
      const loadingToast = toast.loading("Loading data...", { duration: 5000 });
      setLoading(true);
      try {
        // payload is changed on each scenario and it will call fetchPolicyData
        const response = await apiService.soldPolicies(payload);
        console.log(response.data.data);
        setData(response.data.data);
        setTotalCount(response.data.totalCount);
        console.log(data);
      } catch (error) {
        console.error("Error fetching policy data:", error);
        toast.error("Error fetching policy data");
      } finally {
        toast.dismiss(loadingToast);
        setLoading(false);
      }
    };
    const resetState = () => {
        setSelectedTabIndex(0);
        setData([]);
        setTotalCount(0);
        setDisableAboutToExpireSelect(true);
        setFilteredData([]);
        setActiveTabName("");
        setSorting([]);
        setPayload({
          pagination: {
            pageNumber: 1,
            pageSize: 5,
          },approvalStatusId: null,
        });
        setShowDateRangeModal(false);
        filterdataModal(false);
        setLoading(true);
        setNoRecordFound(false);
        dateFilterForm.resetForm();
        
      };
    useEffect(() => {
      fetchPolicyData();
    }, [payload]);
  
    useEffect(() => {
      if (data.length == 0) {
        setNoRecordFound(true);
      } else {
        setNoRecordFound(false);
      }
    }, [data]);
  
    const reasonOptions = [
      { id: 1, label: "Wrong Data" },
      { id: 2, label: "Other" },
    ];
  
    return (
      <>
        <Dialog
          open={showCancelPolicyModal}
          onClose={() => setShowCancelPolicyModal(false)}
          className="relative z-50"
        >
          <div className="fixed inset-0 bg-black/75" aria-hidden="true" />
  
          <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
            <DialogPanel className="max-w-xl w-full space-y-4 border bg-white border-neutral rounded">
              <div className="bg-neutral-lightest border-b border-neutral px-6 py-3 flex justify-center text-left">
                <span className="font-bold text-xl text-left w-full">
                  Policy Cancellation Request
                </span>
              </div>
  
              <div className="px-2 pb-6">
                <div className="text-sm text-center px-8">
                  <span>
                    Would you like to proceed with the cancellation of policy
                    number <strong>{policyNumberToCancel}</strong>?
                  </span>
                </div>
                <form onSubmit={cancelPolicyForm.handleSubmit}>
                  <div className="flex flex-col md:flex-row flex-wrap justify-between mt-4 px-8">
                    <div className="flex flex-col items-start justify-start w-full md:w-[45%]">
                      <Input
                        {...cancelPolicyForm.getFieldProps("reason")}
                        formik={cancelPolicyForm}
                        id="reason"
                        name="reason"
                        type="text"
                        label="Reason"
                        placeholder="Enter reason"
                        className="w-full"
                      />
                    </div>
                    <div className="flex flex-col w-full  md:w-[45%]">
                      <Select
                        {...cancelPolicyForm.getFieldProps("type")}
                        formik={cancelPolicyForm}
                        value={cancelPolicyForm.values.type}
                        id="type"
                        name="type"
                        label="Type"
                        onSelect={(value) =>
                          cancelPolicyForm.setFieldValue("type", value)
                        }
                        options={reasonOptions}
                        className="w-full"
                      />
                    </div>
                    <div className="mt-4 flex flex-col w-full  md:w-[45%]">
                      <File
                        {...cancelPolicyForm.getFieldProps("file")}
                        formik={cancelPolicyForm}
                        id="file"
                        name="file"
                        label="Upload Pdf"
                        className="w-full"
                        icon="picture_as_pdf"
                        accept=".pdf"
                        onFileChange={(name, file) => {
                          cancelPolicyForm.setFieldValue("file", file);
                        }}
                      />
                    </div>
                  </div>
  
                  <div className="flex items-center justify-end gap-4 mt-4 px-12">
                    <Button
                      type="button"
                      label="No, Go Back"
                      variant="ghost"
                      onClick={() => setShowCancelPolicyModal(false)}
                      className="w-fit"
                    />
                    <Button
                      type="submit"
                      label="Yes, Cancel Policy"
                      variant="primary"
                      className="text-sm md:text-base w-fit"
                    />
                  </div>
                </form>
              </div>
            </DialogPanel>
          </div>
        </Dialog>
        <Dialog
          open={showDateRangeModal}
          onClose={() => setShowDateRangeModal(false)}
          className="relative z-50"
        >
          <div className="fixed inset-0 bg-black/75" aria-hidden="true" />
  
          <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
            <DialogPanel className=" space-y-4 border bg-white border-neutral rounded">
              <form onSubmit={dateFilterForm.handleSubmit}>
                <div className="bg-neutral-lightest  border-b border-neutral px-6 py-3 flex">
                  <span className="font-bold text-xl py-2 text-neutral-darkest ">
                    Custom Date
                  </span>
                </div>
                <div className="flex flex-col md:flex-row md:gap-x-4 px-4 md:px-8 pt-4">
                  <div className="flex flex-col mb-4 md:mb-0">
                    <Input
                      {...dateFilterForm.getFieldProps("fromDate")}
                      formik={dateFilterForm}
                      id="fromDate"
                      name="fromDate"
                      type="date"
                      max={dateFilterForm.getFieldProps("toDate").value}
                      label="From Date"
                      className="w-full md:w-60"
                    />
                  </div>
                  <div className="flex flex-col">
                    <Input
                      {...dateFilterForm.getFieldProps("toDate")}
                      formik={dateFilterForm}
                      id="toDate"
                      name="toDate"
                      type="date"
                      min={dateFilterForm.getFieldProps("fromDate").value}
                      label="To Date"
                      className="w-full md:w-60"
                    />
                  </div>
                </div>
  
                <div className="flex items-center justify-center gap-4 mt-4 px-12 pb-8">
                  <Button
                    type="button"
                    label="Cancel"
                    variant="ghost"
                    className="w-full md:w-auto"
                    onClick={() => setShowDateRangeModal(false)}
                  />
                  <Button
                    type="submit"
                    label="Submit"
                    variant="primary"
                    className="w-full md:w-auto"
                  />
                </div>
              </form>
              {cancelPolicyMessage && (
                <div className="mt-4">
                  <p>{cancelPolicyMessage}</p>
                </div>
              )}
            </DialogPanel>
          </div>
        </Dialog>
        <Dialog
          open={filterModal}
          onClose={() => filterdataModal(false)}
          className="relative z-50"
        >
          <div className="fixed inset-0 bg-black/75" aria-hidden="true" />
  
          <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
            <DialogPanel className=" space-y-4 border bg-white border-neutral rounded">
              <form onSubmit={dateFilterForm.handleSubmit}>
                <div className="bg-neutral-lightest  border-b border-neutral px-6 py-3 flex">
                  <span className="font-bold text-xl py-2 text-neutral-darkest ">
                    Filter
                  </span>
                </div>
                <div className="flex flex-col gap-y-4 p-4">
                  <select
                    id="searchField"
                    className="mr-2 text-neutral-darkest"
                  >
                    <option value="employee_code">Dealer Code</option>
                    <option value="plan_name">Plan</option>
                    <option value="model_name">Model</option>
                    <option value="engine_no">Engine No.</option>
                    <option value="chassis_no">Chassis No.</option>
                    <option value="sold_policy_no">
                      Policy Number
                    </option>
                    <option value="model_name">Model</option>
                  </select>
  
                  <div className="relative flex items-center w-full md:w-auto">
                    <input
                      type="text"
                      id="search"
                      placeholder="Search..."
                      className="px-3 py-1 border border-neutral-dark rounded-l-sm focus:outline-primary text-base-black w-full md:w-auto"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setPayload((prev) => ({
                          ...prev,
                          search: {
                            field: document.getElementById("searchField").value,
                            value: document.getElementById('search').value,
                          }
                        }));
                      }}
                      className="bg-primary text-white border border-primary px-2 py-1  rounded-r-sm"
                    >
                      <span className="material-symbols-outlined flex items-center justify-center p-0">
                        search
                      </span>
                    </button>
                  </div>
                </div>
  
                <div className="flex items-center justify-center gap-4 mt-4 px-12 pb-8">
                  <Button
                    type="button"
                    label="Cancel"
                    variant="ghost"
                    className="max-w-12"
                    onClick={() => filterdataModal(false)}
                  />
                  <Button
                    type="submit"
                    label="Submit"
                    variant="primary"
                    className="max-w-16"
                  />
                </div>
              </form>
            </DialogPanel>
          </div>
        </Dialog>
  
        <div className="flex flex-col w-full items-center overflow-hidden">
          <div className="sticky -z-10 w-full">
            <img
              src={coverImage}
              className=" w-full h-36 object-cover"
              alt="cover_image"
            />
          </div>
          <div className="w-11/12 bg-white -mt-28 md:-mt-20 border border-neutral-light rounded mx-2">
            <form>
              <div>
                <div className="bg-base-white px-6 py-4 border-b border-neutral-light rounded-t">
                  <h3 className="text-xl font-semibold">
                  Document Status {loading}
                  </h3>
                  <p className="text-neutral-darker mt-1 text-sm">
                    A brief snapshot of your financial activity, showing incoming
                    and outgoing transactions
                  </p>
                </div>
                <div className="px-6 py-4 pb-8">
                  <div>
                    <Tabs
                      selectedIndex={selectedTabIndex}
                      selectedTabClassName="text-primary border-b-2 border-primary font-medium"
                    >
                      <TabList className="flex mb-4 text-neutral-dark text-sm items-center justify-between">
                        <div className="flex">
                          {/* <Tab className="uppercase px-4 pb-2 active:text-secondary-darkest cursor-pointer focus:outline-none">
                            Monthly Bank Statement
                          </Tab> */}
                          {/* <Tab className="uppercase px-4 pb-2 cursor-pointer focus:outline-none">
                            Commission Bank
                          </Tab> */}
                        </div>
                        <div className="md:flex flex-col md:flex-row justify-between items-center w-full">
                          <div className="flex items-center md:gap-x-4 gap-0 mb-2 md:mb-0">
                           
                          </div>
                          <div className="md:flex flex-col md:flex-row items-center  gap-2">
                            <div className=" space-x-4">
                            <button
                              type="button"
                              className="mt-1"
                              onClick={() => {
                                resetState();
                              }}
                            >
                              <span class="material-symbols-outlined text-neutral-darkest">
                                refresh
                              </span>
                            </button>
                              <button
                                type="button"
                                className="mt-1"
                                onClick={() => {
                                  setShowDateRangeModal(true);
                                }}
                              >
                                <span class="material-symbols-outlined text-neutral-darkest">
                                  date_range
                                </span>
                              </button>
  
                              {
                                <button
                                  type="button"
                                  className="mt-1"
                                  onClick={downloadExcel}
                                >
                                  <span class="material-symbols-outlined text-neutral-darkest">
                                    download
                                  </span>
                                </button>
                              }
                              <button
                                type="button"
                                className="mt-1 md:hidden"
                                onClick={() => {
                                  filterdataModal(true);
                                }}
                              >
                                <span className="material-symbols-outlined text-neutral-darkest justify-end">
                                  filter_alt
                                </span>
                              </button>
                            </div>
                            <div className="flex items-center md:flex  ">
                              <select
                                id="searchField"
                                className="mr-2 text-neutral-darkest"
                              >
                                <option value="employee_code">Dealer Code</option>
                                <option value="plan_name">RSA IC Name</option>
                                <option value="model_name">Dealer Name</option>
                                <option value="engine_no">SAP Code</option>
                                <option value="chassis_no">Contact No</option>
                                <option value="sold_policy_no">
                                  Policy Number
                                </option>
                                <option value="model_name">Model</option>
                              </select>
                              <input
                                type="text"
                                id="search"
                                placeholder="Search..."
                                className="px-3 py-1 border border-neutral-dark rounded-l-sm focus:outline-primary text-base-black w-full md:w-60"
                              />
                              <button
                                type="button"
                                onClick={() => {
                                  setPayload((prev) => ({
                                    ...prev,
                                    search: {
                                      field:
                                        document.getElementById("searchField")
                                          .value,
                                      value:
                                        document.getElementById("search").value,
                                    },
                                  }));
                                }}
                                className="bg-primary text-white border border-primary px-2 py-0.5 rounded-r-sm"
                              >
                                <span class="material-symbols-outlined flex items-center justify-center">
                                  search
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </TabList>
  
                      <TabPanel>
                        <div className="flex mb-4 overflow-x-scroll cursor-pointer">
                          <table
                            id="table-to-xls"
                            className="text-sm border border-neutral w-full"
                          >
                            <thead className="bg-primary text-white text-xs">
                              {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                  {headerGroup.headers.map((header) => {
                                    return (
                                      <th
                                        key={header.id}
                                        colSpan={header.colSpan}
                                        className="px-2 py-1 text-left"
                                      >
                                        {header.isPlaceholder ? null : (
                                          <div
                                            className={
                                              header.column.getCanSort()
                                                ? "cursor-pointer select-none"
                                                : ""
                                            }
                                            onClick={header.column.getToggleSortingHandler()}
                                            title={
                                              header.column.getCanSort()
                                                ? header.column.getNextSortingOrder() ===
                                                  "asc"
                                                  ? "Sort ascending"
                                                  : header.column.getNextSortingOrder() ===
                                                    "desc"
                                                    ? "Sort descending"
                                                    : "Clear sort"
                                                : undefined
                                            }
                                          >
                                            {flexRender(
                                              header.column.columnDef.header,
                                              header.getContext()
                                            )}
                                            {{
                                              asc: " 🔼",
                                              desc: " 🔽",
                                            }[header.column.getIsSorted()] ??
                                              null}
                                          </div>
                                        )}
                                      </th>
                                    );
                                  })}
                                </tr>
                              ))}
                            </thead>
  
                            <tbody>
                              {noRecordFound ? (
                                <tr className="min-h-20">
                                  <td
                                    colSpan={columns.length}
                                    className="text-center p-4 min-h-20"
                                  >
                                    No records found
                                  </td>
                                </tr>
                              ) : (
                                table.getRowModel().rows.map((row) => (
                                  <tr
                                    key={row.id}
                                    className="text-xs even:bg-primary-lightest"
                                  >
                                    {row.getVisibleCells().map((cell) => (
                                      <td key={cell.id} className="px-2 py-1">
                                        {flexRender(
                                          cell.column.columnDef.cell,
                                          cell.getContext()
                                        )}
                                      </td>
                                    ))}
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        </div>
  
                        <div className="flex flex-col md:flex-row items-center w-full justify-end text-xs gap-4">
                          <div className="flex items-center gap-2">
                            {" "}
                            <select
                              value={payload.pageSize}
                              onChange={(e) => {
                                const size = Number(e.target.value);
                                setPayload((prev) => ({
                                  ...prev,
                                  pagination: {
                                    pageSize: size,
                                    pageNumber: 1,
                                  },
                                }));
                              }}
                            >
                              {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map(
                                (pageSize) => (
                                  <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                  </option>
                                )
                              )}
                            </select>
                            <span className="font-semibold">
                              <span>Showing </span>
                              {1 +
                                (payload.pagination.pageNumber - 1) *
                                payload.pagination.pageSize}{" "}
                              to{" "}
                              {payload.pagination.pageNumber *
                                payload.pagination.pageSize -
                                payload.pagination.pageSize +
                                data?.length}{" "}
                              of {totalCount} Entries
                            </span>
                          </div>
                          {/* <button type="button" disabled={isFirstPage} onClick={handleFirstPage}>
                            <span className="material-symbols-outlined">
                              first_page
                            </span>
                          </button> */}
                          <div className="flex items-center space-x-2 mt-2 md:mt-0">
                            <button
                              type="button"
                              disabled={isFirstPage}
                              onClick={handleFirstPage}
                              className={`material-symbols-outlined ${isFirstPage
                                ? "cursor-not-allowed text-neutral-light"
                                : "cursor-pointer"
                                }`}
                            >
                              first_page
                            </button>
                            {/* <button type="button" onClick={handlePreviousPage} disabled={isFirstPage}>
                            <span class="material-symbols-outlined">
                              chevron_left
                            </span>
                          </button> */}
                            <button
                              type="button"
                              onClick={handlePreviousPage}
                              disabled={isFirstPage}
                              className={`material-symbols-outlined ${isFirstPage
                                ? "cursor-not-allowed text-neutral-light"
                                : "cursor-pointer"
                                }`}
                            >
                              chevron_left
                            </button>
                            {/* <button type="button" onClick={handleNextPage} disabled={isLastPage}>
                            <span class="material-symbols-outlined">
                              chevron_right
                            </span>
                          </button> */}
                            <button
                              type="button"
                              onClick={handleNextPage}
                              disabled={isLastPage}
                              className={`material-symbols-outlined ${isLastPage
                                ? "cursor-not-allowed text-neutral-light"
                                : "cursor-pointer"
                                }`}
                            >
                              chevron_right
                            </button>
                            {/* <button type="button" className="disabled:text-neutral-light" onClick={handleLastPage} disabled={isLastPage}>
                            <span className="material-symbols-outlined text-inherit">
                              last_page
                            </span>
                          </button> */}
                            <button
                              type="button"
                              className={`material-symbols-outlined ${isLastPage
                                ? "cursor-not-allowed text-neutral-light"
                                : "cursor-pointer"
                                }`}
                              onClick={handleLastPage}
                              disabled={isLastPage}
                            >
                              last_page
                            </button>
                          </div>
                        </div>
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  };
