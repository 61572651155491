import React, { useState, useEffect } from "react";
import coverImage from "../assets/img/coverImage.png";
import Input from "../components/ui/Input";
import Button from "../components/ui/Button";
import toast, { Toaster } from "react-hot-toast";
import { service } from "../service";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "../components/ui/Select";
import File from "../components/ui/File";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useNavigate } from "react-router-dom";
import ReactHTMLTableToExcel from "react-html-table-to-excel"; // Import ReactHTMLTableToExcel
//import { Data } from "../pages/defaultData";
import { apiService } from "../api.service";
import FileSaver from "file-saver";
import Tippy from "@tippyjs/react";
import {
  Description,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import moment from "moment";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { tab } from "@testing-library/user-event/dist/tab";
import { useGlobalContext } from "../context/global.context";

// const defaultData = [...Data];

export const DealerTransactionsStatement = () => {
  const { setMessage, setError } = useGlobalContext();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [data, setData] = useState([]);
  const [transactionTypeOptions, setTransactionTypeOptions] = useState([]);
  const [transactionPurposeOptions, setTransactionPurposeOptions] = useState(
    []
  );
  const [totalCount, setTotalCount] = useState(0);
  const [disableAboutToExpireSelect, setDisableAboutToExpireSelect] =
    useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [activeTabName, setActiveTabName] = useState("");
  const [sorting, setSorting] = React.useState([]);
  const [payload, setPayload] = useState({
    pagination: {
      pageNumber: 1,
      pageSize: 5,
    },
  });
  const [policyNumberToCancel, setPolicyNumberToCancel] = useState();
  const [showDateRangeModal, setShowDateRangeModal] = useState(false);
  const [filterModal, filterdataModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [noRecordFound, setNoRecordFound] = useState(false);

  const navigate = useNavigate();

  const columnHelper = createColumnHelper();

  const dateFilterForm = useFormik({
    initialValues: {
      fromDate: "",
      toDate: "",
    },
    validationSchema: Yup.object().shape({
      fromDate: Yup.date().required("Required"),
      toDate: Yup.date().required("Required"),
    }),
    onSubmit: async (values) => {
      setPayload((prev) => ({
        ...prev,
        fromDate: values.fromDate,
        toDate: values.toDate,
      }));
      setShowDateRangeModal(false);
      dateFilterForm.resetForm();
    },
  });

  const cancelPolicyForm = useFormik({
    initialValues: {
      reason: "",
      type: "",
      file: "",
    },
    // validationSchema: Yup.object().shape({
    //   reason: Yup.string().required("Required"),
    //   type: Yup.string().required(),
    //   file: Yup.mixed().required("Required"),
    // }),
    onSubmit: async (values) => {
      try {
        const payload = {
          policyNumber: policyNumberToCancel,
          file: values.file,
          reason: values.reason,
          type: values.type,
        };
        const loadingToast = toast.loading("Loading Policy cancellation data ");
        await apiService
          .requestPolicyCancellation(payload)
          .then((res) => {
            toast.success(res.data.message);
          })
          .catch((error) => {
            service.notifyApiError(error, setMessage, setError);
          })
          .finally(() => {
            toast.dismiss(loadingToast);
          });
      } catch (error) {
        service.notifyApiError(error, setMessage, setError);
      }
    },
  });

  const columns = [
    columnHelper.accessor("srNo", {
      accessorKey: "srNo",
      header: "#",
    }),
    columnHelper.accessor("transactionNumber", {
      header: "Transaction No",
    }),
    columnHelper.accessor("transactionType", {
      header: "Transaction Type",
    }),
    columnHelper.accessor("transactionPurpose", {
      header: "Transaction Purpose",
    }),
    columnHelper.accessor("transactionAmount", {
      header: "Transaction Amount",
      cell: (data) => <span>₹ {data.getValue()}</span>,
    }),
    columnHelper.accessor("policyNumber", {
      header: "Policy No",
    }),
    columnHelper.accessor("customerName", {
      header: "Customer Name",
    }),
    columnHelper.accessor("vehicleType", {
      header: "Vehicle Type",
    }),
    columnHelper.accessor("model", {
      header: "Mode",
    }),
    columnHelper.accessor("engineNumber", {
      header: "Engine No",
    }),
    columnHelper.accessor("chassisNumber", {
      header: "Chassis No",
    }),
    columnHelper.accessor("planName", {
      header: "Plan Name",
    }),

    // columnHelper.accessor("chassisNumber", {
    //   header: "Transaction Type",
    // }),
    // columnHelper.accessor("chassisNumber", {
    //   header: "Transaction Purpose",
    // }),
    columnHelper.accessor("employeeCode", {
      header: "Employee Code",
    }),
    columnHelper.accessor("employeeName", {
      header: "Employee Name",
    }),

    // columnHelper.accessor("policyStartDate", {
    //   header: "Policy Start Date",
    // }),
    // columnHelper.accessor("policyEndDate", {
    //   header: "Policy End Date",
    // }),
    //   columnHelper.accessor("TransactionAmount", {
    //     header: "Transaction Amount",
    //     cell: data => <span>₹ {data.getValue()}</span>,
    //   }),
    //   columnHelper.accessor("TransactionType", {
    //     header: "Transaction Type",
    //   }),
    //   columnHelper.accessor("TransactionPurpose", {
    //     header: "Transaction Purpose",
    //   }),
    //   columnHelper.accessor("EmployeeCode", {
    //     header: "Employee Code",
    //   }),
    //   columnHelper.accessor("EmployeeName", {
    //     header: "Employee Name",
    //   }),
    columnHelper.accessor("createdAt", {
      header: "Created Date",
    }),
    columnHelper.accessor("month", {
      header: "Months",
    }),
  ];

  const table = useReactTable({
    data: filteredData.length > 0 ? filteredData : data,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    //debugTable: true,
    manualPagination: true,
  });

  const requestPolicyCancellation = async () => {};

  // const handleNextPage = () => {
  //   setPayload((prev) => ({
  //     ...prev,
  //     pageNumber: prev.pageNumber + 1,
  //   }));
  // };

  // const handlePreviousPage = () => {
  //   setPayload((prev) => ({
  //     ...prev,
  //     pageNumber: prev.pageNumber - 1 > 0 ? prev.pageNumber - 1 : 1,
  //   }));
  // };

  const totalPages = Math.ceil(totalCount / payload.pagination.pageSize);
  const isLastPage = payload.pagination.pageNumber === totalPages;
  const isFirstPage = payload.pagination.pageNumber === 1;

  const handleNextPage = () => {
    try {
      setPayload((prev) => {
        const nextPage = prev.pagination.pageNumber + 1;
        return {
          ...prev,
          pagination: {
            ...prev.pagination,
            pageNumber: nextPage > totalPages ? totalPages : nextPage,
          },
        };
      });
    } catch (error) {
      service.notifyApiError(error, setMessage, setError);
    }
  };

  const handleLastPage = () => {
    try {
      setPayload((prev) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          pageNumber: totalPages,
        },
      }));
    } catch (error) {
      service.notifyApiError(error, setMessage, setError);
    }
  };
  const handlePreviousPage = () => {
    try {
      setPayload((prev) => {
        const prevPage = prev.pagination.pageNumber - 1;
        return {
          ...prev,
          pagination: {
            ...prev.pagination,
            pageNumber: prevPage < 1 ? 1 : prevPage,
          },
        };
      });
    } catch (error) {
      service.notifyApiError(error, setMessage, setError);
    }
  };

  const handleFirstPage = () => {
    try {
      setPayload((prev) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          pageNumber: 1,
        },
      }));
    } catch (error) {
      service.notifyApiError(error, setMessage, setError);
    }
  };

  const downloadExcel = async () => {
    const loadingToast = toast.loading("Downloading Excel file...");

    try {
      const payloadData = {
        ...payload,
        download: true,
      };

      const response = await apiService.dealerTransactionStatement(payloadData);
      // return
      const byteCharacters = atob(response.data.excelFile);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Use FileSaver to save the file
      FileSaver.saveAs(blob, "myfile.xlsx");

      toast.success("Excel file downloaded successfully");
    } catch (error) {
      console.error("Error downloading the file", error);
      // toast.error("Error downloading the file");
      service.notifyApiError(error, setMessage, setError);
    } finally {
      toast.dismiss(loadingToast);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevents the default behavior for the Enter key
      onSearch();
    }
  };

  const onSearch = () => {
    setPayload((prev) => ({
      ...prev,
      search: {
        field: document.getElementById("searchField").value,
        value: document.getElementById("search").value,
      },
    }));
  };
  const fetchDealerTransactionStatement = async () => {
    // const loadingToast = toast.loading("Loading Sold Policy data...");
    setLoading(true);
    try {
      // payload is changed on each scenario and it will call fetchPolicyData
      const response = await apiService.dealerTransactionStatement(payload);
      if (response?.data?.data.length === 0) {
        setNoRecordFound(true);
      }
      setData(response.data.data);
      setTotalCount(response.data.totalCount);
      setNoRecordFound(data.length === 0);
    } catch (error) {
      console.error("Error fetching policy data:", error);
      toast.error("Error fetching policy data");
      service.notifyApiError(error, setMessage, setError);
    } finally {
      // toast.dismiss(loadingToast);
      setLoading(false);
    }
  };

  const resetState = () => {
    setSelectedTabIndex(0);
    setData([]);
    setTotalCount(0);
    setDisableAboutToExpireSelect(true);
    setFilteredData([]);
    setActiveTabName("");
    setSorting([]);
    setPayload({
      pagination: {
        pageNumber: 1,
        pageSize: 5,
      },
      transactionType: null,
      transactionPurpose: null,
    });
    setPolicyNumberToCancel(null);
    setShowDateRangeModal(false);
    filterdataModal(false);
    setLoading(true);
    setNoRecordFound(false);
    cancelPolicyForm.resetForm();
    dateFilterForm.resetForm();
  };

  const getTransactionTypeOptions = async () => {
    const loadingToast = toast.loading("Loading data");
    try {
      await apiService.transactionTypeOptions().then((res) => {
        setTransactionTypeOptions(res.data.data);
      });
    } catch (error) {
      console.error("Error fetching genders:", error);
      service.notifyApiError(error, setMessage, setError);
    } finally {
      toast.dismiss(loadingToast);
    }
  };
  const getTransactionPurposeOptions = async () => {
    const loadingToast = toast.loading("Loading data");
    try {
      await apiService.transactionPurposeOptions().then((res) => {
        setTransactionPurposeOptions(res.data.data);
      });
    } catch (error) {
      console.error("Error fetching genders:", error);
      service.notifyApiError(error, setMessage, setError);
    } finally {
      toast.dismiss(loadingToast);
    }
  };
  useEffect(() => {
    getTransactionTypeOptions();
    getTransactionPurposeOptions();
  }, []);
  useEffect(() => {
    fetchDealerTransactionStatement();
  }, [payload]);

  useEffect(() => {
    if (data.length == 0) {
      setNoRecordFound(true);
    } else {
      setNoRecordFound(false);
    }
  }, [data]);

  const reasonOptions = [
    { id: 1, label: "Wrong Data" },
    { id: 2, label: "Other" },
  ];

  return (
    <>
      <Dialog
        open={showDateRangeModal}
        onClose={() => setShowDateRangeModal(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/75" aria-hidden="true" />

        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <DialogPanel className="space-y-4 border bg-white border-neutral rounded overflow-y-scroll">
            <form onSubmit={dateFilterForm.handleSubmit}>
              <div className="bg-neutral-lightest border-b border-neutral px-4 py-3 flex justify-between items-center">
                <span className="font-bold text-xl text-neutral-darkest">
                  Custom Date
                </span>
              </div>
              <div className="flex flex-col md:flex-row gap-4 px-4 pt-4">
                <div className="flex flex-col w-full md:w-1/2">
                  <Input
                    {...dateFilterForm.getFieldProps("fromDate")}
                    formik={dateFilterForm}
                    id="fromDate"
                    name="fromDate"
                    type="date"
                    max={
                      dateFilterForm.getFieldProps("toDate").value ||
                      moment().format("YYYY-MM-DD")
                    }
                    label="From Date"
                    className="w-full"
                  />
                </div>
                <div className="flex flex-col w-full md:w-1/2">
                  <Input
                    {...dateFilterForm.getFieldProps("toDate")}
                    formik={dateFilterForm}
                    id="toDate"
                    name="toDate"
                    type="date"
                    min={dateFilterForm.getFieldProps("fromDate").value}
                    label="To Date"
                    className="w-full"
                  />
                </div>
              </div>

              <div className="flex items-center justify-center gap-4 mt-4 px-4 pb-8">
                <Button
                  type="button"
                  label="Cancel"
                  variant="ghost"
                  className="w-full md:w-auto"
                  onClick={() => setShowDateRangeModal(false)}
                />
                <Button
                  type="submit"
                  label="Submit"
                  variant="primary"
                  className="w-full md:w-auto"
                />
              </div>
            </form>
          </DialogPanel>
        </div>
      </Dialog>

      <Dialog
        open={filterModal}
        onClose={() => filterdataModal(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/75" aria-hidden="true" />

        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <DialogPanel className=" space-y-4 border bg-white border-neutral rounded">
            <form onSubmit={dateFilterForm.handleSubmit}>
              <div className="bg-neutral-lightest  border-b border-neutral px-6 py-3 flex">
                <span className="font-bold text-xl py-2 text-neutral-darkest ">
                  Filter
                </span>
              </div>
              <div className="flex flex-col gap-y-4 p-4">
                <select id="searchField" className="mr-2 text-neutral-darkest">
                  <option value="sold_policy_no">Policy No</option>
                  <option value="engine_no">Engine No</option>
                  <option value="chassis_no">Chassis No</option>
                </select>

                <div className="relative flex items-center w-full md:w-auto">
                  <input
                    type="text"
                    id="search"
                    placeholder="Search..."
                    className="px-3 py-1 border border-neutral-dark rounded-l-sm focus:outline-primary text-base-black w-full md:w-auto"
                  />
                  <button
                    type="button"
                    onClick={() => {
                      setPayload((prev) => ({
                        ...prev,
                        search: {
                          field: document.getElementById("searchField").value,
                          value: document.getElementById("search").value,
                        },
                      }));
                    }}
                    className="bg-primary text-white border border-primary px-2 py-1  rounded-r-sm"
                  >
                    <span className="material-symbols-outlined flex items-center justify-center p-0">
                      search
                    </span>
                  </button>
                </div>
              </div>

              <div className="flex items-center justify-center gap-4 mt-4 px-12 pb-8">
                <Button
                  type="button"
                  label="Cancel"
                  variant="ghost"
                  className="max-w-12"
                  onClick={() => filterdataModal(false)}
                />
                <Button
                  type="submit"
                  label="Submit"
                  variant="primary"
                  className="max-w-16"
                />
              </div>
            </form>
          </DialogPanel>
        </div>
      </Dialog>
      <div className="flex flex-col w-full items-center overflow-hidden">
        <div className="sticky -z-10 w-full">
          <img
            src={coverImage}
            className=" w-full h-36 object-cover"
            alt="cover_image"
          />
        </div>
        <div className="w-11/12 bg-white -mt-28 md:-mt-20 border border-neutral-light rounded mx-2">
          <form>
            <div>
              <div className="bg-base-white px-6 py-4 border-b border-neutral-light rounded-t">
                <h3 className="text-xl font-semibold">
                  Dealer Transaction Statements {loading}
                </h3>
                <p className="text-neutral-darker mt-1 text-sm">
                  A brief snapshot of your financial activity, showing incoming
                  and outgoing transactions
                </p>
              </div>
              <div className="px-6 py-4 pb-8">
                <Tabs
                  selectedIndex={selectedTabIndex}
                  selectedTabClassName="text-primary border-b-2 border-primary font-medium"
                  onSelect={(index) => setSelectedTabIndex(index)}
                >
                  <TabList className="flex mb-4 text-neutral-dark text-sm items-center justify-between">
                    <div className="flex">
                      {/* <Tab className="uppercase px-4 pb-2 active:text-secondary-darkest cursor-pointer focus:outline-none">
                          Monthly Bank Statement
                        </Tab> */}
                      {/* <Tab className="uppercase px-4 pb-2 cursor-pointer focus:outline-none">
                          Commission Bank
                        </Tab> */}
                      <Tab className="cursor-pointer"></Tab> {/* First Tab */}
                      <Tab className="cursor-pointer"></Tab> {/* Second Tab */}
                    </div>
                    <div className="md:flex flex-col md:flex-row justify-between items-center w-full">
                      <div className="flex items-center md:gap-x-4 gap-0 mb-2 md:mb-0  w-full md:w-1/3 ">
                        {/* <div className="flex">
                            <input
                              type="checkbox"
                              onChange={(e) => {
                                setPayload((prev) => ({
                                  ...prev,
                                  dates: {
                                    toDate: e.target.checked
                                      ? moment().format("YYYY-MM-DD")
                                      : undefined,
                                  },
                                }));
                              }}
                            />
                            <span className="mr-4 ml-1 text-neutral-darkest">
                              Show Expired
                            </span>
                          </div> */}
                        <div className="flex">
                          <div className="label flex-col text-xs">
                            <label className="pl-1">Transaction type</label>
                            <select
                              className="mr-2 text-neutral-darkest"
                              value={
                                payload.transactionType || "transactionType"
                              }
                              onChange={(e) => {
                                const selectedValue = e.target.value;
                                setPayload((prev) => ({
                                  ...prev,
                                  transactionType: selectedValue, // Adjust this based on what your payload structure requires
                                }));
                              }}
                            >
                              <option value="transactionType">
                                Transaction Type
                              </option>
                              {transactionTypeOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="label flex-col text-xs">
                            <label className="pl-1">Transaction Purpose</label>
                            <select
                              value={
                                payload.transactionPurpose ||
                                "transactionPurpose"
                              }
                              className="mr-2 text-neutral-darkest"
                              onChange={(e) => {
                                const selectedValue = e.target.value;
                                setPayload((prev) => ({
                                  ...prev,
                                  transactionPurpose: selectedValue, // Adjust this based on what your payload structure requires
                                }));
                              }}
                            >
                              <option value="">
                                Select Transaction Purpose
                              </option>
                              {transactionPurposeOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="flex  items-center md:gap-x-4 gap-2  w-full md:justify-end md:w-1/2">
                        <button
                          type="button"
                          className="mt-1"
                          onClick={() => {
                            resetState();
                          }}
                        >
                          <span className="material-symbols-outlined text-neutral-darkest">
                            refresh
                          </span>
                        </button>
                        <button
                          type="button"
                          className="mt-1"
                          onClick={() => {
                            setShowDateRangeModal(true);
                          }}
                        >
                          <span className="material-symbols-outlined text-neutral-darkest">
                            date_range
                          </span>
                        </button>
                        {
                          <button
                            type="button"
                            className="mt-1"
                            onClick={downloadExcel}
                          >
                            <span className="material-symbols-outlined text-neutral-darkest">
                              download
                            </span>
                          </button>
                        }
                        <button
                          type="button"
                          className="mt-1 md:hidden"
                          onClick={() => {
                            filterdataModal(true);
                          }}
                        >
                          <span className="material-symbols-outlined text-neutral-darkest justify-end">
                            filter_alt
                          </span>
                        </button>

                        <div className="items-center gap-x-4 hidden md:flex">
                          <select
                            id="searchField"
                            className="mr-2 text-neutral-darkest"
                          >
                            {/* <option value="sold_policy_no">Policy No</option> */}
                            <option value="transection_purpose">
                              Transaction Purpose
                            </option>
                            <option value="transection_type">
                              Transaction Type
                            </option>
                            <option value="transection_no">
                              Transaction No
                            </option>
                          </select>

                          <div className="relative flex items-center w-full md:w-auto">
                            <input
                              type="text"
                              id="search"
                              placeholder="Search..."
                              className="px-3 py-1 border border-neutral-dark rounded-l-sm focus:outline-primary text-base-black w-full md:w-full "
                            />
                            <button
                              type="button"
                              onClick={() => {
                                setPayload((prev) => ({
                                  ...prev,
                                  search: {
                                    field:
                                      document.getElementById("searchField")
                                        .value,
                                    value:
                                      document.getElementById("search").value,
                                  },
                                }));
                              }}
                              className="bg-primary text-white border border-primary px-2 py-0.5 rounded-r-sm"
                            >
                              <span className="material-symbols-outlined flex items-center justify-center">
                                search
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="flex flex-col md:flex-row items-center md:gap-x-4 gap-2">
                          <div className="flex flex-col items-center md:items-start w-full md:w-auto mb-2 md:mb-0">
                            <select
                              id="searchField"
                              className="text-neutral-darkest  md:w-auto"
                            >
                              <option value="sold_policy_no">Policy No</option>
                              <option value="engine_no">Engine No</option>
                              <option value="chassis_no">Chassis No</option>
                            </select>
                          </div>

                          <div className="flex items-center w-full max-w-sm mx-auto">
                            <input
                              type="text"
                              id="search"
                              placeholder="Search..."
                              className="px-3 py-1 border border-neutral-dark rounded-l-sm focus:outline-primary text-base-black flex-grow max-w-48"
                            />

                            <button
                              type="button"
                              onClick={() => {
                                setPayload((prev) => ({
                                  ...prev,
                                  search: {
                                    field: document.getElementById("searchField").value,
                                    value: document.getElementById('search').value,
                                  }
                                }));
                              }}
                              className="bg-primary text-white border border-primary px-2 py-1 rounded-r-sm"
                            >
                              <span className="material-symbols-outlined flex items-center justify-center">
                                search
                              </span>
                            </button>

                            <button
                              type="button"
                              className="ml-2"
                              onClick={() => setShowDateRangeModal(true)}
                            >
                              <span className="material-symbols-outlined text-neutral-darkest">
                                date_range
                              </span>
                            </button>

                            <button
                              type="button"
                              className="ml-2"
                              onClick={downloadExcel}
                            >
                              <span className="material-symbols-outlined text-neutral-darkest">
                                download
                              </span>
                            </button>
                          </div>
                        </div> */}
                  </TabList>

                  <TabPanel>
                    <div className="flex mb-4 overflow-x-scroll cursor-pointer">
                      <table
                        id="table-to-xls"
                        className="text-sm border border-neutral w-full"
                      >
                        <thead className="bg-primary text-white text-xs">
                          {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                              {headerGroup.headers.map((header) => {
                                return (
                                  <th
                                    key={header.id}
                                    colSpan={header.colSpan}
                                    className="px-2 py-1 text-left"
                                  >
                                    {header.isPlaceholder ? null : (
                                      <div
                                        className={
                                          header.column.getCanSort()
                                            ? "cursor-pointer select-none"
                                            : ""
                                        }
                                        onClick={header.column.getToggleSortingHandler()}
                                        title={
                                          header.column.getCanSort()
                                            ? header.column.getNextSortingOrder() ===
                                              "asc"
                                              ? "Sort ascending"
                                              : header.column.getNextSortingOrder() ===
                                                "desc"
                                              ? "Sort descending"
                                              : "Clear sort"
                                            : undefined
                                        }
                                      >
                                        {flexRender(
                                          header.column.columnDef.header,
                                          header.getContext()
                                        )}
                                        {{
                                          asc: " 🔼",
                                          desc: " 🔽",
                                        }[header.column.getIsSorted()] ?? null}
                                      </div>
                                    )}
                                  </th>
                                );
                              })}
                            </tr>
                          ))}
                        </thead>
                        {/* <tbody>
                            {table.getRowModel().rows.map((row) => {
                              return (
                                <tr
                                  key={row.id}
                                  className="text-xs even:bg-primary-lightest"
                                >
                                  {row.getVisibleCells().map((cell) => {
                                    return (
                                      <td key={cell.id} className="px-2 py-1">
                                        {" "}
                                        
                                        {flexRender(
                                          cell.column.columnDef.cell,
                                          cell.getContext()
                                        )}
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                          </tbody> */}
                        <tbody>
                          {loading ? (
                            <tr className="min-h-20">
                              <td
                                colSpan={columns.length}
                                className="text-center p-4 min-h-20"
                              >
                                Loading...
                              </td>
                            </tr>
                          ) : noRecordFound ? (
                            <tr className="min-h-20">
                              <td
                                colSpan={columns.length}
                                className="text-center p-4 min-h-20"
                              >
                                No records found
                              </td>
                            </tr>
                          ) : (
                            table.getRowModel().rows.map((row) => (
                              <tr
                                key={row.id}
                                className="text-xs even:bg-base-white"
                              >
                                {row.getVisibleCells().map((cell) => (
                                  <td key={cell.id} className="px-2 py-1">
                                    {flexRender(
                                      cell.column.columnDef.cell,
                                      cell.getContext()
                                    )}
                                  </td>
                                ))}
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>

                    <div className="flex flex-col md:flex-row items-center w-full justify-end text-xs gap-4">
                      <div className="flex items-center gap-2">
                        {" "}
                        <select
                          value={payload.pageSize}
                          onChange={(e) => {
                            const size = Number(e.target.value);
                            setPayload((prev) => ({
                              ...prev,
                              pagination: {
                                pageSize: size,
                                pageNumber: 1,
                              },
                            }));
                          }}
                        >
                          {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map(
                            (pageSize) => (
                              <option key={pageSize} value={pageSize}>
                                {pageSize}
                              </option>
                            )
                          )}
                        </select>
                        <span className="font-semibold">
                          <span>Showing </span>
                          {1 +
                            (payload.pagination.pageNumber - 1) *
                              payload.pagination.pageSize}{" "}
                          to{" "}
                          {payload.pagination.pageNumber *
                            payload.pagination.pageSize -
                            payload.pagination.pageSize +
                            data?.length}{" "}
                          of {totalCount} Entries
                        </span>
                      </div>
                      {/* <button type="button" disabled={isFirstPage} onClick={handleFirstPage}>
                          <span className="material-symbols-outlined">
                            first_page
                          </span>
                        </button> */}
                      <div className="flex items-center space-x-2 mt-2 md:mt-0">
                        <button
                          type="button"
                          disabled={isFirstPage}
                          onClick={handleFirstPage}
                          className={`material-symbols-outlined ${
                            isFirstPage
                              ? "cursor-not-allowed text-neutral-light"
                              : "cursor-pointer"
                          }`}
                        >
                          first_page
                        </button>
                        {/* <button type="button" onClick={handlePreviousPage} disabled={isFirstPage}>
                          <span className="material-symbols-outlined">
                            chevron_left
                          </span>
                        </button> */}
                        <button
                          type="button"
                          onClick={handlePreviousPage}
                          disabled={isFirstPage}
                          className={`material-symbols-outlined ${
                            isFirstPage
                              ? "cursor-not-allowed text-neutral-light"
                              : "cursor-pointer"
                          }`}
                        >
                          chevron_left
                        </button>
                        {/* <button type="button" onClick={handleNextPage} disabled={isLastPage}>
                          <span className="material-symbols-outlined">
                            chevron_right
                          </span>
                        </button> */}
                        <button
                          type="button"
                          onClick={handleNextPage}
                          disabled={isLastPage}
                          className={`material-symbols-outlined ${
                            isLastPage
                              ? "cursor-not-allowed text-neutral-light"
                              : "cursor-pointer"
                          }`}
                        >
                          chevron_right
                        </button>
                        {/* <button type="button" className="disabled:text-neutral-light" onClick={handleLastPage} disabled={isLastPage}>
                          <span className="material-symbols-outlined text-inherit">
                            last_page
                          </span>
                        </button> */}
                        <button
                          type="button"
                          className={`material-symbols-outlined ${
                            isLastPage
                              ? "cursor-not-allowed text-neutral-light"
                              : "cursor-pointer"
                          }`}
                          onClick={handleLastPage}
                          disabled={isLastPage}
                        >
                          last_page
                        </button>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div></div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
