import React, { useEffect, useState, useContext } from "react";
import tvsImage from "../assets/img/TVS-Racer-Bike.webp";
import tvsLogo from "../assets/img/tvs-logo.png";
import Button from "../components/ui/Button";
import Input from "../components/ui/Input";
import Select from "../components/ui/Select";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { apiService } from "../api.service";
import { service } from "../service";
import toast from "react-hot-toast";
import { Dialog, DialogPanel } from "@headlessui/react";
import { useGlobalContext } from "../context/global.context.js";

const validationSchema = Yup.object().shape({
  username: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
  role: Yup.string().required("Required"),
});

export default function Login() {
  const navigate = useNavigate();
  const [roleOptions, setRoleOptions] = useState([]);
  const [showConfirmResetPassword, setShowConfirmResetPassword] =
    useState(false);
  const { setMessage, setError } = useGlobalContext();

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      role: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const request = {
        username: values.username,
        password: values.password,
        role: values.role,
      };
      const loadingToast = toast.loading("Loading data...");

      apiService
        .login(request)
        .then((response) => {
          console.log(response.data);
          const { accessToken, refreshToken } = response?.data?.data?.token;

          // Store tokens and dealer info in local storage
          service.setSessionStorageItem("accessToken", accessToken);
          service.setSessionStorageItem("refreshToken", refreshToken);
          service.setSessionStorageItem(
            "dealerInfo",
            response.data.data?.dealer
          );

          // Navigate to the appropriate route
          navigate(response.data.data.navigateTo);
          window.location.reload();
        })
        .catch((error) => {
          service.notifyApiError(error, setMessage, setError);
        })
        .finally(() => {
          toast.dismiss(loadingToast);
        });
    },
  });

  const onResetPassword = async () => {
    const payload = {
      username: formik.getFieldProps("username")?.value,
      role: formik.getFieldProps("role")?.value,
    };
    await apiService
      .generateResetToken(payload)
      .then((res) => {
        toast.success(res.data.message);
        setShowConfirmResetPassword(false);
      })
      .catch((error) => {
        service.notifyApiError(error, setMessage, setError);
      });
  };

  const onSelectOption = (value) => {
    formik.setFieldValue("role", value);
  };

  const getRoles = () => {
    const loadingToast = toast.loading("Loading data...");
    apiService
      .getRoles()
      .then((res) => {
        setRoleOptions(res.data.data);
      })
      .catch((error) => {
        service.notifyApiError(error, setMessage, setError);
      })
      .finally(() => {
        toast.dismiss(loadingToast);
      });
  };

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <>
      <Dialog
        open={showConfirmResetPassword}
        onClose={() => setShowConfirmResetPassword(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/75" aria-hidden="true" />

        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <DialogPanel className=" space-y-4 border bg-white border-neutral rounded">
            <div className="bg-neutral-lightest border-b border-neutral px-6 py-3 flex justify-center text-left">
              <span className="font-bold text-xl w-full">Reset Password</span>
            </div>
            <div className="px-20 pb-6">
              <span className="text-center">
                Are you sure you want to reset your password?
              </span>

              <div className="flex items-center justify-center gap-4 mt-4 ">
                <Button
                  type="button"
                  label="Cancel"
                  variant="ghost"
                  className="max-w-12"
                  onClick={() => setShowConfirmResetPassword(false)}
                />
                <Button
                  type="submit"
                  label="Reset Password"
                  variant="primary"
                  className="max-w-40"
                  onClick={onResetPassword}
                />
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
      <div className="flex justify-center items-center p-2 mt-2">
        <div className="flex flex-col items-center">
          <div className="bg-white border border-neutral-light rounded">
            <div>
              <img
                src={tvsImage}
                className="w-96 h-52 object-cover rounded-t"
                alt="Tvs Bike Image"
              />
            </div>
            <div className=" flex flex-col items-center w-full md:w-96 pb-8 mt-4">
              <div className="flex flex-col items-center">
                <img src={tvsLogo} className="w-36" />
              </div>
              <div className="mt-2">
                <form onSubmit={formik.handleSubmit}>
                  <div className="grid grid-cols-1 gap-y-2 w-72">
                    <div className="flex flex-col">
                      <Input
                        {...formik.getFieldProps("username")}
                        formik={formik}
                        id="username"
                        name="username"
                        type="text"
                        label="Username"
                        placeholder="Enter your Username"
                      />
                    </div>
                    <div className="flex flex-col">
                      <Input
                        {...formik.getFieldProps("password")}
                        formik={formik}
                        id="password"
                        name="password"
                        type="password"
                        label="Password"
                        placeholder="Enter your password"
                      />
                    </div>
                    <div className="flex flex-col">
                      <Select
                        {...formik.getFieldProps("role")}
                        formik={formik}
                        id="role"
                        className="w-full"
                        name="role"
                        label="Role"
                        onSelect={(text) => onSelectOption(text)}
                        options={roleOptions}
                      />
                    </div>
                    <div className="flex justify-end mt-1">
                      <button
                        type="button"
                        className="text-xs text-primary"
                        onClick={() => setShowConfirmResetPassword(true)}
                      >
                        Reset Password
                      </button>
                    </div>
                    <Button
                      id="login-button"
                      type="submit"
                      label="Login"
                      variant="primary"
                      className="mt-2"
                    ></Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="mt-2 text-xs">
            Copyright © 2017 Indicosmic Capital Pvt. Ltd.
          </div>
        </div>
      </div>
      {/* <Toaster /> Place the Toaster here */}
    </>
  );
}
