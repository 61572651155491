import React, { useState, useEffect } from "react";
import coverImage from "../../assets/img/coverImage.png";
import CurrencyFormat from "react-currency-format";
import Button from "../../components/ui/Button";
import toast, { Toaster } from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import File from "../../components/ui/File";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useNavigate } from "react-router-dom";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { apiService } from "../../api.service";
import FileSaver from "file-saver";
import Tippy from "@tippyjs/react";
import moment from "moment";
import {
    Description,
    Dialog,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import _ from "lodash";
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
    SortingState,
    getSortedRowModel,
    getPaginationRowModel,
} from "@tanstack/react-table";
import { tab } from "@testing-library/user-event/dist/tab";
import { service } from "../../service";
import { useGlobalContext } from "../../context/global.context";
import Select from "../../components/ui/Select";
import Input from "../../components/ui/Input";
import Textarea from "../../components/ui/Textarea";


export const GstApprove = () => {
    const { setMessage, setError } = useGlobalContext()
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [disableAboutToExpireSelect, setDisableAboutToExpireSelect] = useState(true);
    const [ShowGSTStatusModal, setShowGSTStatusModal] = useState(false);
    const [showGSTModal, setshowGSTModal] = useState(false);
    const [showActionModal, setshowActionModal] = useState(false);
    const [showDetailsGSTModal, setShowDetailsGSTModal] = useState({})
    const [updateGstApprovalStatus, setupdateGstApprovalStatus] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [activeTabName, setActiveTabName] = useState("");
    const [sorting, setSorting] = React.useState([]);
    const [payload, setPayload] = useState({
        pagination: {
            pageNumber: 1,
            pageSize: 5,
        },

    });
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [showDateRangeModal, setShowDateRangeModal] = useState(false);
    const [invoiceSummaryData, setInvoiceSummaryData] = useState({});
    const [invoiceData, setInvoiceData] = useState({});
    const [invoiceSummaryFooterData, setInvoiceSummaryFooterData] = useState({});
    const [gstListings, setGstListings] = useState([]);
    const [gstStatusOptions, setgstStatusOptions] = useState([]);
    const [gstSearchOptions, setgstSearchOptions] = useState([]);
    const [searchField, setSearchField] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [searchOptions, setSearchOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [noRecordFound, setNoRecordFound] = useState(false);
    const navigate = useNavigate();
    const [searchClicked, setSearchClicked] = useState(false);
    const columnHelper = createColumnHelper();
    const getButtonStyles = (status) => {
        const baseClasses =
            "w-fit px-4 flex items-center justify-center rounded-full font-semibold";
        switch (status ? status.toLowerCase() : "") {
            case "pending":
                return `${baseClasses} bg-warn-lightest text-warn capitalize`;
            case "rejected":
                return `${baseClasses} bg-neutral-lightest text-danger capitalize`;
            case "approved":
                return `${baseClasses} bg-success-lightest text-success capitalize`;
            case "referback":
                return `${baseClasses} bg-neutral-lightest text-neutral-darkest capitalize`;
            default:
                return `${baseClasses} bg-warn-lightest text-warn.lightest capitalize`;
        }
    };
    const dateFilterForm = useFormik({
        initialValues: {
            fromDate: "",
            toDate: "",
        },
        validationSchema: Yup.object().shape({
            fromDate: Yup.date().required("Required"),
            toDate: Yup.date().required("Required"),
        }),
        onSubmit: async (values) => {
            console.log(values);
            setPayload((prev) => ({
                ...prev,
                fromDate: values.fromDate,
                toDate: values.toDate,
            }));
            console.log(payload);
            setShowDateRangeModal(false);
        },
    });
    const columnsGSTApproval = [

        columnHelper.accessor("dealerName", {
            header: "Dealer Name",
        }),
        columnHelper.accessor("dealerCode", {
            header: "Dealer Code",
        }),
        columnHelper.accessor("gstNo", {
            header: "GST No",
        }),
        columnHelper.accessor("panNo", {
            header: "PAN No",
        }),
        columnHelper.accessor("invoiceNumber", {
            header: "Invoice Number",
        }),
        columnHelper.accessor("invoiceDate", {
            header: "Invoice Date",
            cell: (data) => {
                return (
                    <span>
                        {data.getValue()
                            ? moment(data.getValue()).format("Do MMM YYYY")
                            : "-"}
                    </span>
                );
            },
        }),

        columnHelper.accessor("invoiceMonth", {
            header: "Invoice Month",
        }),
        columnHelper.accessor("gstAmount", {
            header: "GST Amount",
            cell: (data) => {
                return (
                    <span>

                        {data.getValue() ? (
                            <CurrencyFormat
                                value={data.getValue()}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"₹"}
                            />
                        ) : (
                            "-"
                        )}
                    </span>
                );
            },
        }),
        columnHelper.accessor("createdAt", {
            header: "Created Date",
            cell: (data) => {
                return (
                    <span>
                        {data.getValue()
                            ? moment(data.getValue()).format("Do MMM YYYY")
                            : "-"}
                    </span>
                );
            },
        }),

        columnHelper.accessor("status", {
            header: "Status",
            cell: (data) => {
                const status = data.getValue();
                return (
                    <span className={`px-2 py-1 rounded ${getButtonStyles(status)}`}>
                        {status}
                    </span>
                );
            },
        }),
        columnHelper.accessor("gstCompliantFileUrl", {
            header: "Complient Name",
        }),
        columnHelper.accessor("", {
            header: "Action",
            cell: (data) => {
                return (
                    <div className="text-center">

                        <Tippy
                            content="View GST"
                            placement="right"
                            arrow={false}
                            className="rounded-sm text-xs"
                            delay={1000}
                        >
                            <button
                                type="button"
                                className="mt-1 mr-1"
                                onClick={() => { setshowGSTModal(true); console.log(data.row.original); setShowDetailsGSTModal(data.row.original) }}
                            >
                                <span className="material-symbols-outlined text-neutral-darkest text-sm">
                                    visibility
                                </span>
                            </button>
                        </Tippy>


                    </div>
                );
            },
        }),
    ];

    const table = useReactTable({
        columns: columnsGSTApproval,
        data: filteredData.length > 0 ? filteredData : data,
        //onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        //debugTable: true,
        manualPagination: true,
    });

    console.log(columnsGSTApproval, 'columnsGSTApproval ', data, '>>>>>>>>>>>>>>>>>>>>>>>');

    const totalPages = Math.ceil(totalCount / payload.pagination.pageSize);
    const isLastPage = payload.pagination.pageNumber === totalPages;
    const isFirstPage = payload.pagination.pageNumber === 1;

    const handleNextPage = () => {
        try {
            setPayload((prev) => {
                const nextPage = prev.pagination.pageNumber + 1;
                return {
                    ...prev,
                    pagination: {
                        ...prev.pagination,
                        pageNumber: nextPage > totalPages ? totalPages : nextPage,
                    },
                };
            });
        } catch (error) {
            service.notifyApiError(error, setMessage, setError);
        }

    };

    const handleLastPage = () => {
        try {
            setPayload((prev) => ({
                ...prev,
                pagination: {
                    ...prev.pagination,
                    pageNumber: totalPages,
                },
            }));
        } catch (error) {
            service.notifyApiError(error, setMessage, setError)
        }

    };
    const handlePreviousPage = () => {
        try {
            setPayload((prev) => {
                const prevPage = prev.pagination.pageNumber - 1;
                return {
                    ...prev,
                    pagination: {
                        ...prev.pagination,
                        pageNumber: prevPage < 1 ? 1 : prevPage,
                    },
                };
            });
        } catch (error) {
            service.notifyApiError(error, setMessage, setError)
        }

    };
    const handleFirstPage = () => {
        try {
            setPayload((prev) => ({
                ...prev,
                pagination: {
                    ...prev.pagination,
                    pageNumber: 1,
                },
            }));
        } catch (error) {
            service.notifyApiError(error, setMessage, setError)
        }
    };
    const fetchGstTransactions = async () => {

        setLoading(true)
        try {

            const response = await apiService
                .gstListings(payload)
                .then((response) => {
                    if (response?.data?.data.length === 0) {
                        setNoRecordFound(true);
                    }
                    setData(response.data.data);
                    console.log(response.data, 'setData')
                    setTotalCount(response.data.totalCount || data.length);
                    setNoRecordFound(data.length === 0);
                })
                .catch((error) => {

                    service.notifyApiError(error, setMessage, setError);
                })
                .finally(() => {

                    setLoading(false)

                });
            console.log("Gst listings fetch successfully:", response.data);
        } catch (error) {
            console.error("Gst listings fetch successfully:", error);
        }
    };
    const gstListingsApproval = async () => {
        const loadingToast = toast.loading("Loading data");
        try {
            await apiService.gstListings(payload).then((res) => {
                console.log(res.data.data)
                setGstListings(res.data.data);
            });
        } catch (error) {
            console.error("Error fetching status:", error);
            service.notifyApiError(error, setMessage, setError);
        } finally {
            toast.dismiss(loadingToast);
        }
    };

    const getGSTStatusOptions = async () => {
        const loadingToast = toast.loading("Loading data");
        try {
            await apiService.gstStatusOptions().then((res) => {
                setgstStatusOptions(res.data.data);
            });
        } catch (error) {
            console.error("Error fetching gst Status Fetching:", error);
            service.notifyApiError(error, setMessage, setError);
        } finally {
            toast.dismiss(loadingToast);
        }
    };
    const getSearchOptions = async () => {
        const loadingToast = toast.loading("Loading data");
        try {
            const res = await apiService.searchOptions({ type: 'gst' });
            setgstSearchOptions(res.data.data);
            if (res.data.data.length > 0) {
                setSearchField(res.data.data[0].value);
            }
        } catch (error) {
            console.error("Error fetching search options:", error);
            service.notifyApiError(error, setMessage, setError);
        } finally {
            toast.dismiss(loadingToast);
        }
    };

    const onClickAction = () => {
        setshowActionModal(true);
    };


    const gstStatusForm = useFormik({
        initialValues: {
            status: "",
            comments: "",
        },
        validationSchema: Yup.object().shape({
            status: Yup.number().required("Required").integer("must be integer"),
            comments: Yup.string().when("status", {
                is: (status) => status === 2 || status === 4,
                then: () =>
                    Yup.string()
                        .required("Required"),
                otherwise: () =>
                    Yup.string().notRequired()
            })
        }),
        onSubmit: async (values) => {
            console.log("onsumbit", values);
            try {
                const payload = {
                    status: values.status,
                    invoiceNumber: showDetailsGSTModal.invoiceNumber,
                    comments: values.comments,
                }
                console.log("paylaod", payload)
                await apiService.updateGstApprovalStatus(payload)
                    .then((res) => {
                        setupdateGstApprovalStatus(res.data.data);
                        toast.success(res.data.message);
                        resetState()
                    })
            } catch (error) {
                console.error("Error updating GST approval status:", error);
                toast.error("Failed to update GST approval status.");
            }

        },

    });

    const isPending = gstStatusForm.values.status === "Pending";
    console.log("ispending", isPending);


    useEffect(() => {
        getGSTStatusOptions();
        getSearchOptions();
    }, [])
    useEffect(() => {

        if (showDetailsGSTModal && showDetailsGSTModal.status) {
            gstStatusForm.setFieldValue("status", showDetailsGSTModal.status);
        }
    }, [showDetailsGSTModal]);
    const resetState = () => {
        setSelectedTabIndex(0);
        setData([]);
        setTotalCount(0);
        setShowGSTStatusModal(false);
        setFilteredData([]);
        setSorting([]);
        setPayload({
            pagination: {
                pageNumber: 1,
                pageSize: 5
            },
        });
        setShowStatusModal(false);
        setShowDateRangeModal(false);
        setshowGSTModal(false);
        setInvoiceSummaryData({});
        setLoading(true);
        setNoRecordFound(false);
    };
    useEffect(() => {
        console.log("Payload updated:");
        fetchGstTransactions();
    }, [payload]);
    useEffect(() => {
        gstListingsApproval()
    }, [])

    useEffect(() => {
        if (data?.length == 0) {
            setNoRecordFound(true);
        } else {
            setNoRecordFound(false);
        }
    }, [data]);

    return (
        <>
            {gstStatusForm && (
                <Dialog
                    open={showGSTModal}
                    onClose={() => setshowGSTModal(false)}
                    className="relative z-50"
                >
                    <div className="fixed inset-0 bg-black/75" aria-hidden="true" />

                    <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                        <DialogPanel className=" space-y-4  max-w-full  border bg-white border-neutral rounded w-1/2">
                            <form onSubmit={gstStatusForm.handleSubmit}>
                                <div className="bg-neutral-lightest border-b border-neutral px-6 py-3">

                                    <div className="flex items-center justify-between">
                                        <span className="font-bold text-xl text-neutral-darkest">GST Uploaded File</span>
                                    </div>
                                </div>
                                <div className="px-6 py-2 space-y-4">

                                    <div className="flex items-center gap-1">
                                        <span className="text-neutral-darkest">Dealer Name:</span>
                                        <span className="font-semibold text-base">{showDetailsGSTModal.dealerName}</span>
                                        <span className="text-neutral-darkest ml-2">Dealer Code:</span>
                                        <span className="font-semibold text-base">{showDetailsGSTModal.dealerCode}</span>
                                        <span className="text-neutral-darkest ml-2">Invoice No:</span>
                                        <span className="font-semibold text-base">{showDetailsGSTModal.invoiceNumber}</span>
                                    </div>

                                    <div className="flex items-center gap-1">
                                        <span className="text-neutral-darkest">GST No:</span>
                                        <span className="font-semibold text-base">{showDetailsGSTModal.gstNo}</span>
                                        <span className="text-neutral-darkest ml-2">PAN No:</span>
                                        <span className="font-semibold text-base">{showDetailsGSTModal.panNo}</span>
                                    </div>

                                    <div className="flex items-center gap-1 gap-x-2">
                                        <span className="text-neutral-darkest">GST Amount:</span>
                                        <span className="font-semibold text-base">{showDetailsGSTModal.gstAmount}</span>
                                        <span className="mx-4"></span>
                                        <span className="text-neutral-darkest ml-2">GST Month:</span>
                                        <span className="font-semibold text-base">{showDetailsGSTModal.invoiceMonth}</span>

                                    </div>
                                    <div className="flex items-center gap-2">
                                        <span className="text-neutral-darkest">Status:</span>
                                        <span
                                            className={`text-sm ${getButtonStyles(gstStatusForm.values.status)}`}
                                        >
                                            {showDetailsGSTModal.status}
                                        </span>
                                    </div>
                                    {showDetailsGSTModal.status === "Pending" &&
                                        <>
                                            <div className="flex items-start gap-4 mt-2 max-w-full">
                                                <Select
                                                    {...gstStatusForm.getFieldProps("status")}
                                                    formik={gstStatusForm}
                                                    value={gstStatusForm.values.status}
                                                    id="status"
                                                    name="status"
                                                    label=" select status"
                                                    className="border border-gray-300 rounded-lg p-2"
                                                    options={gstStatusOptions.filter(option => option.id != 1)}
                                                    onSelect={(value) => gstStatusForm.setFieldValue("status", value)}
                                                />
                                                <div className="w-1/2">
                                                    <Textarea
                                                        {...gstStatusForm.getFieldProps("comments")}
                                                        formik={gstStatusForm}
                                                        id="comments"
                                                        name="comments"
                                                        label="comments"
                                                        type="text"
                                                        placeholder="Enter comment"
                                                        className="border border-gray-300 rounded-lg p-2 w-full"
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex items-center justify-center gap-4 mt-4 px-12 pb-8">
                                                <Button
                                                    type="button"
                                                    label="Close"
                                                    variant="ghost"
                                                    className="max-w-12"
                                                    onClick={() => setshowGSTModal(false)}
                                                />

                                                <Button
                                                    type="submit"
                                                    label="Confirm"
                                                    variant="primary"
                                                    className="max-w-12"
                                                />


                                            </div>
                                        </>
                                    }
                                    {showDetailsGSTModal.status !== "Pending" && (
                                        <div className="flex justify-center">
                                            <Button
                                                type="button"
                                                label="Close"
                                                variant="ghost"
                                                className="max-w-12"
                                                onClick={() => setshowGSTModal(false)}
                                            />
                                        </div>
                                    )}
                                </div>
                                {/* 
                                    <div className="flex items-start gap-4 mt-2 max-w-full">
                                        {isPending ? (
                                            <Select
                                                {...gstStatusForm.getFieldProps("status")}
                                                formik={gstStatusForm}
                                                value={gstStatusForm.values.status}
                                                id="status"
                                                name="status"
                                                className="border border-gray-300 rounded-lg p-2"
                                                options={gstStatusOptions.filter(option => option.id != 1)}
                                                onSelect={(value) => gstStatusForm.setFieldValue("status", value)}
                                            />
                                        ) : (
                                            <div className="flex items-center gap-2">
                                                <span className="text-neutral-darkest">Status:</span>
                                                <span
                                                    className={`text-sm ${getButtonStyles(gstStatusForm.values.status)}`}
                                                >
                                                    {showDetailsGSTModal.status}
                                                </span>
                                            </div>
                                        )}

                                        {isPending && (
                                            <div className="w-1/2">
                                                <Textarea
                                                    {...gstStatusForm.getFieldProps("comments")}
                                                    formik={gstStatusForm}
                                                    id="comments"
                                                    name="comments"
                                                    label="comments"
                                                    type="text"
                                                    placeholder="Enter comment"
                                                    className="border border-gray-300 rounded-lg p-2 w-full"
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="flex items-center justify-center gap-4 mt-4 px-12 pb-8">
                                    <Button
                                        type="button"
                                        label="Close"
                                        variant="ghost"
                                        className="max-w-12"
                                        onClick={() => setshowGSTModal(false)}
                                    />
                                    {isPending && (
                                        <Button
                                            type="submit"
                                            label="Confirm"
                                            variant="primary"
                                            className="max-w-12"
                                        />
                                    )}

                                </div> */}

                            </form>
                        </DialogPanel>
                    </div>
                </Dialog>
            )}
            <Dialog
                open={showDateRangeModal}
                onClose={() => setShowDateRangeModal(false)}
                className="relative z-50"
            >
                <div className="fixed inset-0 bg-black/75" aria-hidden="true" />

                <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                    <DialogPanel className=" space-y-4 border bg-white border-neutral rounded">
                        <form onSubmit={dateFilterForm.handleSubmit}>
                            <div className="bg-neutral-lightest  border-b border-neutral px-6 py-3 flex">
                                <span className="font-bold text-xl py-2 text-neutral-darkest ">
                                    Filter
                                </span>
                            </div>
                            <div className="flex flex-col gap-y-4 p-4">
                                <select
                                    id="searchField"
                                    className=" py-1 focus:outline-primary text-base-black "
                                >

                                </select>

                                <div className="relative flex items-center w-full md:w-auto">
                                    <input
                                        type="text"
                                        id="search"
                                        placeholder="Search..."
                                        className="px-3 py-1 border border-neutral-dark rounded-l-sm focus:outline-primary text-base-black w-full md:w-auto"
                                    />
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setPayload((prev) => ({
                                                ...prev,
                                                search: {
                                                    field: document.getElementById("searchField").value,
                                                    value: document.getElementById("search").value,
                                                },
                                            }));
                                        }}
                                        className="bg-primary text-white border border-primary px-2 py-1  rounded-r-sm"
                                    >
                                        <span className="material-symbols-outlined flex items-center justify-center p-0">
                                            search
                                        </span>
                                    </button>
                                </div>
                            </div>

                            <div className="flex items-center justify-center gap-4 mt-4 px-12 pb-8">
                                <Button
                                    type="button"
                                    label="Cancel"
                                    variant="ghost"
                                    className="max-w-12"
                                    onClick={() => setShowDateRangeModal(false)}
                                />
                                <Button
                                    type="submit"
                                    label="Submit"
                                    variant="primary"
                                    className="max-w-16"
                                />
                            </div>
                        </form>
                    </DialogPanel>
                </div>
            </Dialog>
            <div className="flex flex-col w-full items-center overflow-hidden">
                <div className="sticky -z-10 w-full">
                    <img
                        src={coverImage}
                        className=" w-full h-36 object-cover"
                        alt="cover_image"
                    />
                </div>
                <div className="w-11/12 bg-white -mt-28 md:-mt-20 border border-neutral-light rounded mx-2">
                    <form>
                        <div>
                            <div className="bg-base-white px-6 py-4 border-b border-neutral-light rounded-t">
                                <h3 className="text-xl font-semibold">
                                    GST Approval{" "}
                                </h3>
                                <p className="text-neutral-darker mt-1 text-sm">
                                    Overview of transaction requests, including their statuses and
                                    approval dates.
                                </p>
                            </div>
                            <div className="px-6 py-4 pb-8">
                                <div>
                                    <Tabs
                                        selectedIndex={selectedTabIndex}
                                        selectedTabClassName="text-primary border-b-2 border-primary font-medium"
                                    >
                                        <TabList className="flex mb-4 text-neutral-dark text-sm items-center justify-between">
                                            <div className="flex gap-1"></div>

                                            <div className="flex flex-col md:flex-row justify-between items-center w-full">
                                                <div className="flex items-center  gap-x-4 mb-2 md:mb-0 w-full">
                                                    <div className="label flex flex-col text-xs">
                                                        <label className="pl-2 ml-2">Approve GST Status</label>
                                                        <select
                                                            value={payload.gstStatusOptions || "gstStatusOptions"}
                                                            onChange={(e) => {
                                                                const gstStatusOptions = parseInt(e.target.value);
                                                                setPayload((prev) => ({
                                                                    ...prev,
                                                                    statusId: gstStatusOptions,
                                                                }));
                                                            }}
                                                            className="px-3 py-1 focus:outline-primary text-base-black"
                                                        >
                                                            <option value="status">Select Status</option>
                                                            {gstStatusOptions.map((option) => (
                                                                <option key={option.value} value={option.id}>
                                                                    {option.label}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>

                                                    <div className="flex">
                                                        <input
                                                            type="checkbox"
                                                            onChange={(e) => {
                                                                setPayload((prev) => ({
                                                                    ...prev,
                                                                    isGstFileUploaded: e.target.checked ? 1 : 0,
                                                                }));
                                                            }}
                                                        />
                                                        <span className="mr-4 ml-1 text-neutral-darkest">
                                                            Document Uploaded
                                                        </span>

                                                    </div>

                                                    <button
                                                        type="button"
                                                        className="mt-1 md:hidden"
                                                        onClick={() => {
                                                            setShowDateRangeModal(true);
                                                        }}
                                                    >
                                                        <span className="material-symbols-outlined text-neutral-darkest justify-end">
                                                            filter_alt
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className="flex items-center gap-x-4 hidden md:flex">
                                                    <button
                                                        type="button"
                                                        className="mt-1"
                                                        onClick={
                                                            resetState
                                                        }
                                                    >
                                                        <span class="material-symbols-outlined text-neutral-darkest">
                                                            refresh
                                                        </span>
                                                    </button>
                                                    <select
                                                        id="searchField"
                                                        value={searchField}
                                                        onChange={(e) => setSearchField(e.target.value)}
                                                        className="px-3 py-1 focus:outline-primary text-base-black"
                                                    >
                                                        {gstSearchOptions.map((option) => (
                                                            <option key={option.value} value={option.value}>
                                                                {option.label}
                                                            </option>
                                                        ))}
                                                    </select>


                                                    <div className="relative flex items-center w-full md:w-auto">
                                                        <input
                                                            type="text"
                                                            id="search"
                                                            value={searchValue}
                                                            onChange={(e) => setSearchValue(e.target.value)}
                                                            placeholder="Search..."
                                                            className="px-3 py-1 border border-neutral-dark rounded-l-sm focus:outline-primary text-base-black w-full md:w-auto"
                                                        />
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                setPayload((prev) => ({
                                                                    ...prev,
                                                                    search: {
                                                                        field: searchField,
                                                                        value: searchValue,
                                                                    },
                                                                }));
                                                            }}

                                                            className="bg-primary text-white border border-primary px-2 py-1 md:py-0.5 rounded-r-sm"
                                                        >
                                                            <span className="material-symbols-outlined flex items-center justify-center">
                                                                search
                                                            </span>
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>

                                        </TabList>
                                        <TabPanel>
                                            <div className="flex mb-4 overflow-x-scroll cursor-pointer">
                                                <table className="text-sm border border-neutral w-full overflow-x-scroll">
                                                    <thead className="bg-primary text-white text-xs">
                                                        {table.getHeaderGroups()?.map((headerGroup) => (
                                                            <tr key={headerGroup.id}>
                                                                {headerGroup.headers.map((header) => {
                                                                    return (
                                                                        <th
                                                                            key={header.id}
                                                                            colSpan={header.colSpan}
                                                                            className="px-2 py-1 text-left min-w-[100px] max-w-[300px]"
                                                                        >
                                                                            {header.isPlaceholder ? null : (
                                                                                <div
                                                                                    className={
                                                                                        header.column.getCanSort()
                                                                                            ? "cursor-pointer select-none"
                                                                                            : ""
                                                                                    }
                                                                                    onClick={header.column.getToggleSortingHandler()}
                                                                                    title={
                                                                                        header.column.getCanSort()
                                                                                            ? header.column.getNextSortingOrder() ===
                                                                                                "asc"
                                                                                                ? "Sort ascending"
                                                                                                : header.column.getNextSortingOrder() ===
                                                                                                    "desc"
                                                                                                    ? "Sort descending"
                                                                                                    : "Clear sort"
                                                                                            : undefined
                                                                                    }
                                                                                >
                                                                                    {flexRender(
                                                                                        header.column.columnDef.header,
                                                                                        header.getContext()
                                                                                    )}
                                                                                    {{
                                                                                        asc: " 🔼",
                                                                                        desc: " 🔽",
                                                                                    }[header.column.getIsSorted()] ??
                                                                                        null}
                                                                                </div>
                                                                            )}
                                                                        </th>
                                                                    );
                                                                })}
                                                            </tr>
                                                        ))}
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            loading ? (
                                                                <tr className="min-h-20">
                                                                    <td colSpan={columnsGSTApproval.length} className="text-center p-4 min-h-20">
                                                                        Loading...
                                                                    </td>
                                                                </tr>
                                                            ) :
                                                                noRecordFound ? (
                                                                    <tr className="min-h-20">
                                                                        <td
                                                                            colSpan={columnsGSTApproval.length}
                                                                            className="text-center p-4 min-h-20"
                                                                        >
                                                                            No records found
                                                                        </td>
                                                                    </tr>
                                                                ) : (
                                                                    table.getRowModel().rows.map((row) => (
                                                                        <tr
                                                                            key={row.id}
                                                                            className="text-xs even:bg-base-white"
                                                                        >
                                                                            {row.getVisibleCells().map((cell) => (
                                                                                <td key={cell.id} className="px-2 py-1">
                                                                                    {flexRender(
                                                                                        cell.column.columnDef.cell,
                                                                                        cell.getContext()
                                                                                    )}
                                                                                </td>
                                                                            ))}
                                                                        </tr>
                                                                    ))
                                                                )}
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="flex flex-col md:flex-row items-center w-full justify-end text-xs gap-4">
                                                <div className="flex items-center gap-2">
                                                    <select
                                                        value={payload.pageSize}
                                                        onChange={(e) => {
                                                            const size = Number(e.target.value);
                                                            setPayload((prev) => ({
                                                                ...prev,
                                                                pagination: {
                                                                    pageSize: size,
                                                                    pageNumber: 1,
                                                                },
                                                            }));
                                                        }}
                                                        className="md:w-auto"
                                                    >
                                                        {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map(
                                                            (pageSize) => (
                                                                <option key={pageSize} value={pageSize}>
                                                                    {pageSize}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                    <span className="font-semibold">
                                                        <span>Showing </span>
                                                        {1 +
                                                            (payload.pagination.pageNumber - 1) *
                                                            payload.pagination.pageSize}{" "}
                                                        to{" "}
                                                        {payload.pagination.pageNumber *
                                                            payload.pagination.pageSize -
                                                            payload.pagination.pageSize +
                                                            data?.length}{" "}
                                                        of {totalCount} Entries
                                                    </span>
                                                </div>

                                                <div className="flex items-center space-x-2 mt-2 md:mt-0">
                                                    <button
                                                        type="button"
                                                        disabled={isFirstPage}
                                                        onClick={handleFirstPage}
                                                        className={`material-symbols-outlined ${isFirstPage
                                                            ? "cursor-not-allowed text-neutral-light"
                                                            : "cursor-pointer"
                                                            }`}
                                                    >
                                                        first_page
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={handlePreviousPage}
                                                        disabled={isFirstPage}
                                                        className={`material-symbols-outlined ${isFirstPage
                                                            ? "cursor-not-allowed text-neutral-light"
                                                            : "cursor-pointer"
                                                            }`}
                                                    >
                                                        chevron_left
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={handleNextPage}
                                                        disabled={isLastPage}
                                                        className={`material-symbols-outlined ${isLastPage
                                                            ? "cursor-not-allowed text-neutral-light"
                                                            : "cursor-pointer"
                                                            }`}
                                                    >
                                                        chevron_right
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className={`material-symbols-outlined ${isLastPage
                                                            ? "cursor-not-allowed text-neutral-light"
                                                            : "cursor-pointer"
                                                            }`}
                                                        onClick={handleLastPage}
                                                        disabled={isLastPage}
                                                    >
                                                        last_page
                                                    </button>
                                                </div>
                                            </div>
                                        </TabPanel>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};