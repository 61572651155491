import "./App.css";
import React, { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { isMobile } from "react-device-detect";
import loadingBarService from "./service/loadingbar.Service";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import DealerDashboard from "./pages/DealerDashboard";
import DealerRegistration from "./pages/DealerRegistration";
import PageNotFound from "./pages/PageNotFound";
import AppLayout from "./AppLayout";
import DealerUploadDocuments from "./pages/DealerUploadDocuments";
import DealerBankTransactions from "./pages/DealerBankTransactions";
import PurchaseStatus from "./pages/PurchaseStatus";
import TransactionRequests from "./pages/TransactionRequests";
import ProtectedRoute from "./components/ProtectedRoute";
import GeneratePolicy from "./pages/GeneratePolicy";
import DealerRegistrationStatus from "./pages/DealerRegistrationStatus";
import SendOTP from "./pages/SendOTP";
import OTPVerification from "./pages/OTPVerification";
import { PolicyData } from "./pages/PolicyData";
import { DealerQRCode } from "./pages/DealerQRCode";
import { Invoice } from "./pages/Invoice";
import { GstTransaction } from "./pages/GstTransaction";
import { TransactionStatement } from "./pages/TransactionStatement";
import { ServerDown } from "./pages/ServerDown";
import { RMData } from "./pages/admin/RMData";
import { SoldPolicies } from "./pages/admin/SoldPolicies";
import { DealerListings } from "./pages/admin/DealerListings";
import { InvoiceApproval } from "./pages/admin/InvoiceApproval.jsx";
import { GstApprove } from "./pages/admin/ApproveGST.jsx";
import { TransactionApproval } from "./pages/admin/TransactionApproval.jsx";
import { Pincode } from "./pages/admin/Pincode";
import { FeedFile } from "./pages/admin/FeedFile";
import { CancelPolicies } from "./pages/admin/CancelPolicies";
import { ICPayments } from "./pages/admin/ICPayments";
import { RsaReport } from "./pages/admin/service/RsaReport";
import { TotalRsaReport } from "./pages/admin/service/TotalRsaReport";
import { SoldPolicies as ServiceSoldPolicies } from "./pages/admin/service/SoldPolicies";
import { ServiceDashboard } from "./pages/admin/service/ServiceDashboard";
import { SalesDashboard } from "./pages/admin/sales/SalesDashboard";
import { AdminDashboard } from "./pages/admin/AdminDashboard";
import { DealerProfile } from "./pages/DealerProfile";
import { service } from "./service.js";
import { GlobalProvider } from "./context/global.context";
import { DealerTransactionsStatement } from "./pages/DealerTransactionsStatement";
import { PolicyCancellationRequest } from "./pages/PolicyCancellationRequest.jsx";
import { useGlobalContext } from "./context/global.context";
import ErrorDialog from "./components/ErrorDialog.jsx";
import ErrorToast from "./components/toast/ErrorToast";
import { DocumentStatus } from "./pages/admin/DocumentStatus.jsx";
import { AddDealer } from "./pages/admin/AddDealer";
import ResetPassword from "./pages/ResetPassword";
import Error from "./pages/Error";

const App = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const accessToken = urlParams.get("accessToken");
    const refreshToken = urlParams.get("refreshToken");
    if (accessToken && refreshToken) {
      // Store tokens and dealer info in local storage
      service.setSessionStorageItem("accessToken", accessToken);
      service.setSessionStorageItem("refreshToken", refreshToken);
    }
  }, []);

  useEffect(() => {
    // Example: Starting the loading bar when the app component mounts
    loadingBarService.start();
    setTimeout(() => loadingBarService.complete(), 2000);
    // return () => loadingBarService.complete();

    // Capture unhandled promise rejections
    window.addEventListener("unhandledrejection", (event) => {
      toast.error(`Unhandled Promise Rejection: ${event.reason}`);
    });

    // Capture uncaught errors
    window.addEventListener("error", (event) => {
      toast.error(`Error: ${event.message}`);
    });

    return () => {
      // Clean up the event listeners on component unmount
      window.removeEventListener("unhandledrejection", () => {});
      window.removeEventListener("error", () => {});
    };
  }, []);

  const toastPosition = isMobile ? "bottom-center" : "bottom-right";

  return (
    <>
      <Toaster
        toastOptions={{
          className: "text-sm",
          position: `${toastPosition}`,
          // duration: 10000,
          success: {
            duration: 10000,
          },
          error: {
            duration: 10000,
          },
          loading: {
            duration: 60000,
          },
          style: {
            borderRadius: "4px",
            padding: "0.5rem 1rem",
            backgroundColor: "#020A12",
            color: "white",
          },
        }}
      />
      {/* Wrapping everything inside ErrorBoundary */}

      <GlobalProvider>
        <ErrorToast />
        <ErrorDialog />
        <BrowserRouter>
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="send-otp" element={<SendOTP />} />
            <Route path="otp-verification" element={<OTPVerification />} />
            <Route path="server-down" element={<ServerDown />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="error" element={<Error />} />
            <Route path="*" element={<PageNotFound />} />

            {/* Protected routes inside AppLayout */}
            <Route element={<AppLayout />}>
              <Route element={<ProtectedRoute />}>
                <Route index element={<Navigate to="login" />} />
                <Route path="profile" element={<DealerProfile />} />
                <Route path="dashboard" element={<DealerDashboard />} />
                <Route
                  path="dealer-registration"
                  element={<DealerRegistration />}
                />
                <Route
                  path="upload-dealer-documents"
                  element={<DealerUploadDocuments />}
                />
                <Route
                  path="dealer-registration-status"
                  element={<DealerRegistrationStatus />}
                />
                <Route path="document-status" element={<DocumentStatus />} />
                <Route
                  path="bank-transactions"
                  element={<DealerBankTransactions />}
                />
                <Route
                  path="service/generate-policy/:dealerId"
                  element={<GeneratePolicy tvsType="service" />}
                />
                <Route
                  path="service/generate-policy"
                  element={<GeneratePolicy tvsType="service" />}
                />
                <Route
                  path="sales/generate-policy/:dealerId"
                  element={<GeneratePolicy tvsType="sales" />}
                />
                <Route
                  path="sales/generate-policy"
                  element={<GeneratePolicy tvsType="sales" />}
                />
                <Route
                  path="payment-status"
                  element={<TransactionRequests />}
                />
                <Route path="sold-policies" element={<PolicyData />} />
                <Route path="qr-code" element={<DealerQRCode />} />
                {/* <Route
                  path="purchase-status/:policyNumber"
                  element={<PurchaseStatus />}
                /> */}
                <Route
                  path="sales/purchase-status/:policyNumber"
                  element={<PurchaseStatus />}
                />
                <Route
                  path="service/purchase-status/:policyNumber"
                  element={<PurchaseStatus />}
                />
                <Route path="invoice" element={<Invoice />} />
                <Route path="gst-transaction" element={<GstTransaction />} />
                <Route
                  path="transaction-statement"
                  element={<TransactionStatement />}
                />
                <Route
                  path="dealer-transaction-statement"
                  element={<DealerTransactionsStatement />}
                />
                <Route
                  path="policy-cancellation-requests"
                  element={<PolicyCancellationRequest />}
                />
                <Route path="reset-password" element={<ResetPassword />} />
              </Route>
              {/* Admin routes */}
              <Route path="admin" element={<ProtectedRoute />}>
                <Route path="dashboard" element={<AdminDashboard />} />
                <Route path="sold-policies" element={<SoldPolicies />} />
                <Route path="rm-data" element={<RMData />} />
                <Route path="pincode" element={<Pincode />} />
                <Route path="ic-payments" element={<ICPayments />} />
                <Route path="feed-file" element={<FeedFile />} />
                <Route path="cancel-policies" element={<CancelPolicies />} />
                <Route path="dealers" element={<DealerListings />} />
                <Route path="invoice-approval" element={<InvoiceApproval />} />
                <Route path="approve-gst" element={<GstApprove />} />
                <Route path="add-dealer" element={<AddDealer />} />
                <Route
                  path="transaction-approval"
                  element={<TransactionApproval />}
                />

                {/* Admin Service */}
                <Route path="service" element={<ProtectedRoute />}>
                  <Route
                    path="sold-policies"
                    element={<ServiceSoldPolicies />}
                  />
                  <Route path="dashboard" element={<ServiceDashboard />} />
                  <Route path="rsa-report" element={<RsaReport />} />
                  <Route path="total-rsa-report" element={<TotalRsaReport />} />
                </Route>

                {/* Admin Sales */}
                <Route path="sales" element={<ProtectedRoute />}>
                  <Route path="dashboard" element={<SalesDashboard />} />
                </Route>
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </GlobalProvider>
    </>
  );
};

export default App;
