import React, { createContext, useState, useContext, useEffect } from "react";

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [isQrPolicy, setIsQrPolicy] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  useEffect(() => {
  }, [showErrorModal]);

  const value = {
    isQrPolicy,
    setIsQrPolicy,
    showErrorModal,
    setShowErrorModal,
    error,
    setError,
    message,
    setMessage,
  };
  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};
