import React, { useEffect, useState, useRef } from "react";
import coverImage from "../assets/img/coverImage.png";
import Input from "../components/ui/Input";
import Button from "../components/ui/Button";
import toast, { Toaster } from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "../components/ui/Select";
import File from "../components/ui/File";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiService } from "../api.service";
import { service } from "../service";
import { useGlobalContext } from "../context/global.context";

export default function DealerUploadDocuments() {
  const { setMessage, setError } = useGlobalContext();

  const navigate = useNavigate();
  const [filesUploaded, setFilesUploaded] = useState(false);
  const [dealerDocs, setDealerDocs] = useState();
  const agreementRef = useRef(null);
  const panRef = useRef(null);
  const gstCertificateRef = useRef(null);
  const cancelledChequeRef = useRef(null);
  const onFileChange = (name, file) => {
    formik.setFieldValue(name, file);
  };

  const formik = useFormik({
    initialValues: {
      agreement: "",
      pan: "",
      gstCertificate: "",
      cancelledCheque: "",
    },
    validationSchema: Yup.object().shape({
      agreement: Yup.mixed().required("Agreement is required"),
      pan: Yup.mixed().required("PAN is required"),
      gstCertificate: Yup.mixed().required("GST Certificate is required"),
      cancelledCheque: Yup.mixed().required("Cancelled Cheque is required"),
    }),
    onSubmit: async (values) => {
      let loading = toast.loading("Uploading files...");

      // navigate("/dealer-bank-transactions");
      // const formData = new FormData();
      // formData.append("agreement", values.agreement);
      // formData.append("pan", values.pan);
      // formData.append("gstCertificate", values.gstCertificate);
      // formData.append("cancelledCheque", values.cancelledCheque);
      // formData.append("userId", 123);

      const payload = {
        agreement: values.agreement,
        pan: values.pan,
        gstCertificate: values.gstCertificate,
        cancelledCheque: values.cancelledCheque,
      };
      const loadingToast = toast.loading("Loading dealer documents");
      apiService
        .uploadDealerDocuments(payload)
        .then((response) => {
          toast.success("Documents uploaded successfully");
          toast.dismiss(loading);
          navigate("/dealer-registration-status");
        })
        .catch((error) => {
          // toast.error(error.response.data.message);
          service.notifyApiError(error, setMessage, setError);
        })
        .finally(() => {
          toast.dismiss(loadingToast);
        })
        .finally(() => {
          toast.dismiss(loadingToast);
        });
    },
  });

  const isFormValid = () => {
    return (
      formik.isValid &&
      formik.values.agreement &&
      formik.values.pan &&
      formik.values.gstCertificate &&
      formik.values.cancelledCheque
    );
  };

  const getDealerDocs = () => {
    const loadingToast = toast.loading("Loading data...");
    try {
      const payload = {
        sapAdCode: service.getLocalStorageItem("dealerInfo").sapAdCode,
      };
      apiService.getDealerInfo(payload).then((res) => {
        const data = res.data.data.documents;
        if (data) {
          let docs = {
            agreement: data.agreement,
            pan: data.pan,
            gstCertificate: data.gstCertificate,
            cancelledCheque: data.cancelledCheque,
          };
          setDealerDocs(docs);
          formik.setValues({
            agreement: docs.agreement,
            cancelledCheque: docs.cancelledCheque,
            gstCertificate: docs.gstCertificate,
            pan: docs.pan,
          });
        }
      });
    } catch (error) {
      toast.error("Something went wrong");
      service.notifyApiError(error, setMessage, setError);
    } finally {
      toast.dismiss(loadingToast);
    }
  };
  const handleReset = () => {
    formik.resetForm();
    agreementRef.current.resetFile();
    panRef.current.resetFile();
    gstCertificateRef.current.resetFile();
    cancelledChequeRef.current.resetFile();
  };

  useEffect(() => {
    // getDealerDocs()
    // const dealerInfo = service.getLocalStorageItem("dealerInfo");
    // if (dealerInfo) {
    //   console.log(dealerInfo);
    //   setDealerInfo(dealerInfo);
    //   // formik.setFieldValue("agreement", dealerInfo.documents.agreement);
    //   console.log(formik.values);
    // }
  }, []);

  return (
    <>
      <div className="flex flex-col w-full items-center">
        <div className="sticky -z-10 top-12 w-full">
          {/* <i className="material-symbols-outlined hidden md:block fixed text-white ml-4 mt-2">
            arrow_back
          </i> */}
          <img
            src={coverImage}
            className=" w-full h-36 object-cover"
            alt="cover_image"
          />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="w-11/12 md:w-1/2 min-w-fit bg-white -mt-28 md:-mt-20 border border-neutral-light rounded mx-2 md:mx-0">
            <div className="bg-base-white px-6 py-4 border-b border-neutral-light rounded-t">
              <h3 className="text-xl font-semibold">Dealer Upload Documents</h3>
              <p className="text-neutral-darker mt-1 text-sm ">
                Start punching policy by uploading Dealer’s Documents
              </p>
            </div>
            <div className="px-10 py-4 pb-8">
              <div className="flex justify-center w-60 my-4 mx-auto">
                <a
                  href={`${process.env.REACT_APP_BASE_URL}/Agreement.pdf`}
                  download
                >
                  <Button
                    type="button"
                    label="Download Agreement PDF"
                    variant="secondary"
                  />
                </a>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-8">
                <div className="flex flex-col">
                  <div className="flex">
                    <File
                      {...formik.getFieldProps("agreement")}
                      formik={formik}
                      id="agreement"
                      name="agreement"
                      label="Agreement Pdf"
                      className="w-full md:w-56"
                      icon="picture_as_pdf"
                      accept=".pdf"
                      value="sdf.pdf"
                      url={dealerDocs?.agreement}
                      onFileChange={onFileChange}
                      ref={agreementRef}
                    />
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="flex">
                    <File
                      {...formik.getFieldProps("pan")}
                      formik={formik}
                      id="pan"
                      name="pan"
                      label="PAN"
                      className="w-full md:w-56"
                      icon="picture_as_pdf"
                      accept=".pdf"
                      url={dealerDocs?.pan}
                      onFileChange={onFileChange}
                      ref={panRef}
                    />
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="flex">
                    <File
                      {...formik.getFieldProps("gstCertificate")}
                      formik={formik}
                      id="gstCertificate"
                      name="gstCertificate"
                      label="GST Certificate"
                      className="w-full md:w-56"
                      icon="picture_as_pdf"
                      accept=".pdf"
                      url={dealerDocs?.gstCertificate}
                      onFileChange={onFileChange}
                      ref={gstCertificateRef}
                    />
                  </div>
                </div>

                <div className="flex flex-col">
                  <div className="flex">
                    <File
                      {...formik.getFieldProps("cancelledCheque")}
                      formik={formik}
                      id="cancelledCheque"
                      name="cancelledCheque"
                      label="Cancelled Cheque"
                      className="w-full md:w-56"
                      icon="picture_as_pdf"
                      accept=".pdf"
                      url={dealerDocs?.cancelledCheque}
                      onFileChange={onFileChange}
                      ref={cancelledChequeRef}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <div className="flex justify-center pt-4 pb-8 w-72">
              <Button
                type="button"
                label="Reset"
                variant="ghost"
                onClick={handleReset}
              />
              <Button type="submit" label="Submit" variant="primary" />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
