import React, { useContext } from "react";
import toast from "react-hot-toast";
import close from "../../assets/img/icons8-close-64.png";

import { useGlobalContext } from "../../context/global.context";

const ErrorToast = ({ message = "", id = null, error = null }) => {
  const { setShowErrorModal, showErrorModal } = useGlobalContext();
  // Return null if there's no error to display


  if (!error) return null;
  return (
    <>
      <div className="flex items-center space-x-2 py-2 px-4 bg-[#020A12] text-[#F5F5F5] rounded text-sm">
        <button
          onClick={() => {
            setShowErrorModal(true);
          }}
          className="flex items-center justify-center bg-[#f94449] h-5 w-5 rounded-full"
        >
          <img src={close} className="h-4 w-4" alt="close" />
        </button>
        <div>{message}</div>
        <button className="text-sm" onClick={() => toast.dismiss(id)}>
          <span className="material-symbols-outlined text-sm ">close</span>
        </button>
      </div>
    </>
  );
};

export default ErrorToast;
