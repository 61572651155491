import React, { useState } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import Button from "../components/ui/Button";

const ViewImageModal = ({
  title,
  isOpen,
  imageUrl,
  width = 800,
  height = 600,
  close,
}) => {
  // Check the file extension
  const fileExtension = imageUrl.split(".").pop().toLowerCase();
  const isPDF = fileExtension === "pdf";
  const isImage = ["jpg", "jpeg", "png", "gif", "bmp", "svg"].includes(
    fileExtension
  );

  console.log(isOpen, "isOpenModel");
  return (
    <Dialog
      open={isOpen}
      onClose={() => close(false)}
      className="relative z-100"
    >
      <div className="fixed inset-0 bg-black/75" aria-hidden="true" />

      <div className="fixed inset-0 flex w-screen items-center justify-center p-4 z-100">
        <DialogPanel className=" space-y-4 border bg-white border-neutral rounded w-1/2 h-full">
          <div className="bg-neutral-lightest  border-b border-neutral px-6 py-3 flex">
            <span className="font-bold text-xl py-2 text-neutral-darkest ">
              {title}
            </span>
          </div>
          <div className="flex flex-col md:flex-row md:gap-x-4 px-4 md:px-8 pt-4">
            {isPDF ? (
              <iframe
                src={imageUrl}
                title={title}
                style={{ width: "100%", height: "100%", border: "none" }}
                className="max-h-full max-w-full overflow-scroll"
              />
            ) : isImage ? (
              <img
                src={imageUrl}
                alt="Preview"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            ) : (
              <div>Unsupported file type</div>
            )}
          </div>

          <div className="flex items-center justify-center gap-4 mt-4 px-12 pb-8">
            <Button
              type="button"
              label="Close"
              variant="primary"
              className="w-full md:w-auto"
              onClick={() => close(false)}
            />
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default ViewImageModal;
