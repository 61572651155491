import React, { useState, useEffect, useLayoutEffect } from "react";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveBar } from "@nivo/bar";
import CountUp from "react-countup";
import imageGold from "../assets/img/gold.png";
import imageSilver from "../assets/img/silver.png";
import imageBronze from "../assets/img/bronze.png";
import chroma from "chroma-js";
import moment from "moment";
import toast from "react-hot-toast";
import { apiService } from "../api.service";
import * as _ from "lodash";
import { service } from "../service";
import { useGlobalContext } from "../context/global.context";
const initalPayload2 = {
  year: moment().format("YYYY"),
};

export const DealerDashboard = () => {
  const { setMessage, setError } = useGlobalContext();
  const [totalSoldPolicyCountFTD, setTotalSoldPolicyCountFTD] = useState(0);
  const [key1, setKey1] = useState(0);
  const [totalSoldPolicyCountMTD, setTotalSoldPolicyCountMTD] = useState(0);
  const [key2, setKey2] = useState(0);
  const [totalSoldPolicyCountYTD, setTotalSoldPolicyCountYTD] = useState(0);
  const [key3, setKey3] = useState(0);
  const [key4, setKey4] = useState(0);
  const [topDealers, setTopDealers] = useState([]);
  const [soldPoliciesChart, setSoldPoliciesChart] = useState([]);
  // const [selectedMonth1, setSelectedMonth1] = useState("");
  const [selectedYear2, setSelectedYear2] = useState("");
  const [isDesktop, setIsDesktop] = useState(false);
  const [numberOfCards, setNumberOfCards] = useState(5);
  const [showCommissionBalance, setShowCommissionBalance] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const [commissionBalance, setCommissionBalance] = useState(0);

  const [payload2, setPayload2] = useState(initalPayload2);
  const [prevPayload2, setPrevPayload2] = useState(initalPayload2);
  const [yearsOptions, setYearOptions] = useState([]);
  const getGridColsClass = () => {
    switch (numberOfCards) {
      case 5:
        return "md:grid-cols-5";
      default:
        return "md:grid-cols-4"; // Fallback or default case
    }
  };
  // try {
  useEffect(() => {}, []);

  useLayoutEffect(() => {}, []);

  const checkScreenSize = () => {
    setIsDesktop(window.innerWidth >= 768);
  };
  const formatMonthYear = (month) => {
    return moment(month, "YYYY-MM").format("MMM YYYY"); // Format: "Jul 2024"
  };

  const fetchTotalSoldPolicyCount = async (frequency) => {
    try {
      const payload = {
        frequency,
      };

      const res = await apiService.dealerTotalSoldPoliciesCount(payload);

      if (res.data && res.data.data) {
        const { soldPoliciesCount } = res.data.data;

        if (frequency === "ftd") {
          setTotalSoldPolicyCountFTD(soldPoliciesCount);
          setKey1((prevKey) => prevKey + 1);
        } else if (frequency === "mtd") {
          setTotalSoldPolicyCountMTD(soldPoliciesCount);
          setKey2((prevKey) => prevKey + 1);
        } else if (frequency === "ytd") {
          setTotalSoldPolicyCountYTD(soldPoliciesCount);
          setKey3((prevKey) => prevKey + 1);
        }
      } else {
        console.error("Invalid response structure:", res);
        // Handle invalid response structure
      }
    } catch (error) {
      console.error("Error fetching total sold policies count:", error);
      // Handle error (e.g., show error message)
      service.notifyApiError(error, setMessage, setError);
    }
  };

  const fetchWalletSummary = async () => {
    try {
      await apiService.getWalletBalance().then((res) => {
        const mainBalance = res.data.data.main;
        const commissionBalance = res.data.data.commission;

        setWalletBalance(mainBalance);
        setCommissionBalance(commissionBalance);
        setKey4((prevKey) => prevKey + 1);
      });
    } catch (error) {
      console.error("Error fetching Wallet Balance:", error);
      // Handle error (e.g., show error message)
      service.notifyApiError(error, setMessage, setError);
    }
  };

  const fetchTopDealers = async () => {
    await apiService
      .topDealersOfMyZone()
      .then((res) => {
        setTopDealers(res.data.data);
      })
      .catch((error) => {
        console.error(error);
        toast.error(error.response.data.message);
        service.notifyApiError(error, setMessage, setError);
      });
  };

  const fetchSoldPoliciesChart = async () => {
    await apiService
      .soldPoliciesChart(payload2)
      .then((res) => {
        // setServiceTargetVsAchievement(res.data.data);
        setSoldPoliciesChart(res.data.data);
        setSelectedYear2(payload2.year);
        setPrevPayload2(payload2);
      })
      .catch((error) => {
        setPayload2(prevPayload2);
        toast.error(error.response.data.message);
        service.notifyApiError(error, setMessage, setError);
      });
  };

  const handleYearChange2 = (e) => {
    const selectedYear = e.target.value;
    setPayload2((prev) => ({
      ...prev,
      year: selectedYear,
    }));
  };
  const generateYearsOptions = () => {
    const currentYear = moment().year();
    const yearsArray = [];
    for (let year = 2016; year <= currentYear; year++) {
      yearsArray.push(year);
    }
    setYearOptions(yearsArray);
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  useLayoutEffect(() => {
    const showCommission =
      service.getSessionStorageItem("dealerInfo").showCommission;
    if (showCommission) {
      setNumberOfCards(5);
      setShowCommissionBalance(true);
    }
  }, []);

  useEffect(() => {
    generateYearsOptions();

    // fetchLoggedInUserCount();
    fetchTotalSoldPolicyCount("ftd"); // Fetch FTD count
    fetchTotalSoldPolicyCount("mtd"); // Fetch MTD count
    fetchTotalSoldPolicyCount("ytd"); // Fetch yTD count
    fetchWalletSummary();
  }, []);

  useEffect(() => {
    fetchTopDealers();
  }, []);

  useEffect(() => {
    // fetchServiceTargetVsAchievement();
    fetchSoldPoliciesChart();
  }, [payload2]);

  return (
    <div className="flex flex-col w-full min-h-screen bg-gray-100 px-6 pb-4 pt-6">
      <div className="w-full">
        <div
          className={`grid grid-cols-2 ${getGridColsClass()} md:gap-4 gap-2 mb-6`}
        >
          <div className="bg-white shadow rounded-lg md:p-4 p-2 transform transition duration-300 flex border border-neutral-light md:space-x-4 space-x-0 group justify-center md:justify-start">
            <h3 className="items-center hidden md:block">
              <span className="material-symbols-outlined text-5xl font-bold text-gray-700">
                description
              </span>
            </h3>
            <div className="flex">
              <div className="flex flex-col">
                <div className="flex md:justify-between justify-center">
                  <p className="mt-2 text-gray-700 md:text-2xl text-3xl font-semibold text-center">
                    <CountUp
                      key={`${key1}-sales`}
                      start={0}
                      end={totalSoldPolicyCountFTD}
                      duration={2.5}
                      separator=","
                    />
                  </p>
                </div>
                <span className="capitalize text-xs mt-2 md:mt-0">
                  Sold Policies (FTD)
                </span>
              </div>
              <div>
                <button
                  className="flex items-center justify-center active:bg-neutral-lightest w-1 h-1 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute right-4"
                  onClick={() => fetchTotalSoldPolicyCount("ftd")}
                >
                  <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black text-sm">
                    refresh
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="bg-white shadow rounded-lg md:p-4 p-2 transform transition duration-300 flex border border-neutral-light md:space-x-4 space-x-0 group justify-center md:justify-start">
            <h3 className="items-center hidden md:block">
              <span className="material-symbols-outlined text-5xl font-bold text-gray-700">
                description
              </span>
            </h3>
            <div className="flex">
              <div className="flex flex-col">
                <div className="flex md:justify-between justify-center">
                  <p className="mt-2 text-gray-700 md:text-2xl text-3xl font-semibold text-center">
                    <CountUp
                      key={`${key2}-sales`}
                      start={0}
                      end={totalSoldPolicyCountMTD}
                      duration={2.5}
                      separator=","
                    />
                  </p>
                </div>
                <span className="capitalize text-xs mt-2 md:mt-0">
                  {" "}
                  Sold Policies (MTD)
                </span>
              </div>
            </div>
            <div>
              <button
                className="flex items-center justify-center active:bg-neutral-lightest w-1 h-1 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute right-4"
                onClick={() => fetchTotalSoldPolicyCount("mtd")}
              >
                <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black text-sm">
                  refresh
                </span>
              </button>
            </div>
          </div>

          <div className="bg-white shadow rounded-lg md:p-4 p-2 transform transition duration-300 flex border border-neutral-light md:space-x-4 space-x-0 group justify-center md:justify-start">
            <h3 className="items-center hidden md:block">
              <span className="material-symbols-outlined text-5xl font-bold text-gray-700">
                description
              </span>
            </h3>
            <div className="flex">
              <div className="flex flex-col">
                <div className="flex md:justify-between justify-center">
                  <p className="mt-2 text-gray-700 md:text-2xl text-3xl font-semibold text-center">
                    <CountUp
                      key={`${key3}-sales`}
                      start={0}
                      end={totalSoldPolicyCountYTD}
                      duration={2.5}
                      separator=","
                    />
                  </p>
                </div>
                <span className="capitalize text-xs mt-2 md:mt-0">
                  Sold Policies (YTD)
                </span>
              </div>
            </div>
            <div>
              <button
                className="flex items-center justify-center active:bg-neutral-lightest w-1 h-1 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute right-4"
                onClick={() => fetchTotalSoldPolicyCount("ytd")}
              >
                <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black text-sm">
                  refresh
                </span>
              </button>
            </div>
          </div>
          {showCommissionBalance && (
            <div className="bg-white shadow rounded-lg md:p-4 p-2 transform transition duration-300 flex border border-neutral-light md:space-x-4 space-x-0 group justify-center md:justify-start">
              <h3 className="items-center hidden md:block">
                <span className="material-symbols-outlined text-5xl font-bold text-gray-700">
                  account_balance_wallet
                </span>
              </h3>
              <div className="flex">
                <div className="flex flex-col">
                  <div className="flex md:justify-between justify-center">
                    <p className="mt-2 text-gray-700 md:text-2xl text-3xl font-semibold text-center">
                      ₹&nbsp;
                      <CountUp
                        key={key4}
                        start={0}
                        end={commissionBalance}
                        duration={2.5}
                        separator=","
                      />
                    </p>
                  </div>
                  <span className="capitalize text-xs mt-2 md:mt-0">
                    Commission Balance
                  </span>
                </div>
                <button
                  className="flex items-center justify-center active:bg-neutral-lightest w-1 h-1 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute right-4"
                  onClick={fetchWalletSummary}
                >
                  <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black text-sm">
                    refresh
                  </span>
                </button>
              </div>
            </div>
          )}

          <div className="bg-white shadow rounded-lg md:py-4 md:px-2 p-2 transform transition duration-300 flex border border-neutral-light md:space-x-4 space-x-0 group justify-center md:justify-start">
            <h3 className="items-center hidden md:block">
              <span className="material-symbols-outlined text-5xl font-bold text-gray-700">
                account_balance_wallet
              </span>
            </h3>
            <div className="flex">
              <div className="flex flex-col">
                <div className="flex md:justify-between justify-center">
                  <p className="mt-2 text-gray-700 md:text-2xl text-3xl font-semibold text-center">
                    ₹&nbsp;
                    <CountUp
                      key={key4}
                      start={0}
                      end={walletBalance}
                      duration={2.5}
                      separator=","
                    />
                  </p>
                </div>
                <span className="capitalize text-xs mt-2 md:mt-0">
                  Wallet Balance
                </span>
              </div>
              <button
                className="flex items-center justify-center active:bg-neutral-lightest w-1 h-1 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute right-4"
                onClick={fetchWalletSummary}
              >
                <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black text-sm">
                  refresh
                </span>
              </button>
            </div>
          </div>
        </div>

        <div className="pt-0 pb-0"></div>
        {/* Top Plans and Remaining Plans Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          {/* Top Plans Card */}

          <div className="bg-white shadow rounded-lg p-6 transform transition duration-300 border border-neutral-light overflow-x-auto">
            <div className="flex justify-between items-center mb-4">
              <h2 className="md:text-lg text-sm font-medium text-gray-700 mb-4">
                Target vs Achievement - {moment(selectedYear2).format("YYYY")}
              </h2>
              {/* <button className='flex items-center justify-center active:bg-neutral-lightest w-8 h-8 rounded-full'><span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">download</span></button> */}
              <button
                className="flex items-center justify-center active:bg-neutral-lightest md:w-8 w-5 md:h-8 h-5 rounded-full mb-4 md:mb-0"
                onClick={() => setPayload2(initalPayload2)}
              >
                <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">
                  refresh
                </span>
              </button>
            </div>
            <div className="flex text-sm space-x-4 w-full justify-end">
              {/* <div className="flex flex-col">
                <label for="zone" className="text-xs text-neutral-darker">
                  Zone
                </label>
                <select
                  name="zone"
                  id="zone"
                  className="md:w-28 w-20"
                  value={payload2.zoneId}
                  onChange={handleZoneChange2}
                >
                  {zoneOptions.map((zone) => (
                    <option key={zone.id} value={zone.id}>
                      {zone.name}
                    </option>
                  ))}
                </select>
              </div> */}

              {/* <div className="flex flex-col">
                <label for="area" className="text-xs text-neutral-darker">
                  Area
                </label>
                <select
                  name="area"
                  id="area"
                  className="md:w-28 w-20"
                  value={payload2.areaId}
                  onChange={handleAreaChange2}
                  disabled={payload2.zoneId === 0}
                >
                  {areaOptions.map((area) => (
                    <option key={area.id} value={area.id}>
                      {area.name}
                    </option>
                  ))}
                </select>
              </div> */}
              <div className="flex flex-col">
                <label htmlFor="month" className="text-xs text-neutral-darker">
                  Year
                </label>
                <select
                  name="year"
                  id="year"
                  value={payload2.year}
                  onChange={handleYearChange2}
                  className="md:w-28 w-20"
                >
                  {yearsOptions.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="h-80 w-[800px] md:w-full overflow-x-auto md:overflow-x-hidden">
              <ResponsiveLine
                data={soldPoliciesChart}
                margin={{ top: 30, right: 40, bottom: 42, left: 60 }}
                xScale={{ type: "point" }}
                yScale={{
                  type: "linear",
                  min: "auto",
                  max: "auto",
                  stacked: false,
                  reverse: false,
                }}
                curve="catmullRom"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  orient: "bottom",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Month",
                  legendOffset: 36,
                  legendPosition: "middle",
                }}
                axisLeft={{
                  orient: "left",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Value",
                  legendOffset: -40,
                  legendPosition: "middle",
                }}
                enableGridX={false}
                colors={({ id }) => (id === "Target" ? "#A5CFFF" : "#092E58")}
                pointSize={4}
                pointColor={{ theme: "background" }}
                pointBorderWidth={2}
                pointBorderColor={{ from: "serieColor" }}
                pointLabelYOffset={-12}
                useMesh={true}
                legends={
                  isDesktop
                    ? [
                        {
                          anchor: "top-right",
                          direction: "column",
                          justify: false,
                          translateX: 80,
                          translateY: 10,
                          itemsSpacing: 2,
                          itemDirection: "left-to-right",
                          itemWidth: 100,
                          itemHeight: 20,
                          itemOpacity: 0.75,
                          symbolSize: 12,
                          symbolBorderColor: "rgba(0, 0, 0, .5)",
                          effects: [
                            {
                              on: "hover",
                              style: {
                                itemBackground: "rgba(0, 0, 0, .03)",
                                itemOpacity: 1,
                              },
                            },
                          ],
                        },
                      ]
                    : []
                }
              />
            </div>
          </div>
          <div className="bg-white shadow rounded-lg p-6 transform transition duration-300 border border-neutral-light ">
            <div className="flex justify-between items-center mb-2">
              <h2 className="md:text-lg text-sm font-medium text-gray-700">
                Top 10 Dealers - {moment().format("MMM YYYY")}{" "}
              </h2>
              <button
                className="flex items-center justify-center active:bg-neutral-lightest md:w-8 w-5 md:h-8 h-5 rounded-full"
                onClick={() => fetchTopDealers()}
              >
                <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">
                  refresh
                </span>
              </button>
            </div>

            <div className="h-full flex flex-col mt-1">
              {topDealers.length !== 0 ? (
                topDealers.map((plan, index) => (
                  <div className="flex items-center">
                    {plan.rank === 1 && (
                      <img
                        src={imageGold}
                        alt="gold-badge"
                        className="w-6 h-9 mr-2"
                      />
                    )}
                    {plan.rank === 2 && (
                      <img
                        src={imageSilver}
                        alt="silver-badge"
                        className="w-6 h-9 mr-2"
                      />
                    )}
                    {plan.rank === 3 && (
                      <img
                        src={imageBronze}
                        alt="bronze-badge"
                        className="w-6 h-9 mr-2"
                      />
                    )}
                    {plan.rank > 3 && (
                      <div className="mr-3">
                        <span className="pl-2 text-xs ">{plan.rank}.</span>
                      </div>
                    )}
                    <div
                      key={plan.id}
                      className={`px-4  rounded flex justify-between items-center w-full text-left ${
                        plan.rank <= 3
                          ? "text-gray-700 font-bold py-1"
                          : "text-gray-700 mb-1"
                      } ${
                        plan.rank === 1
                          ? "bg-primary-light text-gray-700"
                          : plan.rank === 2
                          ? "bg-primary-lighter text-gray-700"
                          : plan.rank === 3
                          ? "bg-primary-lightest text-gray-700"
                          : plan.rank === 3
                          ? "bg-neutral-lightest text-gray-700"
                          : "bg-neutral-lightest text-gray-700"
                      }`}
                    >
                      <div>
                        <span className="text-left text-xs">
                          {plan.dealerName}
                        </span>
                      </div>
                      <span className="ml-4 text-xs">
                        <CountUp
                          start={0}
                          end={plan.count}
                          duration={2.5}
                          separator=","
                        />
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <div className="h-full flex justify-center items-center">
                  <p className="text-sm -mt-24"> Report not available</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealerDashboard;
