import React from 'react'
import { ResponsivePie } from '@nivo/pie';
import chroma from 'chroma-js';


export const PieChart = ({ data }) => {
  // const isBackgroundDark = true;
  // const arcLabelsTextColor = isBackgroundDark ? '#ffffff' : '#000000';


  const getLabelTextColor = (bgColor) => {
    const textColor = chroma.contrast(bgColor, 'white') > 4.5
      ? 'white'
      : 'black';
    return textColor
  }
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <ResponsivePie
        data={data}
        margin={{ top: 20, right: 40, bottom: 40, left: 40 }}
        innerRadius={0.7}
        padAngle={0.7}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.2]]
        }}
        enableArcLinkLabels={false}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}

        arcLabelsSkipAngle={10}
        arcLabelsTextColor={(obj) => (getLabelTextColor(obj.color))}

        // arcLabelsTextColor={{
        //   from: 'color',
        //   modifiers: [['darker', 0]]
        // }}
        colors={data.map(item => item.color)}
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            size: 4,
            padding: 1,
            stagger: true
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            rotation: -45,
            lineWidth: 6,
            spacing: 10
          }
        ]}
        legends={[
          {
            anchor: 'bottom',
            direction: 'row',
            justify: false,
            translateX: 28,
            translateY: 28,
            itemsSpacing: 0,
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: '#999',
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#000'
                }
              }
            ]
          }
        ]}
      />
    </div>
  )
}
