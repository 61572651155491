import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveLine } from "@nivo/line";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { apiService } from "../../../api.service";
import chroma from "chroma-js";
import moment from "moment";
import CountUp from "react-countup";
import imageGold from "../../../assets/img/gold.png";
import imageSilver from "../../../assets/img/silver.png";
import imageBronze from "../../../assets/img/bronze.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import * as _ from "lodash";

import { useGlobalContext } from "../../../context/global.context";
import { service } from "../../../service";
export const SalesDashboard = () => {
  const tvsTypeId = 1;
  const isInclusion = 1;
  const { setMessage, setError } = useGlobalContext();
  const [loggedInUserCount, setLoggedInUserCount] = useState(0);
  const [key1, setKey1] = useState(0);
  const [totalSoldPolicyCount, setTotalSoldPolicyCount] = useState(0);
  const [totalSoldPolicyCountFTD, setTotalSoldPolicyCountFTD] = useState(0);
  const [key2, setKey2] = useState(0);
  const [totalSoldPolicyCountMTD, setTotalSoldPolicyCountMTD] = useState(0);

  const [key3, setKey3] = useState(0);
  const [activeUserCount, setActiveUserCount] = useState(0);
  const [key4, setKey4] = useState(0);
  const [
    totalSoldPolicyCountFTDInclusion,
    setTotalSoldPolicyCountFTDInclusion,
  ] = useState(0);
  const [key5, setKey5] = useState(0);
  const [
    totalSoldPolicyCountMTDInclusion,
    setTotalSoldPolicyCountMTDInclusion,
  ] = useState(0);
  const [hourlySoldPoliciesChart, setHourlySoldPoliciesChart] = useState([]);
  const [modelTypeCategoryOptions, setModelTypeCategoryOptions] = useState([]);
  const [key6, setKey6] = useState(0);
  const initalPayload1 = {
    zoneId: 0,
    areaId: 0,
    month: moment().format("YYYY-MM"),
    tvsTypeId,
  };
  const initalPayload2 = {
    zoneId: 0,
    areaId: 0,
    month: moment().format("YYYY-MM"),
    tvsTypeId,
  };
  const initalPayload3 = {
    zoneId: 0,
    areaId: 0,
    month: moment().format("YYYY-MM"),
    tvsTypeId,
  };
  const initalPayload4 = {
    zoneId: 0,
    areaId: 0,
    month: moment().format("YYYY-MM"),
    tvsTypeId,
  };
  const initalPayload5 = {
    zoneId: 0,
    areaId: 0,
    month: moment().format("YYYY-MM"),
    tvsTypeId,
  };
  const initalPayload6 = {
    zoneId: 0,
    areaId: 0,
    month: moment().format("YYYY-MM"),
    tvsTypeId,
  };
  const initalPayload7 = {
    zoneId: 0,
    areaId: 0,
    year: moment().format("YYYY"),
    tvsTypeId,
  };
  const initalPayload8 = {
    zoneId: 0,
    areaId: 0,
    month: moment().format("YYYY-MM"),
    tvsTypeId,
  };
  const initalPayload9 = {
    zoneId: 0,
    areaId: 0,
    month: moment().format("YYYY-MM"),
    tvsTypeId,
  };
  const initalPayload10 = {
    zoneId: 0,
    areaId: 0,
    tvsTypeId,
  };
  const initalPayload11 = {
    zoneId: 0,
    areaId: 0,
    month: moment().format("YYYY-MM"),
    tvsTypeId,
    modelTypeId: 0,
  };
  const [chartData1, setChartData1] = useState([]);
  const [zoneOptions, setZoneOptions] = useState([]);
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedMonth1, setSelectedMonth1] = useState("");
  const [selectedMonth2, setSelectedMonth2] = useState("");
  const [selectedMonth3, setSelectedMonth3] = useState("");
  const [selectedMonth4, setSelectedMonth4] = useState("");
  const [selectedMonth5, setSelectedMonth5] = useState("");
  const [selectedMonth6, setSelectedMonth6] = useState("");
  const [selectedMonth7, setSelectedMonth7] = useState("");
  const [selectedMonth8, setSelectedMonth8] = useState("");
  const [selectedMonth9, setSelectedMonth9] = useState("");
  const [selectedMonth10, setSelectedMonth10] = useState("");
  const [selectedMonth11, setSelectedMonth11] = useState("");
  const [areaOptions, setAreaOptions] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [yearsOptions, setYearOptions] = useState([]);
  const [payload1, setPayload1] = useState(initalPayload1);
  const [payload2, setPayload2] = useState(initalPayload2);
  const [payload3, setPayload3] = useState(initalPayload3);
  const [payload4, setPayload4] = useState(initalPayload4);
  const [payload5, setPayload5] = useState(initalPayload5);
  const [payload6, setPayload6] = useState(initalPayload6);
  const [payload7, setPayload7] = useState(initalPayload7);
  const [payload8, setPayload8] = useState(initalPayload8);
  const [payload9, setPayload9] = useState(initalPayload9);
  const [payload10, setPayload10] = useState(initalPayload10);
  const [payload11, setPayload11] = useState(initalPayload11);

  const [prevPayload1, setPrevPayload1] = useState(initalPayload1);
  const [prevPayload2, setPrevPayload2] = useState(initalPayload2);
  const [prevPayload3, setPrevPayload3] = useState(initalPayload3);
  const [prevPayload4, setPrevPayload4] = useState(initalPayload4);
  const [prevPayload5, setPrevPayload5] = useState(initalPayload5);
  const [prevPayload6, setPrevPayload6] = useState(initalPayload6);
  const [prevPayload7, setPrevPayload7] = useState(initalPayload7);
  const [prevPayload8, setPrevPayload8] = useState(initalPayload8);
  const [prevPayload9, setPrevPayload9] = useState(initalPayload9);
  const [prevPayload10, setPrevPayload10] = useState(initalPayload10);
  const [prevPayload11, setPrevPayload11] = useState(initalPayload11);

  const formatMonthYear = (month) => {
    return moment(month, "YYYY-MM").format("MMM YYYY"); // Format: "Jul 2024"
  };

  const handleTabSelect = (index) => {
    setSelectedTabIndex(index);
  };

  const generateYearsOptions = () => {
    const currentYear = moment().year();
    const yearsArray = [];
    for (let year = 2016; year <= currentYear; year++) {
      yearsArray.push(year);
    }
    setYearOptions(yearsArray);
  };

  const getLabelTextColor = (bgColor) => {
    console.log(bgColor, chroma.contrast(bgColor, "white"));
    const textColor =
      chroma.contrast(bgColor, "white") > 4.5 ? "white" : "black";
    console.log(textColor);
    return textColor;
  };
  const chartColorSet = [
    "#092E58", // default
    "#70acf0", // light
    "#FF5733", // Vibrant Orange
    "#6B5B95", // Ultra Violet
    "#88B04B", // Greenery
    "#F7CAC9", // Rose Quartz
    "#92A8D1", // Serenity
    "#955251", // Marsala
    "#009473", // Emerald
    "#DD4124", // Tangerine Tango
    "#D94F70", // Honeysuckle
    "#45B8AC", // Turquoise
    "#EFC050", // Mimosa
    "#9B2335", // Chilli Pepper
    "#5B5EA6", // Royal Blue
    "#DFCFBE", // Sand Dollar
    "#55B4B0", // Biscay Bay
    "#E15D44", // Burnt Orange
    "#7FCDCD", // Aquamarine
    "#BC243C", // Scarlet
    "#C3447A", // Raspberry Radiance
    "#98B4D4", // Pastel Blue
  ];
  const getArcLabelColor = (dataArray, label) => {
    const whiteLabels = dataArray.map((item) => item.labelColor == "white");
    console.log(whiteLabels);
    // Customize the color based on the label value
  };

  const [RSAData, setRSAData] = useState([]);
  const [selectedYear7, setSelectedYear7] = useState("");
  const [topDealers, setTopDealers] = useState([]);
  const [topDealersByModelType, setTopDealersByModelType] = useState([]);

  const [planWiseData, setplanWiseData] = useState([]);

  const [renewedPoliciesCountData, setRenewedPoliciesCountData] = useState({});

  const [targetVsAchievementData, settargetVsAchievementData] = useState([]);

  const [dayWiseData, setDayWiseData] = useState([]);

  const [dayWiseTargetvsAchivedData, setdayWiseTargetvsAchivedData] = useState(
    []
  );
  const [serviceTargetVsAchievement, setServiceTargetVsAchievement] = useState(
    []
  );
  const [isDesktop, setIsDesktop] = useState(false);

  const checkScreenSize = () => {
    setIsDesktop(window.innerWidth >= 768);
  };
  const dealerTypeWiseTargetVsAchievementData = async () => {
    await apiService
      .dealerTypeWiseTargetVsAchievementReport(payload1)
      .then((res) => {
        console.log(res.data.data);
        setChartData1(res.data.data);
        setSelectedMonth1(payload1.month);
        setPrevPayload1(payload1);
      })
      .catch((error) => {
        console.error(error);
        setPayload1(prevPayload1);
        toast.error(error.response.data.message);
      });
  };
  const fetchHourlySoldPoliciesData = async () => {
    await apiService
      .getHourlyReport(payload10)
      .then((res) => {
        console.log(res.data.data);
        setHourlySoldPoliciesChart(res.data.data);
        setPrevPayload10(payload10);
      })
      .catch((error) => {
        console.error(error);
        setPayload10(prevPayload10);
        service.notifyApiError(error, setMessage, setError);
      });
  };

  const planWiseSalesReport = async () => {
    await apiService
      .planWiseSalesReport(payload9)
      .then((res) => {
        console.log(res.data.data);
        const data = res.data.data;
        console.log(_.sumBy(res.data.data, (item) => item.value));
        const total = _.sumBy(data, (item) => item.value);
        const formattedData = data.map((item) => ({
          id: item.id,
          label: `${item.id} ${((item.value / total) * 100).toFixed(2)}%`,
          value: item.value,
        }));
        console.log(formattedData);
        // setplanWiseData(res.data.data);
        setplanWiseData(formattedData);
        setSelectedMonth9(payload9.month);
        setPrevPayload9(payload9);
      })
      .catch((error) => {
        console.error(error);
        setPayload9(prevPayload9);
        toast.error(error.response.data.message);
      });
  };
  const categoryWiseDailyReport = async () => {
    await apiService
      .categoryWiseDailyReport(payload3)
      .then((res) => {
        console.log(res.data.data);
        settargetVsAchievementData(res.data.data);
        setSelectedMonth3(payload3.month);
        setPrevPayload3(payload3);
      })
      .catch((error) => {
        console.error(error);
        setPayload3(prevPayload3);
        toast.error(error.response.data.message);
      });
  };
  const planWiseDailyReport = async () => {
    await apiService
      .planWiseDailyReport(payload4)
      .then((res) => {
        console.log(res.data.data);
        setDayWiseData(res.data.data);
        setSelectedMonth4(payload4.month);
        setPrevPayload4(payload4);
      })
      .catch((error) => {
        console.error(error);
        setPayload4(prevPayload4);
        toast.error(error.response.data.message);
      });
  };
  const generateKeys = () => {
    if (dayWiseData.length === 0) return [];
    // Get the keys from the first object in dayWiseData (excluding 'day')
    return Object.keys(dayWiseData[0]).filter((key) => key !== "day");
  };
  const [tooltip, setTooltip] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const fetchLoggedInUserCount = async () => {
    await apiService
      .loggedInUserCount()
      .then((res) => {
        console.log(res.data.count);
        setLoggedInUserCount(res.data.count);
        setKey1((prevKey) => prevKey + 1);
      })
      .catch((error) => {
        console.error(error);
        toast.error(error.response.data.message);
      });
  };
  const fetchTotalSoldPolicyCount = async (frequency) => {
    try {
      const payload = {
        frequency,
        tvsTypeId,
      };
      await apiService.totalSoldPoliciesCount(payload).then((res) => {
        if (frequency === "ftd") {
          setTotalSoldPolicyCountFTD(res.data.data.count);
          setKey2((prevKey) => prevKey + 1);
        } else if (frequency === "mtd") {
          setTotalSoldPolicyCountMTD(res.data.data.count);
          setKey3((prevKey) => prevKey + 1);
        }
      });
    } catch (error) {
      console.error("Error fetching total sold policies count:", error);
      // Handle error (e.g., show error message)
    }
  };
  const fetchTotalSoldPolicyCountInclusion = async (frequency) => {
    try {
      const payload = {
        frequency,
        tvsTypeId,
        isInclusion,
      };
      await apiService.totalSoldPoliciesCount(payload).then((res) => {
        if (frequency === "ftd") {
          setTotalSoldPolicyCountFTDInclusion(res.data.count);
          setKey5((prevKey) => prevKey + 1);
        } else if (frequency === "mtd") {
          setTotalSoldPolicyCountMTDInclusion(res.data.count);
          setKey6((prevKey) => prevKey + 1);
        }
      });
    } catch (error) {
      console.error("Error fetching total sold policies count:", error);
      // Handle error (e.g., show error message)
    }
  };
  const fetchActiveUsersCount = async () => {
    try {
      const payload = {
        tvsTypeId,
      };
      await apiService.activeUsersCount(payload).then((res) => {
        const count = res.data.count;
        setActiveUserCount(count);
        setKey4((prevKey) => prevKey + 1);
      });
    } catch (error) {
      console.error("Error fetching logged in user count:", error);
      // Handle error (e.g., show error message)
    }
  };

  const dailyTargetVsAchievementReport = async () => {
    await apiService
      .dailyTargetVsAchievementReport(payload5)
      .then((res) => {
        console.log(res.data.data);
        setdayWiseTargetvsAchivedData(res.data.data);
        setSelectedMonth5(payload5.month);
        setPrevPayload5(payload5);
      })
      .catch((error) => {
        console.error(error);
        setPayload5(prevPayload5);
        toast.error(error.response.data.message);
      });
  };
  const dealerTypeWiseRSAStatsReport = async () => {
    await apiService
      .dealerTypeWiseRSAStatsReport(payload6)
      .then((res) => {
        console.log(res.data.data);
        setRSAData(res.data.data);
        setSelectedMonth6(payload6.month);
        setPrevPayload6(payload6);
      })
      .catch((error) => {
        console.error(error);
        setPayload6(prevPayload6);
        toast.error(error.response.data.message);
      });
  };
  const fetchZoneOptions = async () => {
    await apiService
      .zoneOptions()
      .then((res) => {
        console.log(res.data.data);
        setZoneOptions(res.data.data);
        if (res.data.data.length > 0) {
          setSelectedZone(res.data.data[0].id);
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error(error.response.data.message);
      });
  };

  const fetchrenewedPoliciesCount = async () => {
    await apiService
      .renewedPoliciesCount(payload2)
      .then((res) => {
        console.log(res.data.data);
        setRenewedPoliciesCountData(res.data.data);
        setSelectedMonth2(payload2.month);
        setPrevPayload2(payload2);
      })
      .catch((error) => {
        console.error(error);
        setPayload2(prevPayload2);
        toast.error(error.response.data.message);
      });
  };
  const fetchServiceTargetVsAchievement = async () => {
    await apiService
      .serviceTargetVsAchievement(payload7)
      .then((res) => {
        console.log(res.data.data);
        setServiceTargetVsAchievement(res.data.data);
        setSelectedYear7(payload7.year);
        setPrevPayload7(payload7);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        setPayload7(prevPayload7);
        toast.error(error.response.data.message);
      });
  };
  const fetchTopDealers = async () => {
    await apiService
      .topDealers(payload8)
      .then((res) => {
        console.log(res.data.data);
        setTopDealers(res.data.data); // Assuming API response contains an array of top dealers
        setSelectedMonth8(payload8.month);
        setPrevPayload8(payload8);
      })
      .catch((error) => {
        console.error(error);
        setPayload8(prevPayload8);
        toast.error(error.response.data.message);
      });
  };

  const fetchTopDealersByModelType = async () => {
    await apiService
      .topDealersByModelType(payload11)
      .then((res) => {
        console.log(res.data.data);
        setTopDealersByModelType(res.data.data); // Assuming API response contains an array of top dealers
        setSelectedMonth11(payload11.month);
        setPrevPayload11(payload11);
      })
      .catch((error) => {
        console.error(error);
        setPayload11(prevPayload11);
        service.notifyApiError(error, setMessage, setError);
      });
  };

  const handleZoneChange1 = async (event) => {
    const selectedZoneId = parseInt(event.target.value);
    setPayload1((prev) => ({
      ...prev,
      zoneId: selectedZoneId,
    }));

    if (selectedZoneId === 0) {
      // Zone "ALL" selected
      setAreaOptions([{ id: 0, name: "ALL", label: "ALL" }]);
      setSelectedArea(0);
    } else {
      // Specific zone selected, fetch corresponding areas
      try {
        await fetchAreaOptions(selectedZoneId);
      } catch (error) {
        console.error(error);
        toast.error(error.response.data.message);
      }
    }
  };

  const handleZoneChange2 = async (event) => {
    const selectedZoneId = parseInt(event.target.value);
    setPayload2((prev) => ({
      ...prev,
      zoneId: selectedZoneId,
    }));
    if (selectedZoneId === 0) {
      // Zone "ALL" selected
      setAreaOptions([{ id: 0, name: "ALL", label: "ALL" }]);
      setSelectedArea(0);
    } else {
      // Specific zone selected, fetch corresponding areas
      try {
        await fetchAreaOptions(selectedZoneId);
      } catch (error) {
        console.error(error);
        toast.error(error.response.data.message);
      }
    }
  };
  const handleZoneChange3 = async (event) => {
    const selectedZoneId = parseInt(event.target.value);
    setPayload3((prev) => ({
      ...prev,
      zoneId: selectedZoneId,
    }));
    // const payload = {
    //   zoneId: payload3.zoneId
    // }
    // await apiService.areaOptions(payload3).then(res => {
    //   setAreaOptions(res.data.data);
    // })
    if (selectedZoneId === 0) {
      // Zone "ALL" selected
      setAreaOptions([{ id: 0, name: "ALL", label: "ALL" }]);
      setSelectedArea(0);
    } else {
      // Specific zone selected, fetch corresponding areas
      try {
        await fetchAreaOptions(selectedZoneId);
      } catch (error) {
        console.error(error);
        toast.error(error.response.data.message);
      }
    }
  };
  const handleZoneChange4 = async (event) => {
    const selectedZoneId = parseInt(event.target.value);
    setPayload4((prev) => ({
      ...prev,
      zoneId: selectedZoneId,
    }));
    if (selectedZoneId === 0) {
      // Zone "ALL" selected
      setAreaOptions([{ id: 0, name: "ALL", label: "ALL" }]);
      setSelectedArea(0);
    } else {
      // Specific zone selected, fetch corresponding areas
      try {
        await fetchAreaOptions(selectedZoneId);
      } catch (error) {
        console.error(error);
        toast.error(error.response.data.message);
      }
    }
  };
  const handleZoneChange5 = async (event) => {
    const selectedZoneId = parseInt(event.target.value);
    setPayload5((prev) => ({
      ...prev,
      zoneId: parseInt(event.target.value),
    }));
    if (selectedZoneId === 0) {
      // Zone "ALL" selected
      setAreaOptions([{ id: 0, name: "ALL", label: "ALL" }]);
      setSelectedArea(0);
    } else {
      // Specific zone selected, fetch corresponding areas
      try {
        await fetchAreaOptions(selectedZoneId);
      } catch (error) {
        console.error(error);
        toast.error(error.response.data.message);
      }
    }
  };
  const handleZoneChange6 = async (event) => {
    const selectedZoneId = parseInt(event.target.value);
    setPayload6((prev) => ({
      ...prev,
      zoneId: selectedZoneId,
    }));
    // const payload = {
    //   zoneId: payload6.zoneId
    // }
    // await apiService.areaOptions(payload6).then(res => {
    //   setAreaOptions(res.data.data);
    // })
    if (selectedZoneId === 0) {
      // Zone "ALL" selected
      setAreaOptions([{ id: 0, name: "ALL", label: "ALL" }]);
      setSelectedArea(0);
    } else {
      // Specific zone selected, fetch corresponding areas
      try {
        await fetchAreaOptions(selectedZoneId);
      } catch (error) {
        console.error(error);
        toast.error(error.response.data.message);
      }
    }
  };
  const handleZoneChange7 = async (event) => {
    const selectedZoneId = parseInt(event.target.value);
    setPayload7((prev) => ({
      ...prev,
      zoneId: selectedZoneId,
    }));

    if (selectedZoneId === 0) {
      // Zone "ALL" selected
      setAreaOptions([{ id: 0, name: "ALL", label: "ALL" }]);
      setSelectedArea(0);
    } else {
      // Specific zone selected, fetch corresponding areas
      try {
        await fetchAreaOptions(selectedZoneId);
      } catch (error) {
        console.error(error);
        toast.error(error.response.data.message);
      }
    }
  };
  const handleZoneChange8 = async (event) => {
    const selectedZoneId = parseInt(event.target.value);
    setPayload8((prev) => ({
      ...prev,
      zoneId: selectedZoneId,
    }));

    if (selectedZoneId === 0) {
      // Zone "ALL" selected
      setAreaOptions([{ id: 0, name: "ALL", label: "ALL" }]);
      setSelectedArea(0);
    } else {
      // Specific zone selected, fetch corresponding areas
      try {
        await fetchAreaOptions(selectedZoneId);
      } catch (error) {
        console.error(error);
        toast.error(error.response.data.message);
      }
    }
  };
  const handleZoneChange9 = async (event) => {
    const selectedZoneId = parseInt(event.target.value);
    setPayload9((prev) => ({
      ...prev,
      zoneId: selectedZoneId,
    }));

    if (selectedZoneId === 0) {
      setAreaOptions([{ id: 0, name: "ALL", label: "ALL" }]);
      setSelectedArea(0);
    } else {
      try {
        await fetchAreaOptions(selectedZoneId);
      } catch (error) {
        console.error(error);
        toast.error(error.response.data.message);
      }
    }
  };
  const handleZoneChange11 = async (event) => {
    const selectedZoneId = parseInt(event.target.value);
    setPayload11((prev) => ({
      ...prev,
      zoneId: selectedZoneId,
    }));

    if (selectedZoneId === 0) {
      // Zone "ALL" selected
      setAreaOptions([{ id: 0, name: "ALL", label: "ALL" }]);
      setSelectedArea(0);
    } else {
      // Specific zone selected, fetch corresponding areas
      try {
        await fetchAreaOptions(selectedZoneId);
      } catch (error) {
        console.error(error);
        toast.error(error.response.data.message);
      }
    }
  };
  const fetchAreaOptions = async (zoneId) => {
    try {
      const payload = {
        zoneId: zoneId,
      };
      const res = await apiService.areaOptions(payload);
      console.log(res.data.data);
      setAreaOptions(res.data.data);
      if (res.data.data.length > 0) {
        setSelectedArea(res.data.data[0].id); // Assuming you want to select the first area by default
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    }
  };
  const handleAreaChange1 = async (event) => {
    setPayload1((prev) => ({
      ...prev,
      areaId: parseInt(event.target.value),
    }));
    const payload = {
      areaId: payload1.areaId,
    };
  };

  const handleAreaChange2 = async (event) => {
    setPayload2((prev) => ({
      ...prev,
      areaId: parseInt(event.target.value),
    }));
    const payload = {
      areaId: payload2.areaId,
    };
  };
  const handleAreaChange3 = async (event) => {
    setPayload3((prev) => ({
      ...prev,
      areaId: parseInt(event.target.value),
    }));
    const payload = {
      areaId: payload3.areaId,
    };
  };
  const handleAreaChange4 = async (event) => {
    setPayload4((prev) => ({
      ...prev,
      areaId: parseInt(event.target.value),
    }));
    const payload = {
      areaId: payload4.areaId,
    };
  };
  const handleAreaChange5 = async (event) => {
    setPayload5((prev) => ({
      ...prev,
      areaId: parseInt(event.target.value),
    }));
    const payload = {
      areaId: payload5.areaId,
    };
  };
  const handleAreaChange6 = async (event) => {
    setPayload6((prev) => ({
      ...prev,
      areaId: parseInt(event.target.value),
    }));
    const payload = {
      areaId: payload6.areaId,
    };
  };
  const handleAreaChange7 = async (event) => {
    setPayload7((prev) => ({
      ...prev,
      areaId: parseInt(event.target.value),
    }));
    const payload = {
      areaId: payload7.areaId,
    };
  };
  const handleAreaChange8 = async (event) => {
    setPayload8((prev) => ({
      ...prev,
      areaId: parseInt(event.target.value),
    }));
    const payload = {
      areaId: payload8.areaId,
    };
  };
  const handleAreaChange9 = async (event) => {
    setPayload9((prev) => ({
      ...prev,
      areaId: parseInt(event.target.value),
    }));
    const payload = {
      areaId: payload9.areaId,
    };
  };

  const handleAreaChange10 = async (event) => {
    setPayload10((prev) => ({
      ...prev,
      areaId: parseInt(event.target.value),
    }));
  };

  const handleAreaChange11 = async (event) => {
    setPayload11((prev) => ({
      ...prev,
      areaId: parseInt(event.target.value),
    }));
  };
  const handleModelTypeChange = (event) => {
    console.log("model type id ", event.target.value);
    setPayload11((prev) => ({
      ...prev,
      modelTypeId: parseInt(event.target.value),
    }));
  };

  const handleMonthChange1 = (event) => {
    const selectedMonth = event.target.value;
    setPayload1((prev) => ({
      ...prev,
      month: selectedMonth,
    }));
  };

  const handleMonthChange2 = (event) => {
    const selectedMonth = event.target.value;
    //const { value } = event.target;
    setPayload2((prev) => ({
      ...prev,
      month: selectedMonth,
    }));
  };
  const handleMonthChange3 = (event) => {
    const selectedMonth = event.target.value;
    //const { value } = event.target;
    setPayload3((prev) => ({
      ...prev,
      month: selectedMonth,
    }));
  };
  const handleMonthChange4 = (event) => {
    const selectedMonth = event.target.value;
    //const { value } = event.target;
    setPayload4((prev) => ({
      ...prev,
      month: selectedMonth,
    }));
  };
  const handleMonthChange5 = (event) => {
    const selectedMonth = event.target.value;
    // const { value } = event.target;
    setPayload5((prev) => ({
      ...prev,
      month: selectedMonth,
    }));
  };
  const handleMonthChange6 = (event) => {
    const selectedMonth = event.target.value;
    // const { value } = event.target;
    setPayload6((prev) => ({
      ...prev,
      month: selectedMonth,
    }));
  };
  const handleYearChange7 = (event) => {
    const selectedYear = event.target.value;
    //const { value } = event.target;
    setPayload7((prev) => ({
      ...prev,
      year: selectedYear,
    }));
  };
  const handleMonthChange8 = (event) => {
    const selectedMonth = event.target.value;
    //const { value } = event.target;
    setPayload8((prev) => ({
      ...prev,
      month: selectedMonth,
    }));
  };
  const handleMonthChange9 = (event) => {
    const selectedMonth = event.target.value;
    //const { value } = event.target;
    setPayload9((prev) => ({
      ...prev,
      month: selectedMonth,
    }));
  };
  const handleMonthChange10 = (event) => {
    const selectedMonth = event.target.value;
    //const { value } = event.target;
    setPayload10((prev) => ({
      ...prev,
      month: selectedMonth,
    }));
  };
  const handleMonthChange11 = (event) => {
    const selectedMonth = event.target.value;
    //const { value } = event.target;
    setPayload11((prev) => ({
      ...prev,
      month: selectedMonth,
    }));
  };
  const handleMouseEnter = (data, event) => {
    const { clientX, clientY } = event;
    setTooltipPosition({ x: clientX + 10, y: clientY + 10 });
    setTooltip(data);
  };
  const handleMouseLeave = () => {
    setTooltip(null);
  };

  const fetchModelTypeCategoryOptions = async () => {
    await apiService
      .modelTypeCategoryOptions()
      .then((res) => {
        let modelTypeOptions = [
          { id: 0, name: "ALL", label: "ALL" },
          ...res.data.data,
        ];

        setModelTypeCategoryOptions(modelTypeOptions);
      })
      .catch((error) => {
        service.notifyApiError(error, setMessage, setError);
      });
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  useEffect(() => {
    fetchZoneOptions();
    fetchModelTypeCategoryOptions();
  }, []);
  useEffect(() => {
    if (selectedZone !== "") {
      fetchAreaOptions(selectedZone);
    }
  }, [selectedZone]);

  useEffect(() => {
    fetchAreaOptions();
  }, []);

  useEffect(() => {
    fetchHourlySoldPoliciesData();
  }, [payload10]);

  useEffect(() => {
    dealerTypeWiseTargetVsAchievementData();
  }, [payload1]); // Empty dependency array means this effect runs only once after initial render
  useEffect(() => {
    planWiseSalesReport();
  }, [payload2]);
  useEffect(() => {
    categoryWiseDailyReport();
  }, [payload3]);
  useEffect(() => {
    planWiseDailyReport();
  }, [payload4]);
  useEffect(() => {
    dailyTargetVsAchievementReport();
  }, [payload5]);
  useEffect(() => {
    dealerTypeWiseRSAStatsReport();
  }, [payload6]);
  useEffect(() => {
    fetchrenewedPoliciesCount();
  }, [payload2]);
  useEffect(() => {
    fetchServiceTargetVsAchievement();
  }, [payload7]);
  useEffect(() => {
    fetchTopDealers();
  }, [payload8]);
  useEffect(() => {
    fetchTopDealersByModelType();
  }, [payload11]);
  useEffect(() => {
    generateYearsOptions();
    fetchLoggedInUserCount();
    fetchTotalSoldPolicyCount("ftd"); // Fetch FTD count
    fetchTotalSoldPolicyCount("mtd"); // Fetch MTD count
    fetchActiveUsersCount();
  }, []);
  useEffect(() => {
    fetchTotalSoldPolicyCountInclusion("ftd"); // Fetch FTD count
    fetchTotalSoldPolicyCountInclusion("mtd"); // Fetch MTD count
  }, []);

  return (
    <>
      <div className="flex flex-col w-full min-h-screen bg-gray-100 px-6 pb-4 pt-6">
        <div className="px-0 py-0 pb-8">
          <Tabs
            selectedIndex={selectedTabIndex}
            onSelect={handleTabSelect}
            selectedTabClassName="text-primary-darkest border-b-2 border-primary font-medium"
          >
            <TabList className="flex justify-center mb-4 text-neutral-dark">
              <Tab className="uppercase px-4 pb-2 active:text-secondary-darkest cursor-pointer focus:outline-none">
                Retail
              </Tab>
              <Tab className="uppercase px-4 pb-2 cursor-pointer focus:outline-none">
                Inclusion
              </Tab>
            </TabList>
            <TabPanel>
              <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 md:gap-4 gap-2  mb-6">
                <div className="bg-white shadow rounded-lg md:p-4 p-2 transform transition duration-300 flex border border-neutral-light md:space-x-4 space-x-0 group justify-center md:justify-start">
                  <h3 className=" items-center hidden md:block">
                    <span className="material-symbols-outlined text-5xl font-bold text-gray-700 ">
                      login
                    </span>
                  </h3>
                  <div className=" flex">
                    <div className="flex flex-col">
                      <div className="flex md:justify-between justify-center">
                        <p className="mt-2 text-gray-700 md:text-2xl text-3xl font-semibold text-center">
                          <CountUp
                            key={key1}
                            start={0}
                            end={loggedInUserCount}
                            duration={2.5}
                            separator=","
                          />
                        </p>
                      </div>

                      <span className="capitalize text-xs">
                        Logged In Dealers (FTD)
                      </span>
                    </div>
                    <div>
                      <button
                        className="flex items-center justify-center active:bg-neutral-lightest w-1 h-1 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute right-4"
                        onClick={fetchLoggedInUserCount}
                      >
                        <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black text-sm ">
                          refresh
                        </span>
                      </button>
                    </div>
                  </div>
                  {/* Assuming sale.value is already formatted as currency */}
                </div>
                <div className="bg-white shadow rounded-lg md:p-4 p-2 transform transition duration-300 flex justify-center md:justify-start border border-neutral-light md:space-x-4 space-x-0 group">
                  <h3 className="items-center hidden md:block">
                    <span className="material-symbols-outlined text-5xl font-bold text-gray-700">
                      description
                    </span>
                  </h3>
                  <div className="flex">
                    <div className="flex flex-col">
                      <div className="flex md:justify-between justify-center">
                        <p className="mt-2 text-gray-700 md:text-2xl text-3xl font-semibold text-center">
                          <CountUp
                            key={key5}
                            start={0}
                            end={totalSoldPolicyCountFTD}
                            duration={2.5}
                            separator=","
                          />
                        </p>
                      </div>
                      <span className="capitalize text-xs mt-2 md:mt-0">
                        Sold Policy (FTD)
                      </span>
                    </div>
                  </div>
                  <div>
                    <button
                      className="flex items-center justify-center active:bg-neutral-lightest w-1 h-1 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute right-4"
                      onClick={() => fetchTotalSoldPolicyCount("ftd")}
                    >
                      <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black text-sm">
                        refresh
                      </span>
                    </button>
                  </div>
                </div>
                <div className="bg-white shadow rounded-lg md:p-4 p-2 transform transition duration-300 flex border border-neutral-light md:space-x-4 space-x-0 group justify-center md:justify-start">
                  <h3 className="items-center hidden md:block">
                    <span className="material-symbols-outlined text-5xl font-bold text-gray-700">
                      description
                    </span>
                  </h3>
                  <div className="flex">
                    <div className="flex flex-col">
                      <div className="flex md:justify-between justify-center">
                        <p className="mt-2 text-gray-700 md:text-2xl text-3xl font-semibold text-center">
                          <CountUp
                            key={key3}
                            start={0}
                            end={totalSoldPolicyCountMTD}
                            duration={2.5}
                            separator=","
                          />
                        </p>
                      </div>
                      <span className="capitalize text-xs mt-2 md:mt-0">
                        Sold Policy (MTD)
                      </span>
                    </div>
                  </div>
                  <div>
                    <button
                      className="flex items-center justify-center active:bg-neutral-lightest w-1 h-1 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute right-4"
                      onClick={() => fetchTotalSoldPolicyCount("mtd")}
                    >
                      <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black text-sm">
                        refresh
                      </span>
                    </button>
                  </div>
                </div>
                <div className="bg-white shadow rounded-lg md:p-4 p-2 transform transition duration-300 flex border border-neutral-light md:space-x-4 space-x-0 group justify-center md:justify-start">
                  <h3 className="flex items-center hidden md:block">
                    <span className="material-symbols-outlined text-5xl font-bold text-gray-700">
                      person
                    </span>
                  </h3>
                  <div className="flex">
                    <div className="flex flex-col">
                      <div className="flex md:justify-between justify-center">
                        <p className="mt-2 text-gray-700 md:text-2xl text-3xl font-semibold text-center">
                          <CountUp
                            key={key4}
                            start={0}
                            end={activeUserCount}
                            duration={2.5}
                            separator=","
                          />
                        </p>
                      </div>
                      <span className="capitalize text-xs mt-2 md:mt-0">
                        Active Users (MTD)
                      </span>
                    </div>
                    <button
                      className="flex items-center justify-center active:bg-neutral-lightest w-1 h-1 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute right-4"
                      onClick={fetchActiveUsersCount}
                    >
                      <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black text-sm">
                        refresh
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="pt-0 pb-0"></div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full mb-8">
                <div className="bg-white shadow rounded-lg p-6 transform transition duration-300 border border-neutral-light min-h-96">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-medium text-gray-700 mb-4">
                      Sales Target vs Achievement -{" "}
                      {moment(selectedYear7).format("YYYY")}
                    </h2>
                    {/* <button className='flex items-center justify-center active:bg-neutral-lightest w-8 h-8 rounded-full'><span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">download</span></button> */}
                    <button
                      className="flex items-center justify-center active:bg-neutral-lightest w-8 h-8 rounded-full"
                      onClick={() => setPayload7(initalPayload7)}
                    >
                      <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">
                        refresh
                      </span>
                    </button>
                  </div>
                  <div className="flex text-sm space-x-4 w-full justify-end">
                    <div className="flex flex-col">
                      <label for="zone" className="text-xs text-neutral-darker">
                        Zone
                      </label>
                      <select
                        name="zone"
                        id="zone"
                        className="w-28"
                        value={payload7.zoneId}
                        onChange={handleZoneChange7}
                      >
                        {zoneOptions.map((zone) => (
                          <option key={zone.id} value={zone.id}>
                            {zone.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="flex flex-col">
                      <label for="area" className="text-xs text-neutral-darker">
                        Area
                      </label>
                      <select
                        name="area"
                        id="area"
                        className="w-28"
                        value={payload7.areaId}
                        onChange={handleAreaChange7}
                        disabled={payload7.zoneId === 0}
                      >
                        {areaOptions.map((area) => (
                          <option key={area.id} value={area.id}>
                            {area.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex flex-col">
                      <label
                        htmlFor="month"
                        className="text-xs text-neutral-darker"
                      >
                        Year
                      </label>
                      <select
                        name="year"
                        id="year"
                        value={payload7.year}
                        onChange={handleYearChange7}
                        className="w-28"
                      >
                        {yearsOptions.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {serviceTargetVsAchievement.length > 0 ? (
                    <div className="h-72">
                      <ResponsiveLine
                        data={serviceTargetVsAchievement}
                        margin={{ top: 30, right: 40, bottom: 42, left: 60 }}
                        xScale={{ type: "point" }}
                        yScale={{
                          type: "linear",
                          min: "auto",
                          max: "auto",
                          stacked: false,
                          reverse: false,
                        }}
                        curve="catmullRom"
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                          orient: "bottom",
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: "Month",
                          legendOffset: 36,
                          legendPosition: "middle",
                        }}
                        axisLeft={{
                          orient: "left",
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: "Value",
                          legendOffset: -40,
                          legendPosition: "middle",
                        }}
                        enableGridX={false}
                        colors={({ id }) =>
                          id === "Target" ? "#A5CFFF" : "#092E58"
                        }
                        pointSize={4}
                        pointColor={{ theme: "background" }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: "serieColor" }}
                        pointLabelYOffset={-12}
                        useMesh={true}
                        legends={[
                          {
                            anchor: "top-right",
                            direction: "column",
                            justify: false,
                            translateX: 40,
                            translateY: 80,
                            itemsSpacing: 2,
                            itemDirection: "left-to-right",
                            itemWidth: 100,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolBorderColor: "rgba(0, 0, 0, .5)",
                            effects: [
                              {
                                on: "hover",
                                style: {
                                  itemBackground: "rgba(0, 0, 0, .03)",
                                  itemOpacity: 1,
                                },
                              },
                            ],
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    <div className="h-full flex justify-center items-center">
                      <p className="text-sm mt-24"> Report not available</p>
                    </div>
                  )}
                </div>
                <div className="bg-white shadow rounded-lg p-6 transform transition duration-300 border border-neutral-light min-h-96 ">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-medium text-gray-700">
                      Top 10 Dealers - {formatMonthYear(selectedMonth8)}{" "}
                    </h2>
                    {/* <button className='flex items-center justify-center active:bg-neutral-lightest w-8 h-8 rounded-full'><span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">download</span></button> */}
                    <button
                      className="flex items-center justify-center active:bg-neutral-lightest w-8 h-8 rounded-full"
                      onClick={() => setPayload8(initalPayload8)}
                    >
                      <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">
                        refresh
                      </span>
                    </button>
                  </div>
                  <div className="flex text-sm space-x-4 w-full justify-end">
                    <div className="flex flex-col">
                      <label for="zone" className="text-xs text-neutral-darker">
                        Zone
                      </label>
                      <select
                        name="zone"
                        id="zone"
                        className="w-28"
                        value={payload8.zoneId}
                        onChange={handleZoneChange8}
                      >
                        {zoneOptions.map((zone) => (
                          <option key={zone.id} value={zone.id}>
                            {zone.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="flex flex-col">
                      <label for="area" className="text-xs text-neutral-darker">
                        Area
                      </label>
                      <select
                        name="area"
                        id="area"
                        className="w-28"
                        value={payload8.areaId}
                        onChange={handleAreaChange8}
                        disabled={payload8.zoneId === 0}
                      >
                        {areaOptions.map((area) => (
                          <option key={area.id} value={area.id}>
                            {area.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex flex-col">
                      <label
                        htmlFor="month"
                        className="text-xs text-neutral-darker"
                      >
                        Month
                      </label>
                      <input
                        type="month"
                        id="month"
                        name="month"
                        className="w-28"
                        value={payload8.month}
                        max={moment().format("YYYY-MM")}
                        onChange={handleMonthChange8}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col mt-4">
                    {topDealers.length > 0 ? (
                      topDealers.map((plan, index) => (
                        <div className="flex items-center">
                          {plan.rank === 1 && (
                            <img
                              src={imageGold}
                              alt="gold-badge"
                              className="w-6 h-9 mr-2"
                            />
                          )}
                          {plan.rank === 2 && (
                            <img
                              src={imageSilver}
                              alt="silver-badge"
                              className="w-6 h-9 mr-2"
                            />
                          )}
                          {plan.rank === 3 && (
                            <img
                              src={imageBronze}
                              alt="bronze-badge"
                              className="w-6 h-9 mr-2"
                            />
                          )}
                          {plan.rank > 3 && (
                            <div className="mr-3">
                              <span className="pl-2 text-xs ">
                                {plan.rank}.
                              </span>
                            </div>
                          )}
                          <div
                            key={plan.id}
                            className={`px-4  rounded flex justify-between items-center w-full text-left ${
                              plan.rank <= 3
                                ? "text-gray-700 font-bold py-1"
                                : "text-gray-700 mb-1"
                            } ${
                              plan.rank === 1
                                ? "bg-primary-light text-gray-700"
                                : plan.rank === 2
                                ? "bg-primary-lighter text-gray-700"
                                : plan.rank === 3
                                ? "bg-primary-lightest text-gray-700"
                                : plan.rank === 3
                                ? "bg-neutral-lightest text-gray-700"
                                : "bg-neutral-lightest text-gray-700"
                            }`}
                          >
                            <div>
                              <span className="text-left text-xs">
                                {plan.dealerName}
                              </span>
                            </div>
                            <span className="ml-4 text-xs">
                              <CountUp
                                start={0}
                                end={plan.count}
                                duration={2.5}
                                separator=","
                              />
                            </span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="h-full flex justify-center items-center">
                        <p className="text-sm mt-24"> Report not available</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full mb-6">
                <div className="bg-white shadow rounded-lg p-6 transform transition duration-300 border border-neutral-light min-h-96">
                  <div className="flex flex-col w  justify-between font-medium text-gray-700 mb-4">
                    <div className="flex  justify-between items-center mb-4">
                      <span className="md:text-lg text-sm ">
                        Dealer Type Wise Target vs Achieved -{" "}
                        {formatMonthYear(selectedMonth1)}
                      </span>
                      <button
                        className="flex items-center justify-center active:bg-neutral-lightest md:w-8 w-5 md:h-8 h-5 rounded-full mb-4 md:mb-0"
                        onClick={() => setPayload1(initalPayload1)}
                      >
                        <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">
                          refresh
                        </span>
                      </button>
                    </div>

                    <div className="flex text-sm space-x-4 w-full justify-end">
                      <div className="flex flex-col">
                        <label
                          for="zone"
                          className="text-xs text-neutral-darker"
                        >
                          Zone
                        </label>
                        <select
                          name="zone"
                          id="zone"
                          className="md:w-28 w-20"
                          value={payload1.zoneId}
                          onChange={handleZoneChange1}
                        >
                          {zoneOptions.map((zone) => (
                            <option key={zone.id} value={zone.id}>
                              {zone.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="flex flex-col">
                        <label
                          for="area"
                          className="text-xs text-neutral-darker"
                        >
                          Area
                        </label>
                        <select
                          name="area"
                          id="area"
                          className="md:w-28 w-20"
                          value={payload1.areaId}
                          onChange={handleAreaChange1}
                          disabled={payload1.zoneId === 0}
                        >
                          {areaOptions.map((area) => (
                            <option key={area.id} value={area.id}>
                              {area.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="flex flex-col">
                        <label
                          htmlFor="month"
                          className="text-xs text-neutral-darker"
                        >
                          Month
                        </label>
                        <input
                          type="month"
                          id="month"
                          name="month"
                          className="md:w-28 w-24"
                          value={payload1.month}
                          onChange={handleMonthChange1}
                        />
                      </div>
                    </div>
                  </div>

                  {chartData1.length > 0 ? (
                    <div className="h-80">
                      <ResponsiveBar
                        data={chartData1}
                        keys={["MTD Target", "Total Achieved", "Total Target"]}
                        indexBy="type"
                        margin={{ top: 20, right: 40, bottom: 30, left: 30 }}
                        padding={0.4}
                        groupMode="grouped"
                        valueScale={{ type: "linear" }}
                        indexScale={{ type: "band", round: true }}
                        colors={chartColorSet}
                        borderColor={{
                          from: "color",
                          modifiers: [["darker", 1.6]],
                        }}
                        axisTop={null}
                        axisRight={null}
                        axisLeft={{
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: "No. of Policies",
                          legendPosition: "middle",
                          legendOffset: -40,
                          truncateTickAt: 0,
                        }}
                        enableLabel={false}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor={{
                          from: "color",
                          modifiers: [["darker", 1.6]],
                        }}
                        legends={
                          isDesktop
                            ? [
                                {
                                  anchor: "top-right",
                                  direction: "column",
                                  justify: false,
                                  translateX: 45,
                                  translateY: 80,
                                  itemsSpacing: 2,
                                  itemDirection: "left-to-right",
                                  itemWidth: 100,
                                  itemHeight: 20,
                                  itemOpacity: 0.75,
                                  symbolSize: 12,
                                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                                  onMouseEnter: handleMouseEnter,
                                  onMouseLeave: handleMouseLeave,
                                  // onMouseEnter: (data) => {
                                  //   console.log(data);
                                  // },
                                  effects: [
                                    {
                                      on: "hover",
                                      style: {
                                        itemBackground: "rgba(0, 0, 0, .03)",
                                        itemOpacity: 1,
                                      },
                                    },
                                  ],
                                },
                              ]
                            : []
                        }
                        role="application"
                        ariaLabel="Nivo bar chart demo"
                        transitionMode="pushOut"
                      />
                    </div>
                  ) : (
                    <div className="h-full flex justify-center items-center">
                      <p className="text-sm mt-24"> Report not available</p>
                    </div>
                  )}
                </div>

                <div className="bg-white shadow rounded-lg p-6 w-full transform transition duration-300 border border-neutral-light">
                  <div className="flex flex-col justify-between font-medium text-gray-700 mb-4">
                    <div className="flex  justify-between items-center mb-4">
                      <span className="md:text-lg text-sm  ">
                        Dealer Type Wise RSA Statistics -{" "}
                        {formatMonthYear(selectedMonth6)}
                      </span>

                      <button
                        className="flex items-center justify-center active:bg-neutral-lightest md:w-8 w-5 md:h-8 h-5 rounded-full"
                        onClick={() => setPayload6(initalPayload6)}
                      >
                        <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">
                          refresh
                        </span>
                      </button>
                    </div>
                    <div className="flex text-sm space-x-4 justify-end">
                      <div className="flex flex-col">
                        <label
                          for="zone"
                          className="text-xs text-neutral-darker"
                        >
                          Zone
                        </label>
                        <select
                          name="zone"
                          id="zone"
                          className="md:w-28 w-20"
                          value={payload6.zoneId}
                          onChange={handleZoneChange6}
                        >
                          {zoneOptions.map((zone) => (
                            <option key={zone.id} value={zone.id}>
                              {zone.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="flex flex-col">
                        <label
                          for="area"
                          className="text-xs text-neutral-darker"
                        >
                          Area
                        </label>
                        <select
                          name="area"
                          id="area"
                          className="md:w-28 w-20"
                          value={payload6.areaId}
                          onChange={handleAreaChange6}
                          disabled={payload6.zoneId === 0}
                        >
                          {areaOptions.map((area) => (
                            <option key={area.id} value={area.id}>
                              {area.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="flex flex-col">
                        <label
                          htmlFor="month"
                          className="text-xs text-neutral-darker"
                        >
                          Month
                        </label>
                        <input
                          type="month"
                          id="month"
                          name="month"
                          className="md:w-28 w-24"
                          value={payload6.month}
                          onChange={handleMonthChange6}
                        />
                      </div>
                    </div>
                  </div>

                  {RSAData.length > 0 ? (
                    <div className="h-80">
                      <ResponsiveBar
                        data={RSAData}
                        keys={["Selling RSA", "Not Selling RSA"]}
                        indexBy="type"
                        margin={{ top: 20, right: 30, bottom: 30, left: 30 }}
                        padding={0.6}
                        groupMode="stacked"
                        valueScale={{ type: "linear" }}
                        indexScale={{ type: "band", round: true }}
                        colors={chartColorSet}
                        borderColor={{
                          from: "color",
                          modifiers: [["darker", 1.6]],
                        }}
                        enableLabel={true}
                        labelTextColor={(obj) => getLabelTextColor(obj.color)}
                        axisTop={null}
                        axisRight={null}
                        axisLeft={{
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: "",
                          legendPosition: "middle",
                          legendOffset: -40,
                          truncateTickAt: 0,
                        }}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        // labelTextColor={{
                        //   from: "color",
                        //   modifiers: [["darker", 1.6]],
                        // }}
                        //labelTextColor={"red"}
                        legends={
                          isDesktop
                            ? [
                                {
                                  anchor: "top-right",
                                  direction: "column",
                                  justify: false,
                                  translateX: 20,
                                  translateY: 80,
                                  itemsSpacing: 2,
                                  itemDirection: "left-to-right",
                                  itemWidth: 100,
                                  itemHeight: 20,
                                  itemOpacity: 0.75,
                                  symbolSize: 12,
                                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                                  effects: [
                                    {
                                      on: "hover",
                                      style: {
                                        itemBackground: "rgba(0, 0, 0, .03)",
                                        itemOpacity: 1,
                                      },
                                    },
                                  ],
                                },
                              ]
                            : []
                        }
                        role="application"
                        ariaLabel="Nivo bar chart demo"
                        barAriaLabel={(e) =>
                          e.id +
                          ": " +
                          e.formattedValue +
                          " in country: " +
                          e.indexValue
                        }
                        enableTotals={true}
                      />
                    </div>
                  ) : (
                    <div className="h-full flex justify-center items-center">
                      <p className="text-sm mt-24"> Report not available</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="bg-white shadow rounded-lg p-6 transform transition duration-300 border border-neutral-light min-h-96 mb-6 overflow-x-auto">
                <div className="flex flex-col justify-between font-medium text-gray-700 mb-4">
                  <div className="flex  justify-between items-center mb-4">
                    <span className="md:text-lg text-sm ">
                      Daily Target vs Achievement -{" "}
                      {formatMonthYear(selectedMonth5)}
                    </span>

                    <button
                      className="flex items-center justify-center active:bg-neutral-lightest md:w-8 w-5 md:h-8 h-5 rounded-full"
                      onClick={() => setPayload5(initalPayload5)}
                    >
                      <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">
                        refresh
                      </span>
                    </button>
                  </div>
                  <div className="flex text-sm space-x-4 justify-end">
                    <div className="flex flex-col">
                      <label for="zone" className="text-xs text-neutral-darker">
                        Zone
                      </label>
                      <select
                        name="zone"
                        id="zone"
                        className="md:w-28 w-20"
                        value={payload5.zoneId}
                        onChange={handleZoneChange5}
                      >
                        {zoneOptions.map((zone) => (
                          <option key={zone.id} value={zone.id}>
                            {zone.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="flex flex-col">
                      <label for="area" className="text-xs text-neutral-darker">
                        Area
                      </label>
                      <select
                        name="area"
                        id="area"
                        className="md:w-28 w-20"
                        value={payload5.areaId}
                        onChange={handleAreaChange5}
                        disabled={payload5.zoneId === 0}
                      >
                        {areaOptions.map((area) => (
                          <option key={area.id} value={area.id}>
                            {area.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex flex-col">
                      <label
                        htmlFor="month"
                        className="text-xs text-neutral-darker"
                      >
                        Month
                      </label>
                      <input
                        type="month"
                        id="month"
                        name="month"
                        className="md:w-28 w-24"
                        value={payload5.month}
                        onChange={handleMonthChange5}
                      />
                    </div>
                  </div>
                </div>
                <div className="h-80 w-[800px] md:w-full overflow-x-auto md:overflow-x-hidden">
                  {dayWiseTargetvsAchivedData.length > 0 ? (
                    <ResponsiveBar
                      data={dayWiseTargetvsAchivedData}
                      keys={["Target", "Achieved"]}
                      enableLabel={false}
                      indexBy="day"
                      margin={{ top: 20, right: 30, bottom: 70, left: 30 }}
                      padding={0.3}
                      groupMode="grouped"
                      valueScale={{ type: "linear" }}
                      indexScale={{ type: "band", round: true }}
                      colors={chartColorSet}
                      borderColor={{
                        from: "color",
                        modifiers: [["darker", 1.6]],
                      }}
                      axisTop={null}
                      axisRight={null}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: "No. of Policies",
                        legendPosition: "middle",
                        legendOffset: -40,
                        truncateTickAt: 0,
                      }}
                      labelSkipWidth={12}
                      labelSkipHeight={12}
                      labelTextColor={{
                        from: "color",
                        modifiers: [["darker", 1.6]],
                      }}
                      legends={
                        isDesktop
                          ? [
                              {
                                anchor: "top-right",
                                direction: "column",
                                justify: false,
                                translateX: 20,
                                translateY: 80,
                                itemsSpacing: 2,
                                itemDirection: "left-to-right",
                                itemWidth: 100,
                                itemHeight: 20,
                                itemOpacity: 0.75,
                                symbolSize: 12,
                                symbolBorderColor: "rgba(0, 0, 0, .5)",
                                effects: [
                                  {
                                    on: "hover",
                                    style: {
                                      itemBackground: "rgba(0, 0, 0, .03)",
                                      itemOpacity: 1,
                                    },
                                  },
                                ],
                              },
                            ]
                          : []
                      }
                      role="application"
                      isInteractive={true}
                      ariaLabel="Nivo bar chart demo"
                      barAriaLabel={(e) =>
                        e.id +
                        ": " +
                        e.formattedValue +
                        " in country: " +
                        e.indexValue
                      }
                    />
                  ) : (
                    <div className="h-full flex justify-center items-center">
                      <p className="text-sm mt-24"> Report not available</p>
                    </div>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full mb-6">
                <div className="bg-white shadow rounded-lg p-6 transform transition duration-300 border border-neutral-light min-h-96">
                  <div className="flex flex-col justify-between font-medium text-gray-700 mb-4">
                    <div className="flex  justify-between items-center mb-4">
                      <span className="text-lg ">
                        Plan Wise Sales - {formatMonthYear(selectedMonth9)}
                      </span>

                      <button
                        className="flex items-center justify-center active:bg-neutral-lightest w-8 h-8 rounded-full"
                        onClick={() => setPayload9(initalPayload9)}
                      >
                        <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">
                          refresh
                        </span>
                      </button>
                    </div>
                    <div className="flex text-sm space-x-4 justify-end">
                      <div className="flex flex-col">
                        <label
                          for="zone"
                          className="text-xs text-neutral-darker"
                        >
                          Zone
                        </label>
                        <select
                          name="zone"
                          id="zone"
                          className="md:w-28 w-20"
                          value={payload9.zoneId}
                          onChange={handleZoneChange9}
                        >
                          {zoneOptions.map((zone) => (
                            <option key={zone.id} value={zone.id}>
                              {zone.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="flex flex-col">
                        <label
                          for="area"
                          className="text-xs text-neutral-darker"
                        >
                          Area
                        </label>
                        <select
                          name="area"
                          id="area"
                          className="md:w-28 w-20"
                          value={payload9.areaId}
                          onChange={handleAreaChange9}
                          disabled={payload9.zoneId === 0}
                        >
                          {areaOptions.map((area) => (
                            <option key={area.id} value={area.id}>
                              {area.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="flex flex-col">
                        <label
                          htmlFor="month"
                          className="text-xs text-neutral-darker"
                        >
                          Month
                        </label>
                        <input
                          type="month"
                          id="month"
                          name="month"
                          className="md:w-28 w-24"
                          value={payload9.month}
                          onChange={handleMonthChange9}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="h-60 mb-4">
                    {planWiseData.length > 0 ? (
                      <ResponsivePie
                        data={planWiseData}
                        //data ={policyWiseData}
                        margin={{ top: 20, right: 20, bottom: 20, left: -250 }}
                        innerRadius={0.7}
                        padAngle={0}
                        cornerRadius={0}
                        colors={chartColorSet}
                        activeOuterRadiusOffset={8}
                        borderWidth={1}
                        borderColor={{
                          from: "color",
                          modifiers: [["darker", 0.2]],
                        }}
                        radialLabelsSkipAngle={10}
                        radialLabelsTextColor="#333333"
                        radialLabelsLinkColor={{ from: "color" }}
                        sliceLabelsSkipAngle={10}
                        sliceLabelsTextColor="#333333"
                        enableArcLinkLabels={false}
                        padding={{ bottom: 20 }}
                        enableArcLabels={true}
                        // arcLabelTextColor="white"
                        arcLabelsTextColor={(obj) =>
                          getLabelTextColor(obj.color)
                        }
                        //arcLabelTextColor={({ datum }) => getArcLabelColor(policyWiseData, datum.label)}
                        arcLabelTextColor={({ datum }) =>
                          getArcLabelColor(planWiseData, datum.label)
                        }
                        legends={
                          isDesktop
                            ? [
                                {
                                  anchor: "top-right",
                                  direction: "column",
                                  justify: false,
                                  translateX: -170,
                                  translateY: 20,
                                  itemsSpacing: 2,
                                  itemDirection: "left-to-right",
                                  itemWidth: 100,
                                  itemHeight: 20,
                                  itemOpacity: 0.75,
                                  symbolSize: 12,
                                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                                  effects: [
                                    {
                                      on: "hover",
                                      style: {
                                        itemBackground: "rgba(0, 0, 0, .03)",
                                        itemOpacity: 1,
                                      },
                                    },
                                  ],
                                },
                              ]
                            : []
                        }
                      />
                    ) : (
                      <div className="h-full flex justify-center items-center">
                        <p className="text-sm mt-24"> Report not available</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 md:gap-4 gap-2  mb-6 justify-center">
                <div></div>
                <div className="bg-white shadow rounded-lg md:p-4 p-2 transform transition duration-300 flex justify-center md:justify-start border border-neutral-light md:space-x-4 space-x-0 group">
                  <h3 className="items-center hidden md:block">
                    <span className="material-symbols-outlined text-5xl font-bold text-gray-700">
                      description
                    </span>
                  </h3>
                  <div className="flex">
                    <div className="flex flex-col">
                      <div className="flex md:justify-between justify-center">
                        <p className="mt-2 text-gray-700 md:text-2xl text-3xl font-semibold text-center">
                          <CountUp
                            key={key5}
                            start={0}
                            end={totalSoldPolicyCountFTDInclusion}
                            duration={2.5}
                            separator=","
                          />
                        </p>
                      </div>
                      <span className="capitalize text-xs mt-2 md:mt-0">
                        Sold Policy (FTD)
                      </span>
                    </div>
                  </div>
                  <div>
                    <button
                      className="flex items-center justify-center active:bg-neutral-lightest w-1 h-1 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute right-4"
                      onClick={() => fetchTotalSoldPolicyCountInclusion("ftd")}
                    >
                      <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black text-sm">
                        refresh
                      </span>
                    </button>
                  </div>
                </div>
                <div className="bg-white shadow rounded-lg md:p-4 p-2 transform transition duration-300 flex border border-neutral-light md:space-x-4 space-x-0 group justify-center md:justify-start">
                  <h3 className="items-center hidden md:block">
                    <span className="material-symbols-outlined text-5xl font-bold text-gray-700">
                      description
                    </span>
                  </h3>
                  <div className="flex">
                    <div className="flex flex-col">
                      <div className="flex md:justify-between justify-center">
                        <p className="mt-2 text-gray-700 md:text-2xl text-3xl font-semibold text-center">
                          <CountUp
                            key={key6}
                            start={0}
                            end={totalSoldPolicyCountMTDInclusion}
                            duration={2.5}
                            separator=","
                          />
                        </p>
                      </div>
                      <span className="capitalize text-xs mt-2 md:mt-0">
                        Sold Policy (MTD)
                      </span>
                    </div>
                  </div>
                  <div>
                    <button
                      className="flex items-center justify-center active:bg-neutral-lightest w-1 h-1 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute right-4"
                      onClick={() => fetchTotalSoldPolicyCountInclusion("mtd")}
                    >
                      <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black text-sm">
                        refresh
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full mb-8">
                <div className="bg-white shadow rounded-lg p-6 transform transition duration-300 border border-neutral-light min-h-96">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-medium text-gray-700 mb-4">
                      Hourly Policies - {moment(selectedMonth10).format("YYYY")}
                    </h2>
                    {/* <button className='flex items-center justify-center active:bg-neutral-lightest w-8 h-8 rounded-full'><span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">download</span></button> */}
                    <button
                      className="flex items-center justify-center active:bg-neutral-lightest w-8 h-8 rounded-full"
                      onClick={() => setPayload10(initalPayload10)}
                    >
                      <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">
                        refresh
                      </span>
                    </button>
                  </div>
                  <div className="flex text-sm space-x-4 w-full justify-end">
                    <div className="flex flex-col">
                      <label for="zone" className="text-xs text-neutral-darker">
                        Zone
                      </label>
                      <select
                        name="zone"
                        id="zone"
                        className="w-28"
                        value={payload10.zoneId}
                        onChange={handleZoneChange7}
                      >
                        {zoneOptions.map((zone) => (
                          <option key={zone.id} value={zone.id}>
                            {zone.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="flex flex-col">
                      <label for="area" className="text-xs text-neutral-darker">
                        Area
                      </label>
                      <select
                        name="area"
                        id="area"
                        className="w-28"
                        value={payload7.areaId}
                        onChange={handleAreaChange7}
                        disabled={payload10.zoneId === 0}
                      >
                        {areaOptions.map((area) => (
                          <option key={area.id} value={area.id}>
                            {area.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {hourlySoldPoliciesChart.length > 0 ? (
                    <div className="h-72">
                      <ResponsiveLine
                        data={hourlySoldPoliciesChart}
                        margin={{ top: 30, right: 40, bottom: 42, left: 60 }}
                        xScale={{ type: "point" }}
                        yScale={{
                          type: "linear",
                          min: "auto",
                          max: "auto",
                          stacked: false,
                          reverse: false,
                        }}
                        curve="catmullRom"
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                          orient: "bottom",
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: "Month",
                          legendOffset: 36,
                          legendPosition: "middle",
                        }}
                        axisLeft={{
                          orient: "left",
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: "Value",
                          legendOffset: -40,
                          legendPosition: "middle",
                        }}
                        enableGridX={false}
                        colors={({ id }) =>
                          id === "Target" ? "#A5CFFF" : "#092E58"
                        }
                        pointSize={4}
                        pointColor={{ theme: "background" }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: "serieColor" }}
                        pointLabelYOffset={-12}
                        useMesh={true}
                        legends={[
                          {
                            anchor: "top-right",
                            direction: "column",
                            justify: false,
                            translateX: 40,
                            translateY: 80,
                            itemsSpacing: 2,
                            itemDirection: "left-to-right",
                            itemWidth: 100,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolBorderColor: "rgba(0, 0, 0, .5)",
                            effects: [
                              {
                                on: "hover",
                                style: {
                                  itemBackground: "rgba(0, 0, 0, .03)",
                                  itemOpacity: 1,
                                },
                              },
                            ],
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    <div className="h-full flex justify-center items-center">
                      <p className="text-sm mt-24"> Report not available</p>
                    </div>
                  )}
                </div>
                <div className="bg-white shadow rounded-lg p-6 transform transition duration-300 border border-neutral-light min-h-96 ">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-medium text-gray-700">
                      Top 10 Dealers - {formatMonthYear(selectedMonth11)}{" "}
                    </h2>
                    {/* <button className='flex items-center justify-center active:bg-neutral-lightest w-8 h-8 rounded-full'><span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">download</span></button> */}
                    <button
                      className="flex items-center justify-center active:bg-neutral-lightest w-8 h-8 rounded-full"
                      onClick={() => setPayload11(initalPayload11)}
                    >
                      <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">
                        refresh
                      </span>
                    </button>
                  </div>

                  <div className="flex text-sm space-x-4 w-full justify-end">
                    <div className="flex flex-col">
                      <label
                        for="modelType"
                        className="text-xs text-neutral-darker"
                      >
                        Model type
                      </label>
                      <select
                        name="modelType"
                        id="modelType"
                        className="w-42"
                        value={payload11.modelTypeId}
                        onChange={handleModelTypeChange}
                      >
                        {modelTypeCategoryOptions.map((ct) => (
                          <option key={ct.id} value={ct.id}>
                            {ct.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex flex-col">
                      <label for="zone" className="text-xs text-neutral-darker">
                        Zone
                      </label>
                      <select
                        name="zone"
                        id="zone"
                        className="w-28"
                        value={payload8.zoneId}
                        onChange={handleZoneChange11}
                      >
                        {zoneOptions.map((zone) => (
                          <option key={zone.id} value={zone.id}>
                            {zone.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="flex flex-col">
                      <label for="area" className="text-xs text-neutral-darker">
                        Area
                      </label>
                      <select
                        name="area"
                        id="area"
                        className="w-28"
                        value={payload11.areaId}
                        onChange={handleAreaChange11}
                        disabled={payload11.zoneId === 0}
                      >
                        {areaOptions.map((area) => (
                          <option key={area.id} value={area.id}>
                            {area.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex flex-col">
                      <label
                        htmlFor="month"
                        className="text-xs text-neutral-darker"
                      >
                        Month
                      </label>
                      <input
                        type="month"
                        id="month"
                        name="month"
                        className="w-28"
                        value={payload11.month}
                        max={moment().format("YYYY-MM")}
                        onChange={handleMonthChange11}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col mt-4">
                    {topDealersByModelType.length > 0 ? (
                      topDealersByModelType.map((dealer, index) => (
                        <div className="flex items-center">
                          {dealer.rank === 1 && (
                            <img
                              src={imageGold}
                              alt="gold-badge"
                              className="w-6 h-9 mr-2"
                            />
                          )}
                          {dealer.rank === 2 && (
                            <img
                              src={imageSilver}
                              alt="silver-badge"
                              className="w-6 h-9 mr-2"
                            />
                          )}
                          {dealer.rank === 3 && (
                            <img
                              src={imageBronze}
                              alt="bronze-badge"
                              className="w-6 h-9 mr-2"
                            />
                          )}
                          {dealer.rank > 3 && (
                            <div className="mr-3">
                              <span className="pl-2 text-xs ">
                                {dealer.rank}.
                              </span>
                            </div>
                          )}
                          <div
                            key={dealer.id}
                            className={`px-4  rounded flex justify-between items-center w-full text-left ${
                              dealer.rank <= 3
                                ? "text-gray-700 font-bold py-1"
                                : "text-gray-700 mb-1"
                            } ${
                              dealer.rank === 1
                                ? "bg-primary-light text-gray-700"
                                : dealer.rank === 2
                                ? "bg-primary-lighter text-gray-700"
                                : dealer.rank === 3
                                ? "bg-primary-lightest text-gray-700"
                                : dealer.rank === 3
                                ? "bg-neutral-lightest text-gray-700"
                                : "bg-neutral-lightest text-gray-700"
                            }`}
                          >
                            <div>
                              <span className="text-left text-xs">
                                {dealer.dealerName}
                              </span>
                            </div>
                            <span className="ml-4 text-xs">
                              <CountUp
                                start={0}
                                end={dealer.count}
                                duration={2.5}
                                separator=","
                              />
                            </span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="h-full flex justify-center items-center">
                        <p className="text-sm mt-24"> Report not available</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </>
  );
};
