import React, { useEffect, useState } from "react";
import coverImage from "../assets/img/coverImage.png";
import Input from "../components/ui/Input";
import Button from "../components/ui/Button";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useNavigate, useLocation } from "react-router-dom";
import QRCode from "react-qr-code";
import { apiService } from "../api.service";
import moment from "moment";
import {
  // Description,
  Dialog,
  DialogPanel,
  // DialogTitle,
} from "@headlessui/react";
import { service } from "../service";
import useDeviceType from "../hooks/useDeviceType";
import { useGlobalContext } from "../context/global.context";
const max = moment().format("YYYY-MM-DD");
// const InfoIcon = () => {
//   const location = useLocation();
//   const isTargetRoute = location.pathname === targetRoute;
//   // const navigate = useNavigate();
// }
export default function DealerBankTransactions() {
  const { setMessage, setError } = useGlobalContext();

  const navigate = useNavigate();
  const dealerCode = service.getSessionStorageItem("dealerInfo")?.dealerCode;
  console.log(dealerCode);
  const upiLink = `upi://pay?pa=indicosmicinfotech@icici&pn=INRecharge&tr=${dealerCode}&am=&cu=INR&mc=5411`;

  const deviceType = useDeviceType();
  const [showUPIModal, setShowUPIModal] = useState(false);
  const [payload, setPayload] = useState({
    // pagination: {
    //   pageNumber: 1,
    //   pageSize: 5,
    // },
    // search: "",
  });
  // const location = useLocation();
  // const targetRoute = "/bank-transactions"; // replace with your specific route
  // const isTargetRoute = location.pathname === targetRoute;
  // console.log('isTargetRoute', isTargetRoute)

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [accountTypeOptions, setAccountTypeOptions] = useState([]);

  const depositFormValidationSchema = Yup.object().shape({
    bankName: Yup.string().required("Bank name is required"),
    accountNumber: Yup.string()
      .required("Account number is required")
      .matches(/^[^\s]+$/, "Account number cannot contain whitespace")
      .matches(
        /^[a-zA-Z0-9]{9,}$/,
        "Account number must be at least 9 characters long and contain only letters and digits"
      ),
    transactionNumber: Yup.string()
      .required("Transaction number is required")
      .matches(/^[^\s]+$/, "Transaction number cannot contain whitespace")
      .matches(
        /^[a-zA-Z0-9]{9,}$/,
        "Transaction number must be at least 9 characters long and contain only letters and digits"
      ),
    depositAmount: Yup.number()
      .required("Deposit amount is required")
      .positive("Deposit amount must be a positive number")
      .integer("Deposit amount must be an integer"),
    transactionDate: Yup.date().required("Transaction date is required"),
  });

  const [showDateRangeModal, setShowDateRangeModal] = useState(false);

  const depositForm = useFormik({
    initialValues: {
      bankName: "",
      accountNumber: "",
      transactionNumber: "",
      depositAmount: "",
      transactionDate: "",
    },
    validationSchema: depositFormValidationSchema,
    onSubmit: (values) => {
      const payload = {
        bankName: values.bankName,
        accountNumber: values.accountNumber,
        transactionNumber: values.transactionNumber,
        depositAmount: Number(values.depositAmount),
        transactionDate: new Date(values.transactionDate).toISOString(),
        transactionType: "DEPOSIT",
      };
      apiService
        .bankTransaction(payload)
        .then((response) => {
          toast.success(response.data.message);
        })
        .catch((error) => {
          // toast.error(error.response.data.message);
          service.notifyApiError(error, setMessage, setError);
        });
      // navigate("/rsa");
    },
  });
  const dateFilterForm = useFormik({
    initialValues: {
      fromDate: "",
      toDate: "",
    },
    validationSchema: Yup.object().shape({
      fromDate: Yup.date().required("Required"),
      toDate: Yup.date().required("Required"),
    }),
    onSubmit: async (values) => {
      console.log(values);
      setPayload((prev) => ({
        ...prev,
        fromDate: values.fromDate,
        toDate: values.toDate,
      }));
      console.log(payload);
      setShowDateRangeModal(false);
    },
  });
  const autoFillBankDetails = () => {
    const loadingToast = toast.loading("Loading data...");
    try {
      apiService
        .getDealerInfo()
        .then(async (res) => {
          const data = res.data.data;
          depositForm.setFieldValue("bankName", data.bankName);
          depositForm.setFieldValue("accountNumber", data.accountNumber);
          withdrawalForm.setFieldValue("bankName", data.bankName);
          withdrawalForm.setFieldValue("accountName", data.accountHolderName);
          withdrawalForm.setFieldValue("accountNumber", data.accountNumber);
          withdrawalForm.setFieldValue(
            "accountType",
            accountTypeOptions.find((item) => item.id === data.accountType)
              ?.label
          );
          withdrawalForm.setFieldValue("ifscCode", data.ifscCode);
        })
        .catch((error) => {
          service.notifyApiError(error, setMessage, setError);
        });
    } catch (error) {
      service.notifyApiError(error, setMessage, setError);
    } finally {
      toast.dismiss(loadingToast);
    }
  };

  const getAccountTypes = () => {
    const loadingToast = toast.loading("Loading data...");
    try {
      apiService.getAccountTypes().then((res) => {
        setAccountTypeOptions(res.data.data);

        console.log(res.data.data, ">>>>>>>>>>>>>>>>>>>>>>>>>>");
        console.log("accountTypeOptions : ", accountTypeOptions);
      });
    } catch (error) {
      service.notifyApiError(error, setMessage, setError);
    } finally {
      toast.dismiss(loadingToast);
    }
  };
  useEffect(() => {
    getAccountTypes();
  }, []);

  useEffect(() => {
    if (accountTypeOptions) {
      autoFillBankDetails();
    }
  }, [accountTypeOptions]);

  const withdrawalFormValidationSchema = Yup.object().shape({
    bankName: Yup.string().required("Bank name is required"),
    accountName: Yup.string().required("Account name is required"),
    accountNumber: Yup.string()
      .required("Account number is required")
      .matches(/^[^\s]+$/, "Account number cannot contain whitespace")
      .matches(
        /^[a-zA-Z0-9]{9,}$/,
        "Account number must be at least 9 characters long and contain only letters and digits"
      ),
    accountType: Yup.string().required("Account type is required"),
    ifscCode: Yup.string().required("IFSC code is required"),
    withdrawalAmount: Yup.number().required("Withdrawal amount is required"),
    transactionDate: Yup.date().required("Transaction date is required"),
  });

  const withdrawalForm = useFormik({
    initialValues: {
      bankName: "",
      accountName: "",
      accountNumber: "",
      accountType: "",
      ifscCode: "",
      withdrawalAmount: "",
      transactionDate: "",
    },
    validationSchema: withdrawalFormValidationSchema,
    onSubmit: (values) => {
      const payload = {
        bankName: values.bankName,
        accountName: values.accountName,
        accountNumber: values.accountNumber,
        accountType: values.accountType,
        ifscCode: values.ifscCode,
        withdrawalAmount: Number(values.withdrawalAmount),
        transactionDate: new Date(values.transactionDate).toISOString(),
        transactionType: "WITHDRAWAL",
      };
      apiService
        .bankTransaction(payload)
        .then((response) => {
          toast.success(response.data.message);
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
          // toast.error(error);
          service.notifyApiError(error, setMessage, setError);
        });
    },
  });

  const handleTabSelect = (index) => {
    setSelectedTabIndex(index);
  };

  const onUPIPayment = () => {
    if (deviceType === "mobile") {
      window.open(upiLink);
    } else {
      setShowUPIModal(true);
      const payload = {
        dealerCode,
      };
      const loadingToast = toast.loading("loading upi payment data...");
      apiService
        .makeUPIDeposit(payload)
        .then((res) => {
          toast.success(res.data.message);
        })
        .catch((error) => {
          service.notifyApiError(error, setMessage, setError);
        })
        .finally(() => {
          toast.dismiss(loadingToast);
        });
    }
  };

  return (
    <>
      <div className="flex flex-col w-full items-center">
        <div className="sticky -z-10 top-12 w-full">
          {/* <i className="material-symbols-outlined hidden md:block fixed text-white ml-4 mt-2">
            arrow_back
          </i> */}
          <img
            src={coverImage}
            className=" w-full h-36 object-cover"
            alt="cover_image"
          />
        </div>
        <div className="md:w-5/6 w-[90%] flex">
          <div className="w-2/3">
            <form
              onSubmit={
                selectedTabIndex === 0
                  ? depositForm.handleSubmit
                  : withdrawalForm.handleSubmit
              }
            >
              <div className="w-11/12 md:w-1/2 min-w-fit bg-white -mt-28 md:-mt-20 border border-neutral-light rounded mx-2 md:mx-0">
                <div className="bg-base-white px-6 py-4 border-b border-neutral-light rounded-t">
                  <h3 className="text-xl font-semibold">
                    Dealer Bank Transactions
                  </h3>
                  <p className="text-neutral-darker mt-1 text-sm">
                    A bank transaction is any money that moves in or out of your
                    bank account
                  </p>
                </div>
                <div className="px-10 py-4 pb-8">
                  <Tabs
                    selectedIndex={selectedTabIndex}
                    onSelect={handleTabSelect}
                    selectedTabClassName="text-primary-darkest border-b-2 border-primary font-medium"
                  >
                    <TabList className="flex justify-center mb-4 text-neutral-dark">
                      <Tab className="uppercase px-4 pb-2 active:text-secondary-darkest cursor-pointer focus:outline-none">
                        Deposit
                      </Tab>
                      <Tab className="uppercase px-4 pb-2 cursor-pointer focus:outline-none">
                        Withdrawal
                      </Tab>
                    </TabList>
                    <TabPanel>
                      <div className="flex items-center justify-center mb-4 ">
                        <Button
                          type="button"
                          label="Instant deposit using UPI"
                          variant="primary"
                          className="max-w-72"
                          onClick={onUPIPayment}
                        />
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-x-8">
                        <div className="flex flex-col">
                          <Input
                            {...depositForm.getFieldProps("bankName")}
                            formik={depositForm}
                            id="bankName"
                            name="bankName"
                            label="Bank"
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...depositForm.getFieldProps("accountNumber")}
                            formik={depositForm}
                            id="accountNumber"
                            name="accountNumber"
                            type="text"
                            label="Account Number"
                            placeholder="Enter your account number"
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...depositForm.getFieldProps("transactionNumber")}
                            formik={depositForm}
                            id="transactionNumber"
                            name="transactionNumber"
                            type="text"
                            label="Transaction Number"
                            placeholder="Enter your transaction number"
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...depositForm.getFieldProps("depositAmount")}
                            formik={depositForm}
                            id="depositAmount"
                            name="depositAmount"
                            type="text"
                            label="Deposit Amount"
                            placeholder="Enter your deposit amount"
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...depositForm.getFieldProps("transactionDate")}
                            formik={depositForm}
                            id="transactionDate"
                            name="transactionDate"
                            type="date"
                            max={max}
                            label="Transaction Date"
                            placeholder="Enter transaction date"
                          />
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-x-8">
                        <div className="flex flex-col">
                          <Input
                            {...withdrawalForm.getFieldProps("bankName")}
                            formik={withdrawalForm}
                            id="bankName"
                            name="bankName"
                            label="Bank"
                            readOnly
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...withdrawalForm.getFieldProps("accountName")}
                            formik={withdrawalForm}
                            id="accountName"
                            name="accountName"
                            type="text"
                            label="Account Name"
                            placeholder="Enter account name"
                            className="w-full md:w-60"
                            readOnly
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...withdrawalForm.getFieldProps("accountNumber")}
                            formik={withdrawalForm}
                            id="accountNumber"
                            name="accountNumber"
                            type="text"
                            label="Account Number"
                            placeholder="Enter your account number"
                            className="w-full md:w-60"
                            readOnly
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...withdrawalForm.getFieldProps("accountType")}
                            formik={withdrawalForm}
                            id="accountType"
                            name="accountType"
                            label="Account type"
                            readOnly
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...withdrawalForm.getFieldProps("ifscCode")}
                            formik={withdrawalForm}
                            id="ifscCode"
                            name="ifscCode"
                            type="text"
                            label="IFSC Code"
                            placeholder="Enter ifsc code"
                            className="w-full md:w-60"
                            readOnly
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...withdrawalForm.getFieldProps(
                              "withdrawalAmount"
                            )}
                            formik={withdrawalForm}
                            id="withdrawalAmount"
                            name="withdrawalAmount"
                            type="text"
                            label="Withdrawal Amount"
                            placeholder="Enter your withdrawal amount"
                            className="w-full md:w-60"
                          />
                        </div>
                        <div className="flex flex-col">
                          <Input
                            {...withdrawalForm.getFieldProps("transactionDate")}
                            formik={withdrawalForm}
                            id="transactionDate"
                            name="transactionDate"
                            type="date"
                            max={max}
                            label="Transaction Date"
                            placeholder="Enter transaction date"
                            className="w-full md:w-60"
                          />
                        </div>
                      </div>
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
              <div className="flex justify-center">
                <div className="flex justify-center pt-4 pb-8 w-72">
                  <Button
                    type="button"
                    label="Reset"
                    variant="ghost"
                    onClick={() => {
                      depositForm.resetForm();
                      withdrawalForm.resetForm();
                    }}
                  />
                  <Button type="submit" label="Submit" variant="primary" />

                  {/* {isTargetRoute && (
                    <div className="absolute top-20 right-5 ">

                      <button
                        type="button"
                        className="mt-1 "
                        onClick={() => {
                          setShowDateRangeModal(true);
                        }}
                      >
                        <span className=" material-symbols-outlined text-neutral-darkest justify-end block md:hidden">
                          info
                        </span>
                      </button>
                    </div>
                  )} */}
                </div>
              </div>
            </form>
            {/* <Dialog
              open={showDateRangeModal}
              onClose={() => setShowDateRangeModal(false)}
              className="relative z-50"
            >
              <div className="fixed inset-0 bg-black/75" aria-hidden="true" />

              <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                <DialogPanel className="space-y-4 border bg-white border-neutral rounded w-full md:w-1/2 lg:w-1/3 mx-auto">
                  <form onSubmit={dateFilterForm.handleSubmit}>

                    <div className="w-full">
                      <div className="bg-white border border-neutral-light rounded">
                        <div className="bg-base-white px-6 py-4 border-b border-neutral-light rounded-t">
                          <h3 className="text-xl font-semibold">Indicosmic Bank Details</h3>
                          <p className="text-neutral-darker mt-1 text-sm">
                            You may use these data to conduct bank transactions
                          </p>
                        </div>
                        <div className="px-6 py-4 pb-8 flex justify-center">
                          <table className="text-sm">
                            <tbody>
                              <tr>
                                <td className="w-36">Beneficiary Name</td>
                                <td className="font-medium">INDICOSMIC PVT LTD</td>
                              </tr>
                              <tr>
                                <td className="w-36">Bank Name</td>
                                <td className="font-medium">ICICI Bank</td>
                              </tr>
                              <tr>
                                <td className="w-36">Branch Name</td>
                                <td className="font-medium">MIDC Andheri (E)</td>
                              </tr>
                              <tr>
                                <td className="w-36">Account Number</td>
                                <td className="font-medium">ICCP00011111</td>
                              </tr>
                              <tr>
                                <td className="w-36">IFSC Code</td>
                                <td className="font-medium">ICIC0000104</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                    </div>
                  </form>
                </DialogPanel>

              </div>
            </Dialog> */}
          </div>
          <div className="hidden md:block md:w-1/3">
            <div className=" min-w-96 bg-white -mt-28 md:-mt-20 border border-neutral-light rounded">
              <div className="bg-base-white px-6 py-4 border-b border-neutral-light rounded-t">
                <h3 className="text-xl font-semibold">
                  Indicosmic Bank Details
                </h3>
                <p className="text-neutral-darker mt-1 text-sm">
                  You may use these data to conduct bank transactions
                </p>
              </div>
              <div className="px-6 py-4 pb-8 flex justify-center">
                <table className="text-sm">
                  <tbody>
                    <tr>
                      <td className="w-36">Beneficiary Name</td>
                      <td className="font-medium">INDICOSMIC PVT LTD</td>
                    </tr>
                    <tr>
                      <td className="w-36">Bank Name</td>
                      <td className="font-medium">ICICI Bank</td>
                    </tr>
                    <tr>
                      <td className="w-36">Branch Name</td>
                      <td className="font-medium">MIDC Andheri (E)</td>
                    </tr>
                    <tr>
                      <td className="w-36">Account Number</td>
                      <td className="font-medium">ICCP00011111</td>
                    </tr>
                    <tr>
                      <td className="w-36">IFSC Code</td>
                      <td className="font-medium">ICIC0000104</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={showUPIModal}
        onClose={() => setShowUPIModal(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/75" aria-hidden="true" />

        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <DialogPanel className="space-y-4 border bg-white border-neutral rounded">
            <div>
              {/* <form onSubmit={invoiceForm.handleSubmit}> */}
              {/* <div className="bg-neutral-lightest border-b border-neutral px-6 py-3 flex flex-col sm:flex-row">
                <span className="font-bold text-xl py-2 text-neutral-darkest text-center">
                  Instant UPI Payment
                </span>
              </div> */}
              <div className="bg-base-white px-6 py-4 border-b border-neutral-light rounded-t text-center">
                <h3 className="text-xl font-semibold">Instant UPI Payment</h3>
                <p className="text-neutral-darker mt-1 text-sm">
                  Use UPI Payments to quickly scan and deposit
                </p>
              </div>

              <div className="flex items-center justify-center mt-8 mb-4">
                <QRCode
                  size={350}
                  style={{ height: "auto", maxWidth: "60%", width: "60%" }}
                  className="md:max-w-[60%] max-w-[50%]"
                  value={upiLink}
                  viewBox={`0 0 256 256`}
                />
              </div>
              <p className="text-center mb-8">INDICOSMIC INFOTECH LTD</p>

              {/* </form> */}
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
}
