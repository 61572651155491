import React from "react";
import error from "../assets/img/error.svg";
import { Link, useLocation } from "react-router-dom";

export default function Error() {
  const location = useLocation();
  const message = location.state?.message;
  return (
    <>
      <div className="flex justify-center mt-12">
        <div className="flex flex-col justify-center items-center text-center">
          <img src={error} className="w-56" alt="something went wrong" />
          <p className="mt-8  text-lg">{message}</p>
          <Link to="/login">
            <p className="mt-2 underline">Back to Login</p>
          </Link>
        </div>
      </div>
    </>
  );
}
