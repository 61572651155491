import React, { useState, useEffect, useContext } from "react";
import coverImage from "../assets/img/coverImage.png";
import CurrencyFormat from "react-currency-format";
import Input from "../components/ui/Input";
import Button from "../components/ui/Button";
import toast, { Toaster } from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../context/global.context";
//import { Data } from "../pages/defaultData";
import { apiService } from "../api.service";
import FileSaver from "file-saver";
import {
  Description,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import _ from "lodash";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { tab } from "@testing-library/user-event/dist/tab";
import { service } from "../service";
import { useGlobalContext } from "../context/global.context";
export const TransactionStatement = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [showDateRangeModal, setShowDateRangeModal] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [sorting, setSorting] = React.useState([]);
  const [payload, setPayload] = useState({
    pagination: {
      pageNumber: 1,
      pageSize: 5,
    },
    search: "",
  });
  const [invoiceSummaryData, setInvoiceSummaryData] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordFound, setNoRecordFound] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [searchClicked, setSearchClicked] = useState(false);
  const columnHelper = createColumnHelper();
  const getButtonStyles = (status) => {
    const baseClasses =
      "w-fit px-4 flex items-center justify-center rounded-full font-semibold";
    switch (status) {
      case "pending":
        return `${baseClasses} bg-warn-lightest text-warn capitalize`;
      case "rejected":
        return `${baseClasses} bg-neutral-lightest text-danger capitalize`;
      case "approved":
        return `${baseClasses} bg-success-lightest text-success capitalize`;
      case "referback":
        return `${baseClasses} bg-neutral-lightest text-neutral-darkest capitalize`;
      default:
        return `${baseClasses} bg-warn-lightest text-warn.lightest capitalize`;
    }
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setMessage, setError } = useGlobalContext();

  const columnsTransactionStatement = [
    columnHelper.accessor("", {
      header: "#",
    }),

    columnHelper.accessor("month", {
      header: "Month",
    }),

    columnHelper.accessor("walletCredit", {
      header: "Wallet Credit",
      cell: (data) => {
        return (
          <span>
             
            {data.getValue() ? (
              <CurrencyFormat
                value={data.getValue()}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
              />
            ) : (
              "-"
            )}
          </span>
        );
      },
    }),
    columnHelper.accessor("withdrawal", {
      header: "Withdrawal",
    }),
    columnHelper.accessor("policyCreated", {
      header: "Policy Created",
      cell: (data) => {
        return (
          <span>
             
            {data.getValue() ? (
              <CurrencyFormat
                value={data.getValue()}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
              />
            ) : (
              "-"
            )}
          </span>
        );
      },
    }),
    columnHelper.accessor("commission", {
      header: "Commission",
      cell: (data) => {
        return (
          <span>
             
            {data.getValue() ? (
              <CurrencyFormat
                value={data.getValue()}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
              />
            ) : (
              "-"
            )}
          </span>
        );
      },
    }),
    columnHelper.accessor("policyCancelled", {
      header: "Policy Cancelled",
    }),
    columnHelper.accessor("commissionDiducted", {
      header: "Commission Deducted",
    }),
    columnHelper.accessor("gstCredit", {
      header: "GST",
      cell: (data) => {
        return (
          <span>
             
            {data.getValue() ? (
              <CurrencyFormat
                value={data.getValue()}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
              />
            ) : (
              "-"
            )}
          </span>
        );
      },
    }),
    columnHelper.accessor("tdsDeducted", {
      header: "TDS Deducted",
      cell: (data) => {
        return (
          <span>
             
            {data.getValue() ? (
              <CurrencyFormat
                value={data.getValue()}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
              />
            ) : (
              "-"
            )}
          </span>
        );
      },
    }),
    columnHelper.accessor("openingBalance", {
      header: "Opening Balance",
      cell: (data) => {
        return (
          <span>
             
            {data.getValue() ? (
              <CurrencyFormat
                value={data.getValue()}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
              />
            ) : (
              "-"
            )}
          </span>
        );
      },
    }),
    columnHelper.accessor("closingBalance", {
      header: "Closing Balance",
      cell: (data) => {
        return (
          <span>
             
            {data.getValue() ? (
              <CurrencyFormat
                value={data.getValue()}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
              />
            ) : (
              "-"
            )}
          </span>
        );
      },
    }),
  ];

  const table = useReactTable({
    columns: columnsTransactionStatement,
    data: filteredData.length > 0 ? filteredData : data,
    //onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    //debugTable: true,
    manualPagination: true,
  });

  console.log(invoiceSummaryData);

  const totalPages = Math.ceil(totalCount / payload.pagination.pageSize);
  const isLastPage = payload.pagination.pageNumber === totalPages;
  const isFirstPage = payload.pagination.pageNumber === 1;

  const handleNextPage = () => {
    setPayload((prev) => {
      const nextPage = prev.pagination.pageNumber + 1;
      return {
        ...prev,
        pagination: {
          ...prev.pagination,
          pageNumber: nextPage > totalPages ? totalPages : nextPage,
        },
      };
    });
  };

  const handleLastPage = () => {
    setPayload((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        pageNumber: totalPages,
      },
    }));
  };
  const handlePreviousPage = () => {
    setPayload((prev) => {
      const prevPage = prev.pagination.pageNumber - 1;
      return {
        ...prev,
        pagination: {
          ...prev.pagination,
          pageNumber: prevPage < 1 ? 1 : prevPage,
        },
      };
    });
  };

  const handleFirstPage = () => {
    setPayload((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        pageNumber: 1,
      },
    }));
  };

  const downloadExcel = async () => {
    const loadingToast = toast.loading("Downloading Excel file...");
    setErrorMessage("");
    try {
      const payloadData = {
        ...payload,
        download: true,
      };

      const response = await apiService.transactionReport(payloadData);
      const byteCharacters = atob(response.data.excelFile);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Use FileSaver to save the file
      FileSaver.saveAs(blob, "myfile.xlsx");
      toast.success("Excel file downloaded successfully");
    } catch (error) {
      console.error("Error downloading the file", error);
      setErrorMessage(
        "Failed to download Excel file. Please check your network and try again."
      );
      // toast.error("Error downloading the file");
      service.notifyApiError(error, setMessage, setError);
    } finally {
      toast.dismiss(loadingToast);
    }
  };

  // const handleTransactionReports = async () => {
  //   //const loadingToast = toast.loading("Loading data...");
  //   setLoading(true)
  //   try {

  //   await apiService
  //     .transactionReport(payload)
  //     .then((response) => {
  //       if (response?.data?.data.length === 0){
  //         setNoRecordFound(true);
  //       }
  //       setData(response.data.data);
  //       setTotalCount(response.data.totalCount);
  //       setNoRecordFound(data.length === 0);
  //     }).catch((error) => {
  //       console.log(error)
  //       service.notifyApiError(error, setMessage, setError);
  //     })
  //     .finally(() => {
  //      // toast.dismiss(loadingToast);
  //       setLoading(false)
  //     });

  //   } catch (error) {
  //     console.log(error)

  //   }
  // };

  const handleTransactionReports = async () => {
    setLoading(true);
    try {
      const response = await apiService.transactionReport(payload);
      if (response?.data?.data.length === 0) {
        setNoRecordFound(true);
      }
      setData(response.data.data);
      setTotalCount(response.data.totalCount);
      setNoRecordFound(data.length === 0);
    } catch (error) {
      console.log(error);
      service.notifyApiError(error, setMessage, setError); // Pass the context function here
    } finally {
      setLoading(false);
    }
  };

  const getUploadGSTFile = async () => {
    const loadingToast = toast.loading("Loading data...");
    try {
      const response = await apiService
        .uploadGSTFile(payload)
        .then((response) => {});
    } catch (error) {
      console.error("Error generated:", error);
      service.notifyApiError(error, setMessage, setError);
    }
  };

  useEffect(() => {
    console.log("Payload updated:");
    handleTransactionReports();
  }, [payload]);

  useEffect(() => {
    if (data?.length == 0) {
      setNoRecordFound(true);
    } else {
      setNoRecordFound(false);
    }
  }, [data]);

  const dateFilterForm = useFormik({
    initialValues: {
      fromDate: "",
      toDate: "",
    },
    validationSchema: Yup.object().shape({
      fromDate: Yup.date().required("Required"),
      toDate: Yup.date().required("Required"),
    }),
    onSubmit: async (values) => {
      console.log(values);
      setPayload((prev) => ({
        ...prev,
        dates: {
          fromDate: values.fromDate,
          toDate: values.toDate,
        },
      }));
      console.log(payload);
      setShowDateRangeModal(false);
    },
  });

  return (
    <>
      <Dialog
        open={showDateRangeModal}
        onClose={() => setShowDateRangeModal(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/75" aria-hidden="true" />

        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <DialogPanel className="space-y-4 border bg-white border-neutral rounded">
            <form onSubmit={dateFilterForm.handleSubmit}>
              <div className="bg-neutral-lightest border-b border-neutral px-4 py-3 flex">
                <span className="font-bold text-lg md:text-xl py-2 text-neutral-darkest">
                  Custom Date
                </span>
              </div>
              <div className="flex flex-col md:flex-row md:gap-x-4 px-4 md:px-8 pt-4">
                <div className="flex flex-col mb-4 md:mb-0">
                  <Input
                    {...dateFilterForm.getFieldProps("fromDate")}
                    formik={dateFilterForm}
                    id="fromDate"
                    name="fromDate"
                    type="month"
                    max={dateFilterForm.getFieldProps("toDate").value}
                    label="From Date"
                    className="w-full"
                  />
                </div>
                <div className="flex flex-col">
                  <Input
                    {...dateFilterForm.getFieldProps("toDate")}
                    formik={dateFilterForm}
                    id="toDate"
                    name="toDate"
                    type="month"
                    min={dateFilterForm.getFieldProps("fromDate").value}
                    label="To Date"
                    className="w-full"
                  />
                </div>
              </div>
              <div className="flex items-center justify-center gap-4 mt-4 px-4 pb-8">
                <Button
                  type="button"
                  label="Cancel"
                  variant="ghost"
                  className="w-full md:w-auto"
                  onClick={() => setShowDateRangeModal(false)}
                />
                <Button
                  type="submit"
                  label="Submit"
                  variant="primary"
                  className="w-full md:w-auto"
                />
              </div>
            </form>
          </DialogPanel>
        </div>
      </Dialog>

      <div className="flex flex-col w-full items-center overflow-hidden">
        <div className="sticky -z-10 w-full">
          <img
            src={coverImage}
            className=" w-full h-36 object-cover"
            alt="cover_image"
          />
        </div>
        <div className="w-11/12 bg-white -mt-28 md:-mt-20 border border-neutral-light rounded mx-2">
          <form>
            <div>
              <div className="bg-base-white px-6 py-4 border-b border-neutral-light rounded-t">
                <h3 className="text-xl font-semibold">
                  Dealer Transaction Statement{" "}
                </h3>
                <p className="text-neutral-darker mt-1 text-sm">
                  Overview of transaction requests, including their statuses and
                  approval dates.
                </p>
              </div>
              <div className="px-6 py-4 pb-8">
                <div>
                  <Tabs
                    selectedIndex={selectedTabIndex}
                    selectedTabClassName="text-primary border-b-2 border-primary font-medium"
                  >
                    <TabList className="flex mb-4 text-neutral-dark text-sm items-center justify-between">
                      <div className="flex gap-1"></div>

                      <div className="flex justify-between items-center w-full">
                        <div className="md:flex md:flex-row flex-col items-center gap-x-4 justify-between w-full">
                          <div className="flex space-x-4"> </div>
                          <div className="flex items-center  gap-5">
                            <button
                              type="button"
                              className="mt-1"
                              onClick={() => {
                                setShowDateRangeModal(true);
                              }}
                            >
                              <span class="material-symbols-outlined text-neutral-darkest">
                                date_range
                              </span>
                            </button>
                            {
                              <button
                                type="button"
                                className="mt-1"
                                onClick={downloadExcel}
                              >
                                <span class="material-symbols-outlined text-neutral-darkest">
                                  download
                                </span>
                              </button>
                            }
                          </div>
                        </div>
                      </div>
                    </TabList>

                    <TabPanel>
                      <div className="flex mb-4 overflow-x-scroll cursor-pointer">
                        <table className="text-sm border border-neutral w-full overflow-x-scroll">
                          <thead className="bg-primary text-white text-xs">
                            {table.getHeaderGroups()?.map((headerGroup) => (
                              <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                  return (
                                    <th
                                      key={header.id}
                                      colSpan={header.colSpan}
                                      className="px-2 py-1 text-left"
                                    >
                                      {header.isPlaceholder ? null : (
                                        <div
                                          className={
                                            header.column.getCanSort()
                                              ? "cursor-pointer select-none"
                                              : ""
                                          }
                                          onClick={header.column.getToggleSortingHandler()}
                                          title={
                                            header.column.getCanSort()
                                              ? header.column.getNextSortingOrder() ===
                                                "asc"
                                                ? "Sort ascending"
                                                : header.column.getNextSortingOrder() ===
                                                  "desc"
                                                ? "Sort descending"
                                                : "Clear sort"
                                              : undefined
                                          }
                                        >
                                          {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                          )}
                                          {{
                                            asc: " 🔼",
                                            desc: " 🔽",
                                          }[header.column.getIsSorted()] ??
                                            null}
                                        </div>
                                      )}
                                    </th>
                                  );
                                })}
                              </tr>
                            ))}
                          </thead>

                          <tbody>
                            {loading ? (
                              <tr className="min-h-20">
                                <td
                                  colSpan={columnsTransactionStatement.length}
                                  className="text-center p-4 min-h-20"
                                >
                                  Loading...
                                </td>
                              </tr>
                            ) : noRecordFound ? (
                              <tr className="min-h-20">
                                <td
                                  colSpan={columnsTransactionStatement.length}
                                  className="text-center p-4 min-h-20"
                                >
                                  No records found
                                </td>
                              </tr>
                            ) : (
                              table.getRowModel().rows.map((row) => (
                                <tr
                                  key={row.id}
                                  className="text-xs even:bg-primary-lightest"
                                >
                                  {row.getVisibleCells().map((cell) => (
                                    <td key={cell.id} className="px-2 py-2">
                                      {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                      )}
                                    </td>
                                  ))}
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>

                      <div className="flex flex-col md:flex-row items-center w-full justify-end text-xs gap-4">
                        <div className="flex items-center gap-2">
                          {" "}
                          <select
                            value={payload.pageSize}
                            onChange={(e) => {
                              const size = Number(e.target.value);
                              setPayload((prev) => ({
                                ...prev,
                                pagination: {
                                  pageSize: size,
                                  pageNumber: 1,
                                },
                              }));
                            }}
                          >
                            {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map(
                              (pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                  {pageSize}
                                </option>
                              )
                            )}
                          </select>
                          <span className="font-semibold">
                            <span>Showing </span>
                            {1 +
                              (payload.pagination.pageNumber - 1) *
                                payload.pagination.pageSize}{" "}
                            to{" "}
                            {payload.pagination.pageNumber *
                              payload.pagination.pageSize -
                              payload.pagination.pageSize +
                              data?.length}{" "}
                            of {totalCount} Entries
                          </span>
                        </div>

                        <div className="flex items-center space-x-2 mt-2 md:mt-0">
                          <button
                            type="button"
                            disabled={isFirstPage}
                            onClick={handleFirstPage}
                            className={`material-symbols-outlined ${
                              isFirstPage
                                ? "cursor-not-allowed text-neutral-light"
                                : "cursor-pointer"
                            }`}
                          >
                            first_page
                          </button>
                          <button
                            type="button"
                            onClick={handlePreviousPage}
                            disabled={isFirstPage}
                            className={`material-symbols-outlined ${
                              isFirstPage
                                ? "cursor-not-allowed text-neutral-light"
                                : "cursor-pointer"
                            }`}
                          >
                            chevron_left
                          </button>
                          <button
                            type="button"
                            onClick={handleNextPage}
                            disabled={isLastPage}
                            className={`material-symbols-outlined ${
                              isLastPage
                                ? "cursor-not-allowed text-neutral-light"
                                : "cursor-pointer"
                            }`}
                          >
                            chevron_right
                          </button>
                          <button
                            type="button"
                            className={`material-symbols-outlined ${
                              isLastPage
                                ? "cursor-not-allowed text-neutral-light"
                                : "cursor-pointer"
                            }`}
                            onClick={handleLastPage}
                            disabled={isLastPage}
                          >
                            last_page
                          </button>
                        </div>
                      </div>
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
