import React, { useState, useEffect } from "react";
import coverImage from "../assets/img/coverImage.png";
import Input from "../components/ui/Input";
import Button from "../components/ui/Button";
import toast, { Toaster } from "react-hot-toast";
import { service } from "../service";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "../components/ui/Select";
import File from "../components/ui/File";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ReactHTMLTableToExcel from "react-html-table-to-excel"; // Import ReactHTMLTableToExcel
//import { Data } from "../pages/defaultData";
import { useNavigate, useParams } from "react-router-dom";
import { apiService } from "../api.service";
import loadingBarService from "../service/loadingbar.Service";
import FileSaver from "file-saver";
import { saveAs } from "file-saver";
import Tippy from "@tippyjs/react";
import {
  Description,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import moment from "moment";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { tab } from "@testing-library/user-event/dist/tab";
import { useGlobalContext } from "../context/global.context";

export const PolicyCancellationRequest = () => {
  const {setMessage, setError} = useGlobalContext()

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [disableAboutToExpireSelect, setDisableAboutToExpireSelect] =
      useState(true);
    const [filteredData, setFilteredData] = useState([]);
    const [activeTabName, setActiveTabName] = useState("");
    const [sorting, setSorting] = React.useState([]);
    const [payload, setPayload] = useState({
      pagination: {
        pageNumber: 1,
        pageSize: 5,
      },
    //   cancellationStatusId: 3, 
    //   statusId: 3,
    //   dates: {
    //     "fromDate": "2024-05-20",
    //     "toDate": "2026-05-19"
    //      }
  });
  const [policyNumberToCancel, setPolicyNumberToCancel] = useState();
  const [policyCancellationStatusOptions, setPolicyCancellationStatusOptions] =
    useState([]);
  const [showDateRangeModal, setShowDateRangeModal] = useState(false);
  const [filterModal, filterdataModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [noRecordFound, setNoRecordFound] = useState(false);
  const [showCancelPolicyModal, setShowCancelPolicyModal] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  const columnHelper = createColumnHelper();
  const getButtonStyles = (status) => {
    const baseClasses =
      "w-fit px-4 flex items-center justify-center rounded-full font-semibold capitalize";

    switch (status.toLowerCase()) {
      case "pending":
        return `${baseClasses} bg-warn-lightest text-warn`;
      case "rejected":
        return `${baseClasses} bg-neutral-lightest text-danger`;
      case "approved":
        return `${baseClasses} bg-success-lightest text-success`;
      case "referback":
        return `${baseClasses} bg-neutral-lightest text-neutral-darkest`;
      default:
        return `${baseClasses} bg-warn-lightest text-warn`;
    }
  };
  const dateFilterForm = useFormik({
    initialValues: {
      fromDate: "",
      toDate: "",
    },
    validationSchema: Yup.object().shape({
      fromDate: Yup.date().required("Required"),
      toDate: Yup.date().required("Required"),
    }),
    onSubmit: async (values) => {
      console.log(values);
      setPayload((prev) => ({
        ...prev,
        dates: {
          fromDate: values.fromDate,
          toDate: values.toDate,
        },
      }));
      console.log(payload);
      setShowDateRangeModal(false);
      dateFilterForm.resetForm();
    },
  });

  const cancelPolicyForm = useFormik({
    initialValues: {
      reason: "",
      type: "",
      file: "",
    },
    // validationSchema: Yup.object().shape({
    //   reason: Yup.string().required("Required"),
    //   type: Yup.string().required(),
    //   file: Yup.mixed().required("Required"),
    // }),
    onSubmit: async (values) => {
      console.log(values);
      const payload = {
        policyNumber: policyNumberToCancel,
        file: values.file,
        reason: values.reason,
        type: values.type,
      };
      const loadingToast = toast.loading("Loading Policy cancellation data ");
      await apiService
        .requestPolicyCancellation(payload)
        .then((res) => {
          console.log(res);
          toast.success(res.data.message);
          setShowCancelPolicyModal(false);
        }).catch((error)=>{
          service.notifyApiError(error, setMessage, setError);
        }).finally(()=>{
          toast.dismiss(loadingToast)
        })
    },
  });

  const columns = [
    columnHelper.accessor("srNo", {
      accessorKey: "srNo",
      header: "#",
    }),
    columnHelper.accessor("policyNumber", {
      header: "Policy No",
    }),
    columnHelper.accessor("customerName", {
      header: "Customer Name",
    }),
    columnHelper.accessor("icName", {
      header: "IC Name",
    }),
    //   columnHelper.accessor("ModelNo", {
    //     header: "Model No",
    //   }),
    columnHelper.accessor("engineNumber", {
      header: "Engine No",
    }),
    columnHelper.accessor("chassisNumber", {
      header: "Chassis No",
    }),
    columnHelper.accessor("policyStartDate", {
      header: "Policy Start Date",
    }),
    columnHelper.accessor("policyEndDate", {
      header: "Policy End Date",
    }),
    //   columnHelper.accessor("TransactionAmount", {
    //     header: "Transaction Amount",
    //     cell: data => <span>₹ {data.getValue()}</span>,
    //   }),
    //   columnHelper.accessor("TransactionType", {
    //     header: "Transaction Type",
    //   }),
    //   columnHelper.accessor("TransactionPurpose", {
    //     header: "Transaction Purpose",
    //   }),
    //   columnHelper.accessor("EmployeeCode", {
    //     header: "Employee Code",
    //   }),
    //   columnHelper.accessor("EmployeeName", {
    //     header: "Employee Name",
    //   }),
    columnHelper.accessor("createdAt", {
      header: "Created Date",
    }),
    columnHelper.accessor("cancellationStatus", {
      header: "Status",
      cell: (data) => {
        const status = data.getValue();
        return <span className={getButtonStyles(status)}>{status}</span>;
      },
    }),
    columnHelper.accessor("", {
      header: "Action",
      cell: (data) => (
        <div className="text-center">
          <Tippy
            content="Download Policy"
            placement="right"
            arrow={false}
            className="rounded-sm text-xs"
            delay={1000}
          >
            <button
              type="button"
              className="mt-1 mr-0"
              onClick={() =>
                downloadPolicyPDF(data.row.getValue("policyNumber"))
              }
            >
              <span class="material-symbols-outlined text-neutral-darkest text-sm">
                download
              </span>
            </button>
          </Tippy>
          {/* <Tippy
              content="Cancel Policy"
              placement="right"
              arrow={false}
              className="rounded-sm text-xs"
              delay={1000}
            >
              <button
                type="button"
                onClick={() => {
                  setShowCancelPolicyModal(true);
                  setPolicyNumberToCancel(data.row.getValue("policyNumber"));
                }}
              >
                <span className="material-symbols-outlined mr-3 md:mr-1 text-sm  font-bold text-danger">
                  cancel
                </span>
              </button>
              
            </Tippy> */}
        </div>
      ),
    }),
  ];

  const table = useReactTable({
    data: filteredData.length > 0 ? filteredData : data,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    //debugTable: true,
    manualPagination: true,
  });

  const requestPolicyCancellation = async () => {};

  // const handleNextPage = () => {
  //   setPayload((prev) => ({
  //     ...prev,
  //     pageNumber: prev.pageNumber + 1,
  //   }));
  // };

  // const handlePreviousPage = () => {
  //   setPayload((prev) => ({
  //     ...prev,
  //     pageNumber: prev.pageNumber - 1 > 0 ? prev.pageNumber - 1 : 1,
  //   }));
  // };

  const totalPages = Math.ceil(totalCount / payload.pagination.pageSize);
  const isLastPage = payload.pagination.pageNumber === totalPages;
  const isFirstPage = payload.pagination.pageNumber === 1;

  const handleNextPage = () => {
    setPayload((prev) => {
      const nextPage = prev.pagination.pageNumber + 1;
      return {
        ...prev,
        pagination: {
          ...prev.pagination,
          pageNumber: nextPage > totalPages ? totalPages : nextPage,
        },
      };
    });
  };

  const handleLastPage = () => {
    setPayload((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        pageNumber: totalPages,
      },
    }));
  };
  const handlePreviousPage = () => {
    setPayload((prev) => {
      const prevPage = prev.pagination.pageNumber - 1;
      return {
        ...prev,
        pagination: {
          ...prev.pagination,
          pageNumber: prevPage < 1 ? 1 : prevPage,
        },
      };
    });
  };

  const handleFirstPage = () => {
    setPayload((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        pageNumber: 1,
      },
    }));
  };

  const downloadExcel = async () => {
    const loadingToast = toast.loading("Downloading Excel file...");
    try {
      const payloadData = {
        ...payload,
        download: true,
      };

      const response = await apiService.policyCancellationRequests(payloadData);
      console.log(response.data);
      // return
      const byteCharacters = atob(response.data.excelFile);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Use FileSaver to save the file
      FileSaver.saveAs(blob, "myfile.xlsx");

      toast.success("Excel file downloaded successfully");
    } catch (error) {
      console.error("Error downloading the file", error);
      // toast.error("Error downloading the file");
      service.notifyApiError(error, setMessage, setError);
    } finally {
      toast.dismiss(loadingToast);
    }
  };
  // const downloadPolicyPDF = async (policyNumber) => {
  //   try {
  //     const toastId = toast.loading('Downloading...');
  //     await apiService.downloadPolicyPdf(policyNumber)
  //       .then((res) => {

  //       })
  //       .catch((error) => {
  //         toast.dismiss(toastId);
  //         toast.error(error.response?.data?.message || "Error occurred");
  //       })
  //       .finally(() => {
  //         toast.dismiss(toastId);
  //       });
  //   } catch (error) {
  //     toast.error("Error downloading the PDF");
  //   } finally {
  //     loadingBarService.complete(); // Complete the loading bar
  //   }
  // };
  const downloadPolicyPDF = async (policyNumber) => {
    try {
      const toastId = toast.loading("Downloading...");
      await apiService
        .downloadPolicyPdf(policyNumber)
        .then((res) => {
          const blob = new Blob([res.data], { type: "application/pdf" });
          const disposition = res.headers;
          console.log(disposition);
          const disposition1 = res.headers["content-disposition"];
          console.log(disposition1);

          let fileName = "default.pdf"; // fallback filename

          // Extract the filename from the Content-Disposition header
          if (disposition) {
            const matches = /filename="([^"]+)"/.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1];
            }
          }
          console.log(disposition);

          // const fileName = disposition ? disposition.split('filename=')[1].replace(/"/g, '') : 'policy.pdf';
          saveAs(blob, fileName);
          toast.dismiss(toastId);
        })
        .catch((error) => {
          toast.dismiss(toastId);
          toast.error(error.response.data.message);
        })
        .finally(() => {
          toast.dismiss(toastId);
        });
    } catch (error) {
      toast.error("Error downloading the PDF");
      service.notifyApiError(error, setMessage, setError);
    } finally {
      loadingBarService.complete(); // Complete the loading bar
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevents the default behavior for the Enter key
      onSearch();
    }
  };

  const onSearch = () => {
    setPayload((prev) => ({
      ...prev,
      search: {
        field: document.getElementById("searchField").value,
        value: document.getElementById("search").value,
      },
    }));
  };
  const fetchPolicyCancellationRequestData = async () => {
    //const loadingToast = toast.loading("Loading Cancelled Policy data...");
    setLoading(true);
    try {
      // payload is changed on each scenario and it will call fetchPolicyData
      const response = await apiService.policyCancellationRequests(payload);
      console.log(response.data.data);
      if (response?.data?.data.length === 0) {
        setNoRecordFound(true);
      }
      setData(response.data.data);
      setTotalCount(response.data.totalCount);
      setNoRecordFound(data.length === 0);
      console.log(data);
    } catch (error) {
      console.error("Error fetching policy data:", error);
      toast.error("Error fetching policy data");
      service.notifyApiError(error, setMessage, setError);
    } finally {
      //toast.dismiss(loadingToast);
      setLoading(false);
    }
  };
  const getPolicyCancellationStatusOptions = async () => {
    const loadingToast = toast.loading("Loading data");
    try {
      await apiService.policyCancellationStatusOptions().then((res) => {
        setPolicyCancellationStatusOptions(res.data.data);
      });
    } catch (error) {
      console.error("Error fetching genders:", error);
    } finally {
      toast.dismiss(loadingToast);
    }
  };

  const resetState = () => {
    setSelectedTabIndex(0);
    setData([]);
    setTotalCount(0);
    setDisableAboutToExpireSelect(true);
    setFilteredData([]);
    setActiveTabName("");
    setSorting([]);
    setPayload({
      pagination: {
        pageNumber: 1,
        pageSize: 5,
      },
      cancellationStatusId: null,
    });
    setPolicyNumberToCancel(null);
    setShowDateRangeModal(false);
    filterdataModal(false);
    setLoading(true);
    setNoRecordFound(false);
    setShowCancelPolicyModal(false);
    cancelPolicyForm.resetForm();
    dateFilterForm.resetForm();
  };
  useEffect(() => {
    fetchPolicyCancellationRequestData();
  }, [payload]);
  useEffect(() => {
    getPolicyCancellationStatusOptions();
  }, []);

  useEffect(() => {
    if (data.length == 0) {
      setNoRecordFound(true);
    } else {
      setNoRecordFound(false);
    }
  }, [data]);

  const reasonOptions = [
    { id: 1, label: "Wrong Data" },
    { id: 2, label: "Other" },
  ];

  return (
    <>
      <Dialog
        open={showCancelPolicyModal}
        onClose={() => setShowCancelPolicyModal(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/75" aria-hidden="true" />

        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <DialogPanel className="max-w-xl w-full space-y-4 border bg-white border-neutral rounded">
            <div className="bg-neutral-lightest border-b border-neutral px-6 py-3 flex justify-center text-left">
              <span className="font-bold text-xl text-left w-full">
                Policy Cancellation Request
              </span>
            </div>

            <div className="px-2 pb-6">
              <div className="text-sm text-center px-8">
                <span>
                  Would you like to proceed with the cancellation of policy
                  number <strong>{policyNumberToCancel}</strong>?
                </span>
              </div>
              <form onSubmit={cancelPolicyForm.handleSubmit}>
                <div className="flex flex-col md:flex-row flex-wrap justify-between mt-4 px-8">
                  <div className="flex flex-col items-start justify-start w-full md:w-[45%]">
                    <Input
                      {...cancelPolicyForm.getFieldProps("reason")}
                      formik={cancelPolicyForm}
                      id="reason"
                      name="reason"
                      type="text"
                      label="Reason"
                      placeholder="Enter reason"
                      className="w-full"
                    />
                  </div>
                  <div className="flex flex-col w-full  md:w-[45%]">
                    <Select
                      {...cancelPolicyForm.getFieldProps("type")}
                      formik={cancelPolicyForm}
                      value={cancelPolicyForm.values.type}
                      id="type"
                      name="type"
                      label="Type"
                      onSelect={(value) =>
                        cancelPolicyForm.setFieldValue("type", value)
                      }
                      options={reasonOptions}
                      className="w-full"
                    />
                  </div>
                  <div className="mt-4 flex flex-col w-full  md:w-[45%]">
                    <File
                      {...cancelPolicyForm.getFieldProps("file")}
                      formik={cancelPolicyForm}
                      id="file"
                      name="file"
                      label="Upload Pdf"
                      className="w-full"
                      icon="picture_as_pdf"
                      accept=".pdf"
                      onFileChange={(name, file) => {
                        cancelPolicyForm.setFieldValue("file", file);
                      }}
                    />
                  </div>
                </div>

                <div className="flex items-center justify-end gap-4 mt-4 px-12">
                  <Button
                    type="button"
                    label="No, Go Back"
                    variant="ghost"
                    onClick={() => setShowCancelPolicyModal(false)}
                    className="w-fit"
                  />
                  <Button
                    type="submit"
                    label="Yes, Cancel Policy"
                    variant="primary"
                    className="text-sm md:text-base w-fit"
                  />
                </div>
              </form>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
      <Dialog
        open={showDateRangeModal}
        onClose={() => setShowDateRangeModal(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/75" aria-hidden="true" />

        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <DialogPanel className="space-y-4 border bg-white border-neutral rounded overflow-y-scroll">
            <form onSubmit={dateFilterForm.handleSubmit}>
              <div className="bg-neutral-lightest border-b border-neutral px-4 py-3 flex justify-between items-center">
                <span className="font-bold text-xl text-neutral-darkest">
                  Custom Date
                </span>
              </div>
              <div className="flex flex-col md:flex-row gap-4 px-4 pt-4">
                <div className="flex flex-col w-full md:w-1/2">
                  <Input
                    {...dateFilterForm.getFieldProps("fromDate")}
                    formik={dateFilterForm}
                    id="fromDate"
                    name="fromDate"
                    type="date"
                    max={
                      dateFilterForm.getFieldProps("toDate").value ||
                      moment().format("YYYY-MM-DD")
                    }
                    label="From Date"
                    className="w-full"
                  />
                </div>
                <div className="flex flex-col w-full md:w-1/2">
                  <Input
                    {...dateFilterForm.getFieldProps("toDate")}
                    formik={dateFilterForm}
                    id="toDate"
                    name="toDate"
                    type="date"
                    min={dateFilterForm.getFieldProps("fromDate").value}
                    label="To Date"
                    className="w-full"
                  />
                </div>
              </div>

              <div className="flex items-center justify-center gap-4 mt-4 px-4 pb-8">
                <Button
                  type="button"
                  label="Cancel"
                  variant="ghost"
                  className="w-full md:w-auto"
                  onClick={() => setShowDateRangeModal(false)}
                />
                <Button
                  type="submit"
                  label="Submit"
                  variant="primary"
                  className="w-full md:w-auto"
                />
              </div>
            </form>
          </DialogPanel>
        </div>
      </Dialog>

      <Dialog
        open={filterModal}
        onClose={() => filterdataModal(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/75" aria-hidden="true" />

        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <DialogPanel className=" space-y-4 border bg-white border-neutral rounded">
            <form onSubmit={dateFilterForm.handleSubmit}>
              <div className="bg-neutral-lightest  border-b border-neutral px-6 py-3 flex">
                <span className="font-bold text-xl py-2 text-neutral-darkest ">
                  Filter
                </span>
              </div>
              <div className="flex flex-col gap-y-4 p-4">
                <select id="searchField" className="mr-2 text-neutral-darkest">
                  <option value="sold_policy_no">Policy No</option>
                  <option value="engine_no">Engine No</option>
                  <option value="chassis_no">Chassis No</option>
                </select>

                <div className="relative flex items-center w-full md:w-auto">
                  <input
                    type="text"
                    id="search"
                    placeholder="Search..."
                    className="px-3 py-1 border border-neutral-dark rounded-l-sm focus:outline-primary text-base-black w-full md:w-auto"
                  />
                  <button
                    type="button"
                    onClick={() => {
                      setPayload((prev) => ({
                        ...prev,
                        search: {
                          field: document.getElementById("searchField").value,
                          value: document.getElementById("search").value,
                        },
                      }));
                    }}
                    className="bg-primary text-white border border-primary px-2 py-1  rounded-r-sm"
                  >
                    <span className="material-symbols-outlined flex items-center justify-center p-0">
                      search
                    </span>
                  </button>
                </div>
              </div>

              <div className="flex items-center justify-center gap-4 mt-4 px-12 pb-8">
                <Button
                  type="button"
                  label="Cancel"
                  variant="ghost"
                  className="max-w-12"
                  onClick={() => filterdataModal(false)}
                />
                <Button
                  type="submit"
                  label="Submit"
                  variant="primary"
                  className="max-w-16"
                />
              </div>
            </form>
          </DialogPanel>
        </div>
      </Dialog>
      <div className="flex flex-col w-full items-center overflow-hidden">
        <div className="sticky -z-10 w-full">
          <img
            src={coverImage}
            className=" w-full h-36 object-cover"
            alt="cover_image"
          />
        </div>
        <div className="w-11/12 bg-white -mt-28 md:-mt-20 border border-neutral-light rounded mx-2">
          <form>
            <div>
              <div className="bg-base-white px-6 py-4 border-b border-neutral-light rounded-t">
                <h3 className="text-xl font-semibold">
                  Request for Cancelled Policy {loading}
                </h3>
                <p className="text-neutral-darker mt-1 text-sm">
                  A brief snapshot of your financial activity, showing incoming
                  and outgoing transactions
                </p>
              </div>
              <div className="px-6 py-4 pb-8">
                <div>
                  <Tabs
                    selectedIndex={selectedTabIndex}
                    selectedTabClassName="text-primary border-b-2 border-primary font-medium"
                  >
                    <TabList className="flex mb-4 text-neutral-dark text-sm items-center justify-between">
                      <div className="flex">
                        {/* <Tab className="uppercase px-4 pb-2 active:text-secondary-darkest cursor-pointer focus:outline-none">
                            Monthly Bank Statement
                          </Tab> */}
                        {/* <Tab className="uppercase px-4 pb-2 cursor-pointer focus:outline-none">
                            Commission Bank
                          </Tab> */}
                      </div>
                      <div className="md:flex flex-col md:flex-row justify-between items-center w-full">
                        <div className="flex items-center md:gap-x-4 gap-0 mb-2 md:mb-0  w-full md:w-1/3 ">
                          <div className="flex">
                            {/* <input
                                type="checkbox"
                                onChange={(e) => {
                                  setPayload((prev) => ({
                                    ...prev,
                                    dates: {
                                      toDate: e.target.checked
                                        ? moment().format("YYYY-MM-DD")
                                        : undefined,
                                    },
                                  }));
                                }}
                              /> */}
                            {/* <span className="mr-4 ml-1 text-neutral-darkest">
                                Show Expired
                              </span> */}
                          </div>
                          <div className="flex flex-col">
                            {/* <input
                                type="checkbox"
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setDisableAboutToExpireSelect(false);
                                    setPayload((prev) => ({
                                      ...prev,
                                      fromDate: moment().format("YYYY-MM-DD"),
                                      toDate: moment()
                                        .add(30, "days")
                                        .format("YYYY-MM-DD"),
                                    }));
                                  } else {
                                    setDisableAboutToExpireSelect(true);
                                    setPayload((prev) => ({
                                      ...prev,
                                      fromDate: undefined,
                                      toDate: undefined,
                                    }));
                                  }
                                }}
                              /> */}
                            {/* <select
                                disabled={disableAboutToExpireSelect}
                                className="mr-2 text-neutral-darkest"
                                onChange={(e) => {
                                  const duration = Number(e.target.value);
                                  setPayload((prev) => ({
                                    ...prev,
                                    fromDate: moment().format("YYYY-MM-DD"),
                                    toDate: moment()
                                      .add(duration, "days")
                                      .format("YYYY-MM-DD"),
                                  }));
                                  console.log(payload);
                                }}
                              >
                                {[30, 45, 60].map((day) => (
                                  <option key={day} value={day}>
                                    
                                    Expiry in {day} days
                                  </option>
                                ))}
                              </select> */}
                            <label className="text-xs">
                              Cancellation Status
                            </label>
                            <select
                              value={
                                payload.cancellationStatusId ||
                                "cancellationStatusId"
                              }
                              onChange={(e) => {
                                const typeId = Number(e.target.value);
                                setPayload((prev) => ({
                                  ...prev,
                                  cancellationStatusId: typeId,
                                }));
                                console.log(payload);
                              }}
                            >
                              <option value="cancellationStatusId">
                                Select Status
                              </option>
                              {policyCancellationStatusOptions.map((option) => (
                                <option key={option.value} value={option.id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="flex  items-center md:gap-x-4 gap-2  w-full md:justify-end md:w-1/2">
                          <button
                            type="button"
                            className="mt-1"
                            onClick={() => {
                              resetState();
                            }}
                          >
                            <span class="material-symbols-outlined text-neutral-darkest">
                              refresh
                            </span>
                          </button>
                          <button
                            type="button"
                            className="mt-1"
                            onClick={() => {
                              setShowDateRangeModal(true);
                            }}
                          >
                            <span class="material-symbols-outlined text-neutral-darkest">
                              date_range
                            </span>
                          </button>
                          {
                            <button
                              type="button"
                              className="mt-1"
                              onClick={downloadExcel}
                            >
                              <span class="material-symbols-outlined text-neutral-darkest">
                                download
                              </span>
                            </button>
                          }
                          <button
                            type="button"
                            className="mt-1 md:hidden"
                            onClick={() => {
                              filterdataModal(true);
                            }}
                          >
                            <span className="material-symbols-outlined text-neutral-darkest justify-end">
                              filter_alt
                            </span>
                          </button>

                          <div className="flex items-center gap-x-4 hidden md:flex">
                            <select
                              id="searchField"
                              className="mr-2 text-neutral-darkest"
                            >
                              <option value="sold_policy_no">Policy No</option>
                              <option value="engine_no">Engine No</option>
                              <option value="chassis_no">Chassis No</option>
                            </select>

                            <div className="relative flex items-center w-full md:w-auto">
                              <input
                                type="text"
                                id="search"
                                placeholder="Search..."
                                className="px-3 py-1 border border-neutral-dark rounded-l-sm focus:outline-primary text-base-black w-full md:w-full "
                              />
                              <button
                                type="button"
                                onClick={() => {
                                  setPayload((prev) => ({
                                    ...prev,
                                    search: {
                                      field:
                                        document.getElementById("searchField")
                                          .value,
                                      value:
                                        document.getElementById("search").value,
                                    },
                                  }));
                                }}
                                className="bg-primary text-white border border-primary px-2 py-0.5 rounded-r-sm"
                              >
                                <span class="material-symbols-outlined flex items-center justify-center">
                                  search
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="flex flex-col md:flex-row items-center md:gap-x-4 gap-2">
                            <div className="flex flex-col items-center md:items-start w-full md:w-auto mb-2 md:mb-0">
                              <select
                                id="searchField"
                                className="text-neutral-darkest  md:w-auto"
                              >
                                <option value="sold_policy_no">Policy No</option>
                                <option value="engine_no">Engine No</option>
                                <option value="chassis_no">Chassis No</option>
                              </select>
                            </div>
  
                            <div className="flex items-center w-full max-w-sm mx-auto">
                              <input
                                type="text"
                                id="search"
                                placeholder="Search..."
                                className="px-3 py-1 border border-neutral-dark rounded-l-sm focus:outline-primary text-base-black flex-grow max-w-48"
                              />
  
                              <button
                                type="button"
                                onClick={() => {
                                  setPayload((prev) => ({
                                    ...prev,
                                    search: {
                                      field: document.getElementById("searchField").value,
                                      value: document.getElementById('search').value,
                                    }
                                  }));
                                }}
                                className="bg-primary text-white border border-primary px-2 py-1 rounded-r-sm"
                              >
                                <span className="material-symbols-outlined flex items-center justify-center">
                                  search
                                </span>
                              </button>
  
                              <button
                                type="button"
                                className="ml-2"
                                onClick={() => setShowDateRangeModal(true)}
                              >
                                <span className="material-symbols-outlined text-neutral-darkest">
                                  date_range
                                </span>
                              </button>
  
                              <button
                                type="button"
                                className="ml-2"
                                onClick={downloadExcel}
                              >
                                <span className="material-symbols-outlined text-neutral-darkest">
                                  download
                                </span>
                              </button>
                            </div>
                          </div> */}
                    </TabList>

                    <TabPanel>
                      <div className="flex mb-4 overflow-x-scroll cursor-pointer">
                        <table
                          id="table-to-xls"
                          className="text-sm border border-neutral w-full"
                        >
                          <thead className="bg-primary text-white text-xs">
                            {table.getHeaderGroups().map((headerGroup) => (
                              <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                  return (
                                    <th
                                      key={header.id}
                                      colSpan={header.colSpan}
                                      className="px-2 py-1 text-left"
                                    >
                                      {header.isPlaceholder ? null : (
                                        <div
                                          className={
                                            header.column.getCanSort()
                                              ? "cursor-pointer select-none"
                                              : ""
                                          }
                                          onClick={header.column.getToggleSortingHandler()}
                                          title={
                                            header.column.getCanSort()
                                              ? header.column.getNextSortingOrder() ===
                                                "asc"
                                                ? "Sort ascending"
                                                : header.column.getNextSortingOrder() ===
                                                  "desc"
                                                ? "Sort descending"
                                                : "Clear sort"
                                              : undefined
                                          }
                                        >
                                          {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                          )}
                                          {{
                                            asc: " 🔼",
                                            desc: " 🔽",
                                          }[header.column.getIsSorted()] ??
                                            null}
                                        </div>
                                      )}
                                    </th>
                                  );
                                })}
                              </tr>
                            ))}
                          </thead>
                          {/* <tbody>
                              {table.getRowModel().rows.map((row) => {
                                return (
                                  <tr
                                    key={row.id}
                                    className="text-xs even:bg-primary-lightest"
                                  >
                                    {row.getVisibleCells().map((cell) => {
                                      return (
                                        <td key={cell.id} className="px-2 py-1">
                                          {" "}
                                          
                                          {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                          )}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                );
                              })}
                            </tbody> */}
                          <tbody>
                            {loading ? (
                              <tr className="min-h-20">
                                <td
                                  colSpan={columns.length}
                                  className="text-center p-4 min-h-20"
                                >
                                  Loading...
                                </td>
                              </tr>
                            ) : noRecordFound ? (
                              <tr className="min-h-20">
                                <td
                                  colSpan={columns.length}
                                  className="text-center p-4 min-h-20"
                                >
                                  No records found
                                </td>
                              </tr>
                            ) : (
                              table.getRowModel().rows.map((row) => (
                                <tr
                                  key={row.id}
                                  className="text-xs even:bg-base-white"
                                >
                                  {row.getVisibleCells().map((cell) => (
                                    <td key={cell.id} className="px-2 py-1">
                                      {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                      )}
                                    </td>
                                  ))}
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>

                      <div className="flex flex-col md:flex-row items-center w-full justify-end text-xs gap-4">
                        <div className="flex items-center gap-2">
                          {" "}
                          <select
                            value={payload.pageSize}
                            onChange={(e) => {
                              const size = Number(e.target.value);
                              setPayload((prev) => ({
                                ...prev,
                                pagination: {
                                  pageSize: size,
                                  pageNumber: 1,
                                },
                              }));
                            }}
                          >
                            {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map(
                              (pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                  {pageSize}
                                </option>
                              )
                            )}
                          </select>
                          <span className="font-semibold">
                            <span>Showing </span>
                            {1 +
                              (payload.pagination.pageNumber - 1) *
                                payload.pagination.pageSize}{" "}
                            to{" "}
                            {payload.pagination.pageNumber *
                              payload.pagination.pageSize -
                              payload.pagination.pageSize +
                              data?.length}{" "}
                            of {totalCount} Entries
                          </span>
                        </div>
                        {/* <button type="button" disabled={isFirstPage} onClick={handleFirstPage}>
                            <span className="material-symbols-outlined">
                              first_page
                            </span>
                          </button> */}
                        <div className="flex items-center space-x-2 mt-2 md:mt-0">
                          <button
                            type="button"
                            disabled={isFirstPage}
                            onClick={handleFirstPage}
                            className={`material-symbols-outlined ${
                              isFirstPage
                                ? "cursor-not-allowed text-neutral-light"
                                : "cursor-pointer"
                            }`}
                          >
                            first_page
                          </button>
                          {/* <button type="button" onClick={handlePreviousPage} disabled={isFirstPage}>
                            <span class="material-symbols-outlined">
                              chevron_left
                            </span>
                          </button> */}
                          <button
                            type="button"
                            onClick={handlePreviousPage}
                            disabled={isFirstPage}
                            className={`material-symbols-outlined ${
                              isFirstPage
                                ? "cursor-not-allowed text-neutral-light"
                                : "cursor-pointer"
                            }`}
                          >
                            chevron_left
                          </button>
                          {/* <button type="button" onClick={handleNextPage} disabled={isLastPage}>
                            <span class="material-symbols-outlined">
                              chevron_right
                            </span>
                          </button> */}
                          <button
                            type="button"
                            onClick={handleNextPage}
                            disabled={isLastPage}
                            className={`material-symbols-outlined ${
                              isLastPage
                                ? "cursor-not-allowed text-neutral-light"
                                : "cursor-pointer"
                            }`}
                          >
                            chevron_right
                          </button>
                          {/* <button type="button" className="disabled:text-neutral-light" onClick={handleLastPage} disabled={isLastPage}>
                            <span className="material-symbols-outlined text-inherit">
                              last_page
                            </span>
                          </button> */}
                          <button
                            type="button"
                            className={`material-symbols-outlined ${
                              isLastPage
                                ? "cursor-not-allowed text-neutral-light"
                                : "cursor-pointer"
                            }`}
                            onClick={handleLastPage}
                            disabled={isLastPage}
                          >
                            last_page
                          </button>
                        </div>
                      </div>
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
