import React from "react";
import coverImage from ".././assets/img/coverImage.png";
import toast, { Toaster } from "react-hot-toast";
import loadingBarService from "../service/loadingbar.Service";
// import { apiService } from "../api.service";
import { service } from "../service";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { useState, useEffect } from "react";
import { apiService } from "../api.service";
import { useGlobalContext } from "../context/global.context";

export const DealerProfile = () => {
  const { setMessage, setError } = useGlobalContext();
  const columnHelper = createColumnHelper();
  const [data, setData] = useState([]);
  const [sorting, setSorting] = React.useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [noRecordFound, setNoRecordFound] = useState(false);
  const [dealerData, setDealerData] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [payload, setPayload] = useState({
    pagination: {
      pageNumber: 1,
      pageSize: 5,
    },
  });

  const documentColumns = [
    columnHelper.accessor("srNo", {
      header: "#",
    }),
    columnHelper.accessor("name", {
      header: "Document Name",
    }),
    columnHelper.accessor("fileName", {
      header: "File Name",
    }),
    // columnHelper.accessor("status", {
    //     header: "Status",
    //     cell: (data) => {
    //         const status = data.getValue();
    //         return (
    //             <span className={`px-2 py-1 rounded ${getButtonStyles(status)}`}>
    //                 {status}
    //             </span>
    //         );
    //     },
    // }),
    columnHelper.accessor("createdAt", {
      header: "Action",
      cell: (data) => {
        const fileName = data.row.original.fileName;
        return (
          <span
            class="material-symbols-outlined text-sm"
            onClick={() => onViewDocument(fileName)}
          >
            visibility
          </span>
        );
      },
    }),
  ];

  // const getButtonStyles = (status) => {
  //     const baseClasses =
  //         "w-fit px-4 flex items-center justify-center rounded-full font-semibold";
  //     switch (status) {
  //         case "pending":
  //             return `${baseClasses} bg-warn-lightest text-warn capitalize`;
  //         case "rejected":
  //             return `${baseClasses} bg-neutral-lightest text-danger capitalize`;
  //         case "approved":
  //             return `${baseClasses} bg-success-lightest text-success capitalize`;
  //         default:
  //             return `${baseClasses} bg-warn-lightest text-warn.lightest capitalize`;
  //     }
  // };

  // const downloadInvoicePdf = async (invoiceMonth) => {
  //     try {
  //       const toastId = toast.loading('Downloading...')
  //       const payload = {
  //         // dealerId : 1,
  //         invoiceMonth: invoiceMonth
  //       };
  //       console.log('paylaod', payload);
  //       await apiService.downloadInvoicePdf(payload).then(res => {
  //         const blob = new Blob([res.data], { type: 'application/pdf' });
  //         const disposition = res.headers;
  //         console.log(disposition);
  //         const disposition1 = res.headers['content-disposition'];
  //         console.log(disposition1);

  //         let fileName = 'invoice.pdf'; // fallback filename

  //         // Extract the filename from the Content-Disposition header
  //         if (disposition) {
  //           const matches = /filename="([^"]+)"/.exec(disposition);
  //           if (matches != null && matches[1]) {
  //             fileName = matches[1];
  //           }
  //         }
  //         console.log(disposition);

  //         // const fileName = disposition ? disposition.split('filename=')[1].replace(/"/g, '') : 'policy.pdf';
  //         saveAs(blob, fileName);
  //         toast.dismiss(toastId);
  //       }).catch(error => {
  //         toast.dismiss(toastId);
  //         toast.error(error.response.data.message);
  //       }).finally(() => {
  //         toast.dismiss(toastId);
  //       });
  //     } catch (error) {
  //       toast.error("Error downloading the PDF");
  //     } finally {
  //       loadingBarService.complete(); // Complete the loading bar
  //     }
  //   };
  const onViewDocument = async (fileName) => {
    const payload = {
      fileName,
    };
    const filePath = await apiService
      .viewDealerDocs(payload)
      .then((data) => data.data.data)
      .catch((error) => service.notifyApiError(error, setMessage, setError));
    window.open(filePath, "_blank");
    // setViewFilePath(filePath)
  };
  const fetchDealerDocuments = async () => {
    const loadingToast = toast.loading("Loading dealer documents...");
    setLoading(true);
    try {
      // payload is changed on each scenario and it will call fetchPolicyData
      const response = await apiService.listDealerDocs(payload);
      setData(response.data.data);
      //   setTotalCount(response.data.totalCount);
    } catch (error) {
      console.error("Error fetching policy data:", error);
      toast.error("Error fetching policy data");
      service.notifyApiError(error, setMessage, setError);
    } finally {
      toast.dismiss(loadingToast);
      setLoading(false);
    }
  };

  const table = useReactTable({
    data: filteredData.length > 0 ? filteredData : data,
    columns: documentColumns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    //debugTable: true,
    manualPagination: true,
  });

  const pincodeTable = useReactTable({
    data: documentData,
    columns: documentColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
  });

  const fetchDealerInfo = async () => {
    await apiService.getDealerInfo().then((res) => {
      setDealerData(res.data.data);
    });
  };

  useEffect(() => {
    fetchDealerInfo();
    fetchDealerDocuments();
  }, []);

  return (
    <>
      <div className="flex flex-col w-full items-center overflow-hidden">
        <div className="sticky -z-10 w-full">
          <img
            src={coverImage}
            className=" w-full h-36 object-cover"
            alt="cover_image"
          />
        </div>
        <div className="w-6/12 bg-white -mt-28 md:-mt-20 border border-neutral-light rounded mx-2">
          <form>
            <div>
              <div className="bg-base-white px-6 py-4 border-b border-neutral-light rounded-t">
                <h3 className="text-xl font-semibold">Dealer Profile</h3>
                <p className="text-neutral-darker mt-1 text-sm">
                  A brief snapshot of your financial activity, showing incoming
                  and outgoing transactions
                </p>
              </div>
              <div className="px-6 py-4 pb-8">
                <div className="flex flex-col items-center">
                  <div className="w-full md:w-1/2">
                    <div className="grid grid-cols-2">
                      <h3 className="text-xl font-semibold col-span-2  mb-2">
                        Basic
                      </h3>
                      <div className="text-sm">Name</div>
                      <div>{dealerData.dealerShipName}</div>
                      <div className="w-20 text-sm">Address</div>
                      <div className="w-80">{dealerData.address}</div>
                      <div className="text-sm">GSTIN</div>
                      <div>{dealerData.gstNumber}</div>
                      <div className="text-sm">PAN</div>
                      <div>{dealerData.panNo}</div>
                      <div className="text-sm">Aadhar Number</div>
                      <div>{dealerData.adhaarNo}</div>
                      <div className="text-sm">Email</div>
                      <div>{dealerData.email}</div>
                      <div className="text-sm">Mobile</div>
                      <div>{dealerData.mobile}</div>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 mt-6">
                    <h3 className="text-xl font-semibold  mb-0">Bank</h3>
                    <div className="grid grid-cols-2 mt-2">
                      <div className="text-sm">Bank Name</div>
                      <div>{dealerData.bankName}</div>
                      <div className="text-sm">Bank IFSC Code</div>
                      <div>{dealerData.ifscCode}</div>
                      <div className="text-sm">Account Holder Name</div>
                      <div>{dealerData.accountHolderName}</div>
                      <div className="text-sm">Account Number</div>
                      <div>{dealerData.accountNumber}</div>
                      <div className="text-sm">Account Type</div>
                      <div>{dealerData.accountTypeName}</div>
                      <div className="w-20 text-sm">Address</div>
                      <div className="w-80">{dealerData.branchAddress}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex mb-4 overflow-x-scroll cursor-pointer">
              <table
                id="table-to-xls"
                className="text-sm border border-neutral w-full"
              >
                <thead className="bg-primary text-white text-xs">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        return (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            className="px-2 py-1 text-left"
                          >
                            {header.isPlaceholder ? null : (
                              <div
                                className={
                                  header.column.getCanSort()
                                    ? "cursor-pointer select-none"
                                    : ""
                                }
                                onClick={header.column.getToggleSortingHandler()}
                                title={
                                  header.column.getCanSort()
                                    ? header.column.getNextSortingOrder() ===
                                      "asc"
                                      ? "Sort ascending"
                                      : header.column.getNextSortingOrder() ===
                                        "desc"
                                      ? "Sort descending"
                                      : "Clear sort"
                                    : undefined
                                }
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: " 🔼",
                                  desc: " 🔽",
                                }[header.column.getIsSorted()] ?? null}
                              </div>
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                {/* <tbody>
                            {table.getRowModel().rows.map((row) => {
                              return (
                                <tr
                                  key={row.id}
                                  className="text-xs even:bg-primary-lightest"
                                >
                                  {row.getVisibleCells().map((cell) => {
                                    return (
                                      <td key={cell.id} className="px-2 py-1">
                                        {" "}
                                        
                                        {flexRender(
                                          cell.column.columnDef.cell,
                                          cell.getContext()
                                        )}
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                          </tbody> */}
                <tbody>
                  {noRecordFound ? (
                    <tr className="min-h-20">
                      <td
                        colSpan={documentColumns.length}
                        className="text-center p-4 min-h-20"
                      >
                        No records found
                      </td>
                    </tr>
                  ) : (
                    table.getRowModel().rows.map((row) => (
                      <tr key={row.id} className="text-xs even:bg-base-white">
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id} className="px-2 py-1">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </form>
          {documentData.length > 0 && (
            <>
              <div className="flex mb-4 overflow-x-scroll cursor-pointer mt-4 justify-center ">
                <table
                  id="table-to-xls"
                  className="text-sm border border-neutral w-2/3"
                >
                  <thead className="bg-primary text-white text-xs">
                    {pincodeTable.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                          return (
                            <th
                              key={header.id}
                              colSpan={header.colSpan}
                              className="px-2 py-1 text-left"
                            >
                              {header.isPlaceholder ? null : (
                                <div>
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                </div>
                              )}
                            </th>
                          );
                        })}
                      </tr>
                    ))}
                  </thead>

                  <tbody>
                    {noRecordFound ? (
                      <tr className="min-h-20">
                        <td
                          colSpan={documentColumns.length}
                          className="text-center p-4 min-h-20"
                        >
                          No records found
                        </td>
                      </tr>
                    ) : (
                      pincodeTable.getRowModel().rows.map((row) => (
                        <tr
                          key={row.id}
                          className="text-xs even:bg-primary-lightest"
                        >
                          {row.getVisibleCells().map((cell) => (
                            <td key={cell.id} className="px-2 py-1">
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          ))}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
