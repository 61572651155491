import React, { useState, useEffect } from "react";
import coverImage from "../../assets/img/coverImage.png";
import CurrencyFormat from "react-currency-format";
// import Input from "../components/ui/Input";
import Button from "../../components/ui/Button";
import toast, { Toaster } from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
// import Select from "../components/ui/Select";
import File from "../../components/ui/File";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useNavigate } from "react-router-dom";
import ReactHTMLTableToExcel from "react-html-table-to-excel"; // Import ReactHTMLTableToExcel
//import { Data } from "../pages/defaultData";
import { apiService } from "../../api.service";
import FileSaver from "file-saver";
import Tippy from "@tippyjs/react";
import moment from "moment";
import {
    Description,
    Dialog,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import _ from "lodash";
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
    SortingState,
    getSortedRowModel,
    getPaginationRowModel,
} from "@tanstack/react-table";
import { tab } from "@testing-library/user-event/dist/tab";
import { service } from "../../service";
import { useGlobalContext } from "../../context/global.context";
const onEdit = (id) => {
    console.log(id);
};

export const TransactionApproval = () => {
    const {setMessage, setError} = useGlobalContext()
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [disableAboutToExpireSelect, setDisableAboutToExpireSelect] =
        useState(true);
    const [ShowGSTStatusModal, setShowGSTStatusModal] = useState(false);
    // const [filteredData, setFilteredData] = useState([]);

    const [filteredData, setFilteredData] = useState([]);
    const [activeTabName, setActiveTabName] = useState("");
    const [sorting, setSorting] = React.useState([]);
    const [payload, setPayload] = useState({
        pagination: {
            pageNumber: 1,
            pageSize: 5,
        },
        // search: "",
    });
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [showDateRangeModal, setShowDateRangeModal] = useState(false);
    const [invoiceSummaryData, setInvoiceSummaryData] = useState({});
    const [invoiceData, setInvoiceData] = useState({});
    const [invoiceSummaryFooterData, setInvoiceSummaryFooterData] = useState({});
    const [gstApprovalStatusOptions, setGstApprovalStatusOptions] = useState([])
    const [loading, setLoading] = useState(true);
    const [noRecordFound, setNoRecordFound] = useState(false);
    const navigate = useNavigate();
    const [searchClicked, setSearchClicked] = useState(false);
    const columnHelper = createColumnHelper();
    const getButtonStyles = (status) => {
        const baseClasses =
            "w-fit px-4 flex items-center justify-center rounded-full font-semibold";
        switch (status) {
            case "pending":
                return `${baseClasses} bg-warn-lightest text-warn capitalize`;
            case "rejected":
                return `${baseClasses} bg-neutral-lightest text-danger capitalize`;
            case "approved":
                return `${baseClasses} bg-success-lightest text-success capitalize`;
            case "referback":
                return `${baseClasses} bg-neutral-lightest text-neutral-darkest capitalize`;
            default:
                return `${baseClasses} bg-warn-lightest text-warn.lightest capitalize`;
        }
    };

    const handleStatusChange = (selectedStatus, rowData) => {
        console.log(`Status changed to: ${selectedStatus}`, rowData);
        // Add code here to update the status in the backend or handle it as required
    };

    const dateFilterForm = useFormik({
        initialValues: {
            fromDate: "",
            toDate: "",
        },
        validationSchema: Yup.object().shape({
            fromDate: Yup.date().required("Required"),
            toDate: Yup.date().required("Required"),
        }),
        onSubmit: async (values) => {
            console.log(values);
            setPayload((prev) => ({
                ...prev,
                fromDate: values.fromDate,
                toDate: values.toDate,
            }));
            console.log(payload);
            setShowDateRangeModal(false);
        },
    });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const columnsTransactionApproval = [
        columnHelper.accessor("", {
            header: "#",
        }),
        columnHelper.accessor("invoiceNumber", {
            header: "Dealer Name",
        }),
        columnHelper.accessor("invoiceNumber", {
            header: "Dealer Code",
        }),
        columnHelper.accessor("invoiceNumber", {
            header: "Transaction No",
        }),

        columnHelper.accessor("invoiceNumber", {
            header: "Deposit Amount",
        }),
        columnHelper.accessor("invoiceNumber", {
            header: "Invoice Number",
        }),
        columnHelper.accessor("invoiceNumber", {
            header: "Bank Name",
        }),

        columnHelper.accessor("invoiceMonth", {
            header: "Transaction Type",
        }),
        columnHelper.accessor("gstAmount", {
            header: "Transaction Date",

        }),
        columnHelper.accessor("state", {
            header: "State",
        }),
        columnHelper.accessor("location", {
            header: "Location",
        }),
        columnHelper.accessor("createdAt", {
            header: "Created Date",
            cell: (data) => {
                return (
                    <span>
                        {data.getValue()
                            ? moment(data.getValue()).format("Do MMM YYYY")
                            : "-"}
                    </span>
                );
            },
        }),
        columnHelper.accessor("updatedDate", {
            header: "Updated Date",

            cell: (data) => {
                return (
                    <span>
                        {data.getValue()
                            ? moment(data.getValue()).format("Do MMM YYYY")
                            : "-"}
                    </span>
                );
            },
        }),
        columnHelper.accessor("status", {
            header: "Status",
            cell: (data) => {
                const status = data.getValue();
                return (
                    <span className={`px-2 py-1 rounded ${getButtonStyles(status)}`}>
                        {status}
                    </span>
                );
            },
        }),
        columnHelper.accessor("location", {
            header: "Approved Date",
        }),
        columnHelper.accessor("location", {
            header: "Request Type",
        }),
        columnHelper.accessor("location", {
            header: "Remark",
        }),
        columnHelper.accessor("location", {
            header: "File",
        }),

        columnHelper.accessor("", {
            header: "Action",
            cell: (data) => {
                return (
                    <div className="text-center">
                        {/* Status Dropdown */}
                        <select
                            className="border border-gray-300 rounded px-2 py-1 text-sm focus:outline-none focus:ring focus:border-blue-300"
                            defaultValue="pending" // Set this to the current status if available
                            onChange={(e) => handleStatusChange(e.target.value, data.row.original)}
                        >
                            <option value="pending">Pending</option>
                            <option value="approve">Approve</option>
                            <option value="reject">Reject</option>
                            <option value="referBack">Refer Back</option>
                        </select>
                    </div>
                );
            },
        }),

    ];

    const table = useReactTable({
        columns: columnsTransactionApproval,
        data: filteredData.length > 0 ? filteredData : data,
        //onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        //debugTable: true,
        manualPagination: true,
    });

    console.log(invoiceSummaryData);

    const totalPages = Math.ceil(totalCount / payload.pagination.pageSize);
    const isLastPage = payload.pagination.pageNumber === totalPages;
    const isFirstPage = payload.pagination.pageNumber === 1;

    const handleNextPage = () => {
        try {
            setPayload((prev) => {
                const nextPage = prev.pagination.pageNumber + 1;
                return {
                    ...prev,
                    pagination: {
                        ...prev.pagination,
                        pageNumber: nextPage > totalPages ? totalPages : nextPage,
                    },
                };
            });
        } catch (error) {
            service.notifyApiError(error, setMessage, setError);
        }

    };

    const handleLastPage = () => {
        try {
            setPayload((prev) => ({
                ...prev,
                pagination: {
                    ...prev.pagination,
                    pageNumber: totalPages,
                },
            }));
        } catch (error) {
            service.notifyApiError(error, setMessage, setError)
        }

    };
    const handlePreviousPage = () => {
        try {
            setPayload((prev) => {
                const prevPage = prev.pagination.pageNumber - 1;
                return {
                    ...prev,
                    pagination: {
                        ...prev.pagination,
                        pageNumber: prevPage < 1 ? 1 : prevPage,
                    },
                };
            });
        } catch (error) {
            service.notifyApiError(error, setMessage, setError)
        }

    };

    const handleFirstPage = () => {
        try {
            setPayload((prev) => ({
                ...prev,
                pagination: {
                    ...prev.pagination,
                    pageNumber: 1,
                },
            }));
        } catch (error) {
            service.notifyApiError(error, setMessage, setError)
        }
    };

    const fetchGstTransactions = async () => {
        // const payload = {
        //     invoiceMonth: gstTransactionForm.getFieldProps("invoiceMonth").value,
        //     invoiceDate: gstTransactionForm.getFieldProps("toDate").value,
        //     invoiceNumber: gstTransactionForm.getFieldProps("fromDate").value
        // };
        //const loadingToast = toast.loading("Loading data...");
        setLoading(true)
        try {
            // Call the generateInvoice API
            // loading start
            const response = await apiService
                .gstTransactions(payload)
                .then((response) => {
                    if (response?.data?.data.length === 0) {
                        setNoRecordFound(true);
                    }
                    setData(response.data.data);
                    setTotalCount(response.data.totalCount || data.length);
                    setNoRecordFound(data.length === 0);
                })
                .catch((error) => {
                    // toast.error(error.response.data.message)
                    service.notifyApiError(error, setMessage, setError);
                })
                .finally(() => {
                    // loading complete
                    // toast.dismiss(loadingToast);
                    setLoading(false)

                });
            console.log("Invoice generated successfully:", response.data);
            // Optionally, show a success message or perform any other actions upon successful generation
        } catch (error) {
            console.error("Error generating invoice:", error);
            // Optionally, show an error message or perform any other error handling
        }
    };
    const getGstApprovalStatusOptions = async () => {
        const loadingToast = toast.loading("Loading data");
        try {
            await apiService.gstApprovalStatusOptions().then((res) => {
                console.log(res.data.data)
                setGstApprovalStatusOptions(res.data.data);
            });
        } catch (error) {
            console.error("Error fetching status:", error);
            service.notifyApiError(error, setMessage, setError);
        } finally {
            toast.dismiss(loadingToast);
        }
    };
    // const getUploadGSTFile =  async () =>{
    //     try {
    //     const response = await apiService.uploadGSTFile(payload).then(response =>{

    //     });
    //     } catch (error) {
    //         console.error("Error generated:", error);
    //     }
    // }

    // useEffect(() => {
    //   console.log("Payload updated:", payload);
    //   fetchInvoiceSummary();
    // }, [payload]);
    // Function to reset all states
    const resetState = () => {
        setSelectedTabIndex(0);
        setData([]);
        setTotalCount(0);
        setShowGSTStatusModal(false);
        setFilteredData([]);
        setSorting([]);
        setPayload({
            pagination: {
                pageNumber: 1,
                pageSize: 5
            }, statusId: null,

        });
        setShowStatusModal(false);
        setShowDateRangeModal(false);
        setInvoiceSummaryData({});
        setLoading(true);
        setNoRecordFound(false);
    };
    useEffect(() => {
        console.log("Payload updated:");
        fetchGstTransactions();
    }, [payload]);
    useEffect(() => {
        getGstApprovalStatusOptions()
    }, [])

    useEffect(() => {
        if (data?.length == 0) {
            setNoRecordFound(true);
        } else {
            setNoRecordFound(false);
        }
    }, [data]);

    const onFileChange = (name, file) => {
        // alert(0)
        console.log(file, name, "iuhygtfc");
        gstTransactionForm.setFieldValue(name, file);
    };

    const gstTransactionForm = useFormik({
        initialValues: {
            invoiceNumber: "",
            gstFile: "",
        },
        validationSchema: Yup.object().shape({
            invoiceNumber: Yup.string().required("Required"),
            gstFile: Yup.mixed().required("Required"),
        }),
        onSubmit: async (values) => {
            // alert(8)
            console.log(values);
            const payload = {
                invoiceNumber: values.invoiceNumber,
                gstFile: values.gstFile,
            };
            const loadingToast = toast.loading("Loading data...");
            await apiService
                .uploadGSTFile(payload)
                .then((response) => {
                    setShowGSTStatusModal(false);
                    toast.success(response.data.message);
                    fetchGstTransactions()
                })
                .catch((error) => {
                    console.error("Error generated:", error);
                    service.notifyApiError(error, setMessage, setError);
                }).finally(() => {
                    toast.dismiss(loadingToast);
                })
        },
    });

    return (
        <>
            <Dialog
                open={ShowGSTStatusModal}
                onClose={() => setShowGSTStatusModal(false)}
                className="relative z-50"
            >
                <div className="fixed inset-0 bg-black/75" aria-hidden="true" />

                <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                    <DialogPanel className=" space-y-4 border bg-white border-neutral rounded w-1/3">
                        <form onSubmit={gstTransactionForm.handleSubmit}>
                            <div className="bg-neutral-lightest  border-b border-neutral px-6 py-3 flex">
                                <span className="font-bold text-xl py-2 text-neutral-darkest ">
                                    GST Compliance
                                </span>
                            </div>
                            <div className="flex gap-x-4 px-8 pt-4">
                                <div className="flex flex-col">
                                    <h2 className="text-xl font-semibold mb-4">Upload File</h2>

                                    <File
                                        {...gstTransactionForm.getFieldProps("gstFile")}
                                        formik={gstTransactionForm}
                                        id="gstFile"
                                        name="gstFile"
                                        label="GST Certificate"
                                        className="w-full md:w-56"
                                        icon="picture_as_pdf"
                                        accept=".pdf"
                                        onFileChange={onFileChange}
                                    />
                                </div>
                            </div>

                            <div className="flex items-center justify-center gap-4 mt-4 px-12 pb-8">
                                <Button
                                    type="button"
                                    label="Cancel"
                                    variant="ghost"
                                    className="max-w-12"
                                    onClick={() => setShowGSTStatusModal(false)}
                                />
                                <Button
                                    type="submit"
                                    label="Upload"
                                    variant="primary"
                                    className="max-w-16"
                                />
                            </div>
                        </form>
                    </DialogPanel>
                </div>
            </Dialog>
            <Dialog
                open={showDateRangeModal}
                onClose={() => setShowDateRangeModal(false)}
                className="relative z-50"
            >
                <div className="fixed inset-0 bg-black/75" aria-hidden="true" />

                <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                    <DialogPanel className=" space-y-4 border bg-white border-neutral rounded">
                        <form onSubmit={dateFilterForm.handleSubmit}>
                            <div className="bg-neutral-lightest  border-b border-neutral px-6 py-3 flex">
                                <span className="font-bold text-xl py-2 text-neutral-darkest ">
                                    Filter
                                </span>
                            </div>
                            <div className="flex flex-col gap-y-4 p-4">
                                <select
                                    id="searchField"
                                    className=" py-1 focus:outline-primary text-base-black "
                                >
                                    <option value="invoice_no">Invoice Number</option>
                                    <option value="dealer_name">Dealer Name</option>
                                    <option value="invoice_no">Invoice Number</option>
                                </select>

                                <div className="relative flex items-center w-full md:w-auto">
                                    <input
                                        type="text"
                                        id="search"
                                        placeholder="Search..."
                                        className="px-3 py-1 border border-neutral-dark rounded-l-sm focus:outline-primary text-base-black w-full md:w-auto"
                                    />
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setPayload((prev) => ({
                                                ...prev,
                                                search: {
                                                    field: document.getElementById("searchField").value,
                                                    value: document.getElementById("search").value,
                                                },
                                            }));
                                        }}
                                        className="bg-primary text-white border border-primary px-2 py-1  rounded-r-sm"
                                    >
                                        <span className="material-symbols-outlined flex items-center justify-center p-0">
                                            search
                                        </span>
                                    </button>
                                </div>
                            </div>

                            <div className="flex items-center justify-center gap-4 mt-4 px-12 pb-8">
                                <Button
                                    type="button"
                                    label="Cancel"
                                    variant="ghost"
                                    className="max-w-12"
                                    onClick={() => setShowDateRangeModal(false)}
                                />
                                <Button
                                    type="submit"
                                    label="Submit"
                                    variant="primary"
                                    className="max-w-16"
                                />
                            </div>
                        </form>
                    </DialogPanel>
                </div>
            </Dialog>
            <div className="flex flex-col w-full items-center overflow-hidden">
                <div className="sticky -z-10 w-full">
                    <img
                        src={coverImage}
                        className=" w-full h-36 object-cover"
                        alt="cover_image"
                    />
                </div>
                <div className="w-11/12 bg-white -mt-28 md:-mt-20 border border-neutral-light rounded mx-2">
                    <form>
                        <div>
                            <div className="bg-base-white px-6 py-4 border-b border-neutral-light rounded-t">
                                <h3 className="text-xl font-semibold">
                                    Transaction Approval{" "}
                                </h3>
                                <p className="text-neutral-darker mt-1 text-sm">
                                    Overview of transaction requests, including their statuses and
                                    approval dates.
                                </p>
                            </div>
                            <div className="px-6 py-4 pb-8">
                                <div>
                                    <Tabs
                                        selectedIndex={selectedTabIndex}
                                        selectedTabClassName="text-primary border-b-2 border-primary font-medium"
                                    >
                                        <TabList className="flex mb-4 text-neutral-dark text-sm items-center justify-between">
                                            <div className="flex gap-1"></div>

                                            {/* <div className="flex flex-col md:flex-row justify-between items-center w-full">
  
                          <div className="flex items-center gap-x-4 mb-2 md:mb-0">
                            <select
                              onChange={(e) => {
                                const typeId = Number(e.target.value);
                                setPayload((prev) => ({
                                  ...prev,
                                  statusId: typeId,
                                }));
                              }}
                              className="px-3 py-1 focus:outline-primary text-base-black"
                            >
                              {[
                                { id: 1, name: "Pending" },
                                { id: 2, name: "Rejected" },
                                { id: 3, name: "Approved" },
                              ].map((type) => (
                                <option key={type.id} value={type.id}>
                                  {type.name}
                                </option>
                              ))}
                            </select>
                          </div>
  
                          <div className="flex items-center gap-x-4 mb-2 md:mb-0 md:flex-1 md:justify-end ">
                            <button
                              type="button"
                              className="mt-1 md:hidden"
                              onClick={() => {
                                setShowDateRangeModal(true);
                              }}
                            >
                              <span className="material-symbols-outlined text-neutral-darkest">
                                filter_alt
                              </span>
  
                            </button>
                            <div className="flex items-center gap-x-4 hidden md:block">
                              <select
                                id="searchField"
                                className="px-3 py-1 focus:outline-primary text-base-black"
                              >
  
                                <option value="invoice_no">Invoice Number</option>
                              </select>
  
                              <div className="relative flex items-center w-full md:w-auto">
                                <input
                                  type="text"
                                  id="search"
                                  placeholder="Search..."
                                  className="px-3 py-1 border border-neutral-dark rounded-l-sm focus:outline-primary text-base-black w-full md:w-auto"
                                />
                                <button
                                  type="button"
                                  onClick={() => {
                                    setPayload((prev) => ({
                                      ...prev,
                                      search: {
                                        field: document.getElementById("searchField").value,
                                        value: document.getElementById('search').value,
                                      }
                                    }));
                                  }}
                                  className="bg-primary text-white border border-primary px-2 py-1 md:py-0.5 rounded-r-sm"
                                >
                                  <span className="material-symbols-outlined flex items-center justify-center">
                                    search
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div> */}
                                            <div className="flex flex-col md:flex-row justify-between items-center w-full">
                                                <div className="label flex flex-col text-xs">
                                                    <label className="pl-2 ml-2">Select Status</label>
                                                    <select
                                                        value={payload.statusId || "statusId"}
                                                        onChange={(e) => {
                                                            const typeId = Number(e.target.value);
                                                            setPayload((prev) => ({
                                                                ...prev,
                                                                statusId: typeId,
                                                            }));
                                                        }}
                                                        className="px-3 py-1 focus:outline-primary text-base-black "
                                                    >
                                                        <option value="status">Select Status</option>
                                                        {gstApprovalStatusOptions.map((option) => (
                                                            <option key={option.value} value={option.id}>
                                                                {option.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {/* <div className="flex">
                                                    <input
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            setPayload((prev) => ({
                                                                ...prev,
                                                                isToday: e.target.checked ? true : false,
                                                            }));
                                                        }}
                                                    />
                                                    <span className="mr-4 ml-1 text-neutral-darkest">
                                                        Document Uploaded
                                                    </span>
                                                </div> */}


                                                    <button
                                                        type="button"
                                                        className="mt-1 md:hidden"
                                                        onClick={() => {
                                                            setShowDateRangeModal(true);
                                                        }}
                                                    >
                                                        <span className="material-symbols-outlined text-neutral-darkest justify-end">
                                                            filter_alt
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className="flex items-center gap-x-4 hidden md:flex">
                                                    <button
                                                        type="button"
                                                        className="mt-1"
                                                        onClick={() => {
                                                            resetState();
                                                        }}
                                                    >
                                                        <span class="material-symbols-outlined text-neutral-darkest">
                                                            refresh
                                                        </span>
                                                    </button>
                                                    <select
                                                        id="searchField"
                                                        className="px-3 py-1 focus:outline-primary text-base-black"
                                                    >
                                                        <option value="invoice_no">Invoice Number</option>
                                                    </select>

                                                    <div className="relative flex items-center w-full md:w-auto">
                                                        <input
                                                            type="text"
                                                            id="search"
                                                            placeholder="Search..."
                                                            className="px-3 py-1 border border-neutral-dark rounded-l-sm focus:outline-primary text-base-black w-full md:w-auto"
                                                        />
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                setPayload((prev) => ({
                                                                    ...prev,
                                                                    search: {
                                                                        field:
                                                                            document.getElementById("searchField")
                                                                                .value,
                                                                        value:
                                                                            document.getElementById("search").value,
                                                                    },
                                                                }));
                                                            }}
                                                            className="bg-primary text-white border border-primary px-2 py-1 md:py-0.5 rounded-r-sm"
                                                        >
                                                            <span className="material-symbols-outlined flex items-center justify-center">
                                                                search
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* </div> */}
                                        </TabList>

                                        <TabPanel>
                                            <div className="flex mb-4 overflow-x-scroll cursor-pointer">
                                                <table className="text-sm border border-neutral w-full overflow-x-scroll">
                                                    <thead className="bg-primary text-white text-xs">
                                                        {table.getHeaderGroups()?.map((headerGroup) => (
                                                            <tr key={headerGroup.id}>
                                                                {headerGroup.headers.map((header) => {
                                                                    return (
                                                                        <th
                                                                            key={header.id}
                                                                            colSpan={header.colSpan}
                                                                            className="px-2 py-1 text-left min-w-[100px] max-w-[300px]"
                                                                        >
                                                                            {header.isPlaceholder ? null : (
                                                                                <div
                                                                                    className={
                                                                                        header.column.getCanSort()
                                                                                            ? "cursor-pointer select-none"
                                                                                            : ""
                                                                                    }
                                                                                    onClick={header.column.getToggleSortingHandler()}
                                                                                    title={
                                                                                        header.column.getCanSort()
                                                                                            ? header.column.getNextSortingOrder() ===
                                                                                                "asc"
                                                                                                ? "Sort ascending"
                                                                                                : header.column.getNextSortingOrder() ===
                                                                                                    "desc"
                                                                                                    ? "Sort descending"
                                                                                                    : "Clear sort"
                                                                                            : undefined
                                                                                    }
                                                                                >
                                                                                    {flexRender(
                                                                                        header.column.columnDef.header,
                                                                                        header.getContext()
                                                                                    )}
                                                                                    {{
                                                                                        asc: " 🔼",
                                                                                        desc: " 🔽",
                                                                                    }[header.column.getIsSorted()] ??
                                                                                        null}
                                                                                </div>
                                                                            )}
                                                                        </th>
                                                                    );
                                                                })}
                                                            </tr>
                                                        ))}
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            loading ? (
                                                                <tr className="min-h-20">
                                                                    <td colSpan={columnsTransactionApproval.length} className="text-center p-4 min-h-20">
                                                                        Loading...
                                                                    </td>
                                                                </tr>
                                                            ) :
                                                                noRecordFound ? (
                                                                    <tr className="min-h-20">
                                                                        <td
                                                                            colSpan={columnsTransactionApproval.length}
                                                                            className="text-center p-4 min-h-20"
                                                                        >
                                                                            No records found
                                                                        </td>
                                                                    </tr>
                                                                ) : (
                                                                    table.getRowModel().rows.map((row) => (
                                                                        <tr
                                                                            key={row.id}
                                                                            className="text-xs even:bg-base-white"
                                                                        >
                                                                            {row.getVisibleCells().map((cell) => (
                                                                                <td key={cell.id} className="px-2 py-1">
                                                                                    {flexRender(
                                                                                        cell.column.columnDef.cell,
                                                                                        cell.getContext()
                                                                                    )}
                                                                                </td>
                                                                            ))}
                                                                        </tr>
                                                                    ))
                                                                )}
                                                    </tbody>
                                                </table>
                                            </div>
                                            {/* <div className="flex items-center w-full justify-end text-xs gap-4">
                                                  Show{" "}
                                                  <select
                                                      value={payload.pageSize}
                                                      onChange={(e) => {
                                                          const size = Number(e.target.value);
                                                          setPayload((prev) => ({
                                                              ...prev,
                                                              pagination: {
                                                                  ...prev.pagination,
                                                                  pageSize: size,
                                                              },
                                                          }));
                                                      }}
                                                      className="bg-base-light text-sm px-2 py-1 rounded-md"
                                                  >
                                                      {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map(
                                                          (pageSize) => (
                                                              <option key={pageSize} value={pageSize}>
                                                                  {pageSize}
                                                              </option>
                                                          )
                                                      )}
                                                  </select>
                                                  <span className="font-semibold">
                                                      {1 +
                                                          (payload.pagination.pageNumber - 1) *
                                                          payload.pagination.pageSize}{" "}
                                                      -{" "}
                                                      {payload.pagination.pageNumber *
                                                          payload.pagination.pageSize -
                                                          payload.pagination.pageSize +
                                                          data?.length}{" "}
                                                      of {totalCount}
                                                  </span>
                                                  <button type="button">
                                                      <span className="material-symbols-outlined">
                                                          first_page
                                                      </span>
                                                  </button>
                                                 
                                                  <button type="button" onClick={handlePreviousPage}>
                                                      <span class="material-symbols-outlined">
                                                          chevron_left
                                                      </span>
                                                  </button>
                                                  
                                                  <button type="button" onClick={handleNextPage}>
                                                      <span class="material-symbols-outlined">
                                                          chevron_right
                                                      </span>
                                                  </button>
                                                  
                                                  <button type="button">
                                                      <span className="material-symbols-outlined">
                                                          last_page
                                                      </span>
                                                  </button>
                                                 
  
                                                 
  
  
                                              </div> */}
                                            <div className="flex flex-col md:flex-row items-center w-full justify-end text-xs gap-4">
                                                <div className="flex items-center gap-2">
                                                    <select
                                                        value={payload.pageSize}
                                                        onChange={(e) => {
                                                            const size = Number(e.target.value);
                                                            setPayload((prev) => ({
                                                                ...prev,
                                                                pagination: {
                                                                    pageSize: size,
                                                                    pageNumber: 1,
                                                                },
                                                            }));
                                                        }}
                                                        className="md:w-auto"
                                                    >
                                                        {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map(
                                                            (pageSize) => (
                                                                <option key={pageSize} value={pageSize}>
                                                                    {pageSize}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                    <span className="font-semibold">
                                                        <span>Showing </span>
                                                        {1 +
                                                            (payload.pagination.pageNumber - 1) *
                                                            payload.pagination.pageSize}{" "}
                                                        to{" "}
                                                        {payload.pagination.pageNumber *
                                                            payload.pagination.pageSize -
                                                            payload.pagination.pageSize +
                                                            data?.length}{" "}
                                                        of {totalCount} Entries
                                                    </span>
                                                </div>

                                                <div className="flex items-center space-x-2 mt-2 md:mt-0">
                                                    <button
                                                        type="button"
                                                        disabled={isFirstPage}
                                                        onClick={handleFirstPage}
                                                        className={`material-symbols-outlined ${isFirstPage
                                                            ? "cursor-not-allowed text-neutral-light"
                                                            : "cursor-pointer"
                                                            }`}
                                                    >
                                                        first_page
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={handlePreviousPage}
                                                        disabled={isFirstPage}
                                                        className={`material-symbols-outlined ${isFirstPage
                                                            ? "cursor-not-allowed text-neutral-light"
                                                            : "cursor-pointer"
                                                            }`}
                                                    >
                                                        chevron_left
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={handleNextPage}
                                                        disabled={isLastPage}
                                                        className={`material-symbols-outlined ${isLastPage
                                                            ? "cursor-not-allowed text-neutral-light"
                                                            : "cursor-pointer"
                                                            }`}
                                                    >
                                                        chevron_right
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className={`material-symbols-outlined ${isLastPage
                                                            ? "cursor-not-allowed text-neutral-light"
                                                            : "cursor-pointer"
                                                            }`}
                                                        onClick={handleLastPage}
                                                        disabled={isLastPage}
                                                    >
                                                        last_page
                                                    </button>
                                                </div>
                                            </div>
                                        </TabPanel>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};