import React, { useEffect, useState } from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { Link, useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { apiService } from "../api.service";

export default function Sidebar({ opened, toggleSidebar }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [selectedParentId, setSelectedParentId] = useState(null);
  const [selectedChildId, setSelectedChildId] = useState(null);

  const [menuArray, setMenuArray] = useState([]);
  const [visibleTippy, setVisibleTippy] = useState({});
  const [openMenus, setOpenMenus] = useState([]);

  const location = useLocation();

  const handleToggleMenu = (id) => {
    setOpenMenus((prev) =>
      prev.includes(id) ? prev.filter((menuId) => menuId !== id) : [id]
    );
  };

  const showSidebar = () => {
    const currentPath = location.pathname;
    if (
      currentPath === "/upload-dealer-documents" ||
      currentPath === "/dealer-registration" ||
      currentPath === "/dealer-registration-status" ||
      currentPath.startsWith("/service/generate-policy/") ||
      currentPath.startsWith("/sales/generate-policy/")
    ) {
      return false;
    }
    return windowWidth > 768 ? true : opened;
  };

  const getActiveColor = (parent, child = null) => {
    if (child) {
      if (selectedChildId == child.id) {
        return "bg-secondary";
      }
    } else {
      if (selectedParentId == parent.id) {
        return "bg-primary";
      }
    }
    return "";
  };
  const handleNavItemClick = (parent, child = null) => {
    setSelectedParentId(parent.id);
    if (child) {
      setSelectedChildId(child.id);
    } else {
      setSelectedChildId(null);
    }

    if (isMobile) {
      toggleSidebar();
      setTimeout(() => {
        toggleSidebar();
      }, 300);
    }
  };

  const setActiveNavItem = (currentPath) => {
    if (Array.isArray(menuArray)) {
      // First, check if the currentPath matches a parent item
      menuArray.find((item) => {
        if (item.path === currentPath) {
          console.log("Parent match:", item.id);
          setSelectedParentId(item.id || null);
        } else {
          // If the item has children, check if any child matches the current path
          if (Array.isArray(item?.children)) {
            const childMatch = item?.children?.find(
              (child) => child.path === currentPath
            );
            if (childMatch) {
              console.log("Child match:", childMatch.id);
              // currentItemId = childMatch.id; // If a child matches, use the parent ID
              setSelectedChildId(childMatch.id || null);
            }
          }
        }
      });
    }
  };

  const handleMouseEnter = (id, hasChild) => {
    setVisibleTippy((prev) => ({ ...prev, [id]: !hasChild }));
  };

  const handleMouseLeave = (id) => {
    setVisibleTippy((prev) => ({ ...prev, [id]: false }));
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  useEffect(() => {
    setActiveNavItem(location.pathname);
    console.log("hj : ", selectedParentId, selectedChildId);
    // If no matching parent or child found, set to null
  }, [location.pathname]);

  useEffect(() => {
    const fetchMenuArray = async () => {
      try {
        const response = await apiService.sidebar();
        setMenuArray(response.data.data || []);
      } catch (error) {
        console.error("Failed to fetch navigation items:", error);
      }
    };
    fetchMenuArray();
  }, []);

  return (
    <>
      {showSidebar() && (
        <aside className="bg-primary text-white fixed md:sticky left-0 top-12 h-[calc(100vh-48px)] md:min-w-60">
          <ul className="flex flex-col items-start">
            {/* {selectedNavItem === navItem.id ? "bg-secondary" : ""} */}
            {/* ${
                     openMenus.includes(navItem.id) ? "bg-primary-darkest" : ""
                   } */}
            {Array.isArray(menuArray) &&
              menuArray.map((navItem) => (
                <li
                  className={`hover:bg-primary-darkest w-full 
                ${getActiveColor(navItem)}
                  `}
                  key={navItem.id}
                  onClick={() => handleNavItemClick(navItem)}
                  onMouseEnter={() =>
                    handleMouseEnter(navItem.id, navItem.children?.length > 0)
                  }
                  onMouseLeave={() => handleMouseLeave(navItem.id)}
                >
                  {/* <Tippy
                    content={navItem.label}
                    placement="right"
                    arrow={false}
                    className="rounded-sm text-xs"
                    visible={visibleTippy[navItem.id] || false}
                  > */}
                  <Link to={navItem.path}>
                    <div className="flex flex-col w-full py-2">
                      <div
                        className="flex  px-4 justify-between items-center"
                        onClick={() => handleToggleMenu(navItem.id)}
                      >
                        <div className="flex items-center space-x-2">
                          <span className="material-symbols-outlined mr-3 md:mr-0">
                            {navItem.icon}
                          </span>
                          <span className="text-white text-sm">
                            {navItem.label}
                          </span>
                        </div>
                        {navItem.children && (
                          <span className="material-symbols-outlined text-sm">
                            keyboard_arrow_down
                          </span>
                        )}
                      </div>
                      <div className="bg-primary-darkest">
                        {navItem.children &&
                          openMenus.includes(navItem.id) &&
                          navItem.children.map((child, i) => (
                            <ul>
                              {console.log(selectedParentId, child.id)}
                              <React.Fragment key={child.id}>
                                <li
                                  className={`hover:bg-black ${getActiveColor(
                                    navItem,
                                    child
                                  )}`}
                                  onClick={() =>
                                    handleNavItemClick(navItem, child)
                                  }
                                >
                                  <Link to={child.path}>
                                    <button className="flex py-1 px-4 pl-8 items-center justify-start md:justify-center">
                                      <span className="text-white text-xs text-left pl-2">
                                        {child.label}
                                      </span>
                                    </button>
                                  </Link>
                                </li>
                              </React.Fragment>
                            </ul>
                          ))}
                      </div>
                      {/* <button onClick={alert(0)}>click</button> */}
                    </div>
                  </Link>
                  {/* </Tippy> */}
                </li>
              ))}
          </ul>
        </aside>
      )}
    </>
  );
}
