import React, { useEffect } from "react";
import coverImage from "../assets/img/coverImage.png";
import Input from "../components/ui/Input";
import Button from "../components/ui/Button";
import { useFormik } from "formik";
import { apiService } from "../api.service";
import toast from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Required"),
    }),
    onSubmit: (values) => {
      console.log(values);
      const payload = {
        tokenpayload: queryParams.get("token"),
        password: values.password,
      };
      apiService
        .updatePassword(payload)
        .then((res) => {
          console.log("sdfsd  res.data.navigateTo: ", res.data);
          toast.success(res.data.message);
          navigate(res.data.data.navigateTo);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    },
  });

  const verifyResetToken = async () => {
    const payload = {
      tokenpayload: queryParams.get("token"),
    };
    await apiService.verifyResetToken(payload).catch((error) => {
      navigate("/error", { state: { message: error.response.data.message } });
    });
  };

  useEffect(() => {
    verifyResetToken();
  }, []);
  return (
    <>
      <div className="flex flex-col w-full items-center overflow-hidden">
        <div className="sticky -z-10 w-full">
          <img
            src={coverImage}
            className=" w-full h-36 object-cover"
            alt="cover_image"
          />
        </div>
        <div className="md:w-1/2 w-4/5 bg-white -mt-16 md:-mt-20 border border-neutral-light rounded mx-2">
          <form onSubmit={formik.handleSubmit}>
            <div>
              <div className="bg-base-white px-6 py-4 border-b border-neutral-light rounded-t">
                <h3 className="text-xl font-semibold">Reset your password</h3>
                <p className="text-neutral-darker mt-1 text-sm">
                  Reset your password to securely regain access to your account
                  and keep your information safe.
                </p>
              </div>
              <div className="px-6 py-4 pb-8">
                <div className="flex flex-col space-y-4">
                  <div className="md:flex flex-col justify-around w-full">
                    <div className="flex flex-col items-center  md:space-y-2 space-y-1">
                      <div className="md:flex flex-col space-x-0">
                        <Input
                          {...formik.getFieldProps("password")}
                          formik={formik}
                          type="text"
                          label="Password"
                          className="md:w-72 w-60"
                          placeholder="Enter password"
                        />
                        <Input
                          {...formik.getFieldProps("confirmPassword")}
                          className={"md:w-72 w-60"}
                          formik={formik}
                          type="text"
                          label="Confirm "
                          placeholder="Enter confirm password"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col  md:space-y-2 space-y-1 items-center">
                    <Button
                      type="submit"
                      label="Reset Password"
                      variant="primary"
                      className="min-w-36 md:w-40 px-3 py-1"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
