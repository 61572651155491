import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useNavigate } from "react-router-dom";
import FileSaver from "file-saver";
import Tippy from "@tippyjs/react";
import {
  Description,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import moment from "moment";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import coverImage from "../../assets/img/coverImage.png";
import Input from "../../components/ui/Input";
import Button from "../../components/ui/Button";
import Select from "../../components/ui/Select";
import File from "../../components/ui/File";
import { apiService } from "../../api.service";
import { service } from "../../service";
import ViewImageDialog from "../../components/ViewImageDialog";
import { useGlobalContext } from "../../context/global.context";
import Textarea from "../../components/ui/Textarea";

export const DealerListings = () => {
  const { setMessage, setError } = useGlobalContext();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [openDialogs, setOpenDialogs] = useState([]);

  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [disableAboutToExpireSelect, setDisableAboutToExpireSelect] =
    useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [activeTabName, setActiveTabName] = useState("");
  const [sorting, setSorting] = React.useState([]);
  const [payload, setPayload] = useState({
    pagination: {
      pageNumber: 1,
      pageSize: 5,
    },
  });
  const [policyNumberToCancel, setPolicyNumberToCancel] = useState();
  const [showDateRangeModal, setShowDateRangeModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cancelPolicyMessage, setCancelPolicyMessage] = useState("");
  const [filterModal, filterdataModal] = useState(false);
  const [noRecordFound, setNoRecordFound] = useState(false);
  const [showCancelPolicyModal, setShowCancelPolicyModal] = useState(false);
  const [showActionModal, setShowActionModal] = useState(false);
  const [docStatusOptions, setDocStatusOptions] = useState([]);
  const [isViewImageOpen, setIsViewImageOpen] = useState(false);
  const [viewImageDialogTitle, setViewImageDialogTitle] = useState("");
  const [viewImageDialogImageUrl, setViewImageDialogImageUrl] = useState("");
  const [dealerData, setDealerData] = useState({});
  const navigate = useNavigate();

  const columnHelper = createColumnHelper();
  const getButtonStyles = (status) => {
    const baseClasses =
      "w-fit px-4 flex items-center justify-center rounded-full font-semibold";
    switch (status) {
      case "Approved":
      case "Active":
        return `${baseClasses} bg-success-lightest text-success capitalize`;
      case "Pending":
      case "Inactive":
        return `${baseClasses} bg-warn-lightest text-warn capitalize`;
      case "Rejected":
        return `${baseClasses} bg-neutral-lightest text-danger`;
      case "Referback":
        return `${baseClasses} bg-neutral-lightest text-neutral-darkest`;
      default:
        return `${baseClasses} capitalize`;
    }
  };

  const dateFilterForm = useFormik({
    initialValues: {
      fromDate: "",
      toDate: "",
    },
    validationSchema: Yup.object().shape({
      fromDate: Yup.date().required("Required"),
      toDate: Yup.date().required("Required"),
    }),
    onSubmit: async (values) => {
      console.log(values);
      setPayload((prev) => ({
        ...prev,
        dates: {
          fromDate: values.fromDate,
          toDate: values.toDate,
        },
      }));
      console.log(payload);
      setShowDateRangeModal(false);
    },
  });

  const actionForm = useFormik({
    initialValues: {
      status: "",
      comments: "",
    },
    validationSchema: Yup.object().shape({
      status: Yup.number().required("Required"),
      comments: Yup.string().when("status", {
        is: (value) => value !== "1" || value !== "2",
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.number().required("Required"),
      }),
    }),
    onSubmit: async (values) => {
      const payload = {
        status: values.status,
        comments: values.comments,
      };
      await apiService
        .updateDealerDocumentsApprovalStatus(payload)
        .then((res) => {
          toast.success(res.data.message);
        })
        .catch((error) => {
          service.notifyApiError(error, setMessage, setError);
        });
      console.log("dd");
    },
  });

  const openDialog = (dialogId) => {
    setOpenDialogs((prev) => [...prev, dialogId]);
  };

  const closeTopDialog = () => {
    setOpenDialogs((prev) => prev.slice(0, -1));
  };

  const downloadExcel = async () => {
    const loadingToast = toast.loading("Downloading Excel file...");

    try {
      const payloadData = {
        ...payload,
        download: true,
      };

      const response = await apiService.dealerTransactionStatement(payloadData);
      console.log(response.data);
      // return
      const byteCharacters = atob(response.data.excelFile);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Use FileSaver to save the file
      FileSaver.saveAs(blob, "myfile.xlsx");

      toast.success("Excel file downloaded successfully");
    } catch (error) {
      console.error("Error downloading the file", error);
      // toast.error("Error downloading the file");
      service.notifyApiError(error, setMessage, setError);
    } finally {
      toast.dismiss(loadingToast);
    }
  };
  const columns = [
    columnHelper.accessor("srNo", {
      accessorKey: "srNo",
      header: "#",
    }),
    columnHelper.accessor("dealerCode", {
      header: "Dealer Code",
    }),
    columnHelper.accessor("dealerName", {
      header: "Dealer Name",
    }),
    columnHelper.accessor("dealerType", {
      header: "Dealer Type",
    }),
    columnHelper.accessor("state", {
      header: "State",
    }),
    columnHelper.accessor("location", {
      header: "Location",
    }),
    columnHelper.accessor("Activated At", {
      header: "Activated At",
    }),
    columnHelper.accessor("lastActive", {
      header: "Last Active",
      cell: (data) => {
        return (
          <span>
            {data.getValue()
              ? moment(data.getValue(), "YYYY-MM-DD HH:mm:ss").format(
                  "Do MMM YYYY, h:mm A"
                )
              : "-"}
          </span>
        );
      },
    }),
    columnHelper.accessor("documents.status", {
      header: "Document Status",
      cell: (data) => {
        const status = data.getValue();
        return <span className={getButtonStyles(status)}>{status}</span>;
      },
    }),
    columnHelper.accessor("status", {
      header: "Dealer Status",
      cell: (data) => {
        const status = data.getValue();
        return <span className={getButtonStyles(status)}>{status}</span>;
      },
    }),
    columnHelper.accessor("action", {
      header: "Action",
      cell: (data) => {
        return (
          <div className="flex justify-center">
            <button
              type="button"
              onClick={() => onClickAction(data.row.original)}
              className="material-symbols-outlined text-sm "
            >
              more_vert
            </button>
          </div>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: filteredData.length > 0 ? filteredData : data,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    //debugTable: true,
    manualPagination: true,
  });

  const totalPages = Math.ceil(totalCount / payload.pagination.pageSize);
  const isLastPage = payload.pagination.pageNumber === totalPages;
  const isFirstPage = payload.pagination.pageNumber === 1;

  const handleFirstPage = () => {
    setPayload((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        pageNumber: 1,
      },
    }));
  };

  const handlePreviousPage = () => {
    setPayload((prev) => {
      const prevPage = prev.pagination.pageNumber - 1;
      return {
        ...prev,
        pagination: {
          ...prev.pagination,
          pageNumber: prevPage < 1 ? 1 : prevPage,
        },
      };
    });
  };

  const handleNextPage = () => {
    setPayload((prev) => {
      const nextPage = prev.pagination.pageNumber + 1;
      return {
        ...prev,
        pagination: {
          ...prev.pagination,
          pageNumber: nextPage > totalPages ? totalPages : nextPage,
        },
      };
    });
  };

  const handleLastPage = () => {
    setPayload((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        pageNumber: totalPages,
      },
    }));
  };

  const onSelectDocStatus = (event) => {
    const value = event.target.value;
    actionForm.setFieldValue("status", value);
  };

  const showDealerListings = async () => {
    try {
      // payload is changed on each scenario and it will call fetchPolicyData
      const response = await apiService.dealerListings(payload);
      console.log(response.data.data);
      setData(response.data.data);
      setTotalCount(response.data.totalCount);
      console.log(data);
    } catch (error) {
      console.error("Error fetching dealer listings:", error);
      toast.error("Error fetching dealer listings");
      service.notifyApiError(error, setMessage, setError);
    }
    // toast.dismiss(loadingToast);
    // setLoading(false);
  };

  const onClickAction = (original) => {
    openDialog("actionModal");
    setDealerData(original);
  };

  const viewImageDialog = (title, fileUrl) => {
    console.log(title, fileUrl, ">>>>>>>>>>>>>>>>>>>>>>>>>>>>");
    openDialog("viewImageModal");
    setIsViewImageOpen(true);
    setViewImageDialogTitle(title);
    setViewImageDialogImageUrl(fileUrl);
  };

  const resetState = () => {
    setSelectedTabIndex(0);
    setData([]);
    setTotalCount(0);
    setFilteredData([]);
    setActiveTabName("");
    setSorting([]);
    setPayload({
      pagination: {
        pageNumber: 1,
        pageSize: 5,
      },
    });

    setShowDateRangeModal(false);
    filterdataModal(false);
    setLoading(true);
    setNoRecordFound(false);

    dateFilterForm.resetForm();
  };

  useEffect(() => {
    showDealerListings();
  }, [payload]);

  useEffect(() => {
    if (data.length == 0) {
      setNoRecordFound(true);
    } else {
      setNoRecordFound(false);
    }
  }, [data]);

  useEffect(() => {
    console.log(isViewImageOpen, "isOpen");
  }, [isViewImageOpen]);

  return (
    <>
      <ViewImageDialog
        isOpen={openDialogs.includes("viewImageModal")}
        title={viewImageDialogTitle}
        imageUrl={viewImageDialogImageUrl}
        close={closeTopDialog}
      />
      <Dialog
        open={openDialogs.includes("actionModal")}
        onClose={closeTopDialog}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/75" aria-hidden="true" />

        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <DialogPanel className=" space-y-4 border bg-white border-neutral rounded w-9/12 md:w-1/2">
            <form onSubmit={actionForm.handleSubmit}>
              <div className="bg-neutral-lightest  border-b border-neutral px-6 py-3 flex">
                <span className="font-bold text-xl py-2 text-neutral-darkest ">
                  Dealer details
                </span>
              </div>
              <div className="flex justify-center">
                <div className="flex md:flex-row md:gap-x-4 px-4 md:px-8 pt-4">
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <div className="w-40 text-sm">Dealer code</div>
                    <div>{dealerData.dealerCode}</div>
                    <div className="text-sm">Dealer name</div>
                    <div>{dealerData.dealerName}</div>
                    <div className="text-sm">Dealer type</div>
                    <div>{dealerData.dealerType}</div>
                    <div className="text-sm">Document status</div>
                    <div
                      className={`text-sm ${getButtonStyles(
                        dealerData?.documents?.status
                      )}`}
                    >
                      {dealerData?.documents?.status}
                    </div>
                    <div className="text-sm ">Dealer status</div>
                    <div
                      className={`text-sm ${getButtonStyles(
                        dealerData.status
                      )}`}
                    >
                      {dealerData.status}
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <div className="w-40 text-sm">Agreement </div>
                    {dealerData.documents?.agreement ? (
                      <div>
                        <button
                          type="button"
                          className="underline text-sm"
                          onClick={() =>
                            viewImageDialog(
                              "Agreement",
                              dealerData.documents?.agreement
                            )
                          }
                        >
                          View
                        </button>
                      </div>
                    ) : (
                      "Not uploaded"
                    )}
                    <div className="text-sm">Pan </div>
                    {dealerData.documents?.agreement ? (
                      <div>
                        <button
                          type="button"
                          className="underline text-sm"
                          onClick={() =>
                            viewImageDialog("PAN", dealerData.documents?.pan)
                          }
                        >
                          View
                        </button>
                      </div>
                    ) : (
                      "Not uploaded"
                    )}
                    <div className="text-sm">Gst </div>
                    {dealerData.documents?.agreement ? (
                      <div>
                        <button
                          type="button"
                          className="underline text-sm"
                          onClick={() =>
                            viewImageDialog(
                              "GST Certificate",
                              dealerData.documents?.gstCertificate
                            )
                          }
                        >
                          View
                        </button>
                      </div>
                    ) : (
                      "Not uploaded"
                    )}
                    <div className="text-sm">Cancelled cheque </div>
                    {dealerData.documents?.agreement ? (
                      <div>
                        <button
                          type="button"
                          className="underline text-sm"
                          onClick={() =>
                            viewImageDialog(
                              "Cancel cheque",
                              dealerData.documents?.cancelCheque
                            )
                          }
                        >
                          View
                        </button>
                      </div>
                    ) : (
                      "Not uploaded"
                    )}
                  </div>
                </div>
              </div>
              {dealerData.documents?.status == "Pending" && (
                <div className="flex justify-center items-start px-8 mt-4">
                  <div className="flex flex-col">
                    <Select
                      {...actionForm.getFieldProps("status")}
                      formik={actionForm}
                      value={actionForm.values.status}
                      id="status"
                      name="status"
                      label="Status"
                      onSelect={onSelectDocStatus}
                      options={docStatusOptions}
                      className="mr-4 flex-col w-full md:w-60"
                      onChange={actionForm.handleChange}
                    />
                  </div>
                  <div className="flex flex-col">
                    <Textarea
                      {...actionForm.getFieldProps("comments")}
                      formik={actionForm}
                      id="comments"
                      name="comments"
                      type="text"
                      label="Comments"
                      placeholder="Enter comments"
                      className="border border-gray-300 rounded-lg p-2 w-full"
                    />
                  </div>
                </div>
              )}

              <div className="flex items-center justify-center gap-4 mt-4 px-12 pb-8">
                <Button
                  type="button"
                  label="Cancel"
                  variant="ghost"
                  className="w-full md:w-auto"
                  onClick={closeTopDialog}
                />
                {dealerData.documents?.status == "Pending" ? (
                  <Button
                    type="submit"
                    label="Update"
                    variant="primary"
                    className="w-full md:w-auto"
                  />
                ) : (
                  <Button
                    type="button"
                    label="Ok"
                    variant="primary"
                    className="w-full md:w-auto"
                    onClick={closeTopDialog}
                  />
                )}
              </div>
            </form>
          </DialogPanel>
        </div>
      </Dialog>
      <Dialog
        open={showDateRangeModal}
        onClose={() => setShowDateRangeModal(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/75" aria-hidden="true" />

        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <DialogPanel className=" space-y-4 border bg-white border-neutral rounded">
            <form onSubmit={dateFilterForm.handleSubmit}>
              <div className="bg-neutral-lightest  border-b border-neutral px-6 py-3 flex">
                <span className="font-bold text-xl py-2 text-neutral-darkest ">
                  Custom Date
                </span>
              </div>
              <div className="flex flex-col md:flex-row md:gap-x-4 px-4 md:px-8 pt-4">
                <div className="flex flex-col mb-4 md:mb-0">
                  <Input
                    {...dateFilterForm.getFieldProps("fromDate")}
                    formik={dateFilterForm}
                    id="fromDate"
                    name="fromDate"
                    type="date"
                    max={dateFilterForm.getFieldProps("toDate").value}
                    label="From Date"
                    className="w-full md:w-60"
                  />
                </div>
                <div className="flex flex-col">
                  <Input
                    {...dateFilterForm.getFieldProps("toDate")}
                    formik={dateFilterForm}
                    id="toDate"
                    name="toDate"
                    type="date"
                    min={dateFilterForm.getFieldProps("fromDate").value}
                    label="To Date"
                    className="w-full md:w-60"
                  />
                </div>
              </div>

              <div className="flex items-center justify-center gap-4 mt-4 px-12 pb-8">
                <Button
                  type="button"
                  label="Cancel"
                  variant="ghost"
                  className="w-full md:w-auto"
                  onClick={() => setShowDateRangeModal(false)}
                />
                <Button
                  type="submit"
                  label="Submit"
                  variant="primary"
                  className="w-full md:w-auto"
                />
              </div>
            </form>
            {cancelPolicyMessage && (
              <div className="mt-4">
                <p>{cancelPolicyMessage}</p>
              </div>
            )}
          </DialogPanel>
        </div>
      </Dialog>
      <Dialog
        open={filterModal}
        onClose={() => filterdataModal(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/75" aria-hidden="true" />

        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <DialogPanel className=" space-y-4 border bg-white border-neutral rounded">
            <form onSubmit={dateFilterForm.handleSubmit}>
              <div className="bg-neutral-lightest  border-b border-neutral px-6 py-3 flex">
                <span className="font-bold text-xl py-2 text-neutral-darkest ">
                  Filter
                </span>
              </div>
              <div className="flex flex-col gap-y-4 p-4">
                <select id="searchField" className="mr-2 text-neutral-darkest">
                  <option value="employee_code">Dealer Code</option>
                  <option value="plan_name">Plan</option>
                  <option value="model_name">Model</option>
                  <option value="engine_no">Engine No.</option>
                  <option value="chassis_no">Chassis No.</option>
                  <option value="sold_policy_no">Policy Number</option>
                  <option value="model_name">Model</option>
                </select>

                <div className="relative flex items-center w-full md:w-auto">
                  <input
                    type="text"
                    id="search"
                    placeholder="Search..."
                    className="px-3 py-1 border border-neutral-dark rounded-l-sm focus:outline-primary text-base-black w-full md:w-auto"
                  />
                  <button
                    type="button"
                    onClick={() => {
                      setPayload((prev) => ({
                        ...prev,
                        search: {
                          field: document.getElementById("searchField").value,
                          value: document.getElementById("search").value,
                        },
                      }));
                    }}
                    className="bg-primary text-white border border-primary px-2 py-1  rounded-r-sm"
                  >
                    <span className="material-symbols-outlined flex items-center justify-center p-0">
                      search
                    </span>
                  </button>
                </div>
              </div>

              <div className="flex items-center justify-center gap-4 mt-4 px-12 pb-8">
                <Button
                  type="button"
                  label="Cancel"
                  variant="ghost"
                  className="max-w-12"
                  onClick={() => filterdataModal(false)}
                />
                <Button
                  type="submit"
                  label="Submit"
                  variant="primary"
                  className="max-w-16"
                />
              </div>
            </form>
          </DialogPanel>
        </div>
      </Dialog>

      <div className="flex flex-col w-full items-center overflow-hidden">
        <div className="sticky -z-10 w-full">
          <img
            src={coverImage}
            className=" w-full h-36 object-cover"
            alt="cover_image"
          />
        </div>
        <div className="w-11/12 bg-white -mt-28 md:-mt-20 border border-neutral-light rounded mx-2">
          <form>
            <div>
              <div className="bg-base-white px-6 py-4 border-b border-neutral-light rounded-t">
                <h3 className="text-xl font-semibold">
                  Dealer Listings {loading}
                </h3>
                <p className="text-neutral-darker mt-1 text-sm">
                  A brief snapshot of your financial activity, showing incoming
                  and outgoing transactions
                </p>
              </div>
              <div className="px-6 py-4 pb-8">
                <div>
                  <Tabs
                    selectedIndex={selectedTabIndex}
                    selectedTabClassName="text-primary border-b-2 border-primary font-medium"
                  >
                    <TabList className="flex mb-4 text-neutral-dark text-sm items-center justify-between">
                      <div className="flex">
                        {/* <Tab className="uppercase px-4 pb-2 active:text-secondary-darkest cursor-pointer focus:outline-none">
                          Monthly Bank Statement
                        </Tab> */}
                        {/* <Tab className="uppercase px-4 pb-2 cursor-pointer focus:outline-none">
                          Commission Bank
                        </Tab> */}
                      </div>
                      <div className="md:flex flex-col md:flex-row justify-between items-center w-full">
                        <div className="flex items-center md:gap-x-4 gap-0 mb-2 md:mb-0">
                          <div className="flex">
                            <input
                              type="checkbox"
                              onChange={(e) => {
                                setPayload((prev) => ({
                                  ...prev,
                                  isToday: e.target.checked ? true : false,
                                }));
                              }}
                            />
                            <span className="mr-4 ml-1 text-neutral-darkest">
                              Today's Logged In Dealers
                            </span>
                          </div>

                          <div className="flex flex-col text-xs">
                            <label className="pl-1 ">Status</label>
                            <select
                              className="mr-2 text-neutral-darkest"
                              onChange={(e) => {
                                // const statusId = Number(e.target.value);
                                let statusId;
                                if (e.target.value === "All") {
                                  statusId = "All";
                                } else {
                                  statusId = Number(e.target.value);
                                }
                                setPayload((prev) => ({
                                  ...prev,
                                  statusId: statusId,
                                }));
                                console.log(payload);
                              }}
                            >
                              {/* <option value="" selected>
                                Select Dealers
                              </option> */}
                              <option value={"All"}>All</option>
                              <option value={1}>Active</option>
                              <option value={0}>Inactive</option>
                            </select>
                          </div>
                        </div>
                        <div className="md:flex flex-col md:flex-row items-center  gap-2">
                          <div className=" space-x-4">
                            <button
                              type="button"
                              className="mt-1"
                              onClick={() => {
                                resetState();
                              }}
                            >
                              <span class="material-symbols-outlined text-neutral-darkest">
                                refresh
                              </span>
                            </button>

                            <button
                              type="button"
                              className="mt-1"
                              onClick={() => {
                                setShowDateRangeModal(true);
                              }}
                            >
                              <span class="material-symbols-outlined text-neutral-darkest">
                                date_range
                              </span>
                            </button>

                            {
                              <button
                                type="button"
                                className="mt-1"
                                onClick={downloadExcel}
                              >
                                <span class="material-symbols-outlined text-neutral-darkest">
                                  download
                                </span>
                              </button>
                            }
                            <button
                              type="button"
                              className="mt-1 md:hidden"
                              onClick={() => {
                                filterdataModal(true);
                              }}
                            >
                              <span className="material-symbols-outlined text-neutral-darkest justify-end">
                                filter_alt
                              </span>
                            </button>
                          </div>
                          <div className="flex items-center hidden md:flex  ">
                            <select
                              id="searchField"
                              className="mr-2 text-neutral-darkest"
                            >
                              <option value="dealer_code">Dealer Code</option>
                              <option value="dealer_name">Dealer Name</option>
                              <option value="dealer_type_name">
                                Dealer Type
                              </option>
                            </select>
                            <input
                              type="text"
                              id="search"
                              placeholder="Search..."
                              className="px-3 py-1 border border-neutral-dark rounded-l-sm focus:outline-primary text-base-black w-full md:w-60"
                            />
                            <button
                              type="button"
                              onClick={() => {
                                setPayload((prev) => ({
                                  ...prev,
                                  search: {
                                    field:
                                      document.getElementById("searchField")
                                        .value,
                                    value:
                                      document.getElementById("search").value,
                                  },
                                }));
                              }}
                              className="bg-primary text-white border border-primary px-2 py-0.5 rounded-r-sm"
                            >
                              <span class="material-symbols-outlined flex items-center justify-center">
                                search
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </TabList>

                    <TabPanel>
                      <div className="flex mb-4 overflow-x-scroll cursor-pointer">
                        <table
                          id="table-to-xls"
                          className="text-sm border border-neutral w-full"
                        >
                          <thead className="bg-primary text-white text-xs">
                            {table.getHeaderGroups().map((headerGroup) => (
                              <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                  <th
                                    key={header.id}
                                    className={
                                      header.column.id === "action"
                                        ? "px-2 py-1 text-center"
                                        : "px-2 py-1 text-left"
                                    } // Apply specific CSS class to the "Age" header
                                  >
                                    {!header.isPlaceholder &&
                                      header.column.columnDef.header}{" "}
                                  </th>
                                ))}
                              </tr>
                            ))}
                          </thead>

                          <tbody>
                            {noRecordFound ? (
                              <tr className="min-h-20">
                                <td
                                  colSpan={columns.length}
                                  className="text-center p-4 min-h-20"
                                >
                                  No records found
                                </td>
                              </tr>
                            ) : (
                              table.getRowModel().rows.map((row) => (
                                <tr
                                  key={row.id}
                                  className="text-xs even:bg-primary-lightest"
                                >
                                  {row.getVisibleCells().map((cell) => (
                                    <td key={cell.id} className="px-2 py-1">
                                      {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                      )}
                                    </td>
                                  ))}
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>

                      <div className="flex flex-col md:flex-row items-center w-full justify-end text-xs gap-4">
                        <div className="flex items-center gap-2">
                          {" "}
                          <select
                            value={payload.pageSize}
                            onChange={(e) => {
                              const size = Number(e.target.value);
                              setPayload((prev) => ({
                                ...prev,
                                pagination: {
                                  pageSize: size,
                                  pageNumber: 1,
                                },
                              }));
                            }}
                          >
                            {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map(
                              (pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                  {pageSize}
                                </option>
                              )
                            )}
                          </select>
                          <span className="font-semibold">
                            <span>Showing </span>
                            {1 +
                              (payload.pagination.pageNumber - 1) *
                                payload.pagination.pageSize}{" "}
                            to{" "}
                            {payload.pagination.pageNumber *
                              payload.pagination.pageSize -
                              payload.pagination.pageSize +
                              data?.length}{" "}
                            of {totalCount} Entries
                          </span>
                        </div>
                        {/* <button type="button" disabled={isFirstPage} onClick={handleFirstPage}>
                          <span className="material-symbols-outlined">
                            first_page
                          </span>
                        </button> */}
                        <div className="flex items-center space-x-2 mt-2 md:mt-0">
                          <button
                            type="button"
                            disabled={isFirstPage}
                            onClick={handleFirstPage}
                            className={`material-symbols-outlined ${
                              isFirstPage
                                ? "cursor-not-allowed text-neutral-light"
                                : "cursor-pointer"
                            }`}
                          >
                            first_page
                          </button>
                          {/* <button type="button" onClick={handlePreviousPage} disabled={isFirstPage}>
                          <span class="material-symbols-outlined">
                            chevron_left
                          </span>
                        </button> */}
                          <button
                            type="button"
                            onClick={handlePreviousPage}
                            disabled={isFirstPage}
                            className={`material-symbols-outlined ${
                              isFirstPage
                                ? "cursor-not-allowed text-neutral-light"
                                : "cursor-pointer"
                            }`}
                          >
                            chevron_left
                          </button>
                          {/* <button type="button" onClick={handleNextPage} disabled={isLastPage}>
                          <span class="material-symbols-outlined">
                            chevron_right
                          </span>
                        </button> */}
                          <button
                            type="button"
                            onClick={handleNextPage}
                            disabled={isLastPage}
                            className={`material-symbols-outlined ${
                              isLastPage
                                ? "cursor-not-allowed text-neutral-light"
                                : "cursor-pointer"
                            }`}
                          >
                            chevron_right
                          </button>
                          {/* <button type="button" className="disabled:text-neutral-light" onClick={handleLastPage} disabled={isLastPage}>
                          <span className="material-symbols-outlined text-inherit">
                            last_page
                          </span>
                        </button> */}
                          <button
                            type="button"
                            className={`material-symbols-outlined ${
                              isLastPage
                                ? "cursor-not-allowed text-neutral-light"
                                : "cursor-pointer"
                            }`}
                            onClick={handleLastPage}
                            disabled={isLastPage}
                          >
                            last_page
                          </button>
                        </div>
                      </div>
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
