// src/services/loadingBarService.js
import React, { useRef, useEffect } from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import LoadingBar from 'react-top-loading-bar';

let loadingBarRef = null; // Reference to the LoadingBar

// Function to render and initialize the LoadingBar component
const initializeLoadingBar = () => {
  const LoadingBarComponent = () => {
    const ref = useRef(null);

    useEffect(() => {
      // Store the ref to be used by the service methods
      loadingBarRef = ref;
    }, []);

    return <LoadingBar color='#f11946' ref={ref} />;
  };

  // Create a container element for the LoadingBar component
  const container = document.createElement('div');
  document.body.appendChild(container);

  // Render the LoadingBar component into the container
  // ReactDOM.render(<LoadingBarComponent />, container);

  const root = createRoot(container);
  root.render(<LoadingBarComponent />);
};

// Initialize the LoadingBar on import
initializeLoadingBar();

// Define methods to control the LoadingBar
const start = () => {
  if (loadingBarRef && loadingBarRef.current) {
    loadingBarRef.current.continuousStart();
  }
};

const complete = () => {
  if (loadingBarRef && loadingBarRef.current) {
    loadingBarRef.current.complete();
  }
};

const setProgress = (value) => {
  if (loadingBarRef && loadingBarRef.current) {
    loadingBarRef.current.setProgress(value);
  }
};

// Export the service object with the methods
const loadingBarService = {
  start,
  complete,
  setProgress,
};

export default loadingBarService;
