import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
const Textarea = React.forwardRef(
  (
    {
      id,
      name,
      type,
      value,
      formik,
      label,
      placeholder,
      className,
      onInputChange,
      onInputKeyDown,
      onBlur,
      min,
      max,
      readOnly,
      disabled,
      onFocus,
      minLength,
      maxLength,
      autocomplete,
      hideErrorMessage,
    },
    ref
  ) => {
    const handleChange = (event) => {
      if (onInputChange) {
        onInputChange(event);
      } else if (formik && formik.handleChange) {
        formik.handleChange(event);
      }
    };
    const handleBlur = (event) => {
      if (onBlur) {
        onBlur(event);
      }

      if (formik && formik.handleBlur) {
        formik.handleBlur(event);
      }
    };

    const classes = classNames(
      "px-3 py-1 placeholder-neutral-dark border border-neutral-dark rounded-sm w-65 focus:outline-none focus:border focus:border-primary",
      className
    );

    return (
      <div className="flex flex-col">
        {label && (
          <label
            htmlFor={id}
            className="w-fit text-sm text-neutral-darker mb-0.5"
          >
            {label}
          </label>
        )}
        <textarea
          id={id}
          name={name}
          className={classes}
          type={type}
          min={min}
          max={max}
          value={formik?.values[name] || value}
          placeholder={placeholder}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={handleBlur}
          onKeyDown={onInputKeyDown}
          readOnly={readOnly}
          disabled={disabled}
          minLength={minLength}
          maxLength={maxLength}
          ref={ref}
          autoComplete={autocomplete}
        />
        {!hideErrorMessage && (
          <div className="text-danger text-xs mt-1">
            {formik?.touched[name] && formik.errors[name] ? (
              <div>{formik.errors[name]}</div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
);

Textarea.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf(["text", "password", "email", "date"]).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onInputChange: PropTypes.func,
};

Textarea.defaultProps = {
  type: "text",
  placeholder: "Type something...",
};

export default Textarea;
