import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
const Input = React.forwardRef(
  (
    {
      id,
      name,
      type,
      value,
      formik,
      label,
      placeholder,
      className,
      onInputChange,
      onInputKeyDown,
      onBlur,
      min,
      max,
      readOnly,
      disabled,
      onFocus,
      minLength,
      maxLength,
      autocomplete,
      hideErrorMessage,
    },
    ref
  ) => {
    const handleChange = (event) => {
      if (onInputChange) {
        onInputChange(event);
      } else if (formik && formik.handleChange) {
        formik.handleChange(event);
      }
    };
    const handleBlur = (event) => {
      if (onBlur) {
        onBlur(event);
      }

      if (formik && formik.handleBlur) {
        formik.handleBlur(event);
      }
    };

    const classes = classNames(
      "px-3 py-1 placeholder-neutral-dark border border-neutral-dark rounded-sm w-65 focus:outline-none focus:border focus:border-primary",
      className,
      readOnly ? "bg-neutral-100 cursor-not-allowed" : ""
    );

    return (
      <div className="flex flex-col">
        {label && (
          <label
            htmlFor={id}
            className="w-fit text-sm text-neutral-darker mb-0.5"
          >
            {label}
          </label>
        )}
        <input
          id={id}
          name={name}
          className={classes}
          type={type}
          min={min}
          max={max}
          value={formik?.values[name] || value}
          placeholder={placeholder}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={handleBlur}
          onKeyDown={onInputKeyDown}
          readOnly={readOnly}
          disabled={disabled}
          minLength={minLength}
          maxLength={maxLength}
          ref={ref}
          autoComplete={autocomplete}
        />
        {!hideErrorMessage && (
          <div className="text-danger text-xs mt-1">
            {formik?.touched[name] && formik.errors[name] ? (
              <div>{formik.errors[name]}</div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
);

Input.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf(["text", "password", "email", "date"]).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onInputChange: PropTypes.func,
};

Input.defaultProps = {
  type: "text",
  placeholder: "Type something...",
};

export default Input;

// import React from "react";
// import PropTypes from "prop-types";
// import classNames from "classnames";

// const Input = ({
//   id,
//   name,
//   type,
//   value,
//   formik,
//   label,
//   placeholder,
//   className,
//   onInputChange,
//   onInputKeyDown,
//   min,
//   max,
//   readOnly,
//   onFocus,
//   minLength,
//   maxLength,
//   autocomplete,
//   ref
// }) => {
//   const handleChange = (event) => {
//     if (onInputChange) {
//       onInputChange(event);
//     } else {
//       formik.handleChange(event);
//     }
//   };

//   const classes = classNames(
//     "px-3 py-1 placeholder-neutral-dark border border-neutral-dark rounded-sm min-w-60 focus:outline-none focus:border focus:border-primary",
//     className
//   );
//   return (
//     <div className="flex flex-col">
//       <label htmlFor={id} className="w-fit text-sm text-neutral-darker mb-0.5">
//         {label}
//       </label>
//       <input
//         id={id}
//         name={name}
//         className={classes}
//         type={type}
//         min={min}
//         max={max}
//         value={formik.values[name]}
//         placeholder={placeholder}
//         onChange={handleChange}
//         onFocus={onFocus}
//         onBlur={formik.handleBlur}
//         onKeyDown={onInputKeyDown}
//         readOnly={readOnly ? true : false}
//         minLength={minLength}
//         maxLength={maxLength}
//         ref={ref}
//         autoComplete={autocomplete}
//       />
//       <div className="text-danger text-xs mt-1">
//         {formik.touched[name] && formik.errors[name] ? (
//           <div>{formik.errors[name]}</div>
//         ) : null}
//       </div>
//     </div>
//   );
// };

// Input.propTypes = {
//   id: PropTypes.string,
//   type: PropTypes.oneOf(["text", "password", "email", "date"]).isRequired,
//   label: PropTypes.string,
//   placeholder: PropTypes.string,
//   className: PropTypes.string,
//   onInputChange: PropTypes.func,
// };

// Input.defaultProps = {
//   type: "text",
//   placeholder: "Type something...",
// };

// export default Input;
