import React, { useState, useId, useEffect, forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Modal from "react-modal";
import toast, { Toaster } from "react-hot-toast";
import Button from "./Button";
Modal.setAppElement("#root");

const File = forwardRef(({
  formik,
  id,
  name,
  label,
  className,
  icon,
  onFileChange,
  accept,
  url,
}, ref) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const fileId = useId()
  useImperativeHandle(ref, () => ({
    resetFile() {
      setSelectedFile(null);
      onFileChange(name, null);
    }
  }));
  const openModal = () => {
    if (selectedFile || url) {
      setModalIsOpen(true);
    } else {
      toast.error("No file chosen");
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    onFileChange(name, file);
  };

  const extractFileName = (url) => {
    if (url) {
      let parts = url.split("/");
      let fileName = parts[parts.length - 1];
      return fileName;
    } else {
      return "";
    }
  };
  const onFileClick = () => {
    let fileInput = document.getElementById(id || fileId)
    fileInput.click()
  }

  const classes = classNames(
    "block w-full text-sm rounded-sm cursor-pointer  focus:outline-none ",
    className
  );
  return (
    <div className="flex flex-col">
      <label htmlFor={id} className="w-60 text-sm text-neutral-darker mb-0.5">
        {label}
      </label>
      <div className="flex">
        <input
          id={id || fileId}
          name={name}
          type="file"
          onChange={handleFileChange}
        onBlur={formik.handleBlur}

          accept={accept}
          hidden
        />
        <button type="button" className="w-fit text-primary text-sm" variant="ghost" onClick={onFileClick}>Choose file</button>
        <span className="ml-2 mt-2 w-24 truncate text-xs ">{selectedFile?.name || extractFileName(url) || "No file chosen"}</span>
        <button type="button" onClick={openModal}>
          <i className="material-symbols-outlined">{icon}</i>
        </button>
      </div>
      <div className="text-danger text-xs mt-1">
        {formik.touched[name] && formik.errors[name] ? (
          <div>{formik.errors[name]}</div>
        ) : null}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        {
          <div>
            <div className="flex justify-end">
              <button type="button" onClick={closeModal}>
                <i className="material-symbols-outlined text-sm">close</i>
              </button>
            </div>
            <iframe
              src={selectedFile ? URL.createObjectURL(selectedFile) : url}
              type="application/pdf"
              width="100%"
              height="440px"
            />
          </div>
        }
      </Modal>
    </div>
  );
});

File.propTypes = {
  id: PropTypes.string,
  // type: PropTypes.oneOf(["text", "password", "email", "file"]).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onInputChange: PropTypes.func,
};

File.defaultProps = {
  type: "text",
  placeholder: "Type something...",
};

export default File;
