import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import coverImage from "../../assets/img/coverImage.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../../components/ui/Input";
import { apiService } from "../../api.service";
import Button from "../../components/ui/Button";
import FileSaver from "file-saver";
import { service } from "../../service";
import { useGlobalContext } from "../../context/global.context";
export const FeedFile = () => {
  const { setMessage, setError } = useGlobalContext();

  const [payload, setPayload] = useState({
    pagination: {
      pageNumber: 1,
      pageSize: 5,
    },
    search: "",
  });

  const [showDateRangeModal, setShowDateRangeModal] = useState(false);
  const dateFilterForm = useFormik({
    initialValues: {
      fromDate: "",
      toDate: "",
    },
    validationSchema: Yup.object().shape({
      fromDate: Yup.date().required("Required"),
      toDate: Yup.date().required("Required"),
    }),
    onSubmit: async (values) => {
      console.log(values);
      setPayload((prev) => ({
        ...prev,
        fromDate: values.fromDate,
        toDate: values.toDate,
      }));
      console.log(payload);
    },
  });

  const downloadExcel = async () => {
    const loadingToast = toast.loading("Downloading Excel file...");
    try {
      const payloadData = {
        ...payload,
        download: true,
      };

      const response = await apiService.downloadFeedFile(payloadData);
      console.log(response.data);
      // return
      const byteCharacters = atob(response.data.excelFile);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Use FileSaver to save the file
      FileSaver.saveAs(blob, "myfile.xlsx");

      toast.success("Excel file downloaded successfully");
    } catch (error) {
      console.error("Error downloading the file", error);
      toast.error("Error downloading the file");
      service.notifyApiError(error, setMessage, setError);
    } finally {
      toast.dismiss(loadingToast);
    }
  };

  return (
    <>
      <div className="flex flex-col w-full items-center overflow-hidden">
        <div className="sticky -z-10 w-full">
          <img
            src={coverImage}
            className=" w-full h-36 object-cover"
            alt="cover_image"
          />
        </div>
        <div className="w-11/12 bg-white -mt-28 md:-mt-20 border border-neutral-light rounded mx-2">
          <form>
            <div>
              <div className="bg-base-white px-6 py-4 border-b border-neutral-light rounded-t">
                <h3 className="text-xl font-semibold">Feed File</h3>
                <p className="text-neutral-darker mt-1 text-sm">
                  A brief snapshot of your financial activity, showing incoming
                  and outgoing transactions
                </p>
              </div>
            </div>
          </form>
          <form onSubmit={dateFilterForm.handleSubmit}>
            <div className="flex flex-col md:flex-row gap-x-4 p-8 pt-4 items-center">
              <div className="flex flex-col mb-4 md:mb-0">
                <Input
                  {...dateFilterForm.getFieldProps("fromDate")}
                  formik={dateFilterForm}
                  id="fromDate"
                  name="fromDate"
                  type="date"
                  max={dateFilterForm.getFieldProps("toDate").value}
                  label="From Date"
                  className="w-full md:w-60"
                />
              </div>
              <div className="flex flex-col">
                <Input
                  {...dateFilterForm.getFieldProps("toDate")}
                  formik={dateFilterForm}
                  id="toDate"
                  name="toDate"
                  type="date"
                  min={dateFilterForm.getFieldProps("fromDate").value}
                  label="To Date"
                  className="w-full md:w-60"
                />
              </div>
              <div className="flex mt-4">
                <button
                  type="button"
                  onClick={downloadExcel}
                  className="flex items-center bg-primary text-white active:bg-primary-darkest text-sm px-3 py-1 rounded-sm space-x-2"
                >
                  <span class="material-symbols-outlined flex items-center justify-center">
                    download
                  </span>
                  <span>Download</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
