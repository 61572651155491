/* eslint-disable no-eval */

import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_ENCRYPTION_SECRET_KEY; 
const cbcsecretKey = process.env.REACT_APP_CBC_ENCRYPTION_SECRET_KEY;
const IV= process.env.REACT_APP_ENCRYPTION_IV


console.log(secretKey,cbcsecretKey,IV,'REACT_APP_REACT_APP_REACT_APP_')
// Function to generate a random IV
function generateRandomIV() {
    return CryptoJS.lib.WordArray.random(16); // 16 bytes = 128 bits (required for AES CBC mode)
}

// Encrypt data using AES (ECB mode)
export function encryptData(data) {
    const jsonData = JSON.stringify(data);
    const encrypted = CryptoJS.AES.encrypt(jsonData, secretKey).toString();
    return encrypted;
}

// Encrypt data using AES (CBC mode) with a random IV
export function encryptcbc(data) {
    const jsonData = JSON.stringify(data);
    
    // Generate a random IV for AES CBC encryption
    const iv = IV;

    const encrypted = CryptoJS.AES.encrypt(jsonData, cbcsecretKey, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    }).toString();
    
    return {
        encryptedData: encrypted,
        iv: iv.toString(CryptoJS.enc.Base64) // Return IV in Base64 for storage or transmission
    };
}

export function finalEncryption(data) {
    const encryptedData = encryptData(data); // First AES encryption
    const obfuscatedData = encryptcbc({ encryptedData }); // Encrypt the result with CBC mode
    const encryptedData1 = encryptData(obfuscatedData); // Encrypt the final result with AES (ECB)

    return encryptedData1;
}

// Decrypt data using AES (ECB mode)
export function decryptData(encryptedData) {
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        const decrypted = bytes.toString(CryptoJS.enc.Utf8);
        
        // Check if decryption was successful
        if (!decrypted) {
            throw new Error("Decryption failed");
        }
        
        return JSON.parse(decrypted);
    } catch (error) {
        // If decryption fails, return the original encrypted data
        console.error("Decryption error:", error);
        return encryptedData;
    }
}

export function decryptTokenData(encryptedData) {
    try {
        // Decrypt the data using AES
        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        const decrypted = bytes.toString(CryptoJS.enc.Utf8);
        
        // Check if decryption was successful (non-empty result)
        if (!decrypted) {
            throw new Error("Decryption failed");
        }

        // Return the decrypted string if successful
        return decrypted.slice(1,-1);
    } catch (error) {
        // Log the error and return the original encrypted data
        console.error("Decryption error:", error);
        return encryptedData;
    }
}


export function decryptcbc(encryptedData, ivBase64) {
    try {
        const iv = CryptoJS.enc.Base64.parse(ivBase64); 

        const decrypted = CryptoJS.AES.decrypt(encryptedData, cbcsecretKey, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        const jsonData = decrypted.toString(CryptoJS.enc.Utf8); 

        return JSON.parse(jsonData);
    } catch (error) {
        console.error("Decryption error:", error);
        return encryptedData;
    }
}

export function finalDecryption(base64ObfuscatedData) {
    const obfuscatedData = decryptData(base64ObfuscatedData);

    console.log(obfuscatedData, 'finalDecryptionfinalDecryption');

    const { encryptedData, iv } = obfuscatedData;

    const res = decryptcbc(encryptedData, iv);

    let data;
    try {
        data = JSON.parse(decryptData(res.encryptedData));
    } catch (error) {
        // If JSON parsing fails, return the original decrypted data as a string
        console.error('JSON parse error:', error);
        data = decryptData(res?.encryptedData);
    }

    return data;
}


export const encryptResponseMiddleware = (req, res, next) => {


    
    // Store the original send method
    const originalSend = res.send.bind(res);

    // Override the res.send method
    res.send = (body) => {
        // Encrypt the response body

        console.log(req.user,'>>>>>>>>>>>>>>>>>>')

        if (req.user && req.user.isEncrypted) {

        const encryptedData = finalEncryption(body);
    
        return originalSend(encryptedData);
    }



        // Call the original send method with the encrypted data
        return originalSend(body);
    };

    next();
};
