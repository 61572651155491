import React from "react";
import coverImage from "../assets/img/coverImage.png";
import Input from "../components/ui/Input";
import Button from "../components/ui/Button";
import { useFormik } from "formik";
import { apiService } from "../api.service";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const SendOTP = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      mobile: "",
      email: "",
    },
    onSubmit: (values) => {
      console.log(values);
      const payload = {
        mobile: values.mobile,
        email: values.email,
      };
      const loadingToast = toast.loading("Sending OTP...");
      apiService
        .sendOTP(payload)
        .then((res) => {
          console.log("sdfsd  res.data.navigateTo: ", res.data);
          toast.success(res.data.message);
          navigate(res.data.data.navigateTo);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        })
        .finally(() => {
          toast.dismiss(loadingToast);
        });
    },
  });
  return (
    <>
      <div className="flex flex-col w-full items-center overflow-hidden">
        <div className="sticky -z-10 w-full">
          <img
            src={coverImage}
            className=" w-full h-36 object-cover"
            alt="cover_image"
          />
        </div>
        <div className="md:w-1/2 w-4/5 bg-white -mt-16 md:-mt-20 border border-neutral-light rounded mx-2">
          <form onSubmit={formik.handleSubmit}>
            <div>
              <div className="bg-base-white px-6 py-4 border-b border-neutral-light rounded-t">
                <h3 className="text-xl font-semibold">
                  Two-Factor Authentication
                </h3>
                <p className="text-neutral-darker mt-1 text-sm">
                  You'll receive a verification code via email and SMS, ensuring
                  that only you can access your account.
                </p>
              </div>
              <div className="px-6 py-4 pb-8">
                <div className="flex flex-col space-y-4">
                  <div className="md:flex flex-col justify-around w-full">
                    <div className="flex flex-col items-center  md:space-y-2 space-y-1">
                      <div className="md:flex flex-col space-x-0">
                        <Input
                          {...formik.getFieldProps("mobile")}
                          formik={formik}
                          type="text"
                          label="Mobile Number"
                          className="md:w-72 w-60"
                          placeholder="Enter mobile number"
                        />
                        <Input
                          {...formik.getFieldProps("email")}
                          className={"md:w-72 w-60"}
                          formik={formik}
                          type="text"
                          label="Email"
                          placeholder="Enter email id"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col  md:space-y-2 space-y-1 items-center">
                    <Button
                      type="submit"
                      label="Send OTP"
                      variant="primary"
                      className="min-w-36 md:w-40 px-3 py-1"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SendOTP;
