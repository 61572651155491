import React, { useState, useEffect } from "react";
import coverImage from "../../assets/img/coverImage.png";
import { useFormik } from "formik";
import Input from "../../components/ui/Input";
import Button from "../../components/ui/Button";
import Select from "../../components/ui/Select";
import { apiService } from "../../api.service";
import { service } from "../../service";
import * as Yup from "yup";
import { toast } from "react-hot-toast";

import { useGlobalContext } from "../../context/global.context";

export const AddDealer = () => {
  const { setMessage, setError } = useGlobalContext();
  const [companyTypeOptions, setCompanyTypeOptions] = useState([]);
  const [rsaIcOptions, setRsaIcOptions] = useState([]);
  const [accountTypeOptions, setAccountTypeOptions] = useState([]);

  const registerDealerFormValidationSchema = Yup.object({
    dealerCode: Yup.string()
      .matches(/^[0-9]+$/, "Must be a number")
      .required("Required"),
    dealerName: Yup.string()
      .matches(/^[A-Za-z ]+$/, "Only alphabetic characters are allowed")
      .required("Required"),
    sapAdCode: Yup.string()
      .matches(/^[0-9]+$/, "Must be a number")
      .required("Required"),
    adName: Yup.string()
      .matches(/^[A-Za-z ]+$/, "Only alphabetic characters are allowed")
      .required("Required"),
    mobile: Yup.string()
      .matches(/^[0-9]{10}$/, "Must be a valid 10-digit mobile number")
      .required("Required"),
    email: Yup.string().email("Invalid email format").required("Required"),
    pincode: Yup.string()
      .matches(/^[0-9]{6}$/, "Pincode must be a 6-digit number")
      .required("Required"),
    city: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    rsaIc: Yup.string().required("Required"),
  });
  const registerDealerForm = useFormik({
    initialValues: {
      dealerCode: "",
      dealerName: "",
      sapAdCode: "",
      adName: "",
      mobile: "",
      email: "",
      pincode: "",
      city: "",
      state: "",
      rsaIc: "",
    },
    validationSchema: registerDealerFormValidationSchema,
    onSubmit: async (values) => {
      console.log(values);
      const payload = values;
      await apiService
        .addDealer(payload)
        .then((res) => {
          toast.success(res.data.message);
        })
        .catch((error) => {
          service.notifyApiError(error, setMessage, setError);
        });
    },
  });

  const handlePincodeChange = (e) => {
    const { value } = e.target;
    if (!isNaN(value) && value.length <= 6) {
      registerDealerForm.handleChange(e);
      if (value.length === 6) {
        // alert(999)
        // const loadingToast = toast.loading("Loading data...");
        apiService
          .location(value)
          .then((response) => {
            registerDealerForm.setFieldValue("city", response.data.data.city);
            registerDealerForm.setFieldValue("state", response.data.data.state);
          })
          .catch((error) => {
            registerDealerForm.setFieldValue("city", "");
            registerDealerForm.setFieldValue("state", "");
            service.notifyApiError(error, setMessage, setError);
          });
      } else {
        registerDealerForm.setFieldValue("city", "");
        registerDealerForm.setFieldValue("state", "");
      }
    }
  };

  const getAccountTypes = async () => {
    try {
      const accountTypes = await apiService.getAccountTypes();
      setAccountTypeOptions(accountTypes.data.data);
    } catch (error) {
      service.notifyApiError(error, setMessage, setError);
    }
  };

  const handleIfscCodeChange = async (e) => {
    const { value } = e.target;
    registerDealerForm.handleChange(e); // Always handle the change event
    // !isNaN(value) &&
    if (value.length <= 11) {
      if (value.length === 11) {
        await apiService
          .ifscCode(value)
          .then((response) => {
            const { data } = response.data;
            registerDealerForm.setFieldValue("bankName", data.name);
            registerDealerForm.setFieldValue("branchAddress", data.branch);
          })
          .catch((error) => {
            registerDealerForm.setFieldValue("bankName", "");
            registerDealerForm.setFieldValue("branchAddress", "");
            service.notifyApiError(error, setMessage, setError);
          });
      } else {
        registerDealerForm.setFieldValue("bankName", "");
        registerDealerForm.setFieldValue("branchAddress", "");
      }
    }
  };

  const getCompanyTypeOptions = async () => {
    try {
      const companyTypes = await apiService.getCompanyTypes();
      setCompanyTypeOptions(companyTypes.data.data);
    } catch (error) {
      service.notifyApiError(error, setMessage, setError);
    }
  };

  const onSelectCompanyType = (value) => {
    registerDealerForm.setFieldValue("companyType", value);
  };

  const onSelectAccountType = (value) => {
    registerDealerForm.setFieldValue("accountType", value);
  };

  const onSelectRSAIC = (value) => {
    registerDealerForm.setFieldError("rsaIc", value);
  };

  const handleGSTChange = (e) => {
    registerDealerForm.setFieldValue("gstNumber", e.target.value.toUpperCase());
  };

  const getRsaIcOptions = async () => {
    try {
      const payload = {
        type: "RSA",
      };
      await apiService.getIcs(payload).then((data) => {
        setRsaIcOptions(data.data.data);
      });
    } catch (error) {
      service.notifyApiError(error, setMessage, setError);
    }
  };

  useEffect(() => {
    getCompanyTypeOptions();
    getRsaIcOptions();
    getAccountTypes();
  }, []);
  return (
    <>
      <div className="flex flex-col w-full items-center overflow-hidden">
        <div className="sticky -z-10 w-full">
          <img
            src={coverImage}
            className=" w-full h-36 object-cover"
            alt="cover_image"
          />
        </div>
        <div className="w-11/12 bg-white -mt-28 md:-mt-20 border border-neutral-light rounded mx-2">
          <div>
            <div className="bg-base-white px-6 py-4 border-b border-neutral-light rounded-t">
              <h3 className="text-xl font-semibold">Register New Dealer</h3>
              <p className="text-neutral-darker mt-1 text-sm">
                Register and onboard new dealers to manage policies, track
                transactions, and access customer information securely within
                the system
              </p>
            </div>
            <div className="px-6 py-4 pb-8">
              <div className="w-full">
                <form onSubmit={registerDealerForm.handleSubmit}>
                  {/* <div className="flex flex-col items-center md:flex-row md:items-center md:justify-center"> */}
                  <div className="px-4">
                    <h4 className="text-lg font-medium py-2">Dealer details</h4>
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-y-2 gap-x-4">
                      <div className="flex flex-col ">
                        <Input
                          {...registerDealerForm.getFieldProps("dealerCode")}
                          formik={registerDealerForm}
                          id="dealerCode"
                          name="dealerCode"
                          type="text"
                          label="Dealer Code"
                          placeholder="Enter dealer code"
                          className="w-full md:w-60"
                          maxLength={8}
                        />
                      </div>
                      <div className="flex flex-col ">
                        <Input
                          {...registerDealerForm.getFieldProps("dealerName")}
                          formik={registerDealerForm}
                          id="dealerName"
                          name="dealerName"
                          type="text"
                          label="Dealer Name"
                          placeholder="Enter dealer name"
                          className="w-full md:w-60"
                        />
                      </div>
                      <div className="flex flex-col ">
                        <Input
                          {...registerDealerForm.getFieldProps("sapAdCode")}
                          formik={registerDealerForm}
                          id="sapAdCode"
                          name="sapAdCode"
                          type="text"
                          label="Sap Ad Code"
                          placeholder="Enter sap ad code"
                          className="w-full md:w-60"
                          maxLength={8}
                        />
                      </div>
                      <div className="flex flex-col ">
                        <Input
                          {...registerDealerForm.getFieldProps("adName")}
                          formik={registerDealerForm}
                          id="adName"
                          name="adName"
                          type="text"
                          label="Ad Name"
                          placeholder="Enter ad name"
                          className="w-full md:w-60"
                        />
                      </div>

                      <div className="flex flex-col ">
                        <Input
                          {...registerDealerForm.getFieldProps("mobile")}
                          formik={registerDealerForm}
                          id="mobile"
                          name="mobile"
                          type="text"
                          label="Mobile"
                          placeholder="Enter mobile number"
                          className="w-full md:w-60"
                          maxLength={10}
                        />
                      </div>
                      <div className="flex flex-col ">
                        <Input
                          {...registerDealerForm.getFieldProps("email")}
                          formik={registerDealerForm}
                          id="email"
                          name="email"
                          type="text"
                          label="Email"
                          placeholder="Enter email"
                          className="w-full md:w-60"
                        />
                      </div>
                      <div className="flex flex-col ">
                        <Select
                          {...registerDealerForm.getFieldProps("rsaIc")}
                          formik={registerDealerForm}
                          value={registerDealerForm.values.companyType}
                          id="rsaIc"
                          name="rsaIc"
                          label="RSA Ic"
                          onSelect={onSelectRSAIC}
                          options={rsaIcOptions}
                          className="mr-4 flex-col w-f"
                        />
                      </div>
                      <div className="flex flex-col ">
                        <div className="flex flex-col ">
                          <Select
                            {...registerDealerForm.getFieldProps("rsaIc")}
                            formik={registerDealerForm}
                            value={registerDealerForm.values.companyType}
                            id="rsaIc"
                            name="rsaIc"
                            label="RSA Ic"
                            onSelect={onSelectRSAIC}
                            options={rsaIcOptions}
                            className="mr-4 flex-col w-f"
                          />
                        </div>
                      </div>
                    </div>
                    <h4 className="text-lg font-medium py-2">IC Mapping</h4>
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-y-2 gap-x-4">
                      <div className="flex flex-col ">
                        <div className="flex flex-col ">
                          <Select
                            {...registerDealerForm.getFieldProps("rsaIc")}
                            formik={registerDealerForm}
                            value={registerDealerForm.values.companyType}
                            id="rsaIc"
                            name="rsaIc"
                            label="RSA Ic"
                            onSelect={onSelectRSAIC}
                            options={rsaIcOptions}
                            className="mr-4 flex-col w-f"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <div className="flex justify-center pt-4 pb-8 w-72">
                      <Button
                        type="button"
                        label="Reset"
                        variant="ghost"
                        onClick={() => {
                          registerDealerForm.resetForm();
                        }}
                      />
                      <Button
                        type="submit"
                        label="Register Dealer"
                        className="min-w-fit"
                        variant="primary"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
