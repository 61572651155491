import React, { useState, useEffect } from "react";
import coverImage from "../assets/img/coverImage.png";
import CurrencyFormat from "react-currency-format";
import Input from "../components/ui/Input";
import Button from "../components/ui/Button";
import toast, { Toaster } from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "../components/ui/Select";
import File from "../components/ui/File";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useNavigate } from "react-router-dom";
import ReactHTMLTableToExcel from "react-html-table-to-excel"; // Import ReactHTMLTableToExcel
//import { Data } from "../pages/defaultData";
import { apiService } from "../api.service";
import loadingBarService from "../service/loadingbar.Service";
import { saveAs } from "file-saver";
import FileSaver from "file-saver";
import Tippy from "@tippyjs/react";
import moment from "moment";
import {
  Description,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import _ from "lodash";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { tab } from "@testing-library/user-event/dist/tab";
import { service } from "../service";
import { useGlobalContext } from "../context/global.context";
// const defaultData = [...Data];

const onEdit = (id) => {
  console.log(id);
};

export const Invoice = () => {
  const { setMessage, setError } = useGlobalContext();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [disableAboutToExpireSelect, setDisableAboutToExpireSelect] =
    useState(true);
  const [disableMonthArray, setDisableMonthArray] = useState([]);
  const [disabledMonths, setDisabledMonths] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  // const [disabledMonthArray, setDisabledMonthArray] = useState([]);
  const [minInvoiceMonth, setMinInvoiceMonth] = useState("");
  const [activeTabName, setActiveTabName] = useState("");
  const [sorting, setSorting] = React.useState([]);
  const [payload, setPayload] = useState({
    pagination: {
      pageNumber: 1,
      pageSize: 5,
    },
  });
  const [showDateRangeModal, setShowDateRangeModal] = useState(false);
  const [invoiceSummaryData, setInvoiceSummaryData] = useState({});
  const [invoiceData, setInvoiceData] = useState({});
  const [invoiceSummaryFooterData, setInvoiceSummaryFooterData] = useState({});
  const [showViewInvoiceModal, setShowViewInvoiceModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [noRecordFound, setNoRecordFound] = useState(false);
  const [filterModal, filterdataModal] = useState(false);
  const [disableViewInvoiceBtn, setDisableViewInvoiceBtn] = useState(false);

  const navigate = useNavigate();
  const [searchClicked, setSearchClicked] = useState(false);
  const columnHelper = createColumnHelper();
  const getButtonStyles = (status) => {
    const baseClasses =
      "w-fit px-4 flex items-center justify-center rounded-full font-semibold";
    switch (status) {
      case "pending":
        return `${baseClasses} bg-warn-lightest text-warn capitalize`;
      case "rejected":
        return `${baseClasses} bg-neutral-lightest text-danger capitalize`;
      case "approved":
        return `${baseClasses} bg-success-lightest text-success capitalize`;
      default:
        return `${baseClasses} bg-warn-lightest text-warn.lightest capitalize`;
    }
  };

  const downloadInvoicePdf = async (invoiceMonth) => {
    try {
      const toastId = toast.loading("Downloading...");
      const payload = {
        // dealerId : 1,
        invoiceMonth: invoiceMonth,
      };
      console.log("paylaod", payload);
      await apiService
        .downloadInvoicePdf(payload)
        .then((res) => {
          const blob = new Blob([res.data], { type: "application/pdf" });
          const disposition = res.headers;
          console.log(disposition);
          const disposition1 = res.headers["content-disposition"];
          console.log(disposition1);

          let fileName = "invoice.pdf"; // fallback filename

          // Extract the filename from the Content-Disposition header
          if (disposition) {
            const matches = /filename="([^"]+)"/.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1];
            }
          }
          console.log(disposition);

          // const fileName = disposition ? disposition.split('filename=')[1].replace(/"/g, '') : 'policy.pdf';
          saveAs(blob, fileName);
          toast.dismiss(toastId);
        })
        .catch((error) => {
          toast.dismiss(toastId);
          toast.error(error.response.data.message);
          service.notifyApiError(error, setMessage, setError);
        })
        .finally(() => {
          toast.dismiss(toastId);
        });
    } catch (error) {
      toast.error("Error downloading the PDF");
    } finally {
      loadingBarService.complete(); // Complete the loading bar
    }
  };

  // const handleMonthChange = (event) => {
  //   const month =moment(event.target.value).format('MMM YYYY');
  //   console.log('Idff', disableMonthArray.includes(month));
  //   console.log('hh', disableMonthArray)
  //   console.log('Selected month:', month);
  //   if (disableMonthArray.includes(moment(event.target.value).format("YYYY-MM"))) {
  //     toast.error(`Invoice is already generated for the month ${month}`);
  //   }
  // };
  const handleMonthChange = (event) => {
    const formattedMonth = moment(event.target.value).format("MMM YYYY"); // For display
    const monthToCheck = moment(event.target.value).format("YYYY-MM"); // For comparison

    console.log("Selected month (display):", formattedMonth);
    console.log("Checking month in YYYY-MM format:", monthToCheck);
    console.log("Disabled months:", disableMonthArray);

    // Check if the selected month is in the disabled array (in YYYY-MM format)
    if (disableMonthArray.includes(monthToCheck)) {
      toast.error(
        `Invoice is already generated for the month ${formattedMonth}`
      );
    }
  };

  const columnsInvoiceList = [
    // columnHelper.accessor("", {
    //   header: "#",
    // }),

    columnHelper.accessor("dealerName", {
      header: "Dealer Name",
    }),
    columnHelper.accessor("invoiceNumber", {
      header: "Invoice Number",
    }),
    columnHelper.accessor("invoiceDate", {
      header: "Invoice Date",
      cell: (data) => {
        return (
          <span>
            {data.getValue()
              ? moment(data.getValue()).format("Do MMM YYYY")
              : "-"}
          </span>
        );
      },
    }),
    columnHelper.accessor("invoiceMonth", {
      header: "Invoice Month",
      cell: (data) => {
        console.log(data.getValue());

        return (
          <span>
            {data.getValue()
              ? moment(data.getValue(), "MM-YYYY").format("MMM YYYY")
              : "-"}
          </span>
        );
      },
    }),
    columnHelper.accessor("totalPolicyCount", {
      header: "Total Policy Count",
    }),
    columnHelper.accessor("totalPolicyPremium", {
      header: "Total Policy Premium",
      cell: (data) => {
        return (
          <span>
            {data.getValue() ? (
              <CurrencyFormat
                value={data.getValue()}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
              />
            ) : (
              "-"
            )}
          </span>
        );
      },
    }),
    columnHelper.accessor("totalGST", {
      header: "Total GST",
      cell: (data) => {
        return (
          <span>
            {data.getValue() ? (
              <CurrencyFormat
                value={data.getValue()}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
              />
            ) : (
              "-"
            )}
          </span>
        );
      },
    }),
    columnHelper.accessor("finalPremium", {
      header: "Final Premium",
      cell: (data) => {
        return (
          <span>
            {data.getValue() ? (
              <CurrencyFormat
                value={data.getValue()}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
              />
            ) : (
              "-"
            )}
          </span>
        );
      },
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: (data) => {
        const status = data.getValue();
        return (
          <span className={`px-2 py-1 rounded ${getButtonStyles(status)}`}>
            {status}
          </span>
        );
      },
    }),
    columnHelper.accessor("transactionDate", {
      header: "Create Date",

      cell: (data) => {
        return (
          <span>
            {data.getValue()
              ? moment(data.getValue()).format("Do MMM YYYY")
              : "-"}
          </span>
        );
      },
    }),

    columnHelper.accessor("approvalDate", {
      header: "Comment",
      cell: (data) => {
        return (
          <span>
            {data.getValue()
              ? moment(data.getValue()).format("DD-MM-YYYY")
              : "-"}
          </span>
        );
      },
    }),
    columnHelper.accessor("createdAt", {
      header: "Action",
      cell: (data) => {
        const status = data.row.original;
        const invoiceMonth = status.invoiceMonth;
        console.log(status);
        return (
          <span
            className="material-symbols-outlined text-neutral-darkest"
            onClick={() => downloadInvoicePdf(invoiceMonth)}
          >
            download
          </span>
        );
      },
    }),
  ];
  const columnsInvoiceSummary = [
    columnHelper.accessor("", {
      header: "#",
    }),

    columnHelper.accessor("planName", {
      header: "Product Name",
      cell: (row) => {
        return <span className="font-semibold">{row.getValue()}</span>;
      },
      footer: "Total",
    }),
    columnHelper.accessor("count", {
      header: "Count Of Policies",
      footer: invoiceSummaryData?.total?.count,
    }),
    columnHelper.accessor("policyPremium", {
      header: "Policy Premium",
      cell: (data) => {
        return (
          <span>
            {data.getValue() ? (
              <CurrencyFormat
                value={data.getValue()}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
              />
            ) : (
              "-"
            )}
          </span>
        );
      },
      footer: () => {
        return (
          <span>
            {invoiceSummaryData?.total?.policyPremium ? (
              <CurrencyFormat
                value={invoiceSummaryData?.total?.policyPremium}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
              />
            ) : (
              "-"
            )}
          </span>
        );
      },
    }),
    columnHelper.accessor("policyGST", {
      header: "Policy Gst",
      cell: (data) => {
        return (
          <span>
            {data.getValue() ? (
              <CurrencyFormat
                value={data.getValue()}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
              />
            ) : (
              "-"
            )}
          </span>
        );
      },
      footer: () => {
        return (
          <span>
            {invoiceSummaryData?.total?.policyGST ? (
              <CurrencyFormat
                value={invoiceSummaryData?.total?.policyGST}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
              />
            ) : (
              "-"
            )}
          </span>
        );
      },
    }),
    columnHelper.accessor("totalPolicyPremium", {
      header: "Total Policy Premium",
      cell: (data) => {
        return (
          <span>
            {data.getValue() ? (
              <CurrencyFormat
                value={data.getValue()}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
              />
            ) : (
              "-"
            )}
          </span>
        );
      },
      footer: () => {
        return (
          <span>
            {invoiceSummaryData?.total?.totalPolicyPremium ? (
              <CurrencyFormat
                value={invoiceSummaryData?.total?.totalPolicyPremium}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
              />
            ) : (
              "-"
            )}
          </span>
        );
      },
    }),
    columnHelper.accessor("commission", {
      header: "Commission",
      cell: (data) => {
        return (
          <span>
            {data.getValue() ? (
              <CurrencyFormat
                value={data.getValue()}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
              />
            ) : (
              "-"
            )}
          </span>
        );
      },
      footer: () => {
        return (
          <span>
            {invoiceSummaryData?.total?.commission ? (
              <CurrencyFormat
                value={invoiceSummaryData?.total?.commission}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
                className=" font-bold text-base"
              />
            ) : (
              "-"
            )}
          </span>
        );
      },
    }),
    // columnHelper.accessor("commissionGST", {
    //   header: "Commission Gst",
    //   cell: (data) => {
    //     return (
    //       <span>
    //         {data.getValue() ? (
    //           <CurrencyFormat
    //             value={data.getValue()}
    //             displayType={"text"}
    //             thousandSeparator={true}
    //             prefix={"₹"}
    //           />
    //         ) : (
    //           "-"
    //         )}
    //       </span>
    //     );
    //   },
    //   footer: () => {
    //     return (
    //       <span>
    //         {invoiceSummaryFooterData.commissionGST ? (
    //           <CurrencyFormat
    //             value={invoiceSummaryFooterData.commissionGST}
    //             displayType={"text"}
    //             thousandSeparator={true}
    //             prefix={"₹"}
    //           />
    //         ) : (
    //           "-"
    //         )}
    //       </span>
    //     )
    //   }
    // }),
    // columnHelper.accessor("totalCommission", {
    //   header: "Total Commission",
    //   cell: (data) => {
    //     return (
    //       <span>
    //         {data.getValue() ? (
    //           <CurrencyFormat
    //             value={data.getValue()}
    //             decimalScale={2}
    //             displayType={"text"}
    //             thousandSeparator={true}
    //             prefix={"₹"}
    //           />
    //         ) : (
    //           "-"
    //         )}
    //       </span>
    //     );
    //   },
    //   footer: () => {
    //     return (
    //       <span>
    //         {invoiceSummaryFooterData.totalCommission ? (
    //           <CurrencyFormat
    //             value={invoiceSummaryFooterData.totalCommission}
    //             displayType={"text"}
    //             thousandSeparator={true}
    //             prefix={"₹"}
    //           />
    //         ) : (
    //           "-"
    //         )}
    //       </span>
    //     )
    //   }
    // }),
  ];

  const table = useReactTable({
    columns: columnsInvoiceList,
    data: filteredData.length > 0 ? filteredData : data,
    //onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    //debugTable: true,
    manualPagination: true,
  });

  console.log(invoiceSummaryData);
  const tableInvoiceSummary = useReactTable({
    columns: columnsInvoiceSummary,
    data: invoiceSummaryData?.summary || [],
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    //debugTable: true,
    manualPagination: true,
  });

  // const tableInvoiceSummary = useReactTable({
  //   data: filteredData.length > 0 ? filteredData : data,
  //   columns: columnsInvoiceSummary,
  //   onSortingChange: setSorting,
  //   getCoreRowModel: getCoreRowModel(),
  //   getSortedRowModel: getSortedRowModel(),
  //   manualPagination: true,
  // });

  // const handleSearch = () => {
  //   const searchValue = document.getElementById("search").value.trim();
  //   console.log("Search value:", searchValue); // Log search value
  //   setPayload((prev) => {
  //     const updatedPayload = {
  //       ...prev,
  //       search: searchValue,
  //       pagination: {
  //         ...prev.pagination,
  //         pageNumber: 1, // Reset to the first page on search
  //       },
  //     };
  //     console.log("Updated payload:", updatedPayload); // Log updated payload
  //     return updatedPayload;
  //   });
  // };

  // const handleNextPage = () => {
  //   setPayload((prev) => ({
  //     ...prev,
  //     pagination: {
  //       ...prev.pagination,
  //       pageNumber: prev.pagination.pageNumber + 1,
  //     },
  //   }));
  // };

  const handleNextPage = () => {
    setPayload((prev) => {
      const nextPage = prev.pagination.pageNumber + 1;
      return {
        ...prev,
        pagination: {
          ...prev.pagination,
          pageNumber: nextPage > totalPages ? totalPages : nextPage,
        },
      };
    });
  };

  const handleLastPage = () => {
    setPayload((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        pageNumber: totalPages,
      },
    }));
  };
  const handlePreviousPage = () => {
    setPayload((prev) => {
      const prevPage = prev.pagination.pageNumber - 1;
      return {
        ...prev,
        pagination: {
          ...prev.pagination,
          pageNumber: prevPage < 1 ? 1 : prevPage,
        },
      };
    });
  };

  const handleFirstPage = () => {
    setPayload((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        pageNumber: 1,
      },
    }));
  };

  const fetchInvoiceSummary = async (request) => {
    //const loadingToast = toast.loading("Loading data...");
    setLoading(true);
    try {
      // const request = {
      //   search: payload.search,
      //   duration: payload.duration,
      //   pageSize: payload.pageSize,
      //   pageNumber: payload.pageNumber,
      // };
      //  ( alert(9))
      const response = await apiService.invoiceSummary(request);
      console.log(response.data.data);

      response.data.data.transferto = [
        {
          id: 1,
          value: "wallet",
          label: "Transfered to Wallet",
        },
        {
          id: 2,
          value: "bank",
          label: "Transfered to Bank",
        },
      ];
      setInvoiceSummaryData(response.data.data);
      console.log(invoiceSummaryData, invoiceSummaryData);

      // setInvoiceSummaryFooterData(response.data.data.total);
      console.log(response.data.data);
      setData(response.data.data);
      setTotalCount(response.data.totalCount);
      setNoRecordFound(response.data.data.length === 0);
      console.log(data);
      setShowViewInvoiceModal(true);
    } catch (error) {
      console.error("Error fetching policy data:", error);
      // toast.error("Error fetching policy data");
      service.notifyApiError(error, setMessage, setError);
    } finally {
      //toast.dismiss(loadingToast);
      setLoading(false);
    }
  };
  const fetchInvoice = async () => {
    //const loadingToast = toast.loading("Loading data...", { duration: 5000 });
    setLoading(true);
    try {
      console.log(payload);
      const response = await apiService.invoice(payload);
      const invoices = response.data.data;
      if (response?.data?.data.length === 0) {
        setNoRecordFound(true);
      }
      setData(invoices);
      setDisableMonthArray(response.data.disabledMonthArray || []);
      extractDisabledMonths(response.data.disabledMonthArray || []);
      setMinInvoiceMonth(response.data.minInvoiceMonth);
      setFilteredData(invoices);
      setTotalCount(response.data.totalCount || invoices.length);
      setNoRecordFound(invoices.length === 0);
      console.log(response.data.data);
      setInvoiceData(response.data.data);

      // setInvoiceSummaryFooterData(response.data.data.total);
      console.log(response.data.data);
      // setData(response.data.data);
      // setTotalCount(response.data.totalCount);
      // setNoRecordFound(response.data.data.length === 0);
      console.log(data);
    } catch (error) {
      console.error("Error fetching policy data:", error);
      // toast.error("Error fetching policy data");
      service.notifyApiError(error, setMessage, setError);

      setNoRecordFound(true);
    } finally {
      //toast.dismiss(loadingToast);
      setLoading(false);
      setDisableViewInvoiceBtn(false);
    }
  };
  const extractDisabledMonths = (disableMonthArray) => {
    const uniqueDisabledMonths = Array.from(
      new Set(
        disableMonthArray
          .map((dateStr) => moment(dateStr, "MM-YYYY").format("YYYY-MM"))
          .filter((month) => month !== "Invalid date")
      )
    );
    setDisabledMonths(uniqueDisabledMonths);
  };

  const handleGenerateInvoice = async () => {
    const loadingToast = toast.loading("Loading data...");
    try {
      const payload = {
        invoiceMonth: invoiceForm.getFieldProps("invoiceMonth").value,
        invoiceDate: invoiceForm.getFieldProps("invoiceDate").value,
        invoiceNumber: invoiceForm.getFieldProps("invoiceNumber").value,
        transfered_amount_to: invoiceForm.getFieldProps("transferto").value,
      };

      // Call the generateInvoice API
      const response = await apiService
        .generateInvoice(payload)
        .then((response) => {
          setShowViewInvoiceModal(false);
          toast.success(response.data.message);
          invoiceForm.resetForm();
        });
      console.log("Invoice generated successfully:", response.data);
      // Optionally, show a success message or perform any other actions upon successful generation
    } catch (error) {
      console.error("Error generating invoice:", error);
      service.notifyApiError(error, setMessage, setError);
      // Optionally, show an error message or perform any other error handling
    } finally {
      toast.dismiss(loadingToast);
    }
  };
  // useEffect(() => {
  //   console.log("Payload updated:", payload);
  //   fetchInvoiceSummary();
  // }, [payload]);

  const resetState = () => {
    setSelectedTabIndex(0); // Resetting selected tab index to default
    setData([]); // Clearing data array
    setTotalCount(0); // Resetting total count to 0
    setDisableAboutToExpireSelect(true); // Disabling a particular selection
    setFilteredData([]); // Clearing filtered data
    setActiveTabName(""); // Resetting active tab name
    setSorting([]); // Clearing sorting array
    setPayload({
      pagination: {
        pageNumber: 1,
        pageSize: 5, // Reset pagination to page 1 and default page size
      },
    });
    setShowDateRangeModal(false); // Hide date range modal
    filterdataModal(false); // Close filter data modal
    setLoading(true); // Set loading to true
    setNoRecordFound(false); // Reset no records found flag
    dateFilterForm.resetForm();
    invoiceForm.resetForm();
  };

  useEffect(() => {
    console.log("Payload updated:");
    fetchInvoice();
  }, [payload]);

  useEffect(() => {
    if (data?.length == 0) {
      setNoRecordFound(true);
    } else {
      setNoRecordFound(false);
    }
  }, [data]);
  const dateFilterForm = useFormik({
    initialValues: {
      fromDate: "",
      toDate: "",
    },
    validationSchema: Yup.object().shape({
      fromDate: Yup.date().required("Required"),
      toDate: Yup.date().required("Required"),
    }),
    onSubmit: async (values) => {
      console.log(values);
      setPayload((prev) => ({
        ...prev,
        fromDate: values.fromDate,
        toDate: values.toDate,
      }));
      console.log(payload);
    },
  });

  const totalPages = Math.ceil(totalCount / payload.pagination.pageSize);
  const isLastPage = payload.pagination.pageNumber === totalPages;
  const isFirstPage = payload.pagination.pageNumber === 1;

  const invoiceForm = useFormik({
    initialValues: {
      invoiceNumber: "",
      invoiceDate: "",
      invoiceMonth: "",
      transferto: "",
      fromDate: "",
      toDate: "",
    },
    validationSchema: Yup.object().shape({
      invoiceNumber: Yup.string().required("Required"),
      invoiceDate: Yup.date()
        .min(
          moment().startOf("month").format("YYYY-MM-DD"),
          "Date cannot be before the start of the month"
        )
        .max(moment().format("YYYY-MM-DD"), "Date cannot be after today")
        .required("Required"),
      invoiceMonth: Yup.date().required("Required"),
    }),
  });
  const generateMonthOptions = () => {
    const months = [];
    const currentMonth = moment().startOf("month");
    for (let i = 0; i < 12; i++) {
      const monthOption = currentMonth.clone().subtract(i, "months");
      months.push({
        value: monthOption.format("YYYY-MM"),
        label: monthOption.format("MMM YYYY"),
        disabled: disabledMonths.includes(monthOption.format("YYYY-MM")),
      });
    }
    return months;
  };
  const monthOptions = generateMonthOptions();

  const onViewInvoice = (invoiceMonth) => {
    invoiceForm.setFieldValue("invoiceMonth", invoiceMonth);
    const payload = {
      invoiceMonth,
    };
    fetchInvoiceSummary(payload);
    // setShowViewInvoiceModal(true)
  };

  return (
    <>
      <Dialog
        open={showViewInvoiceModal}
        onClose={() => setShowViewInvoiceModal(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/75" aria-hidden="true" />

        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <DialogPanel className="space-y-4 border bg-white border-neutral rounded overflow-y-scroll h-full">
            <div className="overflow-x-auto overflow-y-auto h-full">
              <form onSubmit={invoiceForm.handleSubmit}>
                <div className="bg-neutral-lightest border-b border-neutral px-6 py-3 flex flex-col sm:flex-row">
                  <span className="font-bold text-xl py-2 text-neutral-darkest">
                    Generate Invoice
                  </span>
                </div>
                <div className="flex flex-col gap-x-4 px-4 sm:px-8 pt-4">
                  <div className="dealer-info flex flex-col sm:flex-row justify-between">
                    <div className="left-side">
                      <table className="table-auto">
                        <tr>
                          <td className="text-sm font-semibold w-40">
                            Dealership Name
                          </td>
                          <td className="text-sm w-75">
                            {
                              service.getSessionStorageItem("dealerInfo")
                                ?.dealerName
                            }
                          </td>
                        </tr>
                        <tr>
                          <td className="text-sm font-semibold w-40">
                            Address
                          </td>
                          <td className="text-sm">
                            {
                              service.getSessionStorageItem("dealerInfo")
                                ?.address
                            }
                          </td>
                        </tr>
                        <tr>
                          <td className="text-sm font-semibold w-40">
                            GST Number
                          </td>
                          <td className="text-sm">
                            {
                              service.getSessionStorageItem("dealerInfo")
                                ?.gstNumber
                            }
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="right-side flex flex-col justify-center items-start mt-4 sm:mt-0">
                      <div className="flex items-center">
                        <p className="text-sm font-semibold w-28">
                          Invoice Month
                        </p>
                        <span className="text-sm">
                          {moment(
                            invoiceForm.getFieldProps("invoiceMonth").value
                          ).format("MM-YYYY")}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="invoice-details flex flex-col sm:flex-row  gap-x-4 pt-4">
                    <div className="flex flex-col">
                      <Input
                        {...invoiceForm.getFieldProps("invoiceNumber")}
                        formik={invoiceForm}
                        id="invoiceNumber"
                        name="invoiceNumber"
                        type="text"
                        label="Invoice Number"
                        placeholder="Invoice Number"
                        className="w-full sm:w-60"
                      />
                    </div>
                    <div className="flex flex-col">
                      <Input
                        {...invoiceForm.getFieldProps("invoiceDate")}
                        formik={invoiceForm}
                        id="invoiceDate"
                        name="invoiceDate"
                        type="date"
                        min={moment().startOf("month").format("YYYY-MM-DD")}
                        max={moment().format("YYYY-MM-DD")}
                        label="Invoice Date"
                        className="w-full sm:w-60"
                      />
                    </div>
                    <div className="flex flex-col">
                      <option value="status">Transfer To</option>
                      <select
                        id="transferto"
                        name="transferto"
                        value={invoiceForm.values.transferto}
                        onChange={(e) => {
                          invoiceForm.setFieldValue(
                            "transferto",
                            Number(e.target.value)
                          );
                        }}
                        className="border border-gray-300 rounded-md p-2 w-full sm:w-60"
                      >
                        <option value="">Select...</option>
                        {invoiceSummaryData.transferto &&
                        invoiceSummaryData.transferto.length > 0 ? (
                          invoiceSummaryData.transferto.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.label}
                            </option>
                          ))
                        ) : (
                          <option disabled>No options available</option>
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="flex mb-4 overflow-x-scroll cursor-pointer mt-2">
                    <table
                      id="table-to-xls"
                      className="text-sm border border-neutral w-full"
                    >
                      <thead className="bg-primary text-white text-xs">
                        {tableInvoiceSummary
                          .getHeaderGroups()
                          .map((headerGroup) => (
                            <tr key={headerGroup.id}>
                              {headerGroup.headers.map((header) => {
                                return (
                                  <th
                                    key={header.id}
                                    colSpan={header.colSpan}
                                    className="px-2 py-2 text-left"
                                  >
                                    {header.isPlaceholder ? null : (
                                      <div
                                        className={
                                          header.column.getCanSort()
                                            ? "cursor-pointer select-none"
                                            : ""
                                        }
                                        onClick={header.column.getToggleSortingHandler()}
                                        title={
                                          header.column.getCanSort()
                                            ? header.column.getNextSortingOrder() ===
                                              "asc"
                                              ? "Sort ascending"
                                              : header.column.getNextSortingOrder() ===
                                                "desc"
                                              ? "Sort descending"
                                              : "Clear sort"
                                            : undefined
                                        }
                                      >
                                        {flexRender(
                                          header.column.columnDef.header,
                                          header.getContext()
                                        )}
                                        {{
                                          asc: " 🔼",
                                          desc: " 🔽",
                                        }[header.column.getIsSorted()] ?? null}
                                      </div>
                                    )}
                                  </th>
                                );
                              })}
                            </tr>
                          ))}
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr className="min-h-20">
                            <td
                              colSpan={columnsInvoiceList.length}
                              className="text-center p-4 min-h-20"
                            >
                              Loading...
                            </td>
                          </tr>
                        ) : noRecordFound ? (
                          <tr className="min-h-20 py-2">
                            <td
                              colSpan={columnsInvoiceSummary.length}
                              className="text-center p-4 min-h-20"
                            >
                              No records found
                            </td>
                          </tr>
                        ) : (
                          tableInvoiceSummary.getRowModel().rows.map((row) => (
                            <tr
                              key={row.id}
                              className="text-xs even:bg-base-white py-2"
                            >
                              {row.getVisibleCells().map((cell) => (
                                <td key={cell.id} className="px-2 py-2">
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </td>
                              ))}
                            </tr>
                          ))
                        )}
                      </tbody>

                      <tfoot className="bg-neutral-lighter text-sm font-semibold">
                        {tableInvoiceSummary
                          .getFooterGroups()
                          .map((footerGroup) => (
                            <tr key={footerGroup.id}>
                              {footerGroup.headers.map((footer) => {
                                return (
                                  <td
                                    key={footer.id}
                                    colSpan={footer.colSpan}
                                    className="px-2 py-2 text-left "
                                  >
                                    {footer.isPlaceholder ? null : (
                                      <div>
                                        {flexRender(
                                          footer.column.columnDef.footer,
                                          footer.getContext()
                                        )}
                                      </div>
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          ))}
                      </tfoot>
                    </table>
                  </div>

                  <div className="mb-4 overflow-x-scroll ">
                    <table className="border w-full min-w-[4/5] sm:min-w-auto border-neutral-light">
                      <tr className="text-center bg-neutral-lighter px-2 py-2 text-xs">
                        <th rowSpan="2" className="border border-neutral-light">
                          HSN/SAC
                        </th>
                        <th rowSpan="2" className="border border-neutral-light">
                          Taxable Value
                        </th>
                        <th
                          colSpan="2"
                          className="border border-neutral-light py-2"
                        >
                          IGST
                        </th>
                        <th colSpan="2" className="border border-neutral-light">
                          SGST
                        </th>
                        <th colSpan="2" className="border border-neutral-light">
                          CGST
                        </th>
                      </tr>
                      <tr className="bg-neutral-lighter px-2 py-2 text-xs">
                        <th className="py-1 border border-neutral-light">
                          Rate
                        </th>
                        <th className="py-1 border border-neutral-light">
                          Amount
                        </th>
                        <th className="py-1 border border-neutral-light">
                          Rate
                        </th>
                        <th className="py-1 border border-neutral-light">
                          Amount
                        </th>
                        <th className="py-1 border border-neutral-light">
                          Rate
                        </th>
                        <th className="py-1 border border-neutral-light">
                          Amount
                        </th>
                      </tr>
                      <tr className="text-center mt-1 text-xs h-7">
                        <td>{}</td>
                        <td>
                          <CurrencyFormat
                            value={invoiceSummaryData?.tax?.taxableAmount}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"₹"}
                          />
                        </td>
                        <td>{invoiceSummaryData?.tax?.igst?.rate}</td>
                        <td>
                          <CurrencyFormat
                            value={invoiceSummaryData?.tax?.igst?.amount}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"₹"}
                          />
                        </td>
                        <td>{invoiceSummaryData?.tax?.sgst?.rate}</td>
                        <td>
                          <CurrencyFormat
                            value={invoiceSummaryData?.tax?.sgst?.amount}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"₹"}
                          />
                        </td>
                        <td>{invoiceSummaryData?.tax?.cgst?.rate}</td>
                        <td>
                          <CurrencyFormat
                            value={invoiceSummaryData?.tax?.cgst?.amount}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"₹"}
                          />
                        </td>
                      </tr>
                      <tr className="text-center mt-1 text-xs font-semibold h-7 bg-neutral-lighter">
                        <td>Total</td>
                        <td>
                          <CurrencyFormat
                            value={invoiceSummaryData?.tax?.taxableAmount}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"₹"}
                          />
                        </td>
                        <td></td>
                        <td>
                          <CurrencyFormat
                            value={invoiceSummaryData?.tax?.igst?.amount}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"₹"}
                          />
                        </td>
                        <td>-</td>
                        <td>
                          <CurrencyFormat
                            value={invoiceSummaryData?.tax?.sgst?.amount}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"₹"}
                          />
                        </td>
                        <td>-</td>
                        <td>
                          <CurrencyFormat
                            value={invoiceSummaryData?.tax?.cgst?.amount}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"₹"}
                          />
                        </td>
                      </tr>
                    </table>
                  </div>

                  <div className="mb-2 flex justify-end">
                    <div className="w-full sm:w-1/3">
                      <table className="w-full border border-neutral-light">
                        <tr className="bg-neutral-lightest px-4">
                          <td className="text-sm font-semibold w-40 px-4">
                            Taxable Amount
                          </td>
                          <td>
                            <CurrencyFormat
                              value={invoiceSummaryData?.tax?.taxableAmount}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"₹"}
                              className="text-sm font-semibold px-4"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="text-xs w-40 px-4">GST</td>
                          <td>
                            <CurrencyFormat
                              value={invoiceSummaryData?.tax?.totalGST}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"₹"}
                              className="text-sm px-4"
                            />
                          </td>
                        </tr>
                        <tr className="bg-neutral-lightest">
                          <td className="text-sm font-semibold w-40 px-4">
                            Gross Amount
                          </td>
                          <td>
                            <CurrencyFormat
                              value={invoiceSummaryData?.tax?.commissionWithTax}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"₹"}
                              className="text-sm font-semibold px-4"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="text-xs w-40 px-4">TDS</td>
                          <td>
                            <CurrencyFormat
                              value={invoiceSummaryData?.tax?.tds.amount}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"₹"}
                              className="text-sm px-4"
                            />
                          </td>
                        </tr>
                        <tr className="bg-primary-lightest border-b border-primary px-2 py-2">
                          <td className="text-sm font-semibold w-40 py-2 px-4">
                            Payable Amount
                          </td>
                          <td className="font-bold py-2 px-4">
                            <CurrencyFormat
                              value={invoiceSummaryData?.tax?.finalAmount}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"₹"}
                            />
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="flex items-center justify-center gap-4 mt-4 px-4 px-12 pb-8">
                  <Button
                    type="button"
                    label="Cancel"
                    variant="ghost"
                    className="max-w-12"
                    onClick={() => setShowViewInvoiceModal(false)}
                  />
                  <Button
                    type="submit"
                    label="Generate Invoice"
                    variant="primary"
                    className="w-full sm:w-auto px-3 py-2"
                    onClick={handleGenerateInvoice}
                  />
                </div>
              </form>
            </div>
          </DialogPanel>
        </div>
      </Dialog>

      <Dialog
        open={filterModal}
        onClose={() => filterdataModal(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/75" aria-hidden="true" />

        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <DialogPanel className=" space-y-4 border bg-white border-neutral rounded">
            <form onSubmit={dateFilterForm.handleSubmit}>
              <div className="bg-neutral-lightest  border-b border-neutral px-6 py-3 flex">
                <span className="font-bold text-xl py-2 text-neutral-darkest ">
                  Filter
                </span>
              </div>
              <div className="flex flex-col gap-y-4  p-4 ">
                <select
                  onChange={(e) => {
                    const typeId = Number(e.target.value);
                    setPayload((prev) => ({
                      ...prev,
                      approvalStatusId: typeId,
                    }));
                    console.log(payload);
                  }}
                  className=" py-1 rounded-sm focus:outline-primary text-base-black  mr-2"
                >
                  {[
                    { id: 1, name: "Pending" },
                    { id: 2, name: "Rejected" },
                    { id: 3, name: "Approved" },
                  ].map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                  ))}
                </select>

                <div className="relative flex items-center w-full md:w-auto">
                  <input
                    type="text"
                    id="search"
                    placeholder="Search..."
                    className="px-3 py-1 border border-neutral-dark rounded-l-sm focus:outline-primary text-base-black w-full md:w-auto"
                  />
                  <button
                    type="button"
                    onClick={() => {
                      setPayload((prev) => ({
                        ...prev,
                        search: {
                          field: document.getElementById("searchField").value,
                          value: document.getElementById("search").value,
                        },
                      }));
                    }}
                    className="bg-primary text-white border border-primary px-2 py-1  rounded-r-sm"
                  >
                    <span className="material-symbols-outlined flex items-center justify-center p-0">
                      search
                    </span>
                  </button>
                </div>
              </div>

              <div className="flex items-center justify-center gap-4 mt-4 px-12 pb-8">
                <Button
                  type="button"
                  label="Cancel"
                  variant="ghost"
                  className="max-w-12"
                  onClick={() => filterdataModal(false)}
                />
                <Button
                  type="submit"
                  label="Submit"
                  variant="primary"
                  className="max-w-16"
                />
              </div>
            </form>
          </DialogPanel>
        </div>
      </Dialog>

      <div className="flex flex-col w-full items-center overflow-hidden">
        <div className="sticky -z-10 w-full">
          <img
            src={coverImage}
            className=" w-full h-36 object-cover"
            alt="cover_image"
          />
        </div>
        <div className="w-11/12 bg-white -mt-28 md:-mt-20 border border-neutral-light rounded mx-2">
          <form>
            <div>
              <div className="bg-base-white px-6 py-4 border-b border-neutral-light rounded-t">
                <h3 className="text-xl font-semibold">Invoice </h3>
                <p className="text-neutral-darker mt-1 text-sm">
                  Overview of transaction requests, including their statuses and
                  approval dates.
                </p>
              </div>
              <div className="px-6 py-4 pb-8">
                <div>
                  <Tabs
                    selectedIndex={selectedTabIndex}
                    selectedTabClassName="text-primary border-b-2 border-primary font-medium"
                  >
                    <TabList className="flex flex-col md:flex-row md:justify-between mb-4 text-neutral-dark text-sm items-center w-full ">
                      {/* Container for Month and View Invoice */}
                      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 w-full md:w-auto ">
                        <div className="flex space-x-4 w-full md:w-auto">
                          <input
                            type="month"
                            id="invoiceMonth"
                            min={minInvoiceMonth}
                            max={moment()
                              .subtract(1, "month")
                              .format("YYYY-MM")}
                            className="px-3 py-0.5 w-full md:w-40 border border-neutral-dark rounded-sm focus:outline-primary text-base-black"
                            onChange={handleMonthChange}
                          />
                          <button
                            type="button"
                            onClick={() => {
                              onViewInvoice(
                                document.getElementById("invoiceMonth").value
                              );
                            }}
                            className="bg-primary text-white w-full md:w-32 border border-primary px-2 py-0.5 rounded-sm"
                          >
                            View Invoice
                          </button>
                          <button
                            type="button"
                            className="mt-1 md:hidden"
                            onClick={() => {
                              filterdataModal(true);
                            }}
                          >
                            <span className="material-symbols-outlined text-neutral-darkest justify-end">
                              filter_alt
                            </span>
                          </button>
                        </div>
                      </div>

                      {/* Container for Pending and Search */}
                      <div className=" flex-col md:flex-row  items-center md:gap-x-4 gap-2 w-full md:w-auto mt-2 md:mt-0  hidden md:block">
                        <div className="flex items-center  ">
                          <button
                            type="button"
                            className="mt-1"
                            onClick={() => {
                              resetState();
                            }}
                          >
                            <span class="material-symbols-outlined text-neutral-darkest">
                              refresh
                            </span>
                          </button>
                          <select
                            onChange={(e) => {
                              const typeId = Number(e.target.value);
                              setPayload((prev) => ({
                                ...prev,
                                approvalStatusId: typeId,
                              }));
                              console.log(payload);
                            }}
                            className="px-3 py-1 rounded-sm focus:outline-primary text-base-black  mr-2"
                          >
                            {[
                              { id: 1, name: "Pending" },
                              { id: 2, name: "Rejected" },
                              { id: 3, name: "Approved" },
                            ].map((type) => (
                              <option key={type.id} value={type.id}>
                                {type.name}
                              </option>
                            ))}
                          </select>
                          <div className="relative flex items-center  w-full md:w-auto">
                            <input
                              type="text"
                              id="search"
                              placeholder="Search..."
                              className="px-3 py-1 border border-neutral-dark rounded-l-sm focus:outline-primary text-base-black w-full md:w-auto"
                            />
                            <button
                              type="button"
                              onClick={(event) => {
                                setPayload((prev) => ({
                                  ...prev,
                                  search:
                                    document.getElementById("search").value,
                                }));
                              }}
                              className="bg-primary text-white border border-primary px-2 py-0.5 rounded-r-sm "
                            >
                              <span className="material-symbols-outlined flex items-center justify-center">
                                search
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </TabList>

                    <TabPanel>
                      <div className="flex mb-4 overflow-x-scroll cursor-pointer">
                        <table className="text-sm border border-neutral w-fit overflow-x-scroll">
                          <thead className="bg-primary text-white text-xs">
                            {table.getHeaderGroups()?.map((headerGroup) => (
                              <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                  return (
                                    <th
                                      key={header.id}
                                      colSpan={header.colSpan}
                                      className="px-2 py-1 text-left min-w-[150px] max-w-[300px]"
                                    >
                                      {header.isPlaceholder ? null : (
                                        <div
                                          className={
                                            header.column.getCanSort()
                                              ? "cursor-pointer select-none"
                                              : ""
                                          }
                                          onClick={header.column.getToggleSortingHandler()}
                                          title={
                                            header.column.getCanSort()
                                              ? header.column.getNextSortingOrder() ===
                                                "asc"
                                                ? "Sort ascending"
                                                : header.column.getNextSortingOrder() ===
                                                  "desc"
                                                ? "Sort descending"
                                                : "Clear sort"
                                              : undefined
                                          }
                                        >
                                          {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                          )}
                                          {{
                                            asc: " 🔼",
                                            desc: " 🔽",
                                          }[header.column.getIsSorted()] ??
                                            null}
                                        </div>
                                      )}
                                    </th>
                                  );
                                })}
                              </tr>
                            ))}
                          </thead>

                          <tbody>
                            {loading ? (
                              <tr className="min-h-20">
                                <td
                                  colSpan={columnsInvoiceList.length}
                                  className="text-center p-4 min-h-20"
                                >
                                  Loading...
                                </td>
                              </tr>
                            ) : noRecordFound ? (
                              <tr className="min-h-20">
                                <td
                                  colSpan={columnsInvoiceList.length}
                                  className="text-center p-4 min-h-20"
                                >
                                  No records found
                                </td>
                              </tr>
                            ) : (
                              table.getRowModel().rows.map((row) => (
                                <tr
                                  key={row.id}
                                  className="text-xs even:bg-base-white"
                                >
                                  {row.getVisibleCells().map((cell) => (
                                    <td key={cell.id} className="px-2 py-1">
                                      {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                      )}
                                    </td>
                                  ))}
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="flex flex-col md:flex-row items-center w-full justify-end text-xs gap-4">
                        <div className="flex items-center gap-2">
                          {" "}
                          <select
                            value={payload.pageSize}
                            onChange={(e) => {
                              const size = Number(e.target.value);
                              setPayload((prev) => ({
                                ...prev,
                                pagination: {
                                  pageSize: size,
                                  pageNumber: 1,
                                },
                              }));
                            }}
                          >
                            {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map(
                              (pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                  {pageSize}
                                </option>
                              )
                            )}
                          </select>
                          <span className="font-semibold">
                            <span>Showing </span>
                            {1 +
                              (payload.pagination.pageNumber - 1) *
                                payload.pagination.pageSize}{" "}
                            to{" "}
                            {payload.pagination.pageNumber *
                              payload.pagination.pageSize -
                              payload.pagination.pageSize +
                              data?.length}{" "}
                            of {totalCount} Entries
                          </span>
                        </div>
                        {/* <button type="button" disabled={isFirstPage} onClick={handleFirstPage}>
                          <span className="material-symbols-outlined">
                            first_page
                          </span>
                        </button> */}
                        <div className="flex items-center space-x-2 mt-2 md:mt-0">
                          <button
                            type="button"
                            disabled={isFirstPage}
                            onClick={handleFirstPage}
                            className={`material-symbols-outlined ${
                              isFirstPage
                                ? "cursor-not-allowed text-neutral-light"
                                : "cursor-pointer"
                            }`}
                          >
                            first_page
                          </button>

                          {/* <button type="button" onClick={handlePreviousPage} disabled={isFirstPage}>
                          <span class="material-symbols-outlined">
                            chevron_left
                          </span>
                        </button> */}
                          <button
                            type="button"
                            onClick={handlePreviousPage}
                            disabled={isFirstPage}
                            className={`material-symbols-outlined ${
                              isFirstPage
                                ? "cursor-not-allowed text-neutral-light"
                                : "cursor-pointer"
                            }`}
                          >
                            chevron_left
                          </button>
                          {/* <button type="button" onClick={handleNextPage} disabled={isLastPage}>
                          <span class="material-symbols-outlined">
                            chevron_right
                          </span>
                        </button> */}
                          <button
                            type="button"
                            onClick={handleNextPage}
                            disabled={isLastPage}
                            className={`material-symbols-outlined ${
                              isLastPage
                                ? "cursor-not-allowed text-neutral-light"
                                : "cursor-pointer"
                            }`}
                          >
                            chevron_right
                          </button>
                          {/* <button type="button" className="disabled:text-neutral-light" onClick={handleLastPage} disabled={isLastPage}>
                          <span className="material-symbols-outlined text-inherit">
                            last_page
                          </span>
                        </button> */}
                          <button
                            type="button"
                            className={`material-symbols-outlined ${
                              isLastPage
                                ? "cursor-not-allowed text-neutral-light"
                                : "cursor-pointer"
                            }`}
                            onClick={handleLastPage}
                            disabled={isLastPage}
                          >
                            last_page
                          </button>
                        </div>
                      </div>

                      {/* <div className="flex justify-between items-center">
                                                <span>
                                                    Showing {Math.min(
                                                        (payload.pagination.pageNumber - 1) *
                                                            payload.pagination.pageSize +
                                                            1,
                                                        totalCount
                                                    )}{" "}
                                                    to{" "}
                                                    {Math.min(
                                                        payload.pagination.pageNumber *
                                                            payload.pagination.pageSize,
                                                        totalCount
                                                    )}{" "}
                                                    of {totalCount} entries
                                                </span>
                                                <select
                                                    value={payload.pagination.pageSize}
                                                    onChange={(e) => {
                                                        const size = Number(e.target.value);
                                                        setPayload((prev) => ({
                                                            ...prev,
                                                            pagination: {
                                                                ...prev.pagination,
                                                                pageSize: size,
                                                            },
                                                        }));
                                                    }}
                                                    className="bg-base-light text-sm px-2 py-1 rounded-md"
                                                >
                                                    {[5, 10, 20, 30, 40, 50].map((size) => (
                                                        <option key={size} value={size}>
                                                            {size}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div className="flex items-center gap-2">
                                                    <button
                                                        type="button"
                                                        onClick={handleFirstPage}
                                                        disabled={isFirstPage}
                                                        className={`material-symbols-outlined ${isFirstPage ? 'cursor-not-allowed text-neutral-light' : 'cursor-pointer'}`}
                                                    >
                                                        first_page
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={handlePreviousPage}
                                                        disabled={isFirstPage}
                                                        className={`material-symbols-outlined ${isFirstPage ? 'cursor-not-allowed text-neutral-light' : 'cursor-pointer'}`}
                                                    >
                                                        chevron_left
                                                    </button>
                                                    <span>
                                                        Page{" "}
                                                        <input
                                                            type="number"
                                                            value={payload.pagination.pageNumber}
                                                            onChange={(e) => setPayload((prev) => ({
                                                                ...prev,
                                                                pagination: { ...prev.pagination, pageNumber: Number(e.target.value) }
                                                            }))}
                                                            className="border w-12 px-1 py-1 rounded-md"
                                                        />{" "}
                                                        of {totalPages}
                                                    </span>
                                                    <button
                                                        type="button"
                                                        onClick={handleNextPage}
                                                        disabled={isLastPage}
                                                        className={`material-symbols-outlined ${isLastPage ? 'cursor-not-allowed text-neutral-light' : 'cursor-pointer'}`}
                                                    >
                                                        chevron_right
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={handleLastPage}
                                                        disabled={isLastPage}
                                                        className={`material-symbols-outlined ${isLastPage ? 'cursor-not-allowed text-neutral-light' : 'cursor-pointer'}`}
                                                    >
                                                        last_page
                                                    </button>
                                                </div>
                      </div> */}
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
