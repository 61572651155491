// import React from 'react'
import React, { useState, useEffect } from "react";
import coverImage from "../../assets/img/coverImage.png";
import CurrencyFormat from "react-currency-format";
import Input from "../../components/ui/Input";
import Button from "../../components/ui/Button";
import toast, { Toaster } from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
// import Select from "../components/ui/Select";
// import File from "../components/ui/File";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useNavigate } from "react-router-dom";
import ReactHTMLTableToExcel from "react-html-table-to-excel"; // Import ReactHTMLTableToExcel
//import { Data } from "../pages/defaultData";
import { apiService } from "../../api.service";
import loadingBarService from "../../service/loadingbar.Service";
import { saveAs } from "file-saver";
import FileSaver from "file-saver";
import Tippy from "@tippyjs/react";
import moment from "moment";
import {
  Description,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import _ from "lodash";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { tab } from "@testing-library/user-event/dist/tab";
import { service } from "../../service";
import { useGlobalContext } from "../../context/global.context";
import Select from "../../components/ui/Select";
import Textarea from "../../components/ui/Textarea";

export const InvoiceApproval = () => {
  const { setMessage, setError } = useGlobalContext();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [disableAboutToExpireSelect, setDisableAboutToExpireSelect] =
    useState(true);

  const [invoiceSearchOptions, setInvoiceSearchOptions] = useState([]);

  const [disableMonthArray, setDisableMonthArray] = useState([]);
  const [disabledMonths, setDisabledMonths] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  // const [disabledMonthArray, setDisabledMonthArray] = useState([]);
  const [minInvoiceMonth, setMinInvoiceMonth] = useState("");
  const [activeTabName, setActiveTabName] = useState("");
  const [invoiceApprovalStatusOptions, setInvoiceApprovalStatusOptions] =
    useState([]);
  const [gstStatusOptions, setgstStatusOptions] = useState([]);
  const [showInvoiceSummary, setShowInvoiceSummary] = useState(false);

  const [sorting, setSorting] = React.useState([]);
  const [payload, setPayload] = useState({
    pagination: {
      pageNumber: 1,
      pageSize: 5,
    },
  });
  const [showDateRangeModal, setShowDateRangeModal] = useState(false);
  const [invoiceSummaryData, setInvoiceSummaryData] = useState({});
  const [invoiceData, setInvoiceData] = useState({});
  const [invoiceRowData, setInvoiceRowData] = useState({});
  const [showViewInvoiceModal, setShowViewInvoiceModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [noRecordFound, setNoRecordFound] = useState(false);
  const [filterModal, filterdataModal] = useState(false);
  const [disableViewInvoiceBtn, setDisableViewInvoiceBtn] = useState(false);
  const [invoiceStatusOptions, setInvoiceStatusOptions] = useState([]);

  const columnHelper = createColumnHelper();
  const getButtonStyles = (status) => {
    const baseClasses =
      "w-fit px-4 flex items-center justify-center rounded-full font-semibold";
    switch (status) {
      case "pending":
        return `${baseClasses} bg-warn-lightest text-warn capitalize`;
      case "rejected":
        return `${baseClasses} bg-neutral-lightest text-danger capitalize`;
      case "approved":
        return `${baseClasses} bg-success-lightest text-success capitalize`;
      case "referback":
        return `${baseClasses} bg-neutral-lightest text-neutral-darkest capitalize`;
      default:
        return `${baseClasses} bg-warn-lightest text-warn.lightest capitalize`;
    }
  };

  const viewInvoiceSummary = async () => {
    try {
      if (showInvoiceSummary) {
        setShowInvoiceSummary(false);
        return;
      }

      setShowInvoiceSummary(true);
      const payload = {
        dealerCode: invoiceRowData.dealerCode,
        invoiceMonth: moment(invoiceRowData.invoiceMonth, "MM-YYYY").format(
          "YYYY-MM"
        ),
      };
      console.log("paylaod", payload);
      await apiService
        .invoiceSummary(payload)
        .then((res) => {
          setInvoiceSummaryData(res.data.data);
        })
        .catch((error) => {
          service.notifyApiError(error, setMessage, setError);
        });
    } catch (error) {
      toast.error("Error downloading the PDF");
    } finally {
      loadingBarService.complete(); // Complete the loading bar
    }
  };

  const columnsInvoiceList = [
    columnHelper.accessor("dealerCode", {
      header: "Dealer Code",
    }),
    columnHelper.accessor("dealerName", {
      header: "Dealer Name",
    }),

    columnHelper.accessor("invoiceNumber", {
      header: "Invoice Number",
    }),
    columnHelper.accessor("invoiceMonth", {
      header: "Invoice Month",
      cell: (data) => {
        console.log(data.getValue());

        return (
          <span>
            {data.getValue()
              ? moment(data.getValue(), "MM-YYYY").format("MMM YYYY")
              : "-"}
          </span>
        );
      },
    }),
    columnHelper.accessor("invoiceDate", {
      header: "Invoice Date",
      cell: (data) => {
        return (
          <span>
            {data.getValue()
              ? moment(data.getValue()).format("Do MMM YYYY")
              : "-"}
          </span>
        );
      },
    }),

    columnHelper.accessor("status", {
      header: "Status",
      cell: (data) => {
        const status = data.getValue();
        return (
          <span className={`px-2 py-1 rounded ${getButtonStyles(status)}`}>
            {status}
          </span>
        );
      },
    }),

    columnHelper.accessor("comments", {
      header: "Comments",
      cell: (data) => {
        return (
          <span>
            {data.getValue()
              ? moment(data.getValue()).format("DD-MM-YYYY")
              : "-"}
          </span>
        );
      },
    }),
    columnHelper.accessor("totalPolicyCount", {
      header: "Total Policy Count",
    }),
    columnHelper.accessor("totalPolicyPremium", {
      header: "Total Policy Premium",
      cell: (data) => {
        return (
          <span>
            {data.getValue() ? (
              <CurrencyFormat
                value={data.getValue()}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
              />
            ) : (
              "-"
            )}
          </span>
        );
      },
    }),
    columnHelper.accessor("totalGST", {
      header: "Total GST",
      cell: (data) => {
        return (
          <span>
            {data.getValue() ? (
              <CurrencyFormat
                value={data.getValue()}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
              />
            ) : (
              "-"
            )}
          </span>
        );
      },
    }),
    columnHelper.accessor("finalPremium", {
      header: "Final Premium",
      cell: (data) => {
        return (
          <span>
            {data.getValue() ? (
              <CurrencyFormat
                value={data.getValue()}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
              />
            ) : (
              "-"
            )}
          </span>
        );
      },
    }),

    columnHelper.accessor("updatedDate", {
      header: "Updated Date",

      cell: (data) => {
        return (
          <span>
            {data.getValue()
              ? moment(data.getValue()).format("Do MMM YYYY")
              : "-"}
          </span>
        );
      },
    }),
    columnHelper.accessor("transactionDate", {
      header: "Created Date",

      cell: (data) => {
        return (
          <span>
            {data.getValue()
              ? moment(data.getValue()).format("Do MMM YYYY")
              : "-"}
          </span>
        );
      },
    }),

    columnHelper.accessor("action", {
      header: "Action",
      meta: {
        sticky: "right", // Custom metadata to use for styling
      },
      cell: (data) => {
        return (
          <div className="flex justify-center items-center">
            {/* View Policy Button */}
            <Tippy
              content="View Invoice"
              placement="right"
              arrow={false}
              className="rounded-sm text-xs"
              delay={1000}
            >
              <button
                type="button"
                className="mt-1 mr-1"
                onClick={() => {
                  setShowViewInvoiceModal(true);
                  setInvoiceRowData(data.row.original);
                }}
              >
                <span className="material-symbols-outlined text-neutral-darkest text-sm">
                  more_vert
                </span>
              </button>
            </Tippy>
          </div>
        );
      },
    }),
  ];
  const columnsInvoiceSummary = [
    columnHelper.accessor("", {
      header: "#",
    }),

    columnHelper.accessor("planName", {
      header: "Product Name",
      cell: (row) => {
        return <span className="font-semibold">{row.getValue()}</span>;
      },
      footer: "Total",
    }),
    columnHelper.accessor("count", {
      header: "Count Of Policies",
      footer: invoiceSummaryData?.total?.count,
    }),
    columnHelper.accessor("policyPremium", {
      header: "Policy Premium",
      cell: (data) => {
        return (
          <span>
            {data.getValue() ? (
              <CurrencyFormat
                value={data.getValue()}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
              />
            ) : (
              "-"
            )}
          </span>
        );
      },
      footer: () => {
        return (
          <span>
            {invoiceSummaryData?.total?.policyPremium ? (
              <CurrencyFormat
                value={invoiceSummaryData?.total?.policyPremium}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
              />
            ) : (
              "-"
            )}
          </span>
        );
      },
    }),
    columnHelper.accessor("policyGST", {
      header: "Policy Gst",
      cell: (data) => {
        return (
          <span>
            {data.getValue() ? (
              <CurrencyFormat
                value={data.getValue()}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
              />
            ) : (
              "-"
            )}
          </span>
        );
      },
      footer: () => {
        return (
          <span>
            {invoiceSummaryData?.total?.policyGST ? (
              <CurrencyFormat
                value={invoiceSummaryData?.total?.policyGST}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
              />
            ) : (
              "-"
            )}
          </span>
        );
      },
    }),
    columnHelper.accessor("totalPolicyPremium", {
      header: "Total Policy Premium",
      cell: (data) => {
        return (
          <span>
            {data.getValue() ? (
              <CurrencyFormat
                value={data.getValue()}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
              />
            ) : (
              "-"
            )}
          </span>
        );
      },
      footer: () => {
        return (
          <span>
            {invoiceSummaryData?.total?.totalPolicyPremium ? (
              <CurrencyFormat
                value={invoiceSummaryData?.total?.totalPolicyPremium}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
              />
            ) : (
              "-"
            )}
          </span>
        );
      },
    }),
    columnHelper.accessor("commission", {
      header: "Commission",
      cell: (data) => {
        return (
          <span>
            {data.getValue() ? (
              <CurrencyFormat
                value={data.getValue()}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
              />
            ) : (
              "-"
            )}
          </span>
        );
      },
      footer: () => {
        return (
          <span>
            {invoiceSummaryData?.total?.commission ? (
              <CurrencyFormat
                value={invoiceSummaryData?.total?.commission}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
                className=" font-bold text-base"
              />
            ) : (
              "-"
            )}
          </span>
        );
      },
    }),
  ];

  const table = useReactTable({
    columns: columnsInvoiceList,
    data: filteredData.length > 0 ? filteredData : data,
    //onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    //debugTable: true,
    manualPagination: true,
  });

  console.log(invoiceSummaryData);
  const tableInvoiceSummary = useReactTable({
    columns: columnsInvoiceSummary,
    data: invoiceSummaryData?.summary || [],
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    //debugTable: true,
    manualPagination: true,
  });

  const handleNextPage = () => {
    setPayload((prev) => {
      const nextPage = prev.pagination.pageNumber + 1;
      return {
        ...prev,
        pagination: {
          ...prev.pagination,
          pageNumber: nextPage > totalPages ? totalPages : nextPage,
        },
      };
    });
  };

  const handleLastPage = () => {
    setPayload((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        pageNumber: totalPages,
      },
    }));
  };
  const handlePreviousPage = () => {
    setPayload((prev) => {
      const prevPage = prev.pagination.pageNumber - 1;
      return {
        ...prev,
        pagination: {
          ...prev.pagination,
          pageNumber: prevPage < 1 ? 1 : prevPage,
        },
      };
    });
  };

  const handleFirstPage = () => {
    setPayload((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        pageNumber: 1,
      },
    }));
  };

  const fetchInvoiceSummary = async (request) => {
    //const loadingToast = toast.loading("Loading data...");
    setLoading(true);
    try {
      // const request = {
      //   search: payload.search,
      //   duration: payload.duration,
      //   pageSize: payload.pageSize,
      //   pageNumber: payload.pageNumber,
      // };
      //  ( alert(9))
      const response = await apiService.invoiceSummary(request);
      console.log(response.data.data);
      setInvoiceSummaryData(response.data.data);
      console.log(invoiceSummaryData);

      // setInvoiceSummaryFooterData(response.data.data.total);
      console.log(response.data.data);
      setData(response.data.data);
      setTotalCount(response.data.totalCount);
      setNoRecordFound(response.data.data.length === 0);
      console.log(data);
      setShowViewInvoiceModal(true);
    } catch (error) {
      console.error("Error fetching policy data:", error);
      // toast.error("Error fetching policy data");
      service.notifyApiError(error, setMessage, setError);
    } finally {
      //toast.dismiss(loadingToast);
      setLoading(false);
    }
  };

  const onSelectInvoiceStatus = (value) => {
    // console.log(e);
    updateInvoiceStatusForm.setFieldValue("status", value);
  };
  const fetchInvoice = async () => {
    //const loadingToast = toast.loading("Loading data...", { duration: 5000 });
    setLoading(true);
    try {
      console.log(payload);
      const response = await apiService.invoice(payload);
      const invoices = response.data.data;
      if (response?.data?.data.length === 0) {
        setNoRecordFound(true);
      }
      setData(invoices);
      setDisableMonthArray(response.data.disabledMonthArray || []);
      extractDisabledMonths(response.data.disabledMonthArray || []);
      setMinInvoiceMonth(response.data.minInvoiceMonth);
      setFilteredData(invoices);
      setTotalCount(response.data.totalCount || invoices.length);
      setNoRecordFound(invoices.length === 0);
      console.log(response.data.data);
      setInvoiceData(response.data.data);

      // setInvoiceSummaryFooterData(response.data.data.total);
      console.log(response.data.data);
      // setData(response.data.data);
      // setTotalCount(response.data.totalCount);
      // setNoRecordFound(response.data.data.length === 0);
      console.log(data);
    } catch (error) {
      console.error("Error fetching policy data:", error);
      // toast.error("Error fetching policy data");
      service.notifyApiError(error, setMessage, setError);

      setNoRecordFound(true);
    } finally {
      //toast.dismiss(loadingToast);
      setLoading(false);
      setDisableViewInvoiceBtn(false);
    }
  };
  const extractDisabledMonths = (disableMonthArray) => {
    const uniqueDisabledMonths = Array.from(
      new Set(
        disableMonthArray
          .map((dateStr) => moment(dateStr, "MM-YYYY").format("YYYY-MM"))
          .filter((month) => month !== "Invalid date")
      )
    );
    setDisabledMonths(uniqueDisabledMonths);
  };

  const resetState = () => {
    setSelectedTabIndex(0); // Resetting selected tab index to default
    setData([]); // Clearing data array
    setTotalCount(0); // Resetting total count to 0
    setDisableAboutToExpireSelect(true); // Disabling a particular selection
    setFilteredData([]); // Clearing filtered data
    setActiveTabName(""); // Resetting active tab name
    setSorting([]); // Clearing sorting array
    setPayload({
      pagination: {
        pageNumber: 1,
        pageSize: 5, // Reset pagination to page 1 and default page size
      },
    });
    setShowDateRangeModal(false); // Hide date range modal
    filterdataModal(false); // Close filter data modal
    setLoading(true); // Set loading to true
    setNoRecordFound(false); // Reset no records found flag
    dateFilterForm.resetForm();
  };

  useEffect(() => {
    console.log("Payload updated:");
    fetchInvoice();
  }, [payload]);

  useEffect(() => {
    if (data?.length == 0) {
      setNoRecordFound(true);
    } else {
      setNoRecordFound(false);
    }
  }, [data]);
  const dateFilterForm = useFormik({
    initialValues: {
      fromDate: "",
      toDate: "",
    },
    validationSchema: Yup.object().shape({
      fromDate: Yup.date().required("Required"),
      toDate: Yup.date().required("Required"),
    }),
    onSubmit: async (values) => {
      console.log(values);
      setPayload((prev) => ({
        ...prev,
        fromDate: values.fromDate,
        toDate: values.toDate,
      }));
      console.log(payload);
    },
  });

  const updateInvoiceStatusForm = useFormik({
    initialValues: {
      status: "",
      comments: "",
    },
    validationSchema: Yup.object().shape({
      status: Yup.string().required("Required"),
      comments: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      const payload = {
        invoiceNumber: invoiceRowData.invoiceNumber,
        status: values.status,
        comments: values.comments,
      };
      await apiService
        .updateInvoiceStatus(payload)
        .then((res) => {
          toast.success(res.data.message);
          setShowViewInvoiceModal(false);
          resetState();
        })
        .catch((error) => {
          service.notifyApiError(error?.response?.data?.message);
        });
    },
  });

  const totalPages = Math.ceil(totalCount / payload.pagination.pageSize);
  const isLastPage = payload.pagination.pageNumber === totalPages;
  const isFirstPage = payload.pagination.pageNumber === 1;

  const generateMonthOptions = () => {
    const months = [];
    const currentMonth = moment().startOf("month");
    for (let i = 0; i < 12; i++) {
      const monthOption = currentMonth.clone().subtract(i, "months");
      months.push({
        value: monthOption.format("YYYY-MM"),
        label: monthOption.format("MMM YYYY"),
        disabled: disabledMonths.includes(monthOption.format("YYYY-MM")),
      });
    }
    return months;
  };
  const monthOptions = generateMonthOptions();

  const fetchInvoiceStatusOptions = async () => {
    await apiService.invoiceStatusOptions().then((res) => {
      setInvoiceStatusOptions(res.data.data);
    });
  };

  const fetchSearchOptions = async () => {
    const payload = {
      type: "invoice",
    };
    await apiService
      .searchOptions(payload)
      .then((res) => {
        setInvoiceSearchOptions(res.data.data);
      })
      .catch((error) => {
        service.notifyApiError(error, setMessage, setError);
      });
  };
  useEffect(() => {
    fetchInvoiceStatusOptions();
    fetchSearchOptions();
  }, []);

  return (
    <>
      <Dialog
        open={showViewInvoiceModal}
        onClose={() => setShowViewInvoiceModal(false)}
        className="relative z-50 "
      >
        <div className="fixed inset-0 bg-black/75" aria-hidden="true" />

        <div className="fixed inset-0 flex w-screen  items-center justify-center p-4">
          <DialogPanel className="space-y-4 border bg-white border-neutral rounded h-fit max-h-full overflow-y-auto w-200">
            <div className="h-full">
              <form
                onSubmit={updateInvoiceStatusForm.handleSubmit}
                className="h-full"
              >
                <div className="bg-neutral-lightest border-b border-neutral px-6 py-3 flex flex-col sm:flex-row">
                  <span className="font-bold text-xl py-2 text-neutral-darkest">
                    Invoice details
                  </span>
                </div>
                <div className="px-8 py-4 flex flex-col overflow-y-auto">
                  <div className="w-80">
                    <div className="grid grid-cols-2 text-sm space-y-1">
                      <div className="text-sm">Dealer code</div>
                      <div>{invoiceRowData.dealerCode}</div>
                      <div className="text-sm">Dealer name</div>
                      <div>{invoiceRowData.dealerName}</div>
                      <div className="text-sm">Address</div>
                      <div>{invoiceRowData.address}</div>
                      <div className="text-sm">GSTIN</div>
                      <div>{invoiceRowData.gstNumber}</div>
                      <div className="text-sm">Invoice month</div>
                      <div>
                        {moment(invoiceRowData.invoiceMonth, "MM-YYYY").format(
                          "MMM YYYY"
                        )}
                      </div>
                      <div className="text-sm">Status</div>
                      <div className={getButtonStyles(invoiceRowData.status)}>
                        {invoiceRowData.status}
                      </div>
                    </div>
                  </div>

                  <div className="text-center">
                    <button
                      type="button"
                      className="underline text-sm "
                      onClick={viewInvoiceSummary}
                    >
                      {showInvoiceSummary ? "Hide" : "View"} Invoice
                    </button>
                  </div>
                  {showInvoiceSummary && (
                    <>
                      <div className="flex mb-4 overflow-x-scroll cursor-pointer mt-2">
                        <table
                          id="table-to-xls"
                          className="text-sm border border-neutral w-full"
                        >
                          <thead className="bg-primary text-white text-xs">
                            {tableInvoiceSummary
                              .getHeaderGroups()
                              .map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                  {headerGroup.headers.map((header) => {
                                    return (
                                      <th
                                        key={header.id}
                                        colSpan={header.colSpan}
                                        className="px-2 py-2 text-left"
                                      >
                                        {header.isPlaceholder ? null : (
                                          <div
                                            className={
                                              header.column.getCanSort()
                                                ? "cursor-pointer select-none"
                                                : ""
                                            }
                                            onClick={header.column.getToggleSortingHandler()}
                                            title={
                                              header.column.getCanSort()
                                                ? header.column.getNextSortingOrder() ===
                                                  "asc"
                                                  ? "Sort ascending"
                                                  : header.column.getNextSortingOrder() ===
                                                    "desc"
                                                  ? "Sort descending"
                                                  : "Clear sort"
                                                : undefined
                                            }
                                          >
                                            {flexRender(
                                              header.column.columnDef.header,
                                              header.getContext()
                                            )}
                                            {{
                                              asc: " 🔼",
                                              desc: " 🔽",
                                            }[header.column.getIsSorted()] ??
                                              null}
                                          </div>
                                        )}
                                      </th>
                                    );
                                  })}
                                </tr>
                              ))}
                          </thead>
                          <tbody>
                            {loading ? (
                              <tr className="min-h-20">
                                <td
                                  colSpan={columnsInvoiceList.length}
                                  className="text-center p-4 min-h-20"
                                >
                                  Loading...
                                </td>
                              </tr>
                            ) : noRecordFound ? (
                              <tr className="min-h-20 py-2">
                                <td
                                  colSpan={columnsInvoiceSummary.length}
                                  className="text-center p-4 min-h-20 sticky left-96"
                                >
                                  No records found
                                </td>
                              </tr>
                            ) : (
                              tableInvoiceSummary
                                .getRowModel()
                                .rows.map((row) => (
                                  <tr
                                    key={row.id}
                                    className="text-xs even:bg-base-white py-2"
                                  >
                                    {row.getVisibleCells().map((cell) => (
                                      <td key={cell.id} className="px-2 py-2">
                                        {flexRender(
                                          cell.column.columnDef.cell,
                                          cell.getContext()
                                        )}
                                      </td>
                                    ))}
                                  </tr>
                                ))
                            )}
                          </tbody>

                          <tfoot className="bg-neutral-lighter text-sm font-semibold">
                            {tableInvoiceSummary
                              .getFooterGroups()
                              .map((footerGroup) => (
                                <tr key={footerGroup.id}>
                                  {footerGroup.headers.map((footer) => {
                                    return (
                                      <td
                                        key={footer.id}
                                        colSpan={footer.colSpan}
                                        className="px-2 py-2 text-left "
                                      >
                                        {footer.isPlaceholder ? null : (
                                          <div>
                                            {flexRender(
                                              footer.column.columnDef.footer,
                                              footer.getContext()
                                            )}
                                          </div>
                                        )}
                                      </td>
                                    );
                                  })}
                                </tr>
                              ))}
                          </tfoot>
                        </table>
                      </div>

                      <div className="mb-4 overflow-x-scroll ">
                        <table className="border w-full min-w-[4/5] sm:min-w-auto border-neutral-light">
                          <tr className="text-center bg-neutral-lighter px-2 py-2 text-xs">
                            <th
                              rowSpan="2"
                              className="border border-neutral-light"
                            >
                              HSN/SAC
                            </th>
                            <th
                              rowSpan="2"
                              className="border border-neutral-light"
                            >
                              Taxable Value
                            </th>
                            <th
                              colSpan="2"
                              className="border border-neutral-light py-2"
                            >
                              IGST
                            </th>
                            <th
                              colSpan="2"
                              className="border border-neutral-light"
                            >
                              SGST
                            </th>
                            <th
                              colSpan="2"
                              className="border border-neutral-light"
                            >
                              CGST
                            </th>
                          </tr>
                          <tr className="bg-neutral-lighter px-2 py-2 text-xs">
                            <th className="py-1 border border-neutral-light">
                              Rate
                            </th>
                            <th className="py-1 border border-neutral-light">
                              Amount
                            </th>
                            <th className="py-1 border border-neutral-light">
                              Rate
                            </th>
                            <th className="py-1 border border-neutral-light">
                              Amount
                            </th>
                            <th className="py-1 border border-neutral-light">
                              Rate
                            </th>
                            <th className="py-1 border border-neutral-light">
                              Amount
                            </th>
                          </tr>
                          <tr className="text-center mt-1 text-xs h-7">
                            <td>{}</td>
                            <td>
                              <CurrencyFormat
                                value={invoiceSummaryData?.tax?.taxableAmount}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"₹"}
                              />
                            </td>
                            <td>{invoiceSummaryData?.tax?.igst?.rate}</td>
                            <td>
                              <CurrencyFormat
                                value={invoiceSummaryData?.tax?.igst?.amount}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"₹"}
                              />
                            </td>
                            <td>{invoiceSummaryData?.tax?.sgst?.rate}</td>
                            <td>
                              <CurrencyFormat
                                value={invoiceSummaryData?.tax?.sgst?.amount}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"₹"}
                              />
                            </td>
                            <td>{invoiceSummaryData?.tax?.cgst?.rate}</td>
                            <td>
                              <CurrencyFormat
                                value={invoiceSummaryData?.tax?.cgst?.amount}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"₹"}
                              />
                            </td>
                          </tr>
                          <tr className="text-center mt-1 text-xs font-semibold h-7 bg-neutral-lighter">
                            <td>Total</td>
                            <td>
                              <CurrencyFormat
                                value={invoiceSummaryData?.tax?.taxableAmount}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"₹"}
                              />
                            </td>
                            <td></td>
                            <td>
                              <CurrencyFormat
                                value={invoiceSummaryData?.tax?.igst?.amount}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"₹"}
                              />
                            </td>
                            <td>-</td>
                            <td>
                              <CurrencyFormat
                                value={invoiceSummaryData?.tax?.sgst?.amount}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"₹"}
                              />
                            </td>
                            <td>-</td>
                            <td>
                              <CurrencyFormat
                                value={invoiceSummaryData?.tax?.cgst?.amount}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"₹"}
                              />
                            </td>
                          </tr>
                        </table>
                      </div>

                      <div className="mb-2 flex justify-end">
                        <div className="w-full sm:w-1/3">
                          <table className="w-full border border-neutral-light">
                            <tr className="bg-neutral-lightest px-4">
                              <td className="text-sm font-semibold w-40 px-4">
                                Taxable Amount
                              </td>
                              <td>
                                <CurrencyFormat
                                  value={invoiceSummaryData?.tax?.taxableAmount}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"₹"}
                                  className="text-sm font-semibold px-4"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="text-xs w-40 px-4">GST</td>
                              <td>
                                <CurrencyFormat
                                  value={invoiceSummaryData?.tax?.totalGST}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"₹"}
                                  className="text-sm px-4"
                                />
                              </td>
                            </tr>
                            <tr className="bg-neutral-lightest">
                              <td className="text-sm font-semibold w-40 px-4">
                                Gross Amount
                              </td>
                              <td>
                                <CurrencyFormat
                                  value={
                                    invoiceSummaryData?.tax?.commissionWithTax
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"₹"}
                                  className="text-sm font-semibold px-4"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="text-xs w-40 px-4">TDS</td>
                              <td>
                                <CurrencyFormat
                                  value={invoiceSummaryData?.tax?.tds.amount}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"₹"}
                                  className="text-sm px-4"
                                />
                              </td>
                            </tr>
                            <tr className="bg-primary-lightest border-b border-primary px-2 py-2">
                              <td className="text-sm font-semibold w-40 py-2 px-4">
                                Payable Amount
                              </td>
                              <td className="font-bold py-2 px-4">
                                <CurrencyFormat
                                  value={invoiceSummaryData?.tax?.finalAmount}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"₹"}
                                />
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </>
                  )}
                  <div>
                    {invoiceRowData?.status == "pending" && (
                      <div className="flex justify-center items-start px-8 mt-4">
                        <div className="flex flex-col">
                          <Select
                            {...updateInvoiceStatusForm.getFieldProps("status")}
                            formik={updateInvoiceStatusForm}
                            value={updateInvoiceStatusForm.values.status}
                            id="status"
                            name="status"
                            label="Status"
                            onSelect={onSelectInvoiceStatus}
                            options={invoiceStatusOptions.filter(
                              (it) =>
                                it.value !== "pending" &&
                                it.value !== "not_generated"
                            )}
                            className="mr-4 flex-col w-full md:w-60"
                          />
                        </div>
                        <div className="flex flex-col">
                          <Textarea
                            {...updateInvoiceStatusForm.getFieldProps(
                              "comments"
                            )}
                            formik={updateInvoiceStatusForm}
                            id="comments"
                            name="comments"
                            type="text"
                            label="Comments"
                            placeholder="Enter comments"
                            className="border border-gray-300 rounded-lg p-2 w-full"
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="flex items-center justify-center gap-4 mt-4">
                    <Button
                      type="button"
                      label="Cancel"
                      variant="ghost"
                      className="max-w-12"
                      onClick={() => setShowViewInvoiceModal(false)}
                    />
                    {invoiceRowData?.status == "pending" && (
                      <Button
                        type="submit"
                        label="Update Status"
                        variant="primary"
                        className="w-full sm:w-auto px-3 py-2"
                      />
                    )}
                  </div>
                </div>
              </form>
            </div>
          </DialogPanel>
        </div>
      </Dialog>

      <Dialog
        open={filterModal}
        onClose={() => filterdataModal(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/75" aria-hidden="true" />

        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <DialogPanel className=" space-y-4 border bg-white border-neutral rounded">
            <form onSubmit={dateFilterForm.handleSubmit}>
              <div className="bg-neutral-lightest  border-b border-neutral px-6 py-3 flex">
                <span className="font-bold text-xl py-2 text-neutral-darkest ">
                  Filter
                </span>
              </div>
              <div className="flex flex-col gap-y-4  p-4 ">
                <select
                  onChange={(e) => {
                    const typeId = Number(e.target.value);
                    setPayload((prev) => ({
                      ...prev,
                      approvalStatusId: typeId,
                    }));
                    console.log(payload);
                  }}
                  className=" py-1 rounded-sm focus:outline-primary text-base-black  mr-2"
                >
                  {invoiceSearchOptions.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.label}
                    </option>
                  ))}
                </select>

                <div className="relative flex items-center w-full md:w-auto">
                  <input
                    type="text"
                    id="search"
                    placeholder="Search..."
                    className="px-3 py-1 border border-neutral-dark rounded-l-sm focus:outline-primary text-base-black w-full md:w-auto"
                  />
                  <button
                    type="button"
                    onClick={() => {
                      setPayload((prev) => ({
                        ...prev,
                        search: {
                          field: document.getElementById("searchField").value,
                          value: document.getElementById("search").value,
                        },
                      }));
                    }}
                    className="bg-primary text-white border border-primary px-2 py-1  rounded-r-sm"
                  >
                    <span className="material-symbols-outlined flex items-center justify-center p-0">
                      search
                    </span>
                  </button>
                </div>
              </div>

              <div className="flex items-center justify-center gap-4 mt-4 px-12 pb-8">
                <Button
                  type="button"
                  label="Cancel"
                  variant="ghost"
                  className="max-w-12"
                  onClick={() => filterdataModal(false)}
                />
                <Button
                  type="submit"
                  label="Submit"
                  variant="primary"
                  className="max-w-16"
                />
              </div>
            </form>
          </DialogPanel>
        </div>
      </Dialog>

      <div className="flex flex-col w-full items-center overflow-hidden">
        <div className="sticky -z-10 w-full">
          <img
            src={coverImage}
            className=" w-full h-36 object-cover"
            alt="cover_image"
          />
        </div>
        <div className="w-11/12 bg-white -mt-28 md:-mt-20 border border-neutral-light rounded mx-2">
          <form>
            <div>
              <div className="bg-base-white px-6 py-4 border-b border-neutral-light rounded-t">
                <h3 className="text-xl font-semibold">Invoice Approval</h3>
                <p className="text-neutral-darker mt-1 text-sm">
                  Overview of transaction requests, including their statuses and
                  approval dates.
                </p>
              </div>
              <div className="px-6 py-4 pb-8">
                <div>
                  <Tabs
                    selectedIndex={selectedTabIndex}
                    selectedTabClassName="text-primary border-b-2 border-primary font-medium"
                  >
                    <TabList className="flex flex-col md:flex-row md:justify-between mb-4 text-neutral-dark text-sm items-center w-full ">
                      {/* Container for Month and View Invoice */}
                      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 w-full md:w-auto ">
                        <div className="flex items-center  gap-x-4 mb-2 md:mb-0 w-full">
                          <div className="label flex flex-col text-xs">
                            <label className="pl-2 ml-2">Invoice Status</label>
                            <select
                              value={payload.statusId || "statusId"}
                              onChange={(e) => {
                                const typeId = Number(e.target.value);
                                setPayload((prev) => ({
                                  ...prev,
                                  approvalStatusId: typeId,
                                }));
                              }}
                              className="px-3 py-1 focus:outline-primary text-base-black"
                            >
                              <option value="status">Select Status</option>
                              {invoiceStatusOptions.map((option) => (
                                <option key={option.value} value={option.id}>
                                  {option.label}
                                </option>
                              ))}
                            </select>

                            <button
                              type="button"
                              className="mt-1 md:hidden"
                              onClick={() => {
                                setShowDateRangeModal(true);
                              }}
                            >
                              <span className="material-symbols-outlined text-neutral-darkest justify-end">
                                filter_alt
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Container for Pending and Search */}
                      <div className=" flex-col md:flex-row  items-center md:gap-x-4 gap-2 w-full md:w-auto mt-2 md:mt-0  hidden md:block">
                        <div className="flex items-center  ">
                          <button
                            type="button"
                            className="mt-1"
                            onClick={() => {
                              resetState();
                            }}
                          >
                            <span class="material-symbols-outlined text-neutral-darkest">
                              refresh
                            </span>
                          </button>
                          <select
                            id="searchField"
                            className="px-3 py-1 rounded-sm focus:outline-primary text-base-black  mr-2"
                          >
                            {invoiceSearchOptions.map((type) => (
                              <option key={type.id} value={type.value}>
                                {type.label}
                              </option>
                            ))}
                          </select>
                          <div className="relative flex items-center  w-full md:w-auto">
                            <input
                              type="text"
                              id="searchValue"
                              placeholder="Search..."
                              className="px-3 py-1 border border-neutral-dark rounded-l-sm focus:outline-primary text-base-black w-full md:w-auto"
                            />
                            <button
                              type="button"
                              onClick={(event) => {
                                setPayload((prev) => ({
                                  ...prev,
                                  search: {
                                    field:
                                      document.getElementById("searchField")
                                        .value,
                                    value:
                                      document.getElementById("searchValue")
                                        .value,
                                  },
                                }));
                              }}
                              className="bg-primary text-white border border-primary px-2 py-0.5 rounded-r-sm "
                            >
                              <span className="material-symbols-outlined flex items-center justify-center">
                                search
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </TabList>

                    <TabPanel>
                      <div className="relative flex mb-4 overflow-x-scroll cursor-pointer">
                        <table className="text-sm border border-neutral w-fit">
                          <thead className="bg-primary text-white text-xs">
                            {table.getHeaderGroups()?.map((headerGroup) => (
                              <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                  return (
                                    <th
                                      key={header.id}
                                      colSpan={header.colSpan}
                                      className={`px-2 py-1 min-w-[150px] max-w-[300px] bg-primary ${
                                        header.column.columnDef.accessorKey ===
                                        "action"
                                          ? "text-center"
                                          : "text-left"
                                      }`}
                                      style={{
                                        position: header.column.columnDef.meta
                                          ?.sticky
                                          ? "sticky"
                                          : "relative",
                                        [header.column.columnDef.meta
                                          ?.sticky]: 0,

                                        zIndex: header.column.columnDef.meta
                                          ?.sticky
                                          ? 1
                                          : "auto",
                                      }}
                                    >
                                      {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                    </th>
                                  );
                                })}
                              </tr>
                            ))}
                          </thead>

                          <tbody>
                            {loading ? (
                              <tr className="min-h-20">
                                <td
                                  colSpan={columnsInvoiceList.length}
                                  className="text-center p-4 min-h-20"
                                >
                                  Loading...
                                </td>
                              </tr>
                            ) : noRecordFound ? (
                              <tr className="min-h-20">
                                <td
                                  colSpan={columnsInvoiceList.length}
                                  className="text-center p-4 min-h-20"
                                >
                                  No records found
                                </td>
                              </tr>
                            ) : (
                              table.getRowModel().rows.map((row, index) => (
                                <tr
                                  key={row.id}
                                  className="max-h-32 py-1 text-xs even:bg-base-white odd:bg-white"
                                >
                                  {row.getVisibleCells().map((cell) => (
                                    <td
                                      key={cell.id}
                                      className="px-2"
                                      style={{
                                        position: cell.column.columnDef.meta
                                          ?.sticky
                                          ? "sticky"
                                          : "relative",
                                        [cell.column.columnDef.meta?.sticky]: 0,

                                        zIndex: cell.column.columnDef.meta
                                          ?.sticky
                                          ? 1
                                          : "auto",
                                        background:
                                          index % 2 == 0
                                            ? "#F1F5F9"
                                            : "#FFFFFF",
                                      }}
                                    >
                                      {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                      )}
                                    </td>
                                  ))}
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="flex flex-col md:flex-row items-center w-full justify-end text-xs gap-4">
                        <div className="flex items-center gap-2">
                          {" "}
                          <select
                            value={payload.pageSize}
                            onChange={(e) => {
                              const size = Number(e.target.value);
                              setPayload((prev) => ({
                                ...prev,
                                pagination: {
                                  pageSize: size,
                                  pageNumber: 1,
                                },
                              }));
                            }}
                          >
                            {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map(
                              (pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                  {pageSize}
                                </option>
                              )
                            )}
                          </select>
                          <span className="font-semibold">
                            <span>Showing </span>
                            {1 +
                              (payload.pagination.pageNumber - 1) *
                                payload.pagination.pageSize}{" "}
                            to{" "}
                            {payload.pagination.pageNumber *
                              payload.pagination.pageSize -
                              payload.pagination.pageSize +
                              data?.length}{" "}
                            of {totalCount} Entries
                          </span>
                        </div>
                        {/* <button type="button" disabled={isFirstPage} onClick={handleFirstPage}>
                                <span className="material-symbols-outlined">
                                  first_page
                                </span>
                              </button> */}
                        <div className="flex items-center space-x-2 mt-2 md:mt-0">
                          <button
                            type="button"
                            disabled={isFirstPage}
                            onClick={handleFirstPage}
                            className={`material-symbols-outlined ${
                              isFirstPage
                                ? "cursor-not-allowed text-neutral-light"
                                : "cursor-pointer"
                            }`}
                          >
                            first_page
                          </button>

                          {/* <button type="button" onClick={handlePreviousPage} disabled={isFirstPage}>
                                <span class="material-symbols-outlined">
                                  chevron_left
                                </span>
                              </button> */}
                          <button
                            type="button"
                            onClick={handlePreviousPage}
                            disabled={isFirstPage}
                            className={`material-symbols-outlined ${
                              isFirstPage
                                ? "cursor-not-allowed text-neutral-light"
                                : "cursor-pointer"
                            }`}
                          >
                            chevron_left
                          </button>
                          {/* <button type="button" onClick={handleNextPage} disabled={isLastPage}>
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </button> */}
                          <button
                            type="button"
                            onClick={handleNextPage}
                            disabled={isLastPage}
                            className={`material-symbols-outlined ${
                              isLastPage
                                ? "cursor-not-allowed text-neutral-light"
                                : "cursor-pointer"
                            }`}
                          >
                            chevron_right
                          </button>
                          {/* <button type="button" className="disabled:text-neutral-light" onClick={handleLastPage} disabled={isLastPage}>
                                <span className="material-symbols-outlined text-inherit">
                                  last_page
                                </span>
                              </button> */}
                          <button
                            type="button"
                            className={`material-symbols-outlined ${
                              isLastPage
                                ? "cursor-not-allowed text-neutral-light"
                                : "cursor-pointer"
                            }`}
                            onClick={handleLastPage}
                            disabled={isLastPage}
                          >
                            last_page
                          </button>
                        </div>
                      </div>

                      {/* <div className="flex justify-between items-center">
                                                      <span>
                                                          Showing {Math.min(
                                                              (payload.pagination.pageNumber - 1) *
                                                                  payload.pagination.pageSize +
                                                                  1,
                                                              totalCount
                                                          )}{" "}
                                                          to{" "}
                                                          {Math.min(
                                                              payload.pagination.pageNumber *
                                                                  payload.pagination.pageSize,
                                                              totalCount
                                                          )}{" "}
                                                          of {totalCount} entries
                                                      </span>
                                                      <select
                                                          value={payload.pagination.pageSize}
                                                          onChange={(e) => {
                                                              const size = Number(e.target.value);
                                                              setPayload((prev) => ({
                                                                  ...prev,
                                                                  pagination: {
                                                                      ...prev.pagination,
                                                                      pageSize: size,
                                                                  },
                                                              }));
                                                          }}
                                                          className="bg-base-light text-sm px-2 py-1 rounded-md"
                                                      >
                                                          {[5, 10, 20, 30, 40, 50].map((size) => (
                                                              <option key={size} value={size}>
                                                                  {size}
                                                              </option>
                                                          ))}
                                                      </select>
                                                      <div className="flex items-center gap-2">
                                                          <button
                                                              type="button"
                                                              onClick={handleFirstPage}
                                                              disabled={isFirstPage}
                                                              className={`material-symbols-outlined ${isFirstPage ? 'cursor-not-allowed text-neutral-light' : 'cursor-pointer'}`}
                                                          >
                                                              first_page
                                                          </button>
                                                          <button
                                                              type="button"
                                                              onClick={handlePreviousPage}
                                                              disabled={isFirstPage}
                                                              className={`material-symbols-outlined ${isFirstPage ? 'cursor-not-allowed text-neutral-light' : 'cursor-pointer'}`}
                                                          >
                                                              chevron_left
                                                          </button>
                                                          <span>
                                                              Page{" "}
                                                              <input
                                                                  type="number"
                                                                  value={payload.pagination.pageNumber}
                                                                  onChange={(e) => setPayload((prev) => ({
                                                                      ...prev,
                                                                      pagination: { ...prev.pagination, pageNumber: Number(e.target.value) }
                                                                  }))}
                                                                  className="border w-12 px-1 py-1 rounded-md"
                                                              />{" "}
                                                              of {totalPages}
                                                          </span>
                                                          <button
                                                              type="button"
                                                              onClick={handleNextPage}
                                                              disabled={isLastPage}
                                                              className={`material-symbols-outlined ${isLastPage ? 'cursor-not-allowed text-neutral-light' : 'cursor-pointer'}`}
                                                          >
                                                              chevron_right
                                                          </button>
                                                          <button
                                                              type="button"
                                                              onClick={handleLastPage}
                                                              disabled={isLastPage}
                                                              className={`material-symbols-outlined ${isLastPage ? 'cursor-not-allowed text-neutral-light' : 'cursor-pointer'}`}
                                                          >
                                                              last_page
                                                          </button>
                                                      </div>
                            </div> */}
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
