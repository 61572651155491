// import jwt from "jsonwebtoken";
import { jwtDecode } from "jwt-decode";
import CryptoJS from "crypto-js";

export const isTokenExpired = (accessToken) => {
  let decodedToken = jwtDecode(accessToken);
  let currentDate = new Date();

  // JWT exp is in seconds
  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    return true;
  } else {
    return false;
  }
};

// export const encrypt = (value) => {
//   const encryptedHex = CryptoJS.AES.encrypt(
//     String(value),
//     process.env.REACT_APP_ENCRYPTION_KEY
//   ).ciphertext.toString(CryptoJS.enc.Hex);
//   console.log(encryptedHex);
//   return encryptedHex;
// };

// export const decrypt = (encryptedHexMessage) => {
//   const encryptedHex = CryptoJS.enc.Hex.parse(encryptedHexMessage);
//   const encryptedBase64 = CryptoJS.enc.Base64.stringify(encryptedHex); // Convert to Base64 format for decryption

//   const decryptedBytes = CryptoJS.AES.decrypt(
//     String(encryptedBase64),
//     process.env.REACT_APP_ENCRYPTION_KEY
//   );
//   const originalMessage = decryptedBytes.toString(CryptoJS.enc.Utf8);
//   // const encryptedHex = CryptoJS.enc.Hex.parse(value);
//   // const bytes = CryptoJS.AES.decrypt(
//   //   String(encryptedHex),
//   //   process.env.REACT_APP_ENCRYPTION_KEY
//   // );
//   return originalMessage;
// };

// Modify the encryption function
export const encrypt = (value) => {
  const encryptionKey = process.env.REACT_APP_SECRET_KEY;
  const encrypted = CryptoJS.AES.encrypt(
    String(value),
    encryptionKey
  ).toString();

  // URL-safe Base64 encode the encrypted string
  return urlSafeBase64Encode(encrypted);
};

// Modify the decryption function
export const decrypt = (encryptedValue) => {
  const encryptionKey = process.env.REACT_APP_SECRET_KEY;
  const decryptedFromBase64 = urlSafeBase64Decode(encryptedValue);

  const bytes = CryptoJS.AES.decrypt(
    String(decryptedFromBase64),
    encryptionKey
  );
  return bytes.toString(CryptoJS.enc.Utf8);
};

const urlSafeBase64Encode = (str) => {
  return btoa(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
};

// Function to decode URL-safe Base64
const urlSafeBase64Decode = (str) => {
  const padded = str.replace(/-/g, "+").replace(/_/g, "/");
  return atob(padded);
};
