import React from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import Button from "../components/ui/Button";
import { useGlobalContext } from "../context/global.context";

const ErrorDialog = () => {
  const { message, error, showErrorModal, setShowErrorModal } =
    useGlobalContext();

  const handleClose = () => {
    setShowErrorModal(false);
  };

  return (
    <Dialog
      open={showErrorModal}
      onClose={handleClose}
      className="relative z-50"
    >
      <div className="fixed inset-0 bg-black/75" aria-hidden="true" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel className="space-y-4 border bg-white border-neutral rounded w-full md:w-1/2 lg:w-1/3 mx-auto">
          <div className="overflow-x-auto">
            <div className="bg-neutral-lightest border-b border-neutral px-6 py-3 flex flex-col sm:flex-row">
              <span className="font-bold text-xl text-neutral-darkest">
                Error
              </span>
            </div>
            <div className="flex flex-col gap-x-4 px-4 sm:px-8 pt-4 pb-6">
              <b className="text-red-500">{message}</b>
              {error && <p>{error}</p>}
              <Button
                onClick={handleClose}
                className="mt-4 max-w-36 flex justify-center ml-32"
                label="Close"
              ></Button>
            </div>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
};
export default ErrorDialog;
