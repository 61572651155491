import React, { useState, useEffect } from "react";
import QRCode from "react-qr-code";
import { ResponsivePie } from "@nivo/pie";
import { service } from "../service";
import coverImage from "../assets/img/coverImage.png";
import { PieChart } from "../components/charts/PieChart";
import { apiService } from "../api.service";
import toast, { Toaster } from "react-hot-toast";
import * as helper from "../helper.js";
import { encrypt, decrypt } from "../helper.js";
import { useGlobalContext } from "../context/global.context.js";

export const DealerQRCode = () => {
  const { setMessage, setError } = useGlobalContext();

  const a = helper.encrypt(234);
  let encryptedDealerId;
  const dealerId = service.getSessionStorageItem("dealerInfo").dealerId;
  if (dealerId) {
    encryptedDealerId = helper.encrypt(dealerId);
  } else {
    toast.error("Error in getting dealer id");
  }
  const salesQRLink = `${process.env.REACT_APP_BASE_URL}/sales/generate-policy/${encryptedDealerId}`;
  const serviceQRLink = `${process.env.REACT_APP_BASE_URL}/service/generate-policy/${encryptedDealerId}`;
  const [qrReportData, setQrReportData] = useState({});

  const handleQrReports = async () => {
    const loadingToast = toast.loading("Loading...");
    try {
      await apiService.qrReport().then((response) => {
        setQrReportData(response.data.data);
      });
    } catch (error) {
      service.notifyApiError(error, setMessage, setError);
    } finally {
      toast.dismiss(loadingToast);
    }
  };

  const pieData = [
    {
      id: "Success",
      label: "Success",
      value: qrReportData.totalSold,
      color: "#A5CFFF",
    },
    {
      id: "Failed",
      label: "Failed",
      value: qrReportData.totalFailed,
      color: "#092E58",
    },
  ];

  useEffect(() => {
    handleQrReports();
  }, []);

  return (
    <>
      <div className="flex flex-col w-full items-center overflow-hidden">
        <div className="sticky -z-10 w-full">
          <img
            src={coverImage}
            className="w-full h-36 object-cover"
            alt="cover_image"
          />
        </div>
        <div className="w-full md:flex flex-col md:flex-row gap-5 md:px-10 px-2">
          <div className="left-qr-code md:w-[60%] w-full h-fit bg-white -mt-28 md:-mt-24 border border-neutral-light rounded mb-5 md:mb-0">
            <div className="bg-base-white px-6 py-4 border-b border-neutral-light rounded-t">
              <h3 className="text-xl font-semibold">
                QR Code Policy Generation
              </h3>
              <p className="text-neutral-darker mt-1 text-sm">
                Overview of transaction requests, including their statuses and
                approval dates.
              </p>
            </div>
            <div className="md:px-6 px-1 py-4 pb-8 flex flex-col md:flex-row justify-around">
              <div className="flex flex-col items-center py-5 w-full md:w-1/2">
                <QRCode
                  size={150}
                  style={{ height: "auto", maxWidth: "60%", width: "60%" }}
                  className="md:max-w-[60%] max-w-[50%]"
                  value={salesQRLink}
                  viewBox={`0 0 256 256`}
                />
                <span className=" text-sm text-center mt-2">Sales</span>
              </div>
              <div className="flex flex-col items-center py-5 w-full md:w-1/2">
                <QRCode
                  size={150}
                  style={{ height: "auto", maxWidth: "60%", width: "60%" }}
                  className="md:max-w-[60%] max-w-[50%]"
                  value={serviceQRLink}
                  viewBox={`0 0 256 256`}
                />
                <span className="text-sm text-center mt-2">Service</span>
              </div>
            </div>
          </div>
          <div className="right-pie-chart flex flex-col md:flex-row items-center justify-center w-full md:w-[40%] h-fit bg-white -mt-18 md:-mt-24 border border-neutral-light rounded">
            <div className="w-full md:w-3/5   h-72 mb-2 md:mb-0">
              <PieChart data={pieData} />
            </div>
            <div className=" w-full md:w-2/5 flex flex-col space-y-2 px-4 md:px-0 mb-2 md:mb-0">
              <p className="text-sm">
                Total Scans -{" "}
                <span className="text-base font-semibold">
                  {qrReportData.totalScans}
                </span>
              </p>
              <p className="text-sm">
                Payment Attempted -{" "}
                <span className="text-base  font-semibold">
                  {qrReportData.totalProcessToPayment}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
