import axios from "axios";
import { toast } from "react-hot-toast";
import { service } from "./service";
import { useNavigate } from "react-router-dom";
import { DealerListings } from "./pages/admin/DealerListings";
import {
  decryptTokenData,
  finalDecryption,
  finalEncryption,
} from "./utils/encryptionObfuscate";
import { jwtDecode } from "jwt-decode";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  timeout: 60000,
});
const accessToken = service.getSessionStorageItem("accessToken");

api.interceptors.request.use(
  async (config) => {
    const accessToken = service.getSessionStorageItem("accessToken");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  async (response) => {
    // Assuming your response data has an 'encrypted' flag or some way to determine if it's encrypted
    if (response.data) {
      try {
        let type = typeof response.data;

        if (type == "string") {
          response.data = finalDecryption(response.data);
        }
      } catch (error) {
        console.error("Decryption failed:", error);
        return Promise.reject(new Error("Decryption failed"));
      }
    }
    return response;
  },
  async (error) => {
    if (error.code === "ERR_NETWORK") {
      // window.location.href = `${process.env.REACT_APP_BASE_URL}/server-down`;

      return Promise.reject(error);
    }

    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data &&
      error.response.data.message === "Unauthorized - Refresh token expired"
    ) {
      // If refresh token is expired
      toast.error((t) => (
        <span>
          Your session has been expired. Please login! &nbsp;
          <button
            className="underline font-bold text-right"
            onClick={() =>
              window.location.replace(`${process.env.REACT_APP_BASE_URL}/login`)
            }
          >
            Goto Login Page
          </button>
        </span>
      ));
      return Promise.reject(error);
    }

    // Check if the error is due to an expired token
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      // Refresh the access token using your refresh token
      const accessToken = service.getSessionStorageItem("refreshToken");
      const refreshToken = service.getSessionStorageItem("refreshToken");

      if (refreshToken) {
        try {
          const newAccessToken = await apiService.refreshAccessToken(
            refreshToken
          );
          // const newAccessToken = await api.post("/auth/refresh-token", {
          //   refreshToken,
          // });

          // localStorage.setItem("accessToken", newAccessToken.data.accessToken);

          // Retry the original request with the new access token
          originalRequest.headers.authorization = `Bearer ${newAccessToken}`;
          return api(originalRequest);
        } catch (refreshError) {
          console.error("Failed to refresh token:", refreshError);
        }
      }
    }

    return Promise.reject(error);
  }
);

// api.interceptors.response.use(
//   (response) => {

//     console.log(response.data,'>>>>>>>>;;;')
//     return response;
//   },
//   async (error) => {
//     // const navigate = useNavigate();

//     console.log("int Err : ", error);
//     console.log(error.code);

//     if (error.code === "ERR_NETWORK") {
//       // alert(9);
//       // window.location.href = `${process.env.REACT_APP_BASE_URL}/server-down`;

//       return Promise.reject(error);
//     }

//     const originalRequest = error.config;
//     if (
//       error.response &&
//       error.response.status === 401 &&
//       error.response.data &&
//       error.response.data.message === "Unauthorized - Refresh token expired"
//     ) {
//       // If refresh token is expired
//       toast.error((t) => (
//         <span>
//           Your session has been expired. Please login! &nbsp;
//           <button
//             className="underline text-primary text-right"
//             onClick={() =>
//               window.location.replace(`${process.env.REACT_APP_BASE_URL}/auth/login`)
//             }
//           >
//             Goto Login
//           </button>
//         </span>
//       ));
//       return Promise.reject(error);
//     }

//     // Check if the error is due to an expired token
//     if (
//       error.response &&
//       error.response.status === 401 &&
//       !originalRequest._retry
//     ) {
//       originalRequest._retry = true;

//       // Refresh the access token using your refresh token
//       const refreshToken = service.getSessionStorageItem("refreshToken");
//       if (refreshToken) {
//         try {
//           const newAccessToken = await api.post("/refresh-token", {
//             refreshToken,
//           });

//           localStorage.setItem("accessToken", newAccessToken.data.accessToken);

//           // Retry the original request with the new access token
//           originalRequest.headers.authorization = `Bearer ${newAccessToken.data.accessToken}`;
//           return api(originalRequest);
//         } catch (refreshError) {
//           console.error("Failed to refresh token:", refreshError);
//         }
//       }
//     }

//     return Promise.reject(error);
//   }
// );

const getDecodedData = () => {
  const decryptedToken = decryptTokenData(accessToken);
  const data = jwtDecode(decryptedToken);
  return data?.isEncrypted;
};

const postEncryptPayload = async (endpoint, payload, cookies) => {
  if (accessToken) {
    let is_encrypted = getDecodedData();

    if (is_encrypted && payload) {
      payload = { payload: finalEncryption(payload) };
    }
  }

  return await api.post(endpoint, payload, cookies);
};

const getEncryptPayload = async (endpoint, payload, cookies) => {
  if (accessToken) {
    console.log(getDecodedData(), payload, "get Decode Data");
    let is_encrypted = getDecodedData();
    if (is_encrypted && payload) {
      payload = { payload: finalEncryption(payload) };
    }
  }
  return await api.get(endpoint, payload, cookies);
};

export const apiService = {
  invoiceStatusOptions: async () => {
    return getEncryptPayload("admin/invoiceStatusOptions");
  },

  updateInvoiceStatus: async (payload) => {
    return postEncryptPayload("admin/updateInvoiceStatus", payload);
  },

  generateResetToken: async (payload) => {
    return postEncryptPayload("/auth/generateResetToken", payload);
  },

  verifyResetToken: async (payload) => {
    return postEncryptPayload("/auth/verifyResetToken", payload);
  },

  updatePassword: async (payload) => {
    return postEncryptPayload("/auth/updatePassword", payload);
  },

  updateQRReport: async (payload) => {
    return postEncryptPayload("/dealer/updateQRReport", payload);
  },

  makePayment: async () => {
    return postEncryptPayload("/dealer/generateChecksum");
  },

  requestPolicyCancellation: async (payload) => {
    const config = { headers: { "content-type": "multipart/form-data" } };
    return postEncryptPayload(
      "/dealer/requestPolicyCancellation",
      payload,
      config
    );
  },

  downloadExcel: async (payload) => {
    payload = finalEncryption(payload);
    return postEncryptPayload("/dealer/downloadExcel", payload, {
      responseType: "blob", // Important to handle the file download
    });
  },

  getPolicySummary: async (policyNumber) => {
    return getEncryptPayload(`/dealer/policySummary/${policyNumber}`);
  },
  getDealerInfo: async () => {
    return getEncryptPayload("/auth/dealerInfo");
  },
  getRoles: async () => {
    return getEncryptPayload("/auth/roles");
  },

  getCompanyTypes: async () => {
    return getEncryptPayload("/common/companyTypes");
  },

  getIcOptions: async () => {
    return await api.get("/common/getIcs");
  },

  getAccountTypes: async () => {
    return getEncryptPayload("/common/accountTypes");
  },

  getSalutationOptions: async () => {
    return getEncryptPayload("/common/salutations");
  },

  getPolicyHolderTypes: async () => {
    return getEncryptPayload("/common/policyHolderTypes");
  },

  getBankNames: async () => {
    return getEncryptPayload("/common/bankNames");
  },

  getIcs: async (payload) => {
    return await api.post("common/getIcs", payload);
  },

  location: async (payload) => {
    return postEncryptPayload("common/location", { pincode: payload });
  },
  ifscCode: async (ifsc) => {
    return getEncryptPayload(`/common/ifscCode/${ifsc}`);
  },
  gender: async () => {
    return getEncryptPayload("/common/gender");
  },
  vehicleTypes: async (payload) => {
    return postEncryptPayload("/common/vehicleTypes", payload);
  },
  getMakes: async () => {
    return getEncryptPayload("/common/getMakes");
  },
  getModels: async (payload) => {
    return postEncryptPayload("/dealer/getModels", payload);
  },
  generatePolicy: async (payload) => {
    return postEncryptPayload("/dealer/generatePolicy", { ...payload });
  },

  getEmailAndMobile: async () => {
    return postEncryptPayload("/auth/getEmailAndMobile");
  },

  //Login Page
  login: async (request) => {
    return postEncryptPayload("/auth/login", request, {
      withCredentials: true,
    });
  },

  sendOTP: async (payload) => {
    return postEncryptPayload("/auth/sendOTP", payload, {
      withCredentials: true,
    });
  },

  verifyOTP: async (payload) => {
    return postEncryptPayload("/auth/verifyOTP", payload, {
      withCredentials: true,
    });
  },

  logout: async () => {
    return postEncryptPayload("/auth/logout", {}, { withCredentials: true });
  },

  refreshAccessToken: async (refreshToken) => {
    const payload = {
      refreshToken,
    };
    const response = await api.post("/auth/refresh-token", payload);
    const newAccessToken = response.data.accessToken;

    service.setSessionStorageItem("accessToken", newAccessToken);

    return newAccessToken;
  },

  // Generate Policy Page
  getPlans: async (payload) => {
    return postEncryptPayload("/dealer/getPlans", payload);
  },

  policyData: async (payload) => {
    return postEncryptPayload("/dealer/policyData", payload);
  },

  dealerTransactionStatement: async (payload) => {
    return postEncryptPayload("/dealer/dealerTransactionStatement", payload);
  },

  viewDealerDocs: async (payload) => {
    return postEncryptPayload("/dealerProfile/viewDealerDocs", payload);
  },

  listDealerDocs: async (payload) => {
    return postEncryptPayload("/dealer/listDealerDocs", payload);
  },

  search: async (payload) => {
    return postEncryptPayload("/dealer/search", payload);
  },

  uploadDealerDocuments: async (payload) => {
    const config = { headers: { "content-type": "multipart/form-data" } };
    return postEncryptPayload("/dealer/uploadDealerDocuments", payload, config);
  },
  bankTransaction: async (payload) => {
    return postEncryptPayload("/dealer/bankTransaction", payload);
  },

  registerDealer: async (payload) => {
    return postEncryptPayload("/dealer/registerDealer", payload);
  },

  getFile: async () => {
    return getEncryptPayload("getFile");
  },

  downloadPolicyPdf: async (policyNumber) => {
    return getEncryptPayload(`/dealer/downloadPolicy/${policyNumber}`, {
      responseType: "blob",
    });
  },

  downloadInvoicePdf: async (payload) => {
    return postEncryptPayload("/dealer/downloadInvoice", payload, {
      responseType: "blob",
    });
  },

  sidebar: async () => {
    return getEncryptPayload("/common/sidebar");
  },

  bankTransactionRequests: async (payload) => {
    return postEncryptPayload("/dealer/bankTransactionRequests", payload);
  },
  relationships: async () => {
    return getEncryptPayload("/common/relationships");
  },
  invoiceSummary: async (payload) => {
    return postEncryptPayload("/dealer/invoiceSummary", payload);
  },
  invoice: async (payload) => {
    return postEncryptPayload("/dealer/invoice", payload);
  },
  generateInvoice: async (payload) => {
    return postEncryptPayload("/dealer/generateInvoice", payload);
  },
  gstTransactions: async (payload) => {
    return postEncryptPayload("/dealer/gstTransactions", payload);
  },
  uploadGSTFile: async (payload) => {
    const config = { headers: { "content-type": "multipart/form-data" } };
    return postEncryptPayload(
      "/gstTransactions/uploadGSTFile",
      payload,
      config
    );
  },
  transactionReport: async (payload) => {
    return postEncryptPayload("/dealer/transactionReport", payload);
  },
  qrReport: async () => {
    return postEncryptPayload("/dealer/qrReport");
  },

  // ADMIN
  soldPolicies: async (payload) => {
    return postEncryptPayload("/admin/soldPolicies", payload);
  },

  dealerListings: async (payload) => {
    return postEncryptPayload("/admin/dealerListings", payload);
  },

  gstListings: async (payload) => {
    return postEncryptPayload("/admin/gstListings", payload);
  },

  updateGstApprovalStatus: async (paylaod) => {
    return postEncryptPayload("/admin/updateGstApprovalStatus", paylaod);
  },

  gstStatusOptions: async (payload) => {
    return getEncryptPayload("/admin/gstStatusOptions", payload);
  },

  searchOptions: async (payload) => {
    return postEncryptPayload("/admin/searchOptions", payload);
  },

  rmPolicies: async (payload) => {
    return postEncryptPayload("/admin/rmData", payload);
  },

  fetchPincode: async (payload) => {
    return postEncryptPayload("/common/pincode", payload);
  },

  fetchState: async (payload) => {
    return getEncryptPayload("/common/state", payload);
  },

  fetchCity: async (stateId) => {
    return getEncryptPayload(`/common/city/${stateId}`);
  },

  addPincode: async (payload) => {
    return postEncryptPayload("/admin/addPincode", payload);
  },

  icPayments: async (payload) => {
    return postEncryptPayload("/admin/icPayments", payload);
  },

  addIcPayment: async (payload) => {
    return postEncryptPayload("/admin/addICPayment", payload);
  },

  downloadFeedFile: async () => {
    return postEncryptPayload("/admin/downloadFeedFile");
  },
  cancelPolicies: async (payload) => {
    return postEncryptPayload("/admin/cancelPolicies", payload);
  },
  approvePolicyCancellationRequest: async (payload) => {
    return postEncryptPayload(
      "/admin/approvePolicyCancellationRequest",
      payload
    );
  },
  rejectPolicyCancellationRequest: async (payload) => {
    return postEncryptPayload(
      "/dealer/rejectPolicyCancellationRequest",
      payload
    );
  },
  dealerTypeWiseTargetVsAchievementReport: async (payload) => {
    return postEncryptPayload(
      "/reports/dealerTypeWiseTargetVsAchievementReport",
      payload
    );
  },
  planWiseSalesReport: async (payload) => {
    return postEncryptPayload("/reports/planWiseSalesReport", payload);
  },
  categoryWiseDailyReport: async (payload) => {
    return postEncryptPayload("/reports/categoryWiseDailyReport", payload);
  },

  planWiseDailyReport: async (payload) => {
    return postEncryptPayload("/reports/planWiseDailyReport", payload);
  },
  dailyTargetVsAchievementReport: async (payload) => {
    return postEncryptPayload(
      "/reports/dailyTargetVsAchievementReport",
      payload
    );
  },
  dealerTypeWiseRSAStatsReport: async (payload) => {
    return postEncryptPayload("/reports/dealerTypeWiseRSAStatsReport", payload);
  },
  zoneOptions: async () => {
    return postEncryptPayload("/common/zoneOptions");
  },
  areaOptions: async (payload) => {
    return postEncryptPayload("/common/areaOptions", payload);
  },
  loggedInUserCount: async () => {
    return postEncryptPayload("/reports/loggedInUserCount");
  },
  totalSoldPoliciesCount: async (payload) => {
    return postEncryptPayload("/reports/totalSoldPoliciesCount", payload);
  },
  dealerTotalSoldPoliciesCount: async (payload) => {
    return postEncryptPayload("/reports/totalSoldPoliciesCount", payload);
  },
  activeUsersCount: async (payload) => {
    return postEncryptPayload("/reports/activeUsersCount", payload);
  },
  topDealers: async (payload) => {
    return postEncryptPayload("/reports/topDealers", payload);
  },
  modelWiseReport: async (payload) => {
    return postEncryptPayload("/reports/modelWiseReport", payload);
  },
  channelBasedVolumeReport: async (payload) => {
    return postEncryptPayload("/reports/channelBasedVolumeReport", payload);
  },
  serviceTargetVsAchievement: async (payload) => {
    return postEncryptPayload("/reports/serviceTargetVsAchievement", payload);
  },
  renewedPoliciesCount: async (payload) => {
    return postEncryptPayload("/reports/renewedPoliciesCount", payload);
  },

  zoneId: async (payload) => {
    return postEncryptPayload("/common/zoneId", payload);
  },
  makeUPIDeposit: async (payload) => {
    return postEncryptPayload("/dealer/makeUPIPayment", payload);
  },
  getWalletBalance: async () => {
    return getEncryptPayload("/dealer/getWalletBalance");
  },
  modelTypeCategoryOptions: async () => {
    return getEncryptPayload("/common/modelTypeCategoryOptions");
  },
  soldPoliciesChart: async (payload) => {
    return postEncryptPayload("/reports/soldPoliciesChart", payload);
  },

  topDealersByModelType: async (payload) => {
    return postEncryptPayload("/reports/getTopDealersByModelType", payload);
  },

  getHourlyReport: async (payload) => {
    return postEncryptPayload("/reports/getHourlyReport", payload);
  },

  topDealersOfMyZone: async () => {
    return postEncryptPayload("/reports/topDealersOfMyZone");
  },
  policyCancellationRequests: async (payload) => {
    return postEncryptPayload("/dealer/policyCancellationRequests", payload);
  },
  transactionTypeOptions: async () => {
    return getEncryptPayload("/dealer/transactionTypeOptions");
  },
  transactionPurposeOptions: async () => {
    return getEncryptPayload("/dealer/transactionPurposeOptions");
  },
  policyStatusOptions: async () => {
    return getEncryptPayload("/dealer/policyStatusOptions");
  },
  policyCancellationStatusOptions: async () => {
    return getEncryptPayload("/dealer/policyCancellationStatusOptions");
  },
  transactionRequestStatusOptions: async () => {
    return getEncryptPayload("/dealer/transactionRequestStatusOptions");
  },
  gstApprovalStatusOptions: async () => {
    return getEncryptPayload("/dealer/gstApprovalStatusOptions");
  },
  addDealer: async (payload) => {
    return await api.post("/admin/addDealer", payload);
  },
};
