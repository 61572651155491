import React, { useEffect, useRef, useState } from "react";
import coverImage from "../assets/img/coverImage.png";
import Input from "../components/ui/Input";
import Button from "../components/ui/Button";
import { useFormik } from "formik";
import { apiService } from "../api.service";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { service } from "../service";
import { useGlobalContext } from "../context/global.context";

const initialTime = 60;
const OTPVerification = () => {
  const { setMessage, setError } = useGlobalContext();
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [timeLeft, setTimeLeft] = useState(initialTime);
  const [canResend, setCanResend] = useState(false);
  const mobileInputRefs = useRef([]);
  const emailInputRefs = useRef([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const formik = useFormik({
    initialValues: {
      motp1: "",
      motp2: "",
      motp3: "",
      motp4: "",
      motp5: "",
      motp6: "",
      eotp1: "",
      eotp2: "",
      eotp3: "",
      eotp4: "",
      eotp5: "",
      eotp6: "",
    },
    onSubmit: (values) => {
      console.log(values);

      setIsSubmitting(true);
      const payload = {
        mobileOTP: `${values.motp1}${values.motp2}${values.motp3}${values.motp4}${values.motp5}${values.motp6}`,
        emailOTP: `${values.eotp1}${values.eotp2}${values.eotp3}${values.eotp4}${values.eotp5}${values.eotp6}`,
      };
      const loadingToast = toast.loading("Loading...");
      apiService
        .verifyOTP(payload)
        .then((res) => {
          navigate(res.data.navigateTo);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          service.notifyApiError(error, setMessage, setError);
        })
        .finally(() => {
          setIsSubmitting(false);
          toast.dismiss(loadingToast);
        });
    },
  });

  // Function to handle input change and focus management for both mobile and email
  // const handleChange = (e, index, type) => {
  //     console.log(mobileInputRefs.current[0]);
  //     mobileInputRefs.current[5].focus()

  //     const { value } = e.target;
  //     const sanitizedValue = value.slice(0, 1); // Ensure only one character

  //     console.log(sanitizedValue);

  //     if (type === 'mobile') {
  //         formik.setFieldValue(`motp${index}`, sanitizedValue);
  //     } else {
  //         formik.setFieldValue(`eotp${index}`, sanitizedValue);
  //     }

  //     // Move focus to the next input if the current value is not empty
  //     if (sanitizedValue && index < (type === 'mobile' ? mobileInputRefs.current.length - 1 : emailInputRefs.current.length - 1)) {
  //         (type === 'mobile' ? mobileInputRefs.current[index + 1].focus() : emailInputRefs.current[index + 1]).focus();
  //     }
  // };

  const handleChange = (e, index, type) => {
    const { value } = e.target;
    const sanitizedValue = value.slice(0, 1); // Ensure only one character

    // Update Formik value based on the type
    if (type === "mobile") {
      formik.setFieldValue(`motp${index}`, sanitizedValue);
    } else {
      formik.setFieldValue(`eotp${index}`, sanitizedValue);
    }

    // Move focus to the next input if the current value is not empty and within bounds
    const refs = type === "mobile" ? mobileInputRefs : emailInputRefs;
    // let refs
    // switch (type) {
    //     case 'mobile': refs = mobileInputRefs.current; break;
    //     case 'email': refs = emailInputRefs.current; break;
    // }

    if (sanitizedValue && index < refs.current.length - 1) {
      refs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index, type) => {
    let refs;
    switch (type) {
      case "mobile":
        refs = mobileInputRefs;
        break;
      case "email":
        refs = emailInputRefs;
        break;
    }
    if (e.key === "Backspace") {
      if (!e.target.value && index > 0) {
        refs.current[index - 1].focus();
      }
    }
  };

  // Function to handle backspace and focus management for both mobile and email
  // const handleKeyDown = (e, index, type) => {
  //     console.log(mobileInputRefs.current[0]);

  //     if (e.key === 'Backspace') {
  //         if (!e.target.value && index > 0) {
  //             (type === 'mobile' ? mobileInputRefs.current[index - 1].focus() : emailInputRefs.current[index - 1]).focus();
  //         }
  //     }
  // };

  const handleResendCode = () => {
    const loadingToast = toast.loading("Loading data...");
    apiService
      .sendOTP()
      .then((res) => {
        formik.resetForm();
        setTimeLeft(60);
        handleTimeout();
        setCanResend(false);
        toast.success(res.data.message);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
        service.notifyApiError(error, setMessage, setError);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message); // Show error toast with the error message
        } else {
          toast.error("An error occurred. Please try again."); // Fallback error message
        }
      })
      .finally(() => {
        toast.dismiss(loadingToast);
      });
  };

  const getEmailAndMobile = () => {
    const loadingToast = toast.loading("Loading data...");
    try {
      apiService.getEmailAndMobile().then((res) => {
        setEmail(res.data.data.email);
        setMobile(res.data.data.mobile);
      });
    } catch (error) {
      toast.error(error.response.data.message);
      service.notifyApiError(error, setMessage, setError);
    } finally {
      toast.dismiss(loadingToast);
    }
  };

  const handleTimeout = () => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer); // Clear the timeout if the component unmounts
    } else {
      setCanResend(true);
    }
  };

  const handleSameOTP = (e) => {
    const isSameOTP = e.target.checked;
    if (isSameOTP) {
      formik.setFieldValue("eotp1", formik.getFieldProps("motp1").value);
      formik.setFieldValue("eotp2", formik.getFieldProps("motp2").value);
      formik.setFieldValue("eotp3", formik.getFieldProps("motp3").value);
      formik.setFieldValue("eotp4", formik.getFieldProps("motp4").value);
      formik.setFieldValue("eotp5", formik.getFieldProps("motp5").value);
      formik.setFieldValue("eotp6", formik.getFieldProps("motp6").value);
    } else {
      formik.setFieldValue("eotp1", "");
      formik.setFieldValue("eotp2", "");
      formik.setFieldValue("eotp3", "");
      formik.setFieldValue("eotp4", "");
      formik.setFieldValue("eotp5", "");
      formik.setFieldValue("eotp6", "");
    }
  };

  useEffect(() => {
    getEmailAndMobile();
  }, []);

  useEffect(() => {
    mobileInputRefs.current = Array(6)
      .fill()
      .map((_, i) => mobileInputRefs.current[i] || React.createRef());
    emailInputRefs.current = Array(6)
      .fill()
      .map((_, i) => emailInputRefs.current[i] || React.createRef());
  }, []);

  useEffect(() => {
    handleTimeout();
  }, [timeLeft]);

  return (
    <div className="flex flex-col w-full items-center overflow-hidden">
      <div className="sticky -z-10 w-full">
        <img
          src={coverImage}
          className="w-full h-36 object-cover"
          alt="cover_image"
        />
      </div>
      <div className="md:w-1/2 w-4/5 bg-white -mt-16 md:-mt-20 border border-neutral-light rounded mx-2">
        <form onSubmit={formik.handleSubmit}>
          <div>
            <div className="bg-base-white px-6 py-4 border-b border-neutral-light rounded-t">
              <h3 className="text-xl font-semibold">OTP Verification</h3>
              <p className="text-neutral-darker mt-1 text-sm">
                Enter the code sent to mobile and email to verify your identity
              </p>
            </div>
            <div className="px-6 py-4 pb-8">
              <div className="flex flex-col space-y-4">
                <div className="md:flex md:flex-row flex-col justify-around w-full">
                  <div className="flex flex-col items-center md:space-y-2 space-y-1">
                    <span className="font-semibold">Mobile</span>
                    <span className="text-sm">
                      Enter OTP sent to +91 {mobile}
                    </span>
                    <div className="flex md:space-x-4 space-x-2">
                      {Array.from({ length: 6 }).map((_, index) => (
                        <div
                          key={`mobile-${index + 1}`}
                          className="flex flex-col items-center space-y-2"
                        >
                          <label
                            htmlFor={`mobile-otp${index + 1}`}
                            className="sr-only"
                          >
                            Mobile OTP {index + 1}
                          </label>
                          <Input
                            formik={formik}
                            value={formik.values[`motp${index + 1}`]}
                            id={`mobile-otp${index + 1}`}
                            name={`motp${index + 1}`}
                            type="text"
                            placeholder="-"
                            onInputChange={(e) =>
                              handleChange(e, index + 1, "mobile")
                            }
                            onInputKeyDown={(e) =>
                              handleKeyDown(e, index + 1, "mobile")
                            }
                            ref={(el) =>
                              (mobileInputRefs.current[index + 1] = el)
                            }
                            className="min-w-8 w-9"
                            maxLength={1}
                            autocomplete="off"
                          />
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="flex flex-col items-center md:space-y-2 space-y-1">
                    <span className="font-semibold">Email</span>
                    <span className="text-sm">Enter OTP sent to {email}</span>
                    <div className="flex md:space-x-4 space-x-2">
                      {Array.from({ length: 6 }).map((_, index) => (
                        <div
                          key={`email-${index + 1}`}
                          className="flex flex-col items-center space-y-2"
                        >
                          <label
                            htmlFor={`email-otp${index + 1}`}
                            className="sr-only"
                          >
                            Email OTP {index + 1}
                          </label>
                          <Input
                            formik={formik}
                            value={formik.values[`eotp${index + 1}`]}
                            id={`email-otp${index + 1}`}
                            name={`eotp${index + 1}`}
                            type="text"
                            placeholder="-"
                            onInputChange={(e) =>
                              handleChange(e, index + 1, "email")
                            }
                            onInputKeyDown={(e) =>
                              handleKeyDown(e, index + 1, "email")
                            }
                            ref={(el) =>
                              (emailInputRefs.current[index + 1] = el)
                            }
                            className="min-w-8 w-9"
                            maxLength={1}
                            autocomplete="off"
                          />
                        </div>
                      ))}
                    </div>
                    {/* <div className="flex space-x-4">
                                            
                                            {Array.from({ length: 6 }).map((_, index) => (
                                                <div key={`email-${index + 1}`} className="flex flex-col items-center space-y-2">
                                                    <label htmlFor={`email-otp${index + 1}`} className="sr-only">Email OTP {index + 1}</label>
                                                    <Input
                                                        formik={formik}
                                                        value={formik.values[`eotp${index}`]}
                                                        id={`email-otp${index + 1}`}
                                                        name={`eotp${index + 1}`}
                                                        type="text"
                                                        placeholder="-"
                                                        onInputChange={(e) => handleChange(e, index + 1, 'email')}
                                                        onInputKeyDown={(e) => handleKeyDown(e, index + 1, 'email')}
                                                        ref={(el) => (emailInputRefs.current[index + 1] = el)}
                                                        className="min-w-8 w-9"
                                                        maxLength={1}
                                                        autocomplete='off'
                                                    />
                                                </div>
                                            ))}
                                        </div> */}
                  </div>
                </div>
                <div className="flex flex-col space-y-2 items-center">
                  <div className="text-sm flex space-x-2">
                    <input type="checkbox" onClick={handleSameOTP} />
                    <span>Same OTP</span>
                  </div>
                  <Button
                    type="submit"
                    label={isSubmitting ? "Verifying..." : "Verify OTP"}
                    variant="primary"
                    className="min-w-60 w-60 sm:w-auto px-3 py-1"
                    disabled={isSubmitting}
                  />
                  {!canResend && (
                    <span className="text-xs">
                      Resend OTP in {timeLeft} seconds
                    </span>
                  )}
                  {canResend && (
                    <span className="text-xs">
                      Didn't receive OTP code?{" "}
                      <button
                        type="button"
                        onClick={handleResendCode}
                        disabled={!canResend}
                        className="font-semibold underline text-primary"
                      >
                        Resend Code
                      </button>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default OTPVerification;
